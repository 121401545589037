import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({name: 'linkify'})
export class LinkifyPipe implements PipeTransform {

  constructor(
    private domSanitizer: DomSanitizer
  ) {
  }

  transform(value: any, args?: any): any {
    return this.domSanitizer.bypassSecurityTrustHtml(this.stylize(value));
  }

  // Modify this method according to your custom logic
  private stylize(text: string): string {
    let stylizedText = '';
    const regex = '^(https?:\\/\\/)?([\\da-z\\.-]+)\\.([a-z\\.]{2,6})([\\/\\w \\.-]*)*\\/?$';
    if (text && text.length > 0) {
      for (const line of text.split('\n')) {
        for (const t of line.split(' ')) {
          if (t.match(regex) && t.length > 7) {
            stylizedText += `<a style="color: var(--color-primary)" href="${t}">${t}</a> `;
          } else {
            stylizedText += t + ' ';
          }
        }
        stylizedText += '<br>';
      }
      return stylizedText;
    } else {
      return text;
    }
  }

}
