<form [formGroup]="parentForm">
  <fieldset
    class="fieldset-dropdown {{customClass}}"
    [ngClass]="{
    'filled': onFilled,
    'error': shouldShowErrors(),
    'helper': helperText.length > 1,
    'disabled': readonly,
    'success': success,
    'validate': loading,
    'bg-light-in-mobile': bgLightMobile,
    'bg-light-in-desktop': bgLightDesktop,
    'size-large-mobile': largeMobile,
    'size-large-desktop': largeDesktop,
    'size-large': large
  }"
  >
    <div class="field__dropdown">
      <label class="label">{{label}}</label>
      <p-dropdown
        id="dropdown"
        class="bid-dropdown"
        [filter]="false"
        [showClear]="true"
        [disabled]="loading"
        (click)="onFocusInput()"
        (blur)="onBlurInput()"
        [virtualScroll]="hasVirtualScroll"
        [virtualScrollItemSize]="16"
        [optionLabel]="optionLabel"
        [optionValue]="optionValue"
        placeholder="''"
        [options]="options"
        (onChange)="onChange($event)"
        [formControlName]="ctrlName"
      ></p-dropdown>
      <i class="fa-solid fa-triangle-exclamation text-error text-icon" *ngIf="withIcon && shouldShowErrors()"></i>
      <i class="fa-solid fa-circle-check text-success text-icon" *ngIf="success"></i>
      <i class="fa-light fa-spinner-third spinner-icon" *ngIf="loading"></i>
    </div>
    <p class="hint" *ngIf="hint && shouldShowErrors()">
      <span *ngFor="let error of listOfErrors()">{{error}}</span>
    </p>
  </fieldset>
</form>
