import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  constructor() {
  }

  getBrandName(): string {
    return environment.brandParams.name ? environment.brandParams.name : 'Black iD';
  }

  getBrandLogo(): string {
    return environment.brandParams.logo.name ? environment.brandParams.logo.name : 'logo.svg';
  }

  getBrandUrlLink(): string {
    return environment.brandParams.logo.urlLink ? environment.brandParams.logo.urlLink : '';

  }
}
