<main [ngClass]="withModalDownload ? 'pb-75' : ''" itemscope itemtype="https://schema.org/Event">
  <app-card-info-event data-aos="fade-in" [eventData]="event" [skeletonLoading]="skeletonLoading"
                       (closeButton)="getDataCardComponent($event)"
                       (geoLocation)="goToGeolocation($event)"
                       (backButton)="back($event)"></app-card-info-event>

  <section class="event-details" data-aos="fade-in">
    <div class="wrapper">
      <div class="event-details__description" data-aos="fade-in">
        <p class="text-base font-normal lh-24 text-gray-2 my-0 pre-wrap description" itemprop="description"
           *ngIf="!skeletonLoading" [innerHTML]="event?.eventDescription | linkify"></p>
        <app-skeleton-description *ngIf="skeletonLoading"></app-skeleton-description>
      </div>

      <div class="event-details__buy" data-aos="fade-in">

        <div class="btns__buy" *ngIf="!event?.soldOut"
             [ngClass]="event?.tableTypes.length > 0 && event?.accessTypes.length > 0 ? 'all-buy-types' : 'single-buy-types'">
          <div class="btn__buy-ticket"
               [ngClass]="{'disabled': event?.soldOut, 'single-type': event?.accessTypes.length === 0}"
               *ngIf="!skeletonLoading && event?.tableTypes.length > 0">
            <div class="btns__buy--header">
              <div class="col-icon">
                <i class="fa-light fa-champagne-glasses"></i>
                <p class="font-secondary font-semibold text-gray-1 my-0 title"
                   [ngClass]="event?.soldOut ? 'text-gray-4' : 'text-gray-1'" i18n>Combos / Mesas</p>
              </div>
              <div class="col-pricing" *ngIf="getMinPriceTable() !== null else noPrice">
                <i class="fa-light fa-circle-dollar margin-right-8 margin-bottom-0 text-gray-1"></i>
                <p class="font-normal text-gray-2 my-0 since"
                   [ngClass]="event?.soldOut ? 'text-gray-4' : 'text-gray-2'">
                  <ng-container i18n>Desde {{currency}}</ng-container>
                  {{ getMinPriceTable() | number:'1.0-2' }}
                </p>
              </div>
              <ng-template #noPrice class="col-pricing">
                <div class="col-pricing">
                  <i class="fa-light fa-circle-dollar margin-right-8 margin-bottom-0 text-gray-1"></i>
                  <p class="font-normal text-gray-2 my-0 since">
                    <ng-container i18n>A confirmar</ng-container>
                  </p>
                </div>
              </ng-template>

            </div>
            <div class="btns__buy--buttons">
              <button class="btn btn__secondary btn__sm" [disabled]="event.soldOut" (click)="showInfoModal('T')" i18n>
                Ver info
              </button>
              <button class="btn btn__primary btn__sm" [disabled]="event.soldOut" (click)="buyTicket('T')" i18n>
                Comprar
              </button>
            </div>
          </div>
          <div class="btn__buy-ticket"
               [ngClass]="{'disabled': event?.soldOut, 'single-type': event?.tableTypes.length === 0}"
               *ngIf="!skeletonLoading && event?.accessTypes.length > 0">
            <div class="btns__buy--header">
              <div class="col-icon">
                <i class="fa-light fa-ticket"></i>
                <p class="font-secondary font-semibold text-gray-1 my-0 title"
                   [ngClass]="event?.soldOut ? 'text-gray-4' : 'text-gray-1'" i18n>Tickets</p>
              </div>
              <div class="col-pricing" *ngIf="getMinPriceAccess() !== null else noPrice">
                <i class="fa-light fa-circle-dollar margin-right-8 margin-bottom-0 text-gray-1"></i>
                <p class="text-sm lh-24 font-normal text-gray-2 my-0 since"
                   [ngClass]="event?.soldOut ? 'text-gray-4' : 'text-gray-2'">
                  <ng-container i18n>Desde {{currency}}</ng-container>
                  {{ getMinPriceAccess() | number:'1.0-2' }}
                </p>
              </div>
              <ng-template #noPrice>
                <div class="col-pricing">
                  <i class="fa-light fa-circle-dollar margin-right-8 margin-bottom-0 text-gray-1"></i>
                  <p class="font-normal text-gray-2 my-0 since">
                    <ng-container i18n>A confirmar</ng-container>
                  </p>
                </div>
              </ng-template>
            </div>
            <div class="btns__buy--buttons">
              <button class="btn btn__secondary btn__sm" [disabled]="event.soldOut" (click)="showInfoModal('A')" i18n>
                Ver info
              </button>
              <button class="btn btn__primary btn__sm" [disabled]="event.soldOut" (click)="buyTicket('A')" i18n>
                Comprar
              </button>
            </div>
          </div>
          <div class="skeleton__buy" *ngIf="skeletonLoading">
            <div class="skeleton__buy--title"></div>
            <div class="skeleton__buy--price"></div>
          </div>
        </div>

        <p class="info__buy" data-aos="fade-in"
           *ngIf="!skeletonLoading && !event?.soldOut && (event?.tableTypes.length > 0 || event?.accessTypes.length > 0)"
           i18n>
          Al valor indicado, se sumará el costo por servicio. En caso de reembolso, dicho costo no será reintegrado.</p>
        <button class="button__buy-ticket-on-resale" (click)="goToTicketOnResale()" *ngIf="canShowResaleButton()">
          <i
            class="fas fa-money-bill-transfer text-primary font-size-24 font-weight-900 line-height-40 margin-right-16">
          </i>
          <span class="font-size-18 lh-18 text-gray-1" i18n>Comprar tickets en reventa</span>
        </button>
      </div>

    </div>
  </section>
</main>

<app-modal-image
  [image]="imageEvent"
  [showModal]="showImage"
  (closeButtonEvent)="getDataModalImage($event)">
</app-modal-image>

<app-modal-must-signin
  [showModal]="showModalActionLogin"
  (closeModal)="closeModalLogin()"
  (actionModal)="onActionToLogin()"
></app-modal-must-signin>

<app-modal-info-type
  *ngIf="event"
  [showModal]="showInfoTypeTicketModal"
  [eventId]="event.idEvent"
  [typeInfoSelected]="typeInfoSelected"
  (closeButtonEvent)="onCloseButtonEvent()"
  (closeAndNavigateTo)="buyTicket($event)"
  (showLoginModal)="onShowLoginModal()"
>
</app-modal-info-type>

<app-modal-action [showModal]="showNeedVerifiedAccount" [title]="errorAccountTitle" [content]="errorAccountContent"
                  [textBtn]="'Aceptar'" [iconClass]="errorAccountIcon" [imageName]="errorAccountImage"
                  (closeButtonEvent)="onNeedVerifiedAccount($event)"
                  (action)="onNeedVerifiedAccount($event)"></app-modal-action>

<app-modal-action [showModal]="showErrorModal" [title]="errorTitle" [content]="errorContent" [textBtn]="'Aceptar'"
                  [iconClass]="errorIcon" (closeButtonEvent)="onActionErrorModal($event)"
                  (action)="onActionErrorModal($event)"></app-modal-action>

<app-modal-action [showModal]="showIncorrectPromoCodeModal" [title]="'Código caducado'"
                  [content]="'El código promocional no se encuentra disponible para este evento.'"
                  [textBtn]="'Entendido'" [showHeader]="false"
                  [iconClass]="'fal fa-exclamation-circle text-12xl'"
                  (closeButtonEvent)="onActionIncorrectPromoCodeModal()"
                  (action)="onActionIncorrectPromoCodeModal()">
</app-modal-action>
