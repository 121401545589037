<section class="login-container">
  <div class="card" data-aos="fade-in">
    <div class="card__left">
      <p class="font-secondary lg-24 text-gray-1 font-weight-600 hi-again" i18n [ngClass]="{'with-sub': isWhiteLabel}">
        ¡Hola de nuevo!</p>
      <span *ngIf="isWhiteLabel" class="bid-reminder" i18n>
        Recordá que si ya tenés cuenta de BLACK iD, no es necesario que crees una nueva. Ingresá con tus datos.
      </span>
      <form class="form" [formGroup]="loginForm" (ngSubmit)="login()">
        <bid-input
          label="Email"
          i18n-label
          type="email"
          name="email"
          page="register"
          [largeMobile]="true"
          [preventSpace]="true"
          [readonly]="isReadOnly"
          [onFocus]="true"
          [bgLightDesktop]="true"
          [ctrl]="loginForm.get('email') | formControl"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [value]="loginForm.get('email').value"
          (data)="handleDataEmail($event)"
          (focusout)="focusOutFn()"
        ></bid-input>
        <bid-input-password
          label="Contraseña"
          i18n-label
          type="password"
          name="password"
          page="register"
          [controlName]="'password'"
          [parentForm]="loginForm"
          [readonly]="isReadOnly"
          [largeMobile]="true"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [bgLightDesktop]="true"
          [onError]="false"
          customClass="margin-bottom-24"
        ></bid-input-password>

        <div class="text-primary font-size-14 lg-24 font-weight-500 cursor-pointer">
          <a class="link" (click)="forgotPassword()" i18n>Olvidé mi contraseña</a>
        </div>
        <button
          type="submit"
          [disabled]="!loginForm.valid"
          class="btn btn__md btn__login btn__primary card__signup margin-top-40"
          i18n
        >Iniciar sesión
        </button>

        <app-form-error-message
          [showError]="showError"
          [contentError]="contentError"
          (close)="onCloseFormErrorMessage($event)"
        ></app-form-error-message>
      </form>

      <div class="margin-top-40">
        <app-external-login *ngIf="!showError" (externalLogin)="onExternalLogin($event)"></app-external-login>
      </div>

      <div class="card__no-account margin-top-48">
        <span class="font-weight-400 font-size-16 lg-16 lg-24 text-gray-1 margin-right-20"
              i18n>¿No tenés una cuenta?</span>
        <span class="font-weight-500 font-size-14 lg-16 lg-24 text-primary" (click)="signup()"
              i18n>Registrate gratis</span>
      </div>
    </div>

    <div class="card__right">
      <div *ngIf="!isWhiteLabel">
        <app-download-qr></app-download-qr>
      </div>
      <div *ngIf="isWhiteLabel" class="bid-account">
        <i class="fa-light fa-circle-user"></i>
        <span class="title" i18n>¿Ya tenés una cuenta de BLACK iD?</span>
        <span class="subtitle" i18n>Ingresá y gestioná todo con esa cuenta. No es necesario que crees una nueva.</span>
      </div>

      <div class="margin-top-40">
        <span class="display-block font-secondary font-weight-600 font-size-16 lg-24 text-gray-1 text-center">
          <span i18n>¿Todavía no tenés tu cuenta de </span><span>BLACK iD</span>?
        </span>
        <div class="btn btn__secondary btn__md margin-top-24" (click)="signup()" i18n>Me quiero registrar</div>
      </div>
    </div>
  </div>

</section>
