<div class="modal" [ngClass]="showModal ? 'show__modal' : 'close__modal'">
  <div class="modal__container">
    <div class="modal__header" *ngIf="showHeader">
      <button class="btn__close" (click)="closeModal()">
        <i class="fa-regular fa-xmark icon__close"></i>
      </button>
    </div>
    <div class="modal__body">
      <ng-container *ngIf="iconClass"><i class="icon {{iconClass}}"></i></ng-container>
      <ng-container *ngIf="imageName"><img src="../../../assets/images/{{imageName}}" class="icon-info" alt=""
                                           *ngIf="!iconClass"></ng-container>
      <p class="font-secondary text-xl lh-26 text-gray-1 font-semibold mt-6 mb-2">{{ title }}</p>
      <div class="description">
        <p class="text-sm lh-20 font-primary text-gray-35 mt-0 mb-8">{{ content }}</p>
      </div>
      <button class="btn btn__md btn__primary button_action_download" (click)="downloadApp()" i18n>Descargar Black iD
        App
      </button>
      <button class="btn btn__md btn__primary button_action" (click)="closeModal()" i18n>Entendido</button>
      <button (click)="closeModal()" class="btn btn__md btn__terciary button_to_close" *ngIf="twoBtns" i18n>En otro
        momento
      </button>
    </div>
  </div>
</div>
