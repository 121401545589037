import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {AccessRequest} from '../models/AccessRequest';
import {CheckoutResponse} from '../models/CheckoutResponse';

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  paymentSubject: BehaviorSubject<AccessRequest> = new BehaviorSubject<AccessRequest>(new AccessRequest());
  public readonly currentPayment$: Observable<AccessRequest> = this.paymentSubject.asObservable();

  initPaymentBase: AccessRequest = {
    accessTypeId: null,
    quantity: null,
    eventId: null,
    amountTotal: null,
    ticketName: null,
    type: null,
    owner: null,
    methodPayment: null,
    paymentToken: null,
    promoId: null
  };

  currentPayment: AccessRequest = null;

  constructor(
    private httpC: HttpClient
  ) {
  }

  initPayment() {
    this.currentPayment = this.initPaymentBase;
  }

  saveFistStepDataTicket(data: AccessRequest) {
    this.currentPayment = {
      accessTypeId: data.accessTypeId,
      quantity: data.quantity,
      eventId: data.eventId,
      amountTotal: data.amountTotal,
      ticketName: data.ticketName,
      type: data.type,
      owner: null,
      methodPayment: data.methodPayment,
      paymentToken: data.paymentToken,
      promoId: null,
      free: data.free
    };
    this.paymentSubject.next(this.currentPayment);
  }

  generateCheckout(payload: AccessRequest): Observable<CheckoutResponse> {
    const {id_customer} = JSON.parse(localStorage.getItem('user'));
    return this.httpC.post<CheckoutResponse>(environment.billboardAGUrl + `/cartelera/access/billboard-web/${id_customer}`, payload);
  }

  checkIfWasIngresses(idAccess): Observable<boolean> {
    return this.httpC.get<boolean>(environment.billboardAGUrl + `/cartelera/access/app/check_ingress/${idAccess}`);
  }
}
