import * as AOS from 'aos';
import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {forkJoin, Subscription} from 'rxjs';

import {AlertService} from 'src/app/services/alert-service.service';
import {AuthService} from 'src/app/services/auth.service';
import {CustomValidationService} from '../../services/custom-validation.service';
import {EventService} from '../../services/event.service';
import {FooterService} from 'src/app/services/footer.service';
import {InputValidationService} from 'src/app/services/input-validation.service';
import {LoginForm} from 'src/app/models/LoginForm';
import {LoginService} from '../../services/login.service';
import {Router} from '@angular/router';
import {SpinnerService} from 'src/app/services/spinner.service';
import {UserResponse} from '../../models/UserResponse';
import {ExternalPlatform} from 'src/app/enum/ExternalPlatform';
import {PromotionPersist} from '../../models/PromotionPersist';
import {environment} from 'src/environments/environment';
import {EnvironmentService} from 'src/app/services/environment.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('email') email: ElementRef;
  loginForm: UntypedFormGroup;
  formHasBeenSubmitted = false;
  subs = [];
  showError = false;
  contentError = $localize`Las credenciales no son validas.`;
  detailPage = false;
  eventDetailPage = false;
  cameFromRegistrationPage = false;
  buyPage = false;
  checkoutPage = false;
  idEvent = null;
  buyType = null;
  ticketId = null;
  withCode = false;
  promoCode = null;
  hashPassword = true;
  widthWindowMobile = false;
  isReadOnly = true;
  loading = false;
  EXTERNALLOGIN = ExternalPlatform;
  promotionPersist: PromotionPersist = null;
  lastDetailEvent: number;
  whiteName = '';
  isWhiteLabel = environment.isWhiteLabel;
  showQR = false;
  invitationType = false;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private validateInputService: InputValidationService,
    private authService: AuthService,
    private loginService: LoginService,
    private spinnerSrv: SpinnerService,
    private customValidations: CustomValidationService,
    private eventService: EventService,
    private footerService: FooterService,
    private environmentService: EnvironmentService
  ) {
  }

  async ngOnInit() {
    this.whiteName = this.environmentService.getBrandName();
    this.footerService.showFooter$.next(false);
    this.loginForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
          this.customValidations.emailValidator()
        ],
      ],
      password: ['', Validators.required],
    });

    this.lastDetailEvent = JSON.parse(localStorage.getItem('lastDetailEvent'));
    this.idEvent = this.lastDetailEvent ? this.lastDetailEvent : null;

    if (this.authService.isLoggedIn()) {
      if (this.lastDetailEvent) {
        await this.router.navigate(['/detail-event/' + this.idEvent]);
      } else {
        this.router.navigate(['/']);
      }
    }

    this.subs.push(
      this.loginForm.get('email').valueChanges.subscribe({
        next: event => {
          this.loginForm.get('email').setValue(event.toLowerCase(), {emitEvent: false});
        }
      })
    );

    setTimeout(() => {
      this.isReadOnly = false;
    }, 400);

    this.subs.push(
      this.loginForm.valueChanges.subscribe({
        next: () => {
          this.formHasBeenSubmitted = false;
          this.showError = false;
        }
      })
    );

    const existPromotionPersist = sessionStorage.getItem('promotionPersist');
    if (existPromotionPersist) {
      this.promotionPersist = JSON.parse(sessionStorage.getItem('promotionPersist'));
      this.eventDetailPage = this.promotionPersist.eventDetailPage ?this.promotionPersist.eventDetailPage : false;
      this.detailPage = this.promotionPersist.detailPage ? this.promotionPersist.detailPage : false;
      this.buyPage = this.promotionPersist.buyPage ? this.promotionPersist.buyPage : false;
      this.checkoutPage = this.promotionPersist.checkoutPage ? this.promotionPersist.checkoutPage : false;
      this.cameFromRegistrationPage = this.promotionPersist.comeFromRegistration ? this.promotionPersist.comeFromRegistration : false;
      this.idEvent = this.promotionPersist.eventId ? this.promotionPersist.eventId : null;
      this.buyType = this.promotionPersist.buyType ? this.promotionPersist.buyType : null;
      this.promoCode = this.promotionPersist.promoCode ? this.promotionPersist.promoCode : null;
      this.showQR = this.promotionPersist.showQR ? this.promotionPersist.showQR : null;
      this.invitationType = this.promotionPersist.invitationType ? this.promotionPersist.invitationType : null;
    }

    AOS.init({
      duration: 800,
      once: true
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.email) {
        this.email.nativeElement.focus();
      }
    }, 100);
  }

  signup() {
    this.router.navigate(['/signup']);
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }

  focusOutFn() {
    let email = this.loginForm.get('email').value.trim();
    if (email.endsWith('.')) {
      email = email.slice(0, -1);
    }
    this.loginForm.patchValue({
      email
    });
  }

  login() {
    this.formHasBeenSubmitted = true;
    if (this.loginForm.valid) {
      this.spinnerSrv.loadSpinner.next(true);
      const form: LoginForm = this.loginForm.value;
      form.email.trim();
      form.tenant = environment.identification;
      this.subs.push(
        this.authService.login(form).subscribe({
          next: async (res: UserResponse) => {
            this.spinnerSrv.loadSpinner.next(false);
            if (res.blocked) {
              this.showError = true;
              this.contentError =
                $localize`Cuenta bloqueada. Si crees que se debe a un error, por favor comunicate con soporte.`;
            } else {
              if (res.jwt) {
                localStorage.setItem('BE-JWT', res.jwt);
                localStorage.setItem('isLoggedIn', 'true');
                sessionStorage.setItem('guestLogged', 'false');
                sessionStorage.removeItem('token');
                sessionStorage.setItem('alert-delay', 'true');
                sessionStorage.setItem('alert-current-events', 'true');
                if (this.promotionPersist) {
                  if ( this.showQR) {
                    this.router.navigate(['/home/show']);
                  } else if (this.detailPage || this.eventDetailPage) {
                    this.eventService.getEventsIdsByCustomer().subscribe({
                      next: async (ids: number[]) => {
                        if (ids && ids.length > 0) {
                          const eventIdFound = ids.find(id => id === Number(this.idEvent));
                          if (eventIdFound != null) {
                            await this.router.navigate(['/my-events/event-detail/' + this.idEvent]);
                          }
                        }
                        if (this.promoCode !== null) {
                          await this.router.navigate(['/detail-event/' + this.idEvent + '/code/' + this.promoCode]);
                        } else {
                          await this.router.navigate(['/detail-event/' + this.idEvent]);
                        }
                      }
                    });
                  } else if (this.invitationType) {
                    await this.router.navigate(['/billboard/0/code/' + this.promoCode]);
                  }
                } else {
                  if (this.lastDetailEvent) {
                    await this.router.navigate(['/detail-event/' + this.idEvent]);
                  } else {
                    this.router.navigate(['/']);
                  }
                }
              }
            }
          },
          error: (err) => {
            this.spinnerSrv.loadSpinner.next(false);
            this.showError = true;
            if (err.status === 403) {
              this.contentError = $localize`Credenciales inválidas. Intentá de nuevo.`;
            }
            if (err.status === 406) {
              this.contentError =
                $localize`Acceso bloqueado por numerosos intentos fallidos de acceso. Podrás reintentarlo en 30 segundos.`;
            }
          }
        })
      );
    }
  }

  async redirectToEventPage() {
    this.eventService.getEventsIdsByCustomer().subscribe({
      next: async (ids: number[]) => {
        if (ids && ids.length > 0) {
          const eventIdFound = ids.find(id => id === Number(this.idEvent));
          if (eventIdFound != null) {
            await this.router.navigate(['/my-events/event-detail/' + this.idEvent]);
          }
        }
        if (this.promoCode !== null) {
          await this.router.navigate(['/billboard/detail-event/' + this.idEvent + '/code/' + this.promoCode]);
        } else {
          await this.router.navigate(['/billboard/detail-event/' + this.idEvent]);
        }
      }
    });
  }

  async redirectToBuyPage() {
    this.subs.push(
      forkJoin({
        pendingEventsIds: this.eventService.getEventsIdsByCustomer()
      }).subscribe({
        next: (res) => {
          const eventIdFound = res.pendingEventsIds.find(id => id === Number(this.idEvent));
          if (eventIdFound != null) {
            this.router.navigate(['/my-events/event-detail/' + this.idEvent]);
          } else {
            if (this.promoCode !== null) {
              this.router.navigate(['/buy-ticket/' + this.idEvent + '/' + this.buyType + '/code/' + this.promoCode]);
            }
            this.router.navigate(['/buy-ticket/' + this.idEvent + '/' + this.buyType]);
          }
        }
      })
    );
  }

  onExternalLogin(platform: ExternalPlatform) {
    this.loginService.externalLoginOnBrowser(platform);
  }

  onCloseFormErrorMessage(e) {
    this.showError = false;
  }

  forgotPassword() {
    this.router.navigate(['/forgot-password']);
  }

  ngOnDestroy(): void {
    this.footerService.showFooter$.next(true);
    this.subs.forEach((res: Subscription) => {
      res.unsubscribe();
    });
  }

  handleDataEmail(event) {
    this.loginForm.get('email').setValue(event);
  }
}
