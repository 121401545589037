<fieldset
  class="fieldset-input {{customClass}} fusion-dropdown"
  [formGroup]="parentForm"
  [ngClass]="{
    'focus': isHighlighted,
    'filled': filledInput(),
    'error': shouldShowErrors(),
    'helper': helperText.length > 1,
    'disabled': readonly || inputField.disabled,
    'success': success,
    'validate': validate,
    'bg-light-in-mobile': bgLightMobile,
    'bg-light-in-desktop': bgLightDesktop,
    'size-large-mobile': largeMobile,
    'size-large-desktop': largeDesktop,
    'size-large': large
  }"
>
  <div class="field__input">
    <p-dropdown
      id="dropdown"
      class="bid-dropdown fusion"
      [filter]="false"
      [showClear]="false"
      [optionLabel]="dropdownOptionLabel"
      [optionValue]="dropdownOptionValue"
      [options]="dropdownOptions"
      [formControlName]="dropdownControlName"
      (onChange)="onOptionTypeChange($event)"
    ></p-dropdown>
    <input
      id="id"
      pattern="{{pattern}}"
      [maxLength]="max"
      [minlength]="min"
      [min]="min"
      [max]="max"
      [type]="type"
      [formControlName]="inputControlName"
      (keypress)="verifyID($event)"
      (focus)="onIdFocus()"
      (keydown.space)="$event.preventDefault()"
      (focusout)="onInputFocusOut()"
      class="p-inputtext dropdown"
    >
  </div>
</fieldset>
<p class="field__item--error hint" *ngIf="hint && shouldShowErrors()">
  <span *ngFor="let error of listOfErrors">{{error}}</span>
</p>
<div *ngIf="showCounter" class="field__item--info">
  <span>{{  inputField.value ? inputField.value.length : 0 }}/{{ maxCounter }}</span>
</div>
