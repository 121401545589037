import { Component } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PasswordDto } from 'src/app/models/PasswordDto';
import { AuthService } from 'src/app/services/auth.service';
import { InputValidationService } from 'src/app/services/input-validation.service';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.scss']
})
export class SetNewPasswordComponent {

  loading: boolean;
  recoverPass = false;
  textHeader: string;
  token: string;
  ingressMail = false;
  changePass = false;
  showError: boolean;
  contentError: string;
  sending: boolean;
  customLabel = 'Enviar link de recuperación.';
  customLabelSendPass = 'Cambiar contraseña';
  showSpinner: boolean;
  showInputs = true;
  subs: Subscription[] = [];
  setNewPasswordForm: UntypedFormGroup;
  resendEmailForm: UntypedFormGroup;
  invalidToken = false;
  formHasBeenSubmitted = false;
  canResendEmail = true;
  passwordMatch = true;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private authSrv: AuthService,
    private validateInputService: InputValidationService,
    private router: Router,
    private navbarService: NavbarService
  ) {
    this.setNewPasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.pattern('(?=\\w*\\d)(?=\\w*[A-Z])(?=\\w*[a-z])\\S{8,32}')]],
      newPasswordRepeat: ['', [Validators.required]],
    });
    this.resendEmailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
    });
  }

  get newPasswordField(): AbstractControl {
    return this.setNewPasswordForm.controls.newPassword;
  }

  get newPasswordRepeatField(): AbstractControl {
    return this.setNewPasswordForm.controls.newPasswordRepeat;
  }


  ngOnInit(): void {
    this.navbarService.showNavbar.next(false);
    this.showError = false;
    this.loading = true;
    this.subs.push(this.route.params.subscribe(params => {
      this.token = params.token;
      if (this.token) {
        this.textHeader = 'Ingresá tu nueva contraseña';
        this.subs.push(this.authSrv.changePassword(this.token).subscribe(response => {
          this.loading = false;
          this.changePass = true;
        }, err => {
          console.log('ERROR', err);
          this.loading = false;
          if (err.status === 403) {
            this.textHeader = 'El token ha expirado o es inválido. Por favor solicitá un nuevo link de recuperación para cambiar tu contraseña.';
            this.recoverPass = true;
            this.invalidToken = true;
          }
        }));
      }
    }));
    this.formHasBeenSubmitted = false;

    this.newPasswordField.valueChanges.subscribe(res => {
      this.passwordMatch = true;
    })

    this.newPasswordRepeatField.valueChanges.subscribe(res => {
      this.passwordMatch = true;
    });
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }

  changePassword() {
    this.showError = false;
    this.formHasBeenSubmitted = true;

    if (this.newPasswordField.value !== this.newPasswordRepeatField.value) {
      this.passwordMatch = false;
    }

    if (this.setNewPasswordForm.valid && this.passwordMatch) {
      this.sendNewPass();
    }
  }

  sendNewPass(): void {
    this.customLabelSendPass = 'Cambiando contraseña...';
    this.loading = true;
    if (this.setNewPasswordForm.valid) {
      const newPass: PasswordDto = {
        newPassword: this.newPasswordField.value,
        token: this.token
      };
      this.subs.push(this.authSrv.sendNewPass(newPass).subscribe(response => {
        this.textHeader = 'Tu contraseña se ha cambiado de manera exitosa. Ya podes ingresar desde la aplicación.';
        this.loading = false;
        this.changePass = false;
        this.customLabelSendPass = 'Cambiar contraseña';
        this.showInputs = false;
        /*setTimeout(() => {
          this.router.navigate(['']);
        }, 3000);*/
      }, err => {
        console.log('ERROR', err);
        this.loading = false;
        this.showError = true;
        this.customLabelSendPass = 'Cambiar contraseña';
        if (err.status === 404) {
          this.contentError = 'No se pudo encontrar la cuenta a la que recuperar la contraseña.';
        }
        if (err.status === 403) {
          this.contentError = 'Token expirado o inválido.';
        }
      }));
    } else {
      this.showError = true;
      this.customLabelSendPass = 'Cambiar contraseña';
      this.contentError = 'Las contraseñas deben coincidir.';
    }
  }

  canSubmitResend(): boolean {
    return !this.resendEmailForm.valid || !this.canResendEmail;
  }

  resendEmailToken(): void {
    this.showSpinner = true;
    this.canResendEmail = false;
    this.subs.push(
      this.authSrv.resendPasswordEmailToken(this.resendEmailForm.controls['email'].value).subscribe(
        () => {
          this.showSpinner = false;
          setTimeout(() => {
            this.canResendEmail = true;
          }, 5000);
        },
        (err) => {
          this.showSpinner = false;
          this.showError = true;
          if (err.status === 404) {
            this.contentError =
              'No existe una cuenta asociada al email ingresado.';
            this.canResendEmail = true;
          }
        }
      )
    );
  }

  displayError() {
    this.showError = false;
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => {
      s.unsubscribe();
    });
  }

}
