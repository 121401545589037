import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-modal-account-unverified-reminder',
  templateUrl: './modal-account-unverified-reminder.component.html',
  styleUrls: ['./modal-account-unverified-reminder.component.scss']
})
export class ModalAccountUnverifiedReminderComponent implements OnInit {
  @Output() action = new EventEmitter();
  @Output() cancelAction = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  onAction() {
    this.action.emit();
  }

  cancel() {
    this.cancelAction.emit();
  }
}
