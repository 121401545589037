<div class="modal modal__my-qr my-qr" id="modalAddGuestMobile" [ngClass]="showModal ? '' : 'hidden'">
  <div class="modal-container">
    <div>
      <button class="btn__close" (click)="close()">
        <i class="fa-light fa-close font-size-26 lh-16 text-primary margin-right-8"></i>
      </button>
      <div class="wrapper">
        <div class="myid__container">
          <div class="myid__wrapper">
            <div class="id__container">
              <div class="container__qr">
                <div #personalQr id="personalQr" class="qr"></div>
              </div>
              <div class="profile">
                <div class="profile__thmubnail">
                  <div class="profile__thumbnail--avatar">
                    <img *ngIf="profile?.image" src="{{ mediaUrl + profile?.image }}" class="avatar__img img-avatar" alt="User"/>
                    <i *ngIf="!profile?.image"
                       class="fal fa-user-circle text-gray-4 lh-32 align-self-center avatar__icon avatar"></i>
                  </div>
                </div>
                <div class="profile__name">
                  <p class="font-secondary font-weight-600 text-gray-6 lh-28 my-0 name">
                    {{ profile?.name }} {{ profile?.lastName }}
                  </p>
                  <p class="text-gray-4 margin-bottom-0 document">{{ profile?.document }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="myid__note">
        <p class="text-gray-35 lh-22 my-0 note" i18n>
          Este código QR es personal e intransferible. Es tu credencial digital para acceder a todos tus eventos.
          Tené en cuenta que este QR se modifica cada 15 minutos para que tus compras e invitaciones estén seguras.
        </p>
      </div>
    </div>
  </div>
</div>
