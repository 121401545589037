import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ValidationCodeResult} from 'src/app/enum/ValidationCodeResult';

import {AccessTypeResponse} from 'src/app/models/accessTypeResponse';
import {TableSelected} from 'src/app/models/TableSelected';
import {TableTypeResponse} from 'src/app/models/TableTypeResponse';
import {TicketSelected} from 'src/app/models/TicketSelected';
import {TicketTypeResponseApp} from 'src/app/models/TicketTypeResponseApp';
import {CodeService} from 'src/app/services/code.service';
import {environment} from 'src/environments/environment';
import {EventForCustomer} from '../../../../../../models/events/EventForCustomer';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-ticket-card',
  templateUrl: './ticket-card.component.html',
  styleUrls: ['./ticket-card.component.scss']
})
export class TicketCardComponent implements OnInit {

  @Input() ticket: AccessTypeResponse;
  @Input() table: TableTypeResponse;
  @Input() typeBuy: string;
  @Input() ticketSelectedId: number;
  @Input() eventData: EventForCustomer;
  @Output() ticketClick = new EventEmitter();
  @Output() tableClick = new EventEmitter();
  @Output() quantity = new EventEmitter();
  @Output() notLogged = new EventEmitter();
  max = environment.maxBuyTicketsEvent;
  counterTicket = 1;
  priceTicket = 0;
  priceTotal = 0;
  soldout = false;
  @Input() skeletonLoading = true;
  buyCode = null;
  validatingCode: boolean;
  validationCodeResult = ValidationCodeResult.none;
  ValidationCodeResult = ValidationCodeResult;
  codeValidated = false;
  ticketSelected: TicketSelected = null;
  tableSelected: TableSelected = null;
  tableResponse: TableTypeResponse = null;
  ticketResponse: AccessTypeResponse = null;
  showOverlayCode = false;
  ticketTypeResponseApp: TicketTypeResponseApp = null;
  currency = '$';
  codeForm: UntypedFormGroup;

  constructor(
    private codeSrv: CodeService,
    private formBuilder: UntypedFormBuilder
  ) {
  }

  ngOnInit(): void {
    this.codeForm = this.formBuilder.group({
      buyCode: [
        '',
        [
          Validators.required,
          Validators.maxLength(8),
          Validators.minLength(3),
          Validators.pattern('([A-z0-9À-ž]){3,}')
        ],
      ],
    });
    this.max = this.ticket ? this.ticket.maxQuantityPerBuy : 1;

    if (this.eventData) {
      this.currency = this.eventData.storeDataOnEvent.currency;
    }
    this.codeForm.valueChanges.subscribe({
      next: (ch) => {
        this.validationCodeResult = ValidationCodeResult.none;
        if (ch.buyCode !== '' && ch.buyCode !== null) {
          if (this.containsWhitespace(ch.buyCode)) {
            this.codeForm.controls.buyCode.setErrors({cannotContainSpace: true});
          }
        }
      }
    });
  }

  containsWhitespace(str) {
    return /\s/.test(str);
  }

  incrementQuantity(): void {
    if (this.counterTicket < (this.ticket.quantity > this.max ? this.max : this.ticket.quantity)) {
      this.counterTicket++;
    }
    this.ticketSelected.amountTickets = this.counterTicket;
    const data = this.setDataTicketToEmit(this.ticketSelected, true);
    this.ticketClick.emit(data);
  }

  decrementQuantity(): void {
    if (this.counterTicket > 1) {
      this.counterTicket--;
    }
    this.ticketSelected.amountTickets = this.counterTicket;
    const data = this.setDataTicketToEmit(this.ticketSelected, true);
    this.ticketClick.emit(data);
  }

  clickCardOnTicket(ticket: AccessTypeResponse, checkDoubleClick?: boolean): void {
    if (checkDoubleClick && this.ticketSelectedId === ticket.idAccessType) {
      this.ticketSelected = null;
      this.ticketClick.emit(null);
    } else {
      if (this.ticketSelectedId !== null && this.ticketSelectedId !== ticket.idAccessType) {
        this.counterTicket = 1;
      }
      this.ticketResponse = ticket;
      this.ticketSelected = new TicketSelected();
      this.ticketSelected.ticket = ticket;
      let data = null;
      this.cleanValidationResult();
      if (ticket.needBuyCode && !this.codeValidated && this.ticketSelectedId !== ticket.idAccessType) {
        this.showOverlayCode = true;
      }
      if (ticket.needBuyCode && this.codeValidated) {
        if (!ticket?.soldOut && ticket.quantity > 0) {
          data = this.setDataTicketToEmit(this.ticketSelected, this.codeValidated);
          this.ticketClick.emit(data);
          this.codeValidated = false;
        }
      } else {
        if (!ticket?.soldOut && ticket.quantity > 0) {
          data = this.setDataTicketToEmit(this.ticketSelected, this.codeValidated);
          this.ticketClick.emit(data);
        }
      }
    }
  }

  clickCardOnTable(table: TableTypeResponse, checkDoubleClick?: boolean): void {
    if (checkDoubleClick && this.ticketSelectedId === table.idTableType) {
      this.tableSelected = null;
      this.tableClick.emit(null);
    } else {
      this.tableResponse = table;
      this.tableSelected = new TableSelected();
      this.tableSelected.table = table;
      let data = null;
      this.cleanValidationResult();
      if (table.needBuyCode && !this.codeValidated && this.ticketSelectedId !== table.idTableType) {
        this.showOverlayCode = true;
      }
      if (table.needBuyCode && this.codeValidated) {
        if (!table?.soldOut) {
          data = this.setDataTableToEmit(this.tableSelected, this.codeValidated);
          this.tableClick.emit(data);
          this.codeValidated = false;
        }
      } else {
        if (!table?.soldOut) {
          data = this.setDataTableToEmit(this.tableSelected, this.codeValidated);
          this.tableClick.emit(data);
        }
      }
    }
  }

  setDataTicketToEmit(dataToSend: TicketSelected, codeValidated?: boolean) {
    let data = null;
    if (dataToSend.ticket !== null) {
      this.priceTotal = this.counterTicket * dataToSend.ticket.price;
      data = {
        total: this.priceTotal,
        ticket: dataToSend.ticket,
        amountTickets: this.counterTicket,
        needBuyCode: dataToSend.ticket.needBuyCode,
        free: dataToSend.ticket.free,
        codeValidated
      };
    }
    return data;
  }

  setDataTableToEmit(dataToSend: TableSelected, codeValidated?: boolean) {
    let data = null;
    if (dataToSend.table !== null) {
      this.priceTotal = this.counterTicket * dataToSend.table.price;
      data = {
        total: this.priceTotal,
        table: dataToSend.table,
        needBuyCode: dataToSend.table.needBuyCode,
        free: dataToSend.table.free,
        codeValidated
      };
    }
    return data;
  }

  getMaxQuantityToBuy() {
    return this.ticket.quantity > this.max ? this.max - this.counterTicket : this.ticket.quantity - this.counterTicket;
  }

  isTicketAvailable(ticket: AccessTypeResponse) {
    return !ticket.soldOut && !this.eventData.soldOut && ticket.quantity > 0;
  }

  isTableAvailable(table: TableTypeResponse) {
    return !table.soldOut && !this.eventData.soldOut;
  }

  isTicketSelected(ticket: AccessTypeResponse) {
    return this.ticketSelectedId === ticket.idAccessType;
  }

  isTableSelected(table: TableTypeResponse) {
    return this.ticketSelectedId === table.idTableType;
  }

  validateBuyCode() {
    this.validatingCode = true;
    this.codeSrv.validateBuyCode(this.codeForm.controls.buyCode.value.toUpperCase(), this.ticketSelectedId).subscribe({
      next: (res: TicketTypeResponseApp) => {
        if (res) {
          this.validationCodeResult = ValidationCodeResult.valid;
          this.validatingCode = false;
          this.codeValidated = true;
          this.showOverlayCode = false;
          if (this.typeBuy === 'A') {
            this.clickCardOnTicket(res.accessType, false);
          } else if (this.typeBuy === 'T') {
            this.clickCardOnTable(res.tableType, false);
          }
        }
      },
      error: () => {
        this.validatingCode = false;
        this.validationCodeResult = ValidationCodeResult.invalid;
        this.codeValidated = false;
      }
    });
  }

  cleanValidationResult() {
    this.validationCodeResult = ValidationCodeResult.none;
    this.codeForm?.controls.buyCode.patchValue(null);
  }
}
