import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-skeleton-description',
  templateUrl: './skeleton-description.component.html',
  styleUrls: ['./skeleton-description.component.scss']
})
export class SkeletonDescriptionComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
