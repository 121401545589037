import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SpinnerService} from '../../../services/spinner.service';
import {Subscription} from 'rxjs';
import {CodeService} from '../../../services/code.service';
import {CodeType} from '../../../enum/CodeType';
import {EventDetailForCustomer} from '../../../models/events/EventDetailForCustomer';
import {EventGuestResponse} from '../../../models/EventGuestResponse';
import {InvitationCodeRequest} from '../../../models/InvitationCode';
import {EventService} from '../../../services/event.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {TableRequest} from '../../../models/TableRequest';
import {TicketTypeResponseApp} from '../../../models/TicketTypeResponseApp';
import {TableService} from '../../../services/table.service';
import {AccessService} from '../../../services/access.service';
import {AccessRequest} from '../../../models/AccessRequest';
import {PaymentMethods} from '../../../enum/PaymentMethods';
import {EventGuestService} from '../../../services/event-guest.service';
import {ModalService} from 'src/app/services/modal.service';
import {ModalActionOptions} from 'src/app/models/ModalActionOptions';
import {ModalActionType} from 'src/app/models/ModalActionType';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-modal-invitation-code',
  templateUrl: './modal-invitation-code.component.html',
  styleUrls: ['./modal-invitation-code.component.scss']
})
export class ModalInvitationCodeComponent implements OnInit, OnDestroy {
  initCode: string = undefined;
  @ViewChild('codeInput', {static: false}) codeInput: ElementRef<HTMLInputElement>;
  submitting = false;
  code = null;
  subs: Subscription[] = [];
  errorSubmit = false;
  showModal = false;
  cantCharacterError = false;
  errorMessage = null;
  eventData: EventDetailForCustomer;
  nameEvent = null;
  table: string;
  cancelLabel = $localize`Cancelar`;
  acceptLabel = $localize`Aceptar`;
  inputLabel = $localize`Código`;
  requiredError = false;
  minEventCodeLength = environment.minEventCodeLength;
  maxEventCodeLength = environment.maxEventCodeLength;

  constructor(
    private spinnerSrv: SpinnerService,
    private codeSrv: CodeService,
    private eventGuestSrv: EventGuestService,
    private eventSrv: EventService,
    private messageSrv: MessageService,
    private router: Router,
    private tableSrv: TableService,
    private accessSrv: AccessService,
    private modalService: ModalService
  ) {
  }

  ngOnInit(): void {
    this.eventSrv.showModalEventCode.subscribe({
      next: (res: {
        state: boolean
        code: string
      }) => {
        this.showModal = res.state;
        this.code = res.code;
      }
    });
  }

  ingressInvitationCode() {
    this.submitting = true;
    if (this.code && this.code.length > 0) {
      if (this.code.length > this.minEventCodeLength && this.code.length <= this.maxEventCodeLength) {
        this.requiredError = false;
        this.spinnerSrv.loadSpinner.next(true);
        const {id_customer} = JSON.parse(localStorage.getItem('user'));
        const invitationCodeRequest: InvitationCodeRequest = {
          invitationCode: this.code.toUpperCase(),
        };
        this.subs.push(
          this.codeSrv.getCodeType(this.code.toUpperCase()).subscribe({
            next: res => {
              const codeType = res;
              if (codeType === CodeType.ACCESS_INVITATION_CODE
                || codeType === CodeType.TABLE_INVITATION_CODE
                || codeType === CodeType.LIST_INVITATION_CODE) {
                this.submitInvitationCode(id_customer, invitationCodeRequest);
              } else if (codeType === CodeType.BUY_ACCESS_CODE) {
                this.validateBuyCode('A', this.code.toUpperCase());
              } else if (codeType === CodeType.BUY_TABLE_CODE) {
                this.validateBuyCode('T', this.code.toUpperCase());
              }
            },
            error: async (error) => {
              this.spinnerSrv.loadSpinner.next(false);

              this.modalService.actionModal$.next({
                showModal: true,
                title: $localize`El código` + ' ' + this.code.toUpperCase() + ' ' + $localize`no se encuentra asociado a ningún Evento`,
                content: $localize`Verifica haberlo ingresado correctamente o comunicate con el comercio.`,
                type: ModalActionType.warning,
                invertBtnsDesktop: true,
                key: 'no-event-code',
                textBtn: 'Volver a ingresar',
                textSecondBtn: 'Cancelar'
              });

              this.showModal = false;
            }
          })
        );
      } else {
        this.cantCharacterError = true;
        this.requiredError = false;
        this.submitting = false;
      }
    } else {
      this.requiredError = true;
      this.cantCharacterError = false;
      this.submitting = false;
    }
  }

  cleanError() {
    this.requiredError = false;
    this.cantCharacterError = false;
  }

  submitInvitationCode(idCustomer, invitationCodeRequest) {
    this.eventGuestSrv.addGuestCode(idCustomer, invitationCodeRequest).subscribe({
      next: async (res: EventGuestResponse) => {
        this.eventSrv.getEventData(res.eventId.toString()).subscribe(async (ev: EventDetailForCustomer) => {
          this.eventData = ev;
          let message = '';
          const addTo = $localize`Te sumaste correctamente a `;
          const inWord = $localize` en `;
          if (ev.tableResponse !== null) {
            message = `${addTo} ${ev.tableResponse.name} ${inWord} ${ev.tableResponse.eventName}!`;
          } else if (ev.accessResponse !== null) {
            message = `${addTo} ${ev.accessResponse.name} ${inWord} ${ev.accessResponse.eventName}!`;
          } else {
            message = `${addTo} ${ev.guestListResponse.name} ${inWord} ${ev.guestListResponse.eventName}!`;
          }
          this.showToastSuccess(message);
          this.getEventName(this.eventData);
        });
        const eventId = res.eventId;
        if (res.tableId) {
          this.table = res.tableReference;
        }
        // await this.alertSuccess(this.table, this.nameEvent, '');
        await this.router.navigate(['/my-events/event-detail', eventId]);
        this.onHide();
        this.spinnerSrv.loadSpinner.next(false);
        this.submitting = false;
      },
      error: async (error) => {
        this.spinnerSrv.loadSpinner.next(false);
        this.errorSubmit = true;
        this.submitting = false;
        this.errorHandler(error.status);
      }
    });
  }

  validateBuyCode(type: string, code: string) {
    this.subs.push(
      this.codeSrv.validateBuyCode(code, null).subscribe({
        next: res => {
          this.setTableOrAccessRequest(type, res);
        },
        error: async error => {
          this.spinnerSrv.loadSpinner.next(false);
          this.errorSubmit = true;
          this.submitting = false;
          if (error.status === 406) {
            this.cantCharacterError = true;
          }
        }
      })
    );
  }

  async goToCheckoutTicket(type: string, eventId?: number) {
    this.spinnerSrv.loadSpinner.next(false);
    this.submitting = false;
    await this.router.navigate(['checkout-ticket/' + eventId + '/' + type]);
    this.onHide();
  }

  async setTableOrAccessRequest(type: string, ticketTypeResponseApp: TicketTypeResponseApp) {
    let tableRequest = null;
    let accessRequest = null;
    let eventId = null;
    if (type === 'T') {
      tableRequest = new TableRequest();
      tableRequest.tableTypeId = ticketTypeResponseApp.tableType.idTableType;
      tableRequest.eventId = ticketTypeResponseApp.tableType.eventId;
      tableRequest.name = ticketTypeResponseApp.tableType.name;
      tableRequest.amountTotal = ticketTypeResponseApp.tableType.price;
      tableRequest.type = type;
      tableRequest.methodPayment = PaymentMethods.CREDIT_CARD;
      tableRequest.free = ticketTypeResponseApp.tableType.free;
      tableRequest.owner = null;
      tableRequest.paymentToken = null;
      tableRequest.promoId = null;
      eventId = ticketTypeResponseApp.tableType.eventId;
      this.tableSrv.paymentSubject.next(tableRequest);
      localStorage.setItem('ticketState', JSON.stringify(tableRequest));
      await this.goToCheckoutTicket(type, eventId);
    } else if (type === 'A') {
      accessRequest = new AccessRequest();
      accessRequest.accessTypeId = ticketTypeResponseApp.accessType.idAccessType;
      accessRequest.eventId = ticketTypeResponseApp.accessType.eventId;
      accessRequest.quantity = 1;
      accessRequest.ticketName = ticketTypeResponseApp.accessType.name;
      accessRequest.amountTotal = ticketTypeResponseApp.accessType.price;
      accessRequest.type = type;
      accessRequest.methodPayment = PaymentMethods.CREDIT_CARD;
      accessRequest.free = ticketTypeResponseApp.accessType.free;
      accessRequest.owner = null;
      accessRequest.paymentToken = null;
      accessRequest.promoId = null;
      eventId = ticketTypeResponseApp.accessType.eventId;
      this.accessSrv.paymentSubject.next(accessRequest);
      localStorage.setItem('ticketState', JSON.stringify(accessRequest));
      await this.goToCheckoutTicket(type, eventId);
    }
  }

  errorHandler(status) {
    let toastError;
    let modalError: ModalActionOptions;
    switch (status) {
      case 400:
        toastError = {
          summary: $localize`El evento no existe o ha finalizado.`,
          detail: null,
          severity: 'error'
        };
        break;
      case 403:
        modalError = {
          showModal: true,
          type: ModalActionType.info,
          title: $localize`Ya formás parte del evento asociado al código ingresado ` + `(${this.code.toUpperCase()}).`,
          textBtn: 'Aceptar'
        };
        break;
      case 404:
        // Table Not Found, Access not found OR InvalidCodeLength}
        // El codigo ingresado no se encuentra asociado a ninguna evento
        // Verifica haberlo ingresado correctamente o comunicate con el comercio.
        modalError = {
          showModal: true,
          title: $localize`El código` + ' ' + this.code.toUpperCase() + ' ' + $localize`no se encuentra asociado a ningún Evento`,
          content: $localize`Verifica haberlo ingresado correctamente o comunicate con el comercio.`,
          type: ModalActionType.warning,
          textBtn: 'Volver a ingresar',
          textSecondBtn: 'Cancelar'
        };
        break;
      case 406:
        toastError = {
          summary: $localize`Ha ocurrido un error con tu usuario, intenta loguearte y probar nuevamente.`, // User not found
          detail: null,
          severity: 'error'
        };
        break;
      case 409:
        toastError = {
          summary: $localize`Se ha alcanzado la capacidad máxima permitida.`, // User not found
          detail: null,
          severity: 'warning'
        };
        break;
      default:
        toastError = {
          summary: $localize`Ha ocurrido un error, por favor intentalo más tarde.`, // User not found
          detail: null,
          severity: 'error'
        };
        break;
    }
    if (toastError) {
      this.messageSrv.add(toastError);
    }
    if (modalError) {
      this.showModal = false;
      this.modalService.actionModal$.next(modalError);
    }
    ;

    setTimeout(() => {
      this.messageSrv.clear();
    }, 5000);
  }

  getEventName(event: EventDetailForCustomer) {
    if (event.tableResponse) {
      this.nameEvent = event.tableResponse.eventName;
    } else if (event.accessResponse) {
      this.nameEvent = event.accessResponse.eventName;
    } else if (event.guestListResponse) {
      this.nameEvent = event.guestListResponse.eventName;
    }
  }

  showToastSuccess(message: string) {
    this.messageSrv.add({
      summary: message,
      detail: null,
      severity: 'success'
    });
    setTimeout(() => {
      this.messageSrv.clear();
    }, 5000);
  }

  onHide() {
    this.showModal = false;
    this.code = null;
    this.cantCharacterError = false;
    this.errorSubmit = false;
    document.body.style.position = '';
  }

  ngOnDestroy() {
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
      document.body.style.position = '';
    });
  }

}
