import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {LegalsFormat} from '../../../models/LegalsFormat';

@Component({
  selector: 'app-privacy-policies',
  templateUrl: './privacy-policies.component.html',
  styleUrls: ['./privacy-policies.component.scss']
})
export class PrivacyPoliciesComponent implements OnInit {

  privacyPolicies: LegalsFormat;

  constructor() {
  }

  async ngOnInit() {
    const {default: data} = await import(`src/assets/legales/${environment.policiesFile}`);
    this.privacyPolicies = data;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  scrollToElement(elementID): void {
    const $element = document.getElementById(elementID);
    $element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

}
