import {Directive, HostBinding, HostListener} from '@angular/core';

@Directive({
  selector: '[appScroll]'
})
export class ScrollDirective {

  constructor() {
  }

  @HostBinding('class.navbar--sticky') newNavbar: boolean;

  @HostListener('window:scroll') onScroll() {
    if (window.scrollY >= 50) {
      this.newNavbar = true;
    } else {
      this.newNavbar = false;
    }
  }

}
