import * as AOS from 'aos';

import {ActivatedRoute, Params} from '@angular/router';
import {Component, OnDestroy, OnInit} from '@angular/core';

import {AuthService} from 'src/app/services/auth.service';
import {Subscription} from 'rxjs';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-confirm-registration',
  templateUrl: './confirm-registration.component.html',
  styleUrls: ['./confirm-registration.component.scss']
})
export class ConfirmRegistrationComponent implements OnInit, OnDestroy {

  loading: boolean;
  resendEmail = false;
  textHeader: string;
  showSpinner = false;
  token: string;
  subs: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private authSrv: AuthService,
    private navbarService: NavbarService
  ) {
  }

  ngOnInit() {
    this.navbarService.showNavbar.next(false);
    this.loading = true;
    this.subs.push(
      this.route.params.subscribe({
        next: (params: Params) => {
          this.token = params.token;
          if (this.token) {
            this.textHeader = $localize`Activando cuenta...`;
            this.showSpinner = true;
            setTimeout(() => {
              this.subs.push(
                this.authSrv.validateToken(this.token).subscribe({
                  next: () => {
                    this.showSpinner = false;
                    this.loading = false;
                    this.textHeader = $localize`Tu cuenta ha sido activada con éxito.`;
                  },
                  error: (err) => {
                    this.loading = false;
                    this.showSpinner = false;
                    if (err.status === 401 || err.status === 500) {
                      this.textHeader =
                        $localize`Solicitá uno nuevo haciendo click en el boton que se encuentra aquí abajo.`;
                      this.resendEmail = true;
                    }
                  }
                })
              );
            }, 2000);
          } else {
            this.resendEmail = false;
            this.textHeader =
              $localize`Por favor revisá tu casilla de mail y seguí las indicaciones para activar tu cuenta.`;
            this.loading = false;
          }
        }
      })
    );
    AOS.init({
      duration: 800,
    });
  }

  ngOnDestroy() {
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }

  reSendToken() {
    this.authSrv.reSendToken(this.token).subscribe({
      next: () => {
        this.resendEmail = false;
        this.textHeader = $localize`Por favor revisá tu casilla de mail y seguí las indicaciones para activar tu cuenta.`;
        this.loading = false;
      },
      error: (err) => {
        if (err.status === 401 || err.status === 500) {
          this.textHeader = $localize`No pudimos enviarte el token, intentá nuevamente mas tarde, o contactate con soporte`;
          this.resendEmail = true;
        }
      }
    });
  }

}
