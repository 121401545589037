<div class="card" (click)="detailEvent()" *ngIf="!skeletonLoading && event">
  <div class="card__image">
    <img src="{{ mediaUrl + event?.image }}" class="image" alt=""/>
    <div class="chip-info out" *ngIf="event.status === eventStatus.CANCELED && !skeletonLoading">
      <span i18n>CANCELADO</span>
    </div>
  </div>
  <div class="card__body">
    <div class="body__header">
      <div class="logo__place">
        <img src="{{ mediaUrl + event.storeDataOnEvent?.logo }}" class="place" alt=""/>
      </div>
      <div class="text-hypens ellipsis-two-lines break-word max-title">
        <p class="name-producer text-hypens ellipsis-two-lines break-word my-0">{{event.storeDataOnEvent?.name}}</p>
      </div>
    </div>
    <div class="title">
      <p class="name-event text-hypens ellipsis-two-lines break-word">{{event.eventName}}</p>
    </div>
    <img src="../../../../../assets/images/separator.svg" class="divisor" alt="Divisor"/>
    <div class="body__date">
      <div class="row__date">
        <div class="col__date">
          <p class="info-event" i18n>Fecha</p>
          <p class="info-event-detail" [ngClass]="event.status === eventStatus.CANCELED ? 'strikethrough' : ''">
            <span class="uppercase">{{event.dateInit | localeDate:'dd'}}</span>
            <ng-container i18n> al </ng-container>
            <span class="uppercase">{{(event.dateEnd | localeDate:'dd MMM').replace(".", "")}}</span>
            , {{' ' + event.dateEnd | localeDate:'YYYY'}}
          </p>
          <div class="skeleton__date mt-2" *ngIf="skeletonLoading"></div>
        </div>
        <div class="col__date pl-4">
          <p class="info-event" i18n>Horario</p>
          <p class="info-event-detail"
             [ngClass]="event.status === eventStatus.CANCELED ? 'strikethrough' : ''">{{event.dateInit |
            localeDate:'HH:mm'}}
            <ng-container i18n> a</ng-container>
            {{' ' + event.dateEnd | localeDate:'HH:mm'}} hs
          </p>
        </div>
      </div>
      <div class="row__date mt-4" [ngClass]="skeletonLoading ? 'mb-4' : '' ">
        <div class="col__date">
          <p class="info-event" i18n>Dirección</p>
          <p class="info-event-detail ellipsis-two-lines break-word"
             [ngClass]="event.status === eventStatus.CANCELED ? 'strikethrough' : ''">
            {{event.address}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card noHover" *ngIf="skeletonLoading">
  <div class="card__image">
    <div class="skeleton__image" *ngIf="skeletonLoading"></div>
  </div>
  <div class="card__body">
    <div class="body__header">
      <div class="skeleton__image--store" *ngIf="skeletonLoading"></div>
      <div class="skeleton__place" *ngIf="skeletonLoading"></div>
    </div>
    <div class="w-full my-2" *ngIf="skeletonLoading">
      <div class="skeleton__title--large"></div>
      <div class="skeleton__title--small mt-2"></div>
    </div>
    <div class="event__info">
      <div class="w-full" *ngIf="skeletonLoading">
        <div class="skeleton__description--large"></div>
        <div class="skeleton__description--small mt-2"></div>
      </div>
    </div>
  </div>
</div>
