import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {Locale, setLocale, supportedLocales} from '../../../../locale/i18n';
import {User} from '../../../models/user';
import {SpinnerService} from '../../../services/spinner.service';
import {LanguageResponse} from '../../../models/Language';

@Component({
  selector: 'app-modal-change-language',
  templateUrl: './modal-change-language.component.html',
  styleUrls: ['./modal-change-language.component.scss']
})
export class ModalChangeLanguageComponent implements OnInit {

  showModal = false;
  languages: Locale[] = supportedLocales;
  languageSelected: string;
  cancelLabel = $localize`Cancelar`;
  acceptLabel = $localize`Aceptar`;

  constructor(
    private authSrv: AuthService,
    private spinnerSrv: SpinnerService
  ) {
    const locale = localStorage.getItem('current_locale');
    this.languageSelected = this.languages.find(l => l.name === locale).name;
  }

  ngOnInit(): void {
    this.authSrv.showModalLanguage.subscribe({
      next: (res: {
        state: boolean
      }) => {
        this.showModal = res.state;
      }
    });
  }

  onLanguageChange(event) {
    this.languageSelected = this.languages.find(l => l.name === event.value).name;
  }

  onHide() {
    this.showModal = false;
    document.body.style.position = '';
  }

  changeLocale() {
    if (localStorage.getItem('user')) {
      this.spinnerSrv.loadSpinner.next(true);
      setLocale(this.languageSelected);
      this.authSrv.setLanguage(this.languageSelected).subscribe({
        next: (res: LanguageResponse) => {
          this.authSrv.getCurrentUserData().subscribe({
            next: (user: User) => {
              this.spinnerSrv.loadSpinner.next(false);
              user.language = res.language;
              this.authSrv.updateUser(user);
              document.body.style.position = '';
            },
            error: () => {
              this.spinnerSrv.loadSpinner.next(false);
            }
          });
        },
        error: () => {
          this.spinnerSrv.loadSpinner.next(false);
          document.body.style.position = '';
        }
      });
    } else {
      setLocale(this.languageSelected);
      document.body.style.position = '';
    }
  }


}
