<section class="success" data-aos="fade-in">
  <div class="success__container">
    <div class="success__header">
      <i class="fa-light fa-circle-check icon__success" *ngIf="currentState === PaymentState.approved"></i>
      <div *ngIf="currentState ===  PaymentState.approved" class="text-center">
        <p class="font-secondary text-2xl lh-32 font-semibold text-gray-1 mt-6 mb-6 text-center" i18n>
          ¡Compra exitosa!
        </p>
        <div class="font-size-14 lh-20 font-weight-400 text-gray-35 mt-4 margin-bottom-24 text-center" i18n>
          Tu evento adquirido aparecerá en la sección Mis Eventos.
        </div>
      </div>
      <a class="link" (click)="myEvents()" *ngIf="currentState === PaymentState.approved && !cameFromApp" i18n>
        Ir a Mis Eventos
      </a>
      <a class="link" (click)="returnToApp()" *ngIf="currentState === PaymentState.approved && cameFromApp" i18n>
        Volver a la App
      </a>
      <i class="fa-light fa-watch icon__pending" *ngIf="currentState ===  PaymentState.pending"></i>
      <p class="font-secondary text-2xl lh-32 font-semibold text-gray-1 mt-6 mb-0 text-center"
         *ngIf="currentState ===  PaymentState.pending" i18n>
        ¡No cierres esta ventana!
      </p>
      <p class="font-secondary text-sm lh-20 font-regular text-gray-35 mt-4 mb-0 text-center"
         *ngIf="currentState ===  PaymentState.pending" i18n>
        Tu pago está siendo procesado y se encuentra pendiente de aprobación.
      </p>
      <i class="fa-light fa-circle-xmark icon__invalid" *ngIf="currentState ===  PaymentState.rejected"></i>
      <p class="font-secondary text-2xl lh-32 font-semibold text-gray-1 mt-6 mb-0 text-center"
         *ngIf="currentState ===  PaymentState.rejected" i18n>
        Compra rechazada
      </p>
      <p class="font-secondary text-sm lh-20 font-regular text-gray-35 mt-4 mb-0 text-center"
         *ngIf="currentState ===  PaymentState.rejected">
        <ng-container i18n>
          Algo no salió durante el proceso de pago.
        </ng-container>
        <br/>
        <ng-container i18n>
          Intentá nuevamente iniciando el proceso de compra.
        </ng-container>
      </p>
      <button class="btn btn__md btn__primary mt-10 mb-4" (click)="returnToBuy()"
              *ngIf="currentState === PaymentState.rejected" i18n>
        Volver a Cartelera de Eventos
      </button>
      <button class="btn__refresh" (click)="refresh()"
              *ngIf="currentState === PaymentState.pending">
        <i class="fa-light fa-arrows-rotate icon__rotate"></i>
        <ng-container i18n>
          Actualizar
        </ng-container>
      </button>
    </div>

    <div class="add-guests-info margin-bottom-32 margin-top-32" *ngIf="currentState === PaymentState.approved">
      <i class="fal fa-users-gear text-info font-size-56 margin-bottom-24 margin-top-16"></i>
      <div class="text-gray-1 font-secondary font-weight-600 font-size-18 font-secondary lh-24 text-center" i18n>
        ¿Cómo agrego a mis invitados?
      </div>
      <div class="text-gray-3 font-size-14 font-weight-300 lh-18 margin-top-8 text-center">
        <div i18n>Tocá en ”Agregar invitados” e ingresá el DNI de la persona que desees agregar.
        </div>
      </div>
      <div class="margin-top-32">
        <button class="btn btn__terciary" (click)="goToEvent()">
          <i class="fal fa-user-plus margin-right-8 font-size-16"></i>
          <ng-template i18n>
            <span class="font-size-14px lg-24 font-width-500">Agregar invitados</span>
          </ng-template>
        </button>
      </div>
    </div>

    <div class="ingress-info" *ngIf="currentState === PaymentState.approved">
      <i class="fal fa-qrcode text-info font-size-56 margin-bottom-24 margin-top-16"></i>
      <div class="text-gray-1 font-secondary font-weight-600 font-size-18 lh-24 text-center" i18n>
        ¿Cómo ingreso al evento?
      </div>
      <div class="text-gray-3 font-size-14 font-weight-300 lh-18 margin-top-8 text-center">
        <div i18n>
          Mostrá tu QR de identificación personal, desde el botón ”Mi QR” en la home de Black iD App. También
          podes hacerlo mostrando el documento con el cual te registraste.
        </div>
      </div>
    </div>

    <div class="success__body" *ngIf="currentState !== PaymentState.rejected && !cameFromApp">
      <div class="success__card">
        <div class="ticket">
          <div class="ticket__head">
            <div class="w-full flex items-center justify-start">
              <div class="place__image">
                <img src="{{environment.mediaUrl + event?.storeDataOnEvent.logo}}" class="image__place"
                     *ngIf="event?.storeDataOnEvent" [alt]="event?.storeDataOnEvent.name"/>
              </div>
              <p class="font-secondary text-base lh-16 text-gray-1 font-weight-400 uppercase ml-10 my-0"
                 *ngIf="event?.storeDataOnEvent">{{event?.storeDataOnEvent.name}}</p>
            </div>

            <img src="../../../../../../../assets/logo/{{ logoName }}" class="logo" alt="logo">
          </div>
          <div class="ticket__body">
            <div>
              <p class="font-secondary font-size-16 lh-24 my-0 font-weight-600 text-gray-1">{{ event?.eventName }}
              </p>
              <p
                class="font-secondary font-size-16 lh-24 my-0 font-weight-400 text-gray-1"
                *ngIf="currentTransaction">{{ currentTransaction?.ticketName }}
              </p>
            </div>
            <div class="flex items-start margin-top-16">
              <i class="fa-solid fa-calendar-clock icon__date"></i>
              <p class="text-sm my-0 lh-24 text-gray-2 font-normal" *ngIf="event?.dateInit">
                <span class="uppercase">
                  {{ (event?.dateInit | date:dateFormat).replace(".", "")}}
                </span>
                <ng-container i18n> al</ng-container>
                <span class="uppercase">
                  {{ ' ' + (event?.dateEnd | localeDate:dateFormat).replace(".", "") }}
                </span>
                , {{ event?.dateInit | date:'HH:mm' }}
                <ng-container i18n> a</ng-container>
                {{' ' + event?.dateEnd | date:'HH:mm' }}</p>
            </div>
            <div class="flex items-start margin-top-4">
              <i class="fa-solid fa-location-dot icon__date"></i>
              <p class="text-sm my-0 lh-24 text-gray-2 font-normal">{{ event?.address }}</p>
            </div>
            <div class="flex items-start margin-top-4" *ngIf="currentTransaction">
              <i class="fa-solid fa-champagne-glasses icon__date"></i>
              <span>
                <span *ngIf="currentTransaction.type === 'A'">
                  <span class="text-sm font-normal text-gray-1 lh-24 my-0">
                    x{{ currentTransaction?.quantity }}
                    <ng-container i18n>Tickets: </ng-container>
                    {{currency}}{{getTotalTransaction(currentTransaction?.amountTotal)}}
                  </span>
                </span>
                <span *ngIf="currentTransaction.type === 'T'">
                  <span class="text-sm font-normal text-gray-1 lh-24 my-0">
                    <ng-container i18n>Combo/Mesa: </ng-container>
                    {{currency}}{{getTotalTransaction(currentTransaction?.amountTotal)}}
                </span>
                </span>
              </span>
            </div>
            <img src="../../../../../../../assets/images/separator.svg" class="divisor" alt=""/>
            <ul class="list-benefits" *ngIf="typeBuy === 'A'">
              <li class="item-benefit" *ngFor="let description of accessData?.descriptions">
                <i class="fa-light fa-circle-check icon__check"></i>
                <p class="text-sm my-0 lh-24 text-gray-2 font-normal">
                  {{description.value}}
                </p>
              </li>
            </ul>
            <ul class="list-benefits" *ngIf="typeBuy === 'T'">
              <li class="item-benefit">
                <i class="fa-light fa-people-pants-simple icon__check"></i>
                <p class="text-sm my-0 lh-24 text-gray-2 font-normal">
                  {{tableData.maxCapacity}}
                  <ng-container i18n>Personas</ng-container>
                </p>
              </li>
              <li class="item-benefit">
                <i class="fa-light fa-circle-dollar-to-slot icon__check"></i>
                <p class="text-sm my-0 lh-24 text-gray-2 font-normal">
                  <ng-container i18n>Crédito digital:</ng-container>
                  {{currency}}{{ tableData.consumerCredit | number:'1.0-2' }}
                </p>
              </li>
              <li class="item-benefit" *ngFor="let description of tableData?.descriptions">
                <i class="fa-light fa-circle-check icon__check"></i>
                <p class="text-sm my-0 lh-24 text-gray-2 font-normal">
                  {{description.value}}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="banner__container">
      <div class="banner__download">
        <div class="qr__container">
          <img src="../../../../../../../assets/images/qr.png" class="qr" alt="">
        </div>
        <div class="content">
          <p class="font-secondary text-lg font-semibold lh-18 my-0 text-gray-1" i18n>
            Descargá Black iD en tu celular
          </p>
          <p class="font-light text-sm lh-24 mt-2 mb-0 text-gray-35 font-primary" i18n>
            Escaneá el QR con tu celular y disfrutá una experiencia distinta.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<app-modal-action [showModal]="showErrorModal" [title]="errorTitle" [content]="errorContent" [textBtn]="'Aceptar'"
                  [iconClass]="'fa-thin fa-triangle-exclamation'" (closeButtonEvent)="onActionErrorModal($event)"
                  (action)="onActionErrorModal($event)"></app-modal-action>
