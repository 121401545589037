import * as AOS from 'aos';

import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Component, ComponentRef, OnDestroy, OnInit} from '@angular/core';

import {AccessResponse} from 'src/app/models/AccessResponse';
import {AlertService} from 'src/app/services/alert-service.service';
import {EventDetailForCustomer} from 'src/app/models/events/EventDetailForCustomer';
import {EventForCustomer} from 'src/app/models/events/EventForCustomer';
import {EventService} from 'src/app/services/event.service';
import {GuestListResponse} from 'src/app/models/GuestListResponse';
import {Location} from '@angular/common';
import {TableResponse} from 'src/app/models/TableResponse';
import {environment} from 'src/environments/environment';
import {UniqueInvitationResponse} from '../../../models/UniqueInvitationResponse';
import {ModalDownloadAppComponent} from '../../../share/components/modal-download-app/modal-download-app.component';
import {EventStatus} from '../../../enum/EventStatus';
import {EventGuestResponse} from '../../../models/EventGuestResponse';
import {SpinnerService} from '../../../services/spinner.service';
import {AuthService} from '../../../services/auth.service';
import {Clipboard} from '@angular/cdk/clipboard';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Sector} from '../../../models/events/Sector';
import {SectorService} from '../../../services/sector.service';
import {EventGuestService} from '../../../services/event-guest.service';
import {AccessService} from '../../../services/access.service';
import {TableService} from '../../../services/table.service';
import {ModalController} from '../../../controller/modal-controller';
import {ModalDelegateOwnershipComponent} from '../../../share/components/modal-delegate-ownership/modal-delegate-ownership.component';
import {ModalActionComponent} from '../../../share/components/modal-action/modal-action.component';
import {MentaService} from '../../../services/menta.service';
import {User} from '../../../models/user';
import { PromotionPersist } from 'src/app/models/PromotionPersist';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
})
export class EventDetailComponent implements OnInit, OnDestroy {
  event: EventForCustomer;
  skeletonLoading = true;
  showImage: boolean;
  imageEvent = '';
  accessBought: AccessResponse;
  tableResponse: TableResponse;
  listsResponse: GuestListResponse;
  uniqueInvitationResponse: UniqueInvitationResponse = null;
  accessId = null;
  marker: any;
  withModalDownload = false;
  modal: any = null;
  ref: ComponentRef<ModalDownloadAppComponent>;
  idEvent: number;
  eventStatus = EventStatus;
  currency = '$';
  guests: EventGuestResponse[] = [];
  mediaUrl: string;
  eventIsEnded = false;
  typeInvitation = '';
  descriptions: string[] = null;
  isInvitedDetails = false;
  limitHasBeenReached = false;
  loadingData = false;
  invitationLink = null;
  customerIsTableAdmin = false;
  customerIsListAdmin = false;
  customerIsAccessOwner = false;
  customerIsTableOwner = false;
  isFree = false;
  sectorsStore: Array<Sector> = [];
  sectorEvent: Sector;
  viewMenu = false;
  leavingEvent = false;
  ticketUUID: string = null;
  isOnResale = false;
  showModalResale = false;
  allowResale = false;
  isAlreadyIngress = false;
  user: User;
  hasAuthority = false;
  sizeListPagination = 10;
  initialListPagePagination = 0;
  plussCount = 0;
  showModalActionLogin = false;
  
  constructor(
    private router: Router,
    private eventSrv: EventService,
    private route: ActivatedRoute,
    private location: Location,
    private alertSrv: AlertService,
    private spinnerSrv: SpinnerService,
    private authSrv: AuthService,
    private clipboard: Clipboard,
    private messageSrv: MessageService,
    private sectorSrv: SectorService,
    private eventGuestSrv: EventGuestService,
    private confirmationSrv: ConfirmationService,
    private accessSrv: AccessService,
    private tableSrv: TableService,
    private modalCtrl: ModalController,
    private mentaSrv: MentaService
  ) {
  }

  ngOnInit(): void {
    this.getUserData();
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
    this.mediaUrl = environment.mediaUrl;
    this.route.paramMap.subscribe({
      next: (params: ParamMap) => {
        const id = params.get('id');
        if (id) {
          this.idEvent = Number(id);
          this.loadEvent();
        }
      }
    });

    const logged = this.authSrv.isLoggedIn();
    if (!logged) {
      this.showModalActionLogin = true;
    } else {
      const data = JSON.parse(sessionStorage.getitem('promotionPersist'));
      if (data) sessionStorage.removeItem('promotionPersist');
    }

    AOS.init({
      duration: 800,
    });
  }

  getUserData() {
    const logged = this.authSrv.isLoggedIn();
    if (logged) {
      this.authSrv.getCurrentUserData().subscribe({
        next: (user: User) => {
          this.user = user;
          if (user.document === null || user.document === '0' || user.document === '') {
            this.router.navigate(['data-required']);
          }
        }
      });
    }
  }

  async ngOnDestroy() {
    if (this.modal) {
      const elements = document.getElementsByClassName('show__modal');
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
    }
  }

  openMenu(): void {
    this.viewMenu = !this.viewMenu;
  }

  async leaveEvent() {
    if (!this.user.blocked) {
      if (this.canCustomerRemoveFromEvent()) {
        this.modalLeaveEvent();
      }
    } else {
      const modal = await this.createModalAction(
        'icon-warning.svg',
        $localize`Cuenta bloqueada`,
        $localize`Tu cuenta se encuentra bloqueada para operar. Si crees que se debe a un error, por favor comunicate con soporte.`,
        $localize`Aceptar`
      );
      const data = modal.onDidDismiss();
      if (data) {
        this.authSrv.logout();
      }
    }
  }

  back() {
    this.router.navigate(['my-events']);
  }

  goToGeolocation() {
    window.open('https://www.google.com/maps/search/?api=1&query=' + this.event.latitude + ',' + this.event.longitude, '_blank');
  }

  loadEvent(): void {
    this.skeletonLoading = true;
    const logged = this.authSrv.isLoggedIn();
    this.accessBought = null;
    this.tableResponse = null;
    this.listsResponse = null;
    this.eventSrv.getEventById(this.idEvent).subscribe({
      next: async (event: EventForCustomer) => {
        this.event = event;
        this.checkIfEventEndedOrCanceled(event);
        this.currency = event.storeDataOnEvent?.currency;
        this.imageEvent = environment.mediaUrl + this.event.image;
        this.sectorsStore = await this.getSectorsStore(this.event.storeId);
        if (logged) {
          this.getInvitationData();
        } else {
          this.skeletonLoading = false;
        }
      }, error: (err) => {
        if (err.status === 404) {
          this.router.navigate(['/event-not-found']);
        }
      }
    });
  }

  checkIfEventEndedOrCanceled(event: EventForCustomer) {
    if (new Date() > new Date(event.dateEnd)
      || (event.status === EventStatus.FINALIZED || event.status === EventStatus.CANCELED)) {
      this.eventIsEnded = true;
    }
  }

  async openModalRemoveResale() {
    if (!this.user.blocked) {
      this.showModalResale = true;
    } else {
      const modal = await this.createModalAction(
        'icon-warning.svg',
        $localize`Cuenta bloqueada`,
        $localize`Tu cuenta se encuentra bloqueada para operar. Si crees que se debe a un error, por favor comunicate con soporte.`,
        $localize`Aceptar`
      );
      const data = modal.onDidDismiss();
      if (data) {
        this.authSrv.logout();
      }
    }
  }

  removeFromResale() {
    this.mentaSrv.removeTicketOfResale(this.ticketUUID).subscribe({
      next: () => {
        this.messageSrv.add({
          summary: $localize`Se quitó el ticket de la reventa.`,
          detail: null,
          severity: 'success'
        });
        setTimeout(() => {
          this.messageSrv.clear();
        }, 3500);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    });
  }

  onCloseModalResale(e) {
    this.showModalResale = false;
  }

  async getSectorsStore(storeId: number) {
    return this.sectorSrv.getSectors(storeId).toPromise();
  }

  getSectorEvent(sectorId: number) {
    if (this.sectorsStore && this.sectorsStore.length > 0) {
      this.sectorEvent = this.sectorsStore.filter(sector => {
        return sector.id === sectorId;
      })[0];
    }
  }

  getInvitationData() {
    this.loadingData = true;
    this.eventSrv.getEventDataPaginated(this.idEvent, this.initialListPagePagination, this.sizeListPagination).subscribe({
      next: async (evData: EventDetailForCustomer) => {
        this.allowResale = evData.allowResale;
        if (evData.accessResponse !== null) {
          this.accessBought = evData.accessResponse;
          this.guests = evData.accessResponse.guests;
          this.typeInvitation = 'A';
          this.descriptions = this.accessBought.descriptions;
          this.limitHasBeenReached = this.accessBought.quantity === this.accessBought.guests.length;
          this.invitationLink = this.accessBought.codeInvitationLink;
          this.isFree = this.accessBought.free;
          this.ticketUUID = !this.isFree ? this.accessBought.uuid : null;
          this.isOnResale = this.accessBought.isOnResale && this.accessBought.resaleStatus === 'ON_RESALE';
          this.getSectorEvent(this.accessBought.sectorId);
          this.checkIngressInitial('A', this.accessBought.idAccess);
        }
        if (evData.tableResponse !== null) {
          this.tableResponse = evData.tableResponse;
          this.guests = evData.tableResponse.guests;
          this.typeInvitation = 'T';
          this.descriptions = this.tableResponse.descriptions;
          this.limitHasBeenReached = this.tableResponse.capacity === this.tableResponse.guests.length;
          this.invitationLink = this.tableResponse.codeInvitationLink;
          this.isFree = this.tableResponse.free;
          this.ticketUUID = !this.isFree ? this.tableResponse.uuid : null;
          this.isOnResale = this.tableResponse.isOnResale && this.tableResponse.resaleStatus === 'ON_RESALE';
          this.getSectorEvent(this.tableResponse.sectorId);
          this.checkIngressInitial('T', this.tableResponse.idTable);
        }
        if (evData.guestListResponse !== null) {
          this.listsResponse = evData.guestListResponse;
          this.guests = evData.guestListResponse.guestsPaginated.content;
          this.typeInvitation = 'L';
          this.limitHasBeenReached = this.listsResponse.capacity === evData.guestListResponse.guestsPaginated.content.length;
          this.invitationLink = this.listsResponse.codeInvitationLink;
          this.plussCount =  (evData.guestListResponse.capacity - evData.guestListResponse.available) - 3;
          this.getSectorEvent(this.listsResponse.sectorId);
          this.hasAuthority = evData.guestListResponse.hasAuthority;
        }
        if (evData.uniqueInvitationResponse !== null) {
          this.uniqueInvitationResponse = evData.uniqueInvitationResponse;
          this.guests.push(evData.uniqueInvitationResponse.guest);
          this.typeInvitation = 'U';
          this.getSectorEvent(this.uniqueInvitationResponse.guest.sectorId);
        }
        this.skeletonLoading = false;
        this.loadingData = false;
        this.verifyAdminPermission();
      },
      error: async (err) => {
        await this.handleErrorEventData(err);
      }
    });
  }

  async handleErrorEventData(err) {
    let summary = null;
    let detail = null;
    let severity = null;
    if (err.status === 400) {
      summary = $localize`Invitación inválida`;
      detail = $localize`Ya no formas parte del evento.`;
      severity = 'error';
    } else if (err.status === 404) {
      summary = $localize`El evento no existe o ha finalizado.`;
      detail = null;
      severity = 'error';
    } else if (err.status === 409) {
      summary = $localize`Invitación inválida`;
      detail = $localize`No se pudo recuperar la información de la invitación.`;
      severity = 'error';
    }
    this.messageSrv.add({
      summary,
      detail,
      severity
    });
    setTimeout(() => {
      this.messageSrv.clear();
    }, 5000);
    await this.router.navigate(['/']);
  }

  checkIngressInitial(type: string, id: number) {
    if (type === 'A') {
      this.accessSrv.checkIfWasIngresses(id).subscribe({
        next: async (thereWasIngress: boolean) => {
          this.isAlreadyIngress = thereWasIngress;
        }
      });
    } else if (type === 'T') {
      this.tableSrv.checkIfWasIngresses(id).subscribe({
        next: async (thereWasIngress: boolean) => {
          this.isAlreadyIngress = thereWasIngress;
        }
      });
    }
  }

  removeGuestFromEvent() {
    this.leavingEvent = true;
    this.eventGuestSrv.leaveEvent(this.event.idEvent).subscribe({
      next: () => {
        this.leavingEvent = false;
        this.router.navigate(['/']);
      },
      error: () => {
        this.leavingEvent = false;
      }
    });
  }

  addPeople() {
    const {access, table, list} = this.prepareModalAddGuests();
    this.eventSrv.showModalAddGuest.next({
      state: true,
      invitationDataAccess: access,
      invitationDataTable: table,
      invitationDataList: list,
      event: this.event,
      invitationType: this.typeInvitation
    });
  }

  showAddGuestButton() {
    return !this.eventIsEnded
      && this.typeInvitation !== 'U'
      && this.verifyAdminPermission()
      && !this.isOnResale
      && !this.user.blocked
      && !this.limitHasBeenReached;
  }

  async showAllGuests() {
    if (!this.user.blocked) {
      const {access, table, list} = this.prepareModalAddGuests();
      this.eventSrv.showModalGuests.next({
        state: true,
        invitationDataAccess: access,
        invitationDataTable: table,
        invitationDataList: list,
        event: this.event,
        invitationType: this.typeInvitation,
        limitHasBeenReached: this.limitHasBeenReached
      });
      document.body.style.position = 'fixed';
    } else {
      const modal = await this.createModalAction(
        'icon-warning.svg',
        $localize`Cuenta bloqueada`,
        $localize`Tu cuenta se encuentra bloqueada para operar. Si crees que se debe a un error, por favor comunicate con soporte.`,
        $localize`Aceptar`
      );
      const data = modal.onDidDismiss();
      if (data) {
        this.authSrv.logout();
      }
    }
  }

  private prepareModalAddGuests() {
    let access = null;
    let table = null;
    let list = null;
    if (this.typeInvitation === 'A') {
      access = this.accessBought;
    } else if (this.typeInvitation === 'T') {
      table = this.tableResponse;
    } else if (this.typeInvitation === 'L') {
      list = this.listsResponse;
    }
    return {access, table, list};
  }

  buyTicket(): void {
    this.router.navigate(['/buy-ticket/']);
  }

  viewImage(): void {
    this.showImage = !this.showImage;
  }

  getDataModalImage(data): void {
    if (data === false) {
      this.showImage = false;
    }
  }

  getDataCardComponent(data): void {
    this.showImage = data;
  }

  showEventDetails() {
    return (this.accessBought !== null || this.tableResponse !== null || this.listsResponse !== null
      || this.uniqueInvitationResponse !== null) && this.event?.status !== this.eventStatus.CANCELED;
  }

  canCollapse(): boolean {
    return this.typeInvitation !== 'U' && this.typeInvitation !== 'L';
  }

  collapseExpandDetails() {
    if (this.canCollapse()) {
      this.isInvitedDetails = !this.isInvitedDetails;
    }
  }

  checkIfListIsPrivate() {
    if (this.typeInvitation === 'L') {
      return this.listsResponse?.privateView;
    } else {
      return false;
    }
  }

  showListGuests() {
    return (this.typeInvitation !== 'U' && this.typeInvitation !== 'A') || (this.typeInvitation === 'A' && this.accessBought.quantity > 1);
  }

  canShowResaleButton() {
    if (this.typeInvitation === 'A') {
      return this.isEventValidToResale()
        && (
          !(this.accessBought?.isOnResale && this.accessBought?.resaleStatus === 'ON_RESALE')
          && !this.accessBought?.free
          && this.accessBought.quantity === 1
          && this.customerIsAccessOwner
        );
    } else if (this.typeInvitation === 'T') {
      return this.isEventValidToResale()
        && (
          !(this.tableResponse?.isOnResale && this.accessBought?.resaleStatus === 'ON_RESALE')
          && !this.tableResponse?.free
          && this.customerIsTableOwner
        );
    } else {
      return false;
    }
  }

  private isEventValidToResale() {
    return this.allowResale && this.event.status === EventStatus.PENDING && (this.event.dateInit - ((new Date()).getTime()) > 3600000);
  }

  isResalePending() {
    if (this.typeInvitation === 'A') {
      return this.accessBought?.isOnResale && this.accessBought?.resaleStatus === 'PENDING';
    } else if (this.typeInvitation === 'T') {
      return this.accessBought?.isOnResale && this.tableResponse?.resaleStatus === 'PENDING';
    } else {
      return false;
    }
  }

  ticketAlreadyOnResale() {
    return (this.accessBought?.isOnResale && this.accessBought?.resaleStatus === 'ON_RESALE')
      || (this.tableResponse?.isOnResale && this.tableResponse?.resaleStatus === 'ON_RESALE');
  }

  async viewOnResale() {
    let uuid;
    if (this.typeInvitation === 'A') {
      uuid = this.accessBought.uuid;
    } else if (this.typeInvitation === 'T') {
      uuid = this.tableResponse.uuid;
    }
    const returnUrl = environment.brandParams.logo.urlLink + '/my-events/event-detail/' + this.idEvent;
    const urlDetailTicket = await this.mentaSrv.getLinkTicketDetails(uuid, returnUrl);
    if (urlDetailTicket.data) {
      window.location.href = urlDetailTicket.data.data;
    }
  }

  verifyAdminPermission(): boolean {
    const {id_customer} = JSON.parse(localStorage.getItem('user'));
    const customerGuest = this.guests.find(
      (guest: EventGuestResponse) => guest.customerId === id_customer
    );
    if (this.typeInvitation === 'T') {
      this.customerIsTableAdmin = customerGuest.tableAdmin;
      this.customerIsTableOwner = customerGuest.idEventGuest === this.tableResponse.owner;
      return this.customerIsTableOwner || this.customerIsTableAdmin;
    } else if (this.typeInvitation === 'L') {
      //this.customerIsListAdmin = customerGuest.listAdmin;
      //return this.customerIsListAdmin;
      this.customerIsListAdmin = this.hasAuthority;
      return this.customerIsListAdmin;
    } else if (this.typeInvitation === 'A') {
      this.customerIsAccessOwner = customerGuest.idEventGuest === this.accessBought.owner;
      return this.customerIsAccessOwner;
    }
  }

  canCustomerRemoveFromEvent() {
    const isOwner = this.customerIsAccessOwner || this.customerIsTableOwner;
    return (!isOwner || this.isFree) && (this.event?.status !== EventStatus.FINALIZED && this.event?.status !== EventStatus.CANCELED);
  }

  canDelegateOwnership() {
    return !this.isFree && (this.customerIsAccessOwner || this.customerIsTableOwner)
      && (this.event?.status !== EventStatus.FINALIZED && this.event?.status !== EventStatus.CANCELED);
  }

  copyEventLink() {
    this.clipboard.copy(this.invitationLink);
    this.messageSrv.add({
      summary: $localize`Evento copiado en portapapeles`,
      detail: null,
      severity: 'success'
    });
    setTimeout(() => {
      this.messageSrv.clear();
    }, 3500);
  }

  async checkIfAccessNotUsed() {
    if (!this.user.blocked) {
      this.spinnerSrv.loadSpinner.next(true);
      if (this.typeInvitation === 'A') {
        this.accessSrv.checkIfWasIngresses(this.accessBought.idAccess).subscribe({
          next: async (thereWasIngress: boolean) => {
            if (thereWasIngress) {
              await this.atLeastOneIngress();
            } else {
              await this.modalChangeOwnership();
            }
          },
          error: () => {
            this.spinnerSrv.loadSpinner.next(false);
          }
        });
      } else if (this.typeInvitation === 'T') {
        this.tableSrv.checkIfWasIngresses(this.tableResponse.idTable).subscribe({
          next: async (thereWasIngress: boolean) => {
            if (thereWasIngress) {
              await this.atLeastOneIngress();
            } else {
              await this.modalChangeOwnership();
            }
          },
          error: () => {
            // this.spinnerSrv.loading.next(false);
          }
        });
      }
    } else {
      const modal = await this.createModalAction(
        'icon-warning.svg',
        $localize`Cuenta bloqueada`,
        $localize`Tu cuenta se encuentra bloqueada para operar. Si crees que se debe a un error, por favor comunicate con soporte.`,
        $localize`Aceptar`
      );
      const data = modal.onDidDismiss();
      if (data) {
        this.authSrv.logout();
      }
    }
  }

  async atLeastOneIngress() {
    let quantity = 0;
    if (this.typeInvitation === 'T') {
      quantity = this.tableResponse.capacity;
    } else if (this.typeInvitation === 'A') {
      quantity = this.accessBought.quantity;
    }
    return await this.createModalAction(
      'icon-warning.svg',
      $localize`No se puede transferir el evento`,
      quantity > 1
        ? $localize`Uno de los accesos ya fue utilizado para ingresar.`
        : $localize`El acceso ya fue utilizado para ingresar.`,
      $localize`Aceptar`
    );
  }

  private async createModalAction(icon: string, title: string, content: string, textBtn: string) {
    const modal = await this.modalCtrl.create({
      component: ModalActionComponent,
      props: {
        icon,
        title,
        content,
        textBtn
      },
    });
    await modal.present();
    return modal;
  }

  async modalChangeOwnership() {
    const modal = await this.modalCtrl.create({
      component: ModalDelegateOwnershipComponent,
      props: {
        showModal: true,
        typeInvitation: this.typeInvitation,
        accessId: this.accessBought ? this.accessBought.idAccess : null,
        tableId: this.tableResponse ? this.tableResponse.idTable : null,
        accessData: this.accessBought ? this.accessBought : null,
        tableData: this.tableResponse ? this.tableResponse : null,
        currency: this.currency,
        event: this.event
      },
    });
    await modal.present();
    return modal;
  }

  modalLeaveEvent() {
    this.confirmationSrv.confirm({
      message: $localize`¿Deseas abandonar este evento?` + ' \n <span class="subheader"> ' + $localize`Al hacerlo, se eliminará también de tu lista de eventos y ya no podrás acceder a el.` + '<span>',
      header: '',
      icon: 'icon-warning icon-4xl icon-yellow',
      rejectIcon: 'none',
      acceptIcon: 'none',
      acceptLabel: $localize`Abandonar`,
      rejectLabel: $localize`Cancelar`,
      accept: () => {
        this.removeGuestFromEvent();
      },
    });
  }

  onActionToLogin() {
    const promotionPersist: PromotionPersist = new PromotionPersist();
    promotionPersist.eventDetailPage = true;
    promotionPersist.eventId = this.idEvent.toString();
    promotionPersist.promoCode = null;
    sessionStorage.setItem('promotionPersist', JSON.stringify(promotionPersist));

    this.router.navigate(['/login']);
  }

  closeModalLogin() {
    this.showModalActionLogin = false;
  }


}
