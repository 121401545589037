<section class="login-container">
  <div *ngIf="!emailSent; else emailHasBeenSended" class="card">
    <div class="card__left">
      <div class="back-container margin-bottom-24 cursor-pointer" (click)="backToLogin()">
        <i class="fa-light fa-arrow-left-long text-primary margin-right-8"></i>
        <span class="display-block font-size-12 text-gray-1 font-weight-400" i18n>
          Volver a Iniciar sesión
        </span>
      </div>
      <span class="display-block font-secondary lg-24 text-gray-1 font-weight-600 forgot-password margin-bottom-16"
            i18n>
        ¿Olvidaste tu contraseña?
      </span>
      <span class="display-block font-secondary lg-18 text-gray-35 font-weight-400 font-size-13 margin-bottom-16" i18n>
        Indica el correo con el que te registraste y te enviaremos un enlace para recuperar tu contraseña.
      </span>
      <form class="form" [formGroup]="recoverEmailForm">
        <bid-input
          label="Email"
          i18n-label
          type="email"
          name="email"
          [page]="'recoverPassword'"
          [preventSpace]="true"
          [largeMobile]="true"
          customClass="margin-bottom-16"
          [value]="recoverEmailForm.get('email').value"
          [ctrl]="recoverEmailForm.get('email') | formControl"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          (data)="handleDataEmail($event)"
          (focusout)="focusOutFn()"
        ></bid-input>

        <button
          pButton
          type="submit"
          class="btn btn__md btn__login btn__primary card__recover-password"
          (click)="submitRecoverPassword()"
          [disabled]="formHasBeenSubmitted  && !recoverEmailForm.valid"
          i18n
        >Recuperar contraseña
        </button>
      </form>
    </div>

    <div class="card__right">
      <div class="recover-with-email">
        <i class="fa-light fa-circle-question"></i>
        <span class="title" i18n>¿No recordás tu email?</span>
        <span class="subtitle" i18n>Recuperá tu contraseña ingresando tu documento</span>
        <button class="btn btn__secondary" (click)="goToRecoverWithDNI()">Recuperar contraseña</button>
      </div>
    </div>
  </div>
  <ng-template #emailHasBeenSended class="card email-sended">
    <div class="card email-sended">
      <div class="card__left">
        <div class="back-container margin-bottom-24 cursor-pointer" (click)="backToLogin()">
          <i class="fa-light fa-arrow-left-long text-primary margin-right-8"></i>
          <span class="display-block font-size-12 text-gray-1 font-weight-400" i18n>
            Volver a Iniciar sesión
          </span>
        </div>
        <span class="display-block font-secondary lg-24 text-gray-1 font-weight-600 forgot-password margin-bottom-16"
              i18n>¡Revisá tu casilla de correo!
        </span>
        <span class="display-block font-secondary lg-18 text-gray-35 font-weight-400 font-size-13 margin-bottom-16"
              i18n>
          Te enviamos un mail al correo que indicaste anteriormente. Por favor seguí las instrucciones para recuperar tu contraseña.
        </span>
        <span class="display-block font-secondary lg-18 text-gray-1 font-weight-400 font-size-13 margin-bottom-16" i18n>
          El link tendrá una validez de 30 minutos. Pasado ese tiempo, deberás enviarlo nuevamente.
        </span>
        <button
          pButton
          type="submit"
          class="btn btn__sm btn__login btn__secondary card__recover-password margin-top-16"
          (click)="resendEmail()"
          i18n
        >Enviar nuevamente
        </button>
      </div>

      <div class="card__right">
        <img class="card__mailbox--desktop" src="../../../assets/images/mail-box.png" alt="mail-box"/>
        <img class="card__mailbox--mobile" src="../../../assets/images/mail-box-mobile.png" alt="mail-box"/>
      </div>
    </div>
  </ng-template>


</section>
