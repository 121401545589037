import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AccessResponse} from '../../../../../models/AccessResponse';
import {TableResponse} from '../../../../../models/TableResponse';
import {GuestListResponse} from '../../../../../models/GuestListResponse';
import {Subscription} from 'rxjs';
import {EventService} from '../../../../../services/event.service';
import {SpinnerService} from '../../../../../services/spinner.service';
import {MessageService} from 'primeng/api';
import {environment} from '../../../../../../environments/environment';
import {EventForCustomer} from '../../../../../models/events/EventForCustomer';
import {EventStatus} from '../../../../../enum/EventStatus';
import {EventGuestResponse} from '../../../../../models/EventGuestResponse';
import {EventGuestService} from '../../../../../services/event-guest.service';

@Component({
  selector: 'app-modal-show-guests',
  templateUrl: './modal-show-guests.component.html',
  styleUrls: ['./modal-show-guests.component.scss']
})
export class ModalShowGuestsComponent implements OnInit, OnDestroy {
  @Output() updateListGuests = new EventEmitter<boolean>();
  @Output() addNewGuestToEvent = new EventEmitter<boolean>();
  showModal = false;
  invitationDataAccess: AccessResponse = null;
  invitationDataTable: TableResponse = null;
  invitationDataList: GuestListResponse = null;
  invitationType = '';
  headerPopup: string;
  headerSubtitle: string;
  subs: Subscription[] = [];
  mediaUrl: string;
  cancelLabel = $localize`Cancelar`;
  addGuestLabel = $localize`Agregar invitado`;
  isEventFinalizedOrCanceled = false;
  event: EventForCustomer;
  customerGuest: EventGuestResponse;
  customerIsTableAdmin = false;
  customerIsListAdmin = false;
  customerIsAccessOwner = false;
  customerIsTableOwner = false;
  limitHasBeenReached = false;
  available = 0;
  maxCapacity = 0;
  showDeleteConfirmation = false;
  guestSelected: EventGuestResponse = null;
  sum = 100;
  currentPaginationPage = 0;
  paginationNumber = 15;
  hasAuthority = false;
  guests = [];

  constructor(
    private eventSrv: EventService,
    private eventGuestSrv: EventGuestService,
    private spinnerSrv: SpinnerService,
    private messageSrv: MessageService
  ) {
    this.mediaUrl = environment.mediaUrl;
  }

  ngOnInit(): void {
    this.subs.push(
      this.eventSrv.showModalGuests.subscribe({
        next: (res: {
          state: boolean,
          invitationDataAccess: AccessResponse,
          invitationDataTable: TableResponse,
          invitationDataList: GuestListResponse,
          event: EventForCustomer,
          invitationType: string,
          limitHasBeenReached: boolean
        }) => {
          this.guests = [];
          this.currentPaginationPage = 0;
          this.event = res.event;
          this.isEventFinalizedOrCanceled = this.evaluateStateOfEvent();
          this.invitationType = res.invitationType;
          let type = '';
          if (res.invitationType === 'A') {
            type = $localize`Ticket `;
            this.invitationDataAccess = res.invitationDataAccess;
            this.headerSubtitle = `${type} <span class="invitationName"> ${this.invitationDataAccess.name}</span>`;
            this.available = this.invitationDataAccess.available;
            this.maxCapacity = this.invitationDataAccess.quantity;
            this.guests = res.invitationDataAccess.guests;
            this.verifyAdminPermission();
          } else if (res.invitationType === 'T') {
            type = $localize`Combo/Mesa `;
            this.invitationDataTable = res.invitationDataTable;
            this.headerSubtitle = `${type} <span class="invitationName"> ${this.invitationDataTable.name}</span>`;
            this.available = this.invitationDataTable.available;
            this.maxCapacity = this.invitationDataTable.capacity;
            this.guests = res.invitationDataTable.guests;
            this.verifyAdminPermission();
          } else if (res.invitationType === 'L') {
            type = $localize`Lista `;
            this.invitationDataList = res.invitationDataList;
            this.headerSubtitle = type + `<span class="invitationName"> ${this.invitationDataList.name}</span>`;
            this.available = this.invitationDataList.available;
            this.maxCapacity = this.invitationDataList.capacity;
            this.eventSrv.getEventDataPaginated(this.event.idEvent , this.currentPaginationPage, this.paginationNumber).subscribe((res) => {
              this.guests = res.guestListResponse.guestsPaginated.content;
              this.hasAuthority = res.guestListResponse.hasAuthority;
              this.verifyAdminPermission();
            });
          }
          this.showModal = res.state;
          this.limitHasBeenReached = res.limitHasBeenReached;
        }
      })
    );
  }

  evaluateStateOfEvent() {
    const isFinalized = new Date(this.event.dateEnd) < new Date() || this.event.status === EventStatus.FINALIZED;
    const isCanceled = this.event.status === EventStatus.CANCELED;
    return isFinalized || isCanceled;
  }

  verifyAdminPermission(): boolean {
    const {id_customer} = JSON.parse(localStorage.getItem('user'));
    this.customerGuest = this.guests.find(
      (guest: EventGuestResponse) => guest.customerId === id_customer
    );

    if (this.invitationType === 'T') {
      this.customerIsTableAdmin = this.customerGuest.tableAdmin;
      this.customerIsTableOwner = this.customerGuest.idEventGuest === this.invitationDataTable.owner;
      return this.customerIsTableOwner || this.customerIsTableAdmin;
    } else if (this.invitationType === 'L') {
        //hasAuthority tiene el valor
        this.customerIsListAdmin = this.hasAuthority;
        return this.customerIsListAdmin;
    } else if (this.invitationType === 'A') {
      this.customerIsAccessOwner = this.customerGuest.idEventGuest === this.invitationDataAccess.owner;
      return this.customerIsAccessOwner;
    }
  }

  cancel() {
    this.onHide();
  }

  onHide() {
    this.showModal = false;
    document.body.style.position = '';
  }

  addNewGuest() {
    this.showModal = false;
    this.addNewGuestToEvent.emit(true);
    document.body.style.position = '';
  }

  openDeleteConfirmation(guest: EventGuestResponse) {
    this.guestSelected = guest;
  }

  cancelDelete() {
    this.guestSelected = null;
  }

  setShowDeleteConfirmation(idGuest: number) {
    if (this.guestSelected) {
      this.showDeleteConfirmation = this.guestSelected.idEventGuest === idGuest;
      return this.guestSelected.idEventGuest === idGuest;
    }
  }

  submitDeleteGuest(guest: EventGuestResponse) {
    this.spinnerSrv.loadSpinner.next(true);
    this.eventGuestSrv.deleteGuest(guest.idEventGuest).subscribe({
      next: () => {
        this.spinnerSrv.loadSpinner.next(false);
        this.guestSelected = null;
        this.showModal = false;
        this.updateListGuests.emit(true);
        let detailMessage;
        const message =$localize`se borró correctamente de`;
        if (guest.name !== null && guest.lastName !== null) {
          detailMessage = guest.name + ' ' + message + ' ' + this.event.eventName + '.';
        } else {
          detailMessage = guest.document + ' ' + message + ' ' + this.event.eventName + '.';
        }
        this.messageSrv.add({
          summary: $localize`Invitado borrado correctamente`,
          detail: detailMessage,
          severity: 'success'
        });
        setTimeout(() => {
          this.messageSrv.clear();
        }, 5000);
      },
      error: (err) => {
        this.spinnerSrv.loadSpinner.next(false);
        if (err.status === 404) {
          this.messageSrv.add({
            summary: $localize`El invitado ya no es parte del evento.`,
            severity: 'success'
          });
          setTimeout(() => {
            this.messageSrv.clear();
          }, 5000);
        } else if (err.status === 406) {
          this.messageSrv.add({
            summary: $localize`No se puede borrar un invitado que ya ingresó.`,
            severity: 'warning'
          });
          setTimeout(() => {
            this.messageSrv.clear();
          }, 5000);
        } else if (err.status === 409) {
          this.messageSrv.add({
            summary: $localize`No se puede borrar al comprador de los tickets.`,
            severity: 'error'
          });
          setTimeout(() => {
            this.messageSrv.clear();
          }, 5000);
        } else if (err.status === 418) {
          this.messageSrv.add({
            summary: $localize`Error al ejecutarse la devolución de la compra.`,
            severity: 'error'
          });
          setTimeout(() => {
            this.messageSrv.clear();
          }, 5000);
        }
      }
    });

  }

  capitalizeGuestName(str) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  isLastIndex(guest): boolean {
    return (
      this.guests.length > 1 &&
      (this.guests.indexOf(guest) === 0 ||
        this.guests.indexOf(guest) !== this.guests.length - 1)
    );
  }

  onScroll(ev) {
    this.currentPaginationPage += 1;
    //this.appendItems(start, this.sum);
    this.eventSrv.getEventDataPaginated(this.event.idEvent , this.currentPaginationPage, this.paginationNumber).subscribe((res) => {
      res.guestListResponse.guestsPaginated.content.forEach(el => {
        this.guests.push(el);
      });
    });
  }

  ngOnDestroy() {
    this.guests = [];
    this.currentPaginationPage = 0;
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }
}
