import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalActionOptions } from '../models/ModalActionOptions';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  actionModal$ = new Subject<ModalActionOptions>();

  constructor() { }
}
