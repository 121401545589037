<div class="social-login">
    <p class="text-gray-2 font-size-12 lh-24 font-weight-400 signup-text" i18n>
      o iniciar sesión con
    </p>
  <div class="redes-container margin-top-8">
    <!--<div class="red-card margin-right-8 cursor-pointer {{ btnBackgroundClass }}"
         (click)="doExternalLogin(EXTERNALLOGIN.FACEBOOK)">
      <img src="../../../assets/icon/facebook.svg"/>Facebook
    </div>-->
    <div class="red-card margin-right-8 cursor-pointer"
         (click)="doExternalLogin(EXTERNALLOGIN.GOOGLE)">
      <img src="../../../../../assets/icon/google.svg" alt=""/>Gmail
    </div>
    <div class="red-card cursor-pointer" (click)="doExternalLogin(EXTERNALLOGIN.APPLE)">
      <img src="../../../../../assets/icon/apple.svg" alt=""/>Apple
    </div>
  </div>
</div>
