import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/guards/auth.guard';
import {BillboardComponent} from './pages/billboard/billboard.component';
import {BuyTicketComponent} from './pages/billboard/detail-event/buy-ticket/buy-ticket.component';
import {CheckoutTicketComponent} from './pages/billboard/detail-event/buy-ticket/checkout-ticket/checkout-ticket.component';
import {CompleteFirstLoginComponent} from './pages/complete-first-login/complete-first-login/complete-first-login.component';
import {ConfirmRegistrationComponent} from './pages/login/components/confirm-registration/confirm-registration.component';
import {DetailEventComponent} from './pages/billboard/detail-event/detail-event.component';
import {DownloadAppComponent} from './pages/download-app/download-app.component';
import {EventDetailComponent} from './pages/my-events/event-detail/event-detail.component';
import {ForgotPasswordComponent} from './pages/forgot-password/forgot-password.component';
import {HistoryComponent} from './pages/my-events/history/history.component';
import {LoginComponent} from './pages/login/login.component';
import {MyEventsComponent} from './pages/my-events/my-events.component';
import {NgModule} from '@angular/core';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {NotFoundEventComponent} from './pages/not-found-event/not-found-event.component';
import {ProfileComponent} from './pages/profile/profile.component';
import {SignupComponent} from './pages/signup/signup.component';
import {
  SuccessPurchaseComponent
} from './pages/billboard/detail-event/buy-ticket/checkout-ticket/success-purchase/success-purchase.component';
import {MaintenanceComponent} from './pages/maintenance/maintenance.component';
import {DataRequiredComponent} from './pages/billboard/detail-event/buy-ticket/checkout-ticket/data-required/data-required.component';
import {RecoverPasswordDniComponent} from './pages/recover-password-dni/recover-password-dni.component';
import {SupportComponent} from './pages/support/support.component';
import {ConfigurationsComponent} from './pages/configurations/pages/configurations/configurations.component';
import {ChangePasswordComponent} from './pages/configurations/pages/change-password/change-password.component';
import {TermsAndConditionsComponent} from './pages/terms/terms-and-conditions/terms-and-conditions.component';
import {PrivacyPoliciesComponent} from './pages/terms/privacy-policies/privacy-policies.component';
import { SetNewPasswordComponent } from './pages/set-new-password/set-new-password.component';


const routes: Routes = [
  {
    path: 'billboard/0/code/:code',
    component: BillboardComponent,
    loadChildren: () => import('./pages/pages.module').then(mod => mod.PagesModule),
  },
  {
    path: 'data-required',
    component: DataRequiredComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'home/:myQR',
    component: BillboardComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'support',
    component: SupportComponent,
  },
  {
    path: 'complete-first-login',
    component: CompleteFirstLoginComponent,
  },
  {
    path: 'signup',
    component: SignupComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'set-new-password/:token',
    component: SetNewPasswordComponent,
  },
  {
    path: 'recover-with-dni',
    component: RecoverPasswordDniComponent,
  },
  {
    path: 'confirm-registration/:token',
    component: ConfirmRegistrationComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-events',
    component: MyEventsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-events/event-detail/:id',
    component: EventDetailComponent,
  },
  {
    path: 'history',
    component: HistoryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'billboard',
    component: BillboardComponent,
    loadChildren: () => import('./pages/pages.module').then(mod => mod.PagesModule),
  },
  {
    path: 'billboard/event-detail/:id',
    component: EventDetailComponent,
  },
  {
    path: 'billboard/detail-event/:id',
    component: DetailEventComponent
  },
  {
    path: 'detail-event/:id',
    component: DetailEventComponent
  },
  {
    path: 'billboard/detail-event/:id/code/:code',
    component: DetailEventComponent
  },
  {
    path: 'detail-event/:id/code/:code',
    component: DetailEventComponent
  },
  {
    path: 'buy-ticket/:id/:type',
    component: BuyTicketComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'buy-ticket/:id/:type/code/:code',
    component: BuyTicketComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'buy-ticket/:id/:type/code/:typeCode/:code',
    component: BuyTicketComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'checkout-ticket/:id/:type',
    component: CheckoutTicketComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'checkout-ticket/:id/:type/code/:code',
    component: CheckoutTicketComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'success-purchase',
    component: SuccessPurchaseComponent
  },
  {
    path: 'download-app',
    pathMatch: 'full',
    component: DownloadAppComponent
  },
  {
    path: 'page-not-found',
    component: NotFoundComponent
  },
  {
    path: 'event-not-found',
    pathMatch: 'full',
    component: NotFoundEventComponent
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  {
    path: 'configurations',
    component: ConfigurationsComponent,
    loadChildren: () => import('./pages/configurations/configurations.module').then(mod => mod.ConfigurationsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'configurations/change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
  },
  {
    path: 'privacy-policies',
    component: PrivacyPoliciesComponent,
  },
  {
    path: '',
    component: BillboardComponent,
    loadChildren: () => import('./pages/pages.module').then(mod => mod.PagesModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    useHash: false,
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
