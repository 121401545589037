import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {TokenRefreshResponse} from '../../models/TokenRefreshResponse';
import {environment} from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  lo7161 = '49b6ac243f72b64209d5e76d02256410b7091145';
  jwtToken = null;

  constructor(
    private authSrv: AuthService
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let request = req;
    if (this.authSrv.isAuthenticated()) {
      if (this.authSrv.isTokenExpired()) {
        if (!req.url.includes('refresh-token')) {
          this.authSrv.refreshToken().subscribe({
            next: (newToken: TokenRefreshResponse) => {
              this.authSrv.setNewJWTToken(newToken);
              this.jwtToken = newToken.token;
              window.location.reload();
            }
          });
        }
      } else {
        this.jwtToken = this.authSrv.getJWTToken();
      }
      if (this.jwtToken) {
        request = req.clone({
          setHeaders: {
            authorization: this.jwtToken
          },
        });
      }
    } else if (this.authSrv.isGuestLoggedIn()) {
      // tslint:disable-next-line: no-shadowed-variable
      if (this.authSrv.isTokenExpired()) {
        if (!req.url.includes('customer/guest')) {
          this.authSrv.getSessionGuest().subscribe(session => {
            this.authSrv.setNewJWTTokenGuest(session.jwt);
            this.jwtToken = session.jwt;
            window.location.reload();
          });
        }
      } else {
        this.jwtToken = this.authSrv.getJWTToken();
      }
      if (this.jwtToken) {
        request = req.clone({
          setHeaders: {
            authorization: this.jwtToken
          },
        });
      }
    }
    request = request.clone({
      setHeaders: {
        'x-o7161': this.lo7161,
        'x-r96hl': environment.identification
      },
    });
    return next.handle(request);
  }
}
