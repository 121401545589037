<section class="signup">
  <div>
    <div>
      <div class="card" data-aos="fade-in" [ngClass]="{'step-one': currentStepNumber === signupSteps.one}">
        <div class="back-desktop">
          <div class="back-button-container cursor-pointer" (click)="backTo()">
            <i class="fa-light fa-arrow-left-long text-primary margin-right-8"></i>
            <span class="font-weight-500 font-size-14 lh-24 text-primary" i18n>Volver</span>
          </div>
        </div>

        <div class="card__container">
          <div class="card__header">
            <div class="back-mobile" (click)="backTo()"><i
              class="fa-light fa-arrow-left-long text-primary margin-right-8"></i></div>
            <div class="card__current-step">
              <div class="display-inline-block">{{ currentStepNumber }}</div>
              <ng-container i18n> de</ng-container>
              {{ maxSteps }}
            </div>
          </div>

          <div class="card__content-step">
            <div class="container">
              <div [ngSwitch]="currentStepNumber">
                <div *ngSwitchCase="signupSteps.one">
                  <app-signup-step-one  [parentForm]="signupForm"
                                        [stepHasBeenSubmitted]="stepHasBeenSubmitted[signupSteps.one]"
                                        [passwordMatch]="passwordMatch"
                                    ></app-signup-step-one>
                </div>
                <div *ngSwitchCase="signupSteps.two">
                  <app-signup-step-two [parentForm]="signupForm"
                                        [stepHasBeenSubmitted]="stepHasBeenSubmitted[signupSteps.two]"></app-signup-step-two>
                </div>
              </div>
            </div>

            <div class="card__btn-footer">
              <button class="btn btn__md btn__primary btn-continuar"
                      (click)="nextStep()" [disabled]="loadingStep">
                <span *ngIf="loadingStep"><i class="fa-solid fa-spinner-third fa-spin"></i></span>
                <span class="display-flex" *ngIf="!loadingStep"><ng-container i18n>Continuar</ng-container> <i
                  class="fa-solid fa-chevron-right"></i></span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div data-aos="fade-in" *ngIf="currentStepNumber === signupSteps.two" class="info-card margin-top-16">
        <div class="title margin-bottom-8">
          <i class="fa-solid fa-circle-exclamation text-info margin-right-8"></i>
          <span class="font-secondary font-weight-600 font-size-14 lh-16 text-gray-1" i18n>Ingresá tu DNI o Pasaporte real</span>
        </div>
        <span class="display-block font-weight-400 font-size-14 lh-20 text-gray-35" i18n>Recordá que este documento puede ser solicitado para ingresar a tus eventos o establecimientos. Para evitar contratiempos siempre ingresá tus datos reales.</span>
      </div>
    </div>

    <div class="card__footer" *ngIf="currentStepNumber === signupSteps.one">
      <app-external-login (externalLogin)="onExternalSignup($event)"></app-external-login>
    </div>
  </div>

</section>

<app-modal-action
  [showModal]="showConfirmEmailModal"
  [title]="successSignupModal.title"
  [content]="messageConfirmEmail"
  [imageName]="'approve.svg'"
  [textBtn]="successSignupModal.textBtn"
  (closeButtonEvent)="onActionToLogin()"
  (action)="onActionToLogin()"
></app-modal-action>

<app-modal-action
  [showModal]="showErrorOnSignup" [title]="failSignupModal.title"
  [content]="messageErrorStep"
  [textBtn]="failSignupModal.textBtn"
  [firstButtonClass]="'btn__md btn__secondary'"
  [textSecondBtn]="failSignupModal.textSecondBtn"
  [secondButtonClass]="'btn__md btn__primary'"
  [showHeader]="false"
  [iconClass]="'fal fa-exclamation-circle text-12xl'"
  (closeButtonEvent)="onCloseErrorOnSignupModal()"
  (action)="onCloseErrorOnSignupModal()"
  (actionSecondButton)="onCloseErrorOnSignupTryAgainModal()">
</app-modal-action>

<app-signup-finish-step-modal
  [showModal]="isShowingTermsAndConditionsModal"
  [parentForm]="signupForm"
  (acceptTerms)="onFinishSteps($event)">
</app-signup-finish-step-modal>

