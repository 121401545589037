<div class="container-main background-home">

  <div class="conteiner-card">
    <img src="../../../assets/images/maintenance_icon.png" class="image-maintenance" alt=""/>
    <span class="title" i18n>Estamos haciendo mantenimiento</span>
    <span  class="sub-title">
      <span i18n>En breve podrás volver a utilizar nuestros servicios.</span><br>
      <span i18n>¡Gracias por tu paciencia!</span>
    </span>
  </div>

</div>
