import {ExternalLoginRequest} from '../models/ExternalLoginRequest';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Subject, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {GuestSession} from '../models/GuestSession';
import {LoginForm} from '../models/LoginForm';
import {RegisterPayload} from '../models/RegisterForm';
import {TokenRefreshResponse} from '../models/TokenRefreshResponse';
import {UserResponse} from '../models/UserResponse';
import {VerificationAccount} from '../models/VerificationAccount';
import {User} from '../models/user';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {MailAvailableRequest} from '../models/MailAvailableRequest';
import {IDValidationRequest} from '../models/IDValidationRequest';
import jwt_decode from 'jwt-decode';
import {LanguageRequest, LanguageResponse} from '../models/Language';
import {RecoverPasswordDniResponse} from './RecoverPasswordDniRespo';
import {RecoverPasswordRequest} from '../models/RecoverPasswordRequest';
import {SpinnerService} from './spinner.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  apiUrl = environment.apiAGUrl;
  userSubject = new Subject();
  checkLogged = new Subject();
  jwt: string;
  decodedToken: { [key: string]: string };
  showModalMyQr = new Subject();
  showModalLanguage = new Subject();
  showMainMenu = new Subject();

  constructor(
    private http: HttpClient,
    private router: Router,
    private fbAuth: AngularFireAuth,
    private spinnerSrv: SpinnerService
  ) {
  }

  login(form: LoginForm): Observable<UserResponse> {
    return this.http.post<UserResponse>(`${this.apiUrl}/customer/web/login`, form).pipe(
      map((auth: UserResponse) => {
        // tslint:disable-next-line: no-string-literal
        if (auth && auth['jwt']) {
          localStorage.setItem('user', JSON.stringify(auth));
          localStorage.setItem('BE-JWT', auth.jwt);
          this.checkLogged.next(true);
          this.userSubject.next(auth);
        }
        return auth;
      })
    );
  }

  logout() {
    this.spinnerSrv.loadSpinner.next(true);
    const {id_customer} = JSON.parse(localStorage.getItem('user'));
    this.logoutBack(id_customer).subscribe({
      next: async () => {
        const googleLogged = JSON.parse(localStorage.getItem('isGoogleLogin'));
        if (googleLogged === true) {
          this.fbAuth.signOut().then(() => {
            this.cleanLocalStorageAndLogInGuest();
          });
          this.spinnerSrv.loadSpinner.next(false);
        } else {
          await this.cleanLocalStorageAndLogInGuest();
          this.spinnerSrv.loadSpinner.next(false);
        }
      },
      error: async () => {
        await this.cleanLocalStorageAndLogInGuest();
        this.spinnerSrv.loadSpinner.next(false);
      }
    });
  }

  logoutBack(customerId: number): Observable<void> {
    return this.http.get<void>(`${this.apiUrl}/customer/logout/${customerId}`);
  }

  async cleanLocalStorageAndLogInGuest() {
    localStorage.removeItem('user');
    localStorage.removeItem('BE-JWT');
    localStorage.removeItem('lastDetailEvent');
    localStorage.removeItem('ticketState');
    localStorage.removeItem('ticketData');
    localStorage.removeItem('eventId');
    localStorage.removeItem('typeBuy');
    localStorage.removeItem('isGoogleLogin');
    this.userSubject.next(null);
    await this.checkUserLogged();
    this.checkLogged.next(false);
    await this.router.navigate(['/']);
  }

  register(form: RegisterPayload): Observable<User> {
    return this.http.post<User>(`${this.apiUrl}/customer?web=true`, form);
  }

  validateToken(token): Observable<any> {
    return this.http.get<any>(
      `${environment.apiAGUrl}/customer/registrationConfirm/${token}`
    );
  }

  reSendToken(token): Observable<any> {
    return this.http.get<any>(
      `${environment.apiAGUrl}/customer/resend_token_landing/${token}`
    );
  }

  resendEmailToken(): Observable<void> {
    const {id_customer} = JSON.parse(localStorage.getItem('user'));
    return this.http.get<void>(`${this.apiUrl}/customer/resend_token/${id_customer}`
    );
  }

  public resendPasswordEmailToken(email): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/customer/resetPassword/${email}`, null);
  }

  getSessionGuest(): Observable<GuestSession> {
    return this.http.get<GuestSession>(`${this.apiUrl}/customer/guest`);
  }

  getCurrentUserData(): Observable<User> {
    const {id_customer} = JSON.parse(localStorage.getItem('user'));
    return this.http.get<User>(`${this.apiUrl}/customer/by_id/${id_customer}`);
  }

  getAccountStatus(): Observable<VerificationAccount> {
    const {id_customer} = JSON.parse(localStorage.getItem('user'));
    return this.http.get<VerificationAccount>(
      `${this.apiUrl}/customer/validations/check/${id_customer}`
    );
  }

  saveChangesUser(input: RegisterPayload): Observable<any> {
    const {id_customer} = JSON.parse(localStorage.getItem('user'));
    return this.http.patch<any>(
      `${this.apiUrl}/customer/data_user/${id_customer}`,
      input
    );
  }

  updateUser(user: User) {
    localStorage.setItem('user', JSON.stringify({...user}));
    this.userSubject.next(user);
  }

  public refreshToken(): Observable<TokenRefreshResponse> {
    const token = this.getJWTToken();
    const payload = {
      token,
    };
    return this.http.post<TokenRefreshResponse>(
      `${this.apiUrl}/customer/refresh-token`,
      payload
    );
  }

  public resetPassword(email: string): Observable<any> {
    return this.http.post<any>(
      `${environment.apiAGUrl}/customer/resetPassword/${email}`,
      null
    );
  }

  public resetPasswordWithDni(payload: RecoverPasswordRequest): Observable<RecoverPasswordDniResponse> {
    return this.http.post<RecoverPasswordDniResponse>(
      `${environment.apiAGUrl}/customer/recover_password/dni`,
      payload
    );
  }

  externalLogin(login: ExternalLoginRequest): Observable<User> {
    return this.http
      .post<User>(`${this.apiUrl}/customer/login/external?web=true`, login)
      .pipe(
        map((auth: User) => {
          // tslint:disable-next-line: no-string-literal
          if (auth && auth['jwt']) {
            localStorage.setItem('user', JSON.stringify(auth));
            localStorage.setItem('BE-JWT', auth.jwt);
            this.checkLogged.next(true);
            this.userSubject.next(auth as User);
          }
          return auth;
        })
      );
  }

  isMailAvailable(userEmail: MailAvailableRequest): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.apiUrl}/customer/email-available`,
      userEmail
    );
  }

  public isLoggedIn(): boolean {
    return JSON.parse(localStorage.getItem('isLoggedIn')) === true;
  }

  public isGuestLoggedIn(): boolean {
    return JSON.parse(sessionStorage.getItem('guestLogged')) === true;
  }

  public isAuthenticated(): boolean {
    const user = localStorage.getItem('user');
    const jwt = localStorage.getItem('BE-JWT');
    return (user !== null && user !== '{}') && (jwt != null && jwt !== '');
  }

  getJWTToken() {
    let token;
    if (this.isLoggedIn()) {
      token = localStorage.getItem('BE-JWT');
      this.jwt = token;
    } else {
      token = sessionStorage.getItem('token');
      this.jwt = token;
    }
    return token;
  }

  isTokenExpired(): boolean {
    const expiryTime: number = Number(this.getExpiryTime());
    if (expiryTime) {
      return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
    } else {
      return false;
    }
  }

  getExpiryTime() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.exp : null;
  }

  decodeToken() {
    if (this.getJWTToken()) {
      this.decodedToken = jwt_decode(this.jwt);
    }
  }

  setNewJWTToken(jwtToken) {
    const user = JSON.parse(localStorage.getItem('user'));
    user.jwt = jwtToken.token;
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('BE-JWT', jwtToken.token);
  }

  setNewJWTTokenGuest(jwtToken) {
    sessionStorage.setItem('token', jwtToken);
    localStorage.setItem('isLoggedIn', 'false');
    sessionStorage.setItem('guestLogged', 'true');
  }

  async checkUserLogged(): Promise<boolean> {
    const logged = this.isAuthenticated();
    const guestLogged = this.isGuestLoggedIn();
    return new Promise(async (res, rej) => {
      if ((!logged && !guestLogged) || guestLogged) {
        if (this.getJWTToken() === null) {
          this.getSessionGuest().subscribe(session => {
            sessionStorage.setItem('token', session.jwt);
            localStorage.setItem('isLoggedIn', 'false');
            sessionStorage.setItem('guestLogged', 'true');
            res(true);
          });
        } else {
          res(true);
        }
      } else {
        res(false);
      }
    });
  }

  getGuestTokenSession(): Subscription {
    return this.getSessionGuest().subscribe(session => {
      sessionStorage.setItem('token', session.jwt);
      localStorage.setItem('isLoggedIn', 'false');
      sessionStorage.setItem('guestLogged', 'true');
    });
  }

  validateUserEmail(email: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}/email/validation/${email}`);
  }

  validateDNIorIDFromBack(iDValidationRequest: IDValidationRequest) {
    return this.http.post<boolean>(`${this.apiUrl}/customer/validate-identification`, iDValidationRequest);
  }

  public setLanguage(language: string): Observable<LanguageResponse> {
    const {id_customer} = JSON.parse(localStorage.getItem('user'));
    const payload: LanguageRequest = {
      customerId: id_customer,
      language
    };
    return this.http.patch<LanguageResponse>(`${this.apiUrl}/customer/set-language`, payload);
  }

  public changePassword(token: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/customer/changePassword/${token}`);
  }

  public sendNewPass(newPass): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/customer/savePassword`, newPass);
  }
}
