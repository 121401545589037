import {PaymentMethods} from '../enum/PaymentMethods';

export class TableRequest {
  tableTypeId: number;
  name: string;
  eventId: number;
  owner: number;
  capacity: number;
  amountTotal?: number;
  type?: string;
  methodPayment?: PaymentMethods;
  paymentToken?: string;
  promoId?: number;
  free?: boolean;
}
