<p-dialog [(visible)]="showModal" (onHide)="onHide()" [draggable]="false" [resizable]="false" [modal]="true"
          class="bid-modal add-event-code">
  <ng-template pTemplate="header" class="bid-modal__header">
    <div class="filling-space"></div>
    <div class="display-flex flex-col justify-content-center align-items-center">
      <p class="bid-modal__header--headtitle" i18n>Ingresar código de evento</p>
    </div>
  </ng-template>

  <div class="bid-modal__content">
    <div class="field__group">
      <div
        class="field__item"
        [ngClass]="cantCharacterError || requiredError ? 'has-error': ''">
        <div class="p-float-label">
          <input
            #codeInput
            id="email"
            type="text"
            maxlength="8"
            pInputText
            [(ngModel)]="code"
            (ngModelChange)="cleanError()"
            (keydown.space)="$event.preventDefault()"
            class="p-inputtext p-component"/>
          <label class="title-float" i18n>Código</label>
        </div>
        <p class="hint" *ngIf="cantCharacterError" i18n>
          Cantidad de caracteres inválida.
        </p>
        <p class="hint" *ngIf="requiredError" i18n>
          Cámpo obligatorio
        </p>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer" class="bid-modal__footer">
    <button
      pButton
      class="btn btn__secondary"
      (click)="onHide()"
      label="{{cancelLabel}}"
    ></button>
    <button
      pButton
      class="btn btn__primary"
      (click)="ingressInvitationCode()"
      label="{{acceptLabel}}"
    ></button>
  </ng-template>

</p-dialog>

