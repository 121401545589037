<p-dialog [(visible)]="showModal" (onHide)="onHide()" [draggable]="false" [resizable]="false" [modal]="true"
          class="bid-modal modal__language">
  <ng-template pTemplate="header" class="bid-modal__header">
    <div class="filling-space"></div>
    <div class="display-flex flex-col justify-content-center align-items-center">
      <p class="bid-modal__header--headtitle" i18n>Selecciona tu idioma</p>
    </div>
  </ng-template>

  <div class="bid-modal__content">
    <div class="field__group">
      <div
        class="field__item">
        <div class="p-float-label">
          <p-dropdown
            id="dropdown"
            class="bid-dropdown fusion select__language"
            [filter]="false"
            [showClear]="false"
            [optionLabel]="'label'"
            [optionValue]="'name'"
            [options]="languages"
            appendTo="body"
            [(ngModel)]="languageSelected"
            (onChange)="onLanguageChange($event)"
          ></p-dropdown>
        </div>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer" class="bid-modal__footer">
    <button
      pButton
      class="btn btn__secondary"
      (click)="onHide()"
      label="{{cancelLabel}}"
    ></button>
    <button
      pButton
      class="btn btn__primary"
      (click)="changeLocale()"
      label="{{acceptLabel}}"
    ></button>
  </ng-template>

</p-dialog>

