import {PaymentMethods} from '../enum/PaymentMethods';

export class AccessRequest {
  accessTypeId: number;
  quantity: number;
  eventId: number;
  amountTotal?: number;
  ticketName?: string;
  type?: string;
  owner?: number;
  methodPayment?: PaymentMethods;
  paymentToken?: string;
  promoId?: number;
  free?: boolean;
}
