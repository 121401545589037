<section class="buy-tickets">
  <div class="wrapper">
    <div class="back__container">
      <button class="btn btn__back color-primary" (click)="back()">
        <i class="fa-regular fa-arrow-left icon__back"></i>
        <span i18n>Volver</span>
      </button>
    </div>

    <div class="card__container">
      <div class="card__header">
        <div class="hearder__info">
          <div class="event__image">
            <img *ngIf="!skeletonLoading && eventData?.image" src="{{environment.mediaUrl + eventData?.image}}"
                 class="image" [alt]="eventData?.eventName"/>
            <div class="skeleton__image" *ngIf="skeletonLoading"></div>
          </div>
          <div class="event__data">
            <p class="text-lg text-gray-1 my-0 font-secondary font-semibold lh-16" *ngIf="!skeletonLoading">{{
              eventData?.eventName }}</p>
            <p class="text-sm text-gray-3 mt-2 mb-0 font-secondary font-medium lh-24" *ngIf="!skeletonLoading">
              {{ (eventData.dateInit | localeDate:dateFormat | uppercase).replace(".", "") }} -
              {{ eventData.dateInit | localeDate:timeFormat }}
              <ng-container i18n="Date range separator, it will be placed between the event start date and end date">a
              </ng-container>
              {{eventData.dateEnd | localeDate:'HH:mm'}}</p>
            <div class="skeleton__title" *ngIf="skeletonLoading"></div>
            <div class="skeleton__description" *ngIf="skeletonLoading"></div>
          </div>
        </div>
        <div class="next-step">
          <button class="btn btn__next" (click)="verifyUserData()" *ngIf="!skeletonLoading">
            <span class="text-base lh-24 text-gray-6 font-semibold" i18n>Continuar</span>
          </button>
          <div class="skeleton__btn" *ngIf="skeletonLoading"></div>
        </div>
      </div>
      <div class="card__body">
        <div class="skeleton__tickets" *ngIf="skeletonLoading"></div>
        <p class="text-xl text-gray-1 lh-24 mt-0 mb-6 font-semibold font-secondary" *ngIf="!skeletonLoading" i18n>
          Detalle de compra
        </p>
        <div class="details__purchase" *ngIf="!skeletonLoading">
          <div class="w-full flex items-center justify-between mb-2">
            <div class="name__ticket">
              <i class="fa-light fa-ticket text-gray-3" *ngIf="typeBuy === 'A'"></i>
              <i class="fa-light fa-champagne-glasses text-gray-3" *ngIf="typeBuy === 'T'"></i>
              <p class="text-sm lh-16 my-0 text-gray-3 font-normal ml-2" *ngIf="typeBuy === 'A'">
                <span>{{ currentPayment?.ticketName }}</span> x {{currentPayment?.quantity}}
              </p>
              <p class="text-sm lh-16 my-0 text-gray-3 font-normal ml-2" *ngIf="typeBuy === 'T'">
                <span>{{currentPaymentTable?.name }}</span>
              </p>
            </div>
            <p class="text-sm lh-16 font-normal text-gray-3 my-0">{{currency}}{{ getSubtotal() | number:'1.0-2' }}</p>
          </div>
          <div class="w-full flex items-center justify-between">
            <p class="text-sm lh-16 my-0 text-gray-3 font-normal" i18n>Costo del servicio</p>
            <p class="text-sm lh-16 font-normal text-gray-3 my-0">
              {{currency}}{{ getServicePrice() | number:'1.0-2' }}
            </p>
          </div>
          <div class="w-full flex items-center justify-between mt-2" *ngIf="promoCodeValid">
            <p class="text-sm lh-16 my-0 text-success font-normal">
              <ng-container i18n>Descuento promocional -</ng-container>
              {{promoDiscount}}
              <ng-container i18n>% OFF</ng-container>
            </p>
            <p class="text-sm lh-16 font-normal text-success my-0">- {{currency}}{{
              getPromoDiscountAmount() | number:'1.0-2' }}</p>
          </div>
          <div class="w-full flex items-center justify-between mt-10">
            <p class="text-xl lh-24 my-0 text-gray-1 font-secondary font-semibold" i18n>Total</p>
            <p class="text-xl lh-24 text-gray-1 font-secondary font-bold my-0">{{currency}}{{
              getFinalPrice() | number:'1.0-2' }}</p>
          </div>

          <div class="divider" *ngIf="currentPayment && !currentPayment.free || currentPaymentTable !== null"></div>

          <app-promotional-code
            *ngIf="eventData && ((currentPayment && !currentPayment.free) || currentPaymentTable !== null) && !skeletonLoading"
            [storeId]="eventData.storeId" [buyType]="typeBuy"
            [eventId]="eventData.idEvent" [codePromo]="code"
            (promoEmit)="getDataPromoComponent($event)">
          </app-promotional-code>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="footer" *ngIf="!skeletonLoading">
  <button class="btn btn__next" (click)="verifyUserData()">
    <span class="text-base lh-24 text-gray-6 font-semibold" i18n>Continuar</span>
  </button>
</div>

<app-modal-must-signin
  [showModal]="showModalActionLogin"
  (closeModal)="getDataModalImage()"
  (actionModal)="onActionToLogin()"
></app-modal-must-signin>

<app-modal-action [showModal]="showNeedVerifiedAccount" [title]="errorAccountTitle" [content]="errorAccountContent"
                  [textBtn]="'Aceptar'" [iconClass]="errorAccountIcon" [imageName]="errorAccountImage"
                  (closeButtonEvent)="onNeedVerifiedAccount($event)"
                  (action)="onNeedVerifiedAccount($event)"></app-modal-action>

<app-modal-action [showModal]="showErrorModal" [title]="errorTitle" [content]="errorContent" [textBtn]="'Aceptar'"
                  [iconClass]="errorIcon" (closeButtonEvent)="onActionErrorModal($event)"
                  (action)="onActionErrorModal($event)"></app-modal-action>

<app-modal-action
  *ngIf="showRedirectPaymentModal"
  [showModal]="true"
  [imageName]="imagePlatformName"
  [title]="modalRedirectPaymentTitle"
  [content]="''"
  [textBtn]="firstToMobbexModal"
  [invertBtnsDesktop]="true"
  [textSecondBtn]="secondToMobbexModal"
  (action)="onGoToPaymentModal()"
  (actionSecondButton)="onClosePaymentModal()"
  (closeButtonEvent)="onClosePaymentModal()">
</app-modal-action>
