import {AccessTypeResponse} from './accessTypeResponse';

export class TicketSelected {
  total: number;
  ticket: AccessTypeResponse;
  amountTickets: number;
  needBuyCode?: boolean;
  codeValidated?: boolean;
  free?: boolean;
}
