<div class="container">
  <h2 class="text-center title">{{ privacyPolicies?.title }} </h2>
  <p> {{ privacyPolicies?.description }}</p>

  <div class="links-container">
    <div *ngFor="let policy of privacyPolicies?.items">
      <a class="link cursor-pointer" (click)="scrollToElement(policy.ancle)">{{ policy.title }}</a>
    </div>
  </div>

  <div class="container-policies">
    <div id="{{ policy.ancle }}" class="policy" *ngFor="let policy of privacyPolicies?.items">
      <h3 class="policy-title">{{ policy.title }}</h3>
      <div [innerHTML]="policy.content" class="policy-content"></div>
    </div>
  </div>
</div>
