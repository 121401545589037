import {Injectable} from '@angular/core';
import {SpinnerService} from './spinner.service';
import {Router} from '@angular/router';
import {User} from '../models/user';
import {FirebaseRemoteConfigService} from './firebase-remote-config.service';
import {environment} from '../../environments/environment';
import * as jose from 'jose';

@Injectable({
  providedIn: 'root'
})
export class QrService {

  showingID = false;

  constructor(
    private spinnerService: SpinnerService,
    private router: Router,
    private firebaseRemoteConfigSrv: FirebaseRemoteConfigService
  ) {
  }

  async generateQR(): Promise<string> {
    const user: User = JSON.parse(localStorage.getItem('user'));
    const payload = {id: user.id_customer};
    // FOR RS256
    // const privateKey = await jose.importPKCS8(environment.secretKey, environment.algorithm);
    const qrKey: string = await this.firebaseRemoteConfigSrv.getSignQRKey();
    if (!qrKey || qrKey.length < 32) {
      // await this.alertSrv.alertErrorGettingKey();
      this.spinnerService.loadSpinner.next(false);
      return;
    }
    this.spinnerService.loadSpinner.next(false);
    const uint8array = new TextEncoder().encode(qrKey);
    return await new jose.SignJWT(payload).setProtectedHeader({alg: environment.algorithm}).setIssuedAt().sign(uint8array);
  }
}
