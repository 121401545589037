import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {CheckoutResponse} from '../models/CheckoutResponse';
import {TableRequest} from '../models/TableRequest';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  paymentSubject: BehaviorSubject<TableRequest> = new BehaviorSubject<TableRequest>(new TableRequest());
  public readonly currentPaymentTable$: Observable<TableRequest> = this.paymentSubject.asObservable();

  initPaymentTableBase: TableRequest = {
    tableTypeId: null,
    name: null,
    eventId: null,
    owner: null,
    capacity: null,
    amountTotal: null,
    type: null,
    methodPayment: null,
    paymentToken: null,
    promoId: null
  };

  currentPaymentTable: TableRequest = null;

  constructor(
    private httpC: HttpClient
  ) {
  }

  initPaymentTable() {
    this.currentPaymentTable = this.initPaymentTableBase;
  }

  saveFistStepDataTable(data: TableRequest) {
    this.currentPaymentTable = {
      tableTypeId: data.tableTypeId,
      name: data.name,
      eventId: data.eventId,
      capacity: data.capacity,
      amountTotal: data.amountTotal,
      type: data.type,
      owner: data.owner,
      methodPayment: data.methodPayment,
      paymentToken: data.paymentToken,
      promoId: null,
      free: data.free
    };
    this.paymentSubject.next(this.currentPaymentTable);
  }

  generateCheckoutTable(payload: TableRequest): Observable<CheckoutResponse> {
    return this.httpC.post<CheckoutResponse>(environment.billboardAGUrl + `/cartelera/app/table/billboard-web/`, payload);
  }

  checkIfWasIngresses(idAccess): Observable<boolean> {
    return this.httpC.get<boolean>(environment.billboardAGUrl + `/cartelera/app/table/check_ingress/${idAccess}`);
  }
}
