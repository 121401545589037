import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router) {

  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (environment.isUnderMaintenance) {
      await this.router.navigateByUrl('maintenance');
      return false;
    }
    if (!this.authService.isLoggedIn()) {
      await this.router.navigateByUrl('/');
      return false;
    } else {
      return true;
    }
  }

}
