<main class="wrapper">
  <div class="ups">
    <img src="../../../assets/images/img-ups.svg" class="img-ups" alt="Not found"/>
    <div class="ups__content">
      <h1 class="title">
        <ng-container i18n>Ups..!</ng-container>
        <br/>
        <ng-container i18n>algo no salio tan bien...</ng-container>
      </h1>
      <p class="description" i18n>No pudimos encontrar lo que estabas buscando.</p>
      <a href="/" class="btn btn__primary" i18n>Volvamos al inicio</a>
    </div>
  </div>
</main>
