<p-dialog [(visible)]="showModal" (onHide)="onHide()" [draggable]="false" [resizable]="false" [modal]="true"
          class="bid-modal">
  <ng-template pTemplate="header" class="bid-modal__header">
    <div class="filling-space"></div>
    <div class="display-flex flex-col justify-content-center align-items-center">
      <p class="bid-modal__header--headtitle">{{headerPopup}}</p>
      <div class="bid-modal__header--title" [innerHTML]="headerSubtitle"></div>
    </div>
  </ng-template>

  <div class="bid-modal__content" *ngIf="!showingDataCustomerAndInvitation">
    <form [formGroup]="newGuestForm">
      <div class="field__group">
        <bid-infinite-scroll
          *ngIf="allCountries && allCountries.length > 0"
          [parentForm]="newGuestForm"
          [formControlName]="'country'"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [items]="allCountries"
          [page]="'modalAddGuest'"
          [placeholder]="placeHolderCountry"
          [bindLabel]="'es'"
          [bindValue]="'alpha3'"
          (dropdownChanges)="onDropdownChange($event)"
        ></bid-infinite-scroll>

        <app-bid-input-dni
          [type]="inputType"
          [min]="minDocumentLength"
          [max]="maxDocumentLength"
          [parentForm]="newGuestForm"
          [page]="'modalAddGuest'"
          [dropdownControlName]="'documentType'"
          [dropdownOptions]="idOptionTypes"
          [dropdownOptionLabel]="'name'"
          [dropdownOptionValue]="'value'"
          [inputControlName]="'document'"
          [largeMobile]="true"
          [bgLightDesktop]="true"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [pattern]="idPattern"
          (dropdownChange)="onDropdownOptionChange($event)">
        </app-bid-input-dni>

        <div class="field__item--info mt-2">
          <span i18n>El invitado deberá estar registrado en Black iD para que su acceso sea válido.</span>
        </div>
        <div class="field-checkbox check-admin" *ngIf="invitationType !== 'A'">
          <p-checkbox label="{{designAdminLabel}}" binary="true" formControlName="tableAdmin"></p-checkbox>
          <p class="info__checkbox" i18n>
            El administrador es el único que podrá agregar o remover invitados.
          </p>
        </div>
      </div>
    </form>
  </div>

  <div class="bid-modal__content" *ngIf="showingDataCustomerAndInvitation">
    <div *ngIf="!guestAlreadyAuthorize">
      <div class="container__user-data" *ngIf="guestIsCustomer && !userIsBlocked">
        <div>
          <div class="avatar__profile" *ngIf="userToAdd.image else placeholder">
            <img src="{{ mediaUrl + userToAdd.image }}" alt=""/>
          </div>
          <ng-template #placeholder>
            <div class="icon-placeholder">
              <i class="fa-light fa-user text-3xl text-gray-4"></i>
            </div>
          </ng-template>
        </div>
        <div class="display-flex flex-col ml-4">
          <p
            class="my-0 text-gray-1 text-base font-secondary lh-16 font-weight-600">{{userToAdd?.name}} {{userToAdd.lastName}}</p>
          <span
            class="text-gray-3 text-sm lh-16 font-weight-400 mt-2">{{userToAdd?.document}}</span>
          <span
            class="text-gray-3 text-sm lh-16 font-weight-400 mt-2 break-word">{{userToAdd?.email}}</span>
          <span class="text-gray-3 text-sm lh-16 font-weight-400 mt-2"
                *ngIf="tableAdminField.value === true">
            <ng-container i18n>Administrador</ng-container>
            <i class="fal fa-check-circle text-primary text-sm lh-16 ml-2"></i>
          </span>
        </div>
      </div>
      <div class="message__guest_authorized" *ngIf="guestIsCustomer && userIsBlocked">
        <i class="fal fa-info-circle text-info text-12xl mb-2"></i>
        <p class="mb-4 text-xl lh-26 text-gray-1 font-secondary font-weight-600">
          <span i18n>
            La cuenta del usuario que quieres agregar se encuentra bloqueada.
          </span>
        </p>
      </div>
      <div class="container__user-data no_customer" *ngIf="!guestIsCustomer">
        <div class="display-flex flex-col align-items-center">
          <i class="fas fa-id-card text-primary text-3xl lh-24 mb-2"></i>
          <p
            class="my-0 text-gray-1 text-xl font-secondary lh-32 font-weight-600">{{newGuestForm.controls.document.value}}</p>
          <span class="text-gray-3 text-sm lh-16 font-weight-400 mt-2"
                *ngIf="tableAdminField.value === true">
            <ng-container i18n>Administrador</ng-container>
            <i class="fal fa-check-circle text-primary text-sm lh-16 ml-2"></i>
          </span>
        </div>
      </div>
      <div class="mt-5 mb-5 text-center" *ngIf="!userIsBlocked">
        <i class="fal fa-arrow-down-long text-primary text-3xl lh-24"></i>
      </div>
      <div class="container__ticket-data" *ngIf="!guestAlreadyAuthorize && !userIsBlocked">
        <div class="mb-4">
          <p class="text-lg lh-18 text-gray-1 font-light font-secondary my-0">
            <i class="fas fa-champagne-glasses text-gray-3 text-sm lh-16 mr-2"></i>
            <span i18n *ngIf="invitationType === 'A'">Ticket </span>
            <span i18n *ngIf="invitationType === 'T'">Combo/Mesa </span>
            <span i18n *ngIf="invitationType === 'L'">Lista </span>
            <span class="text-primary font-weight-600"
                  *ngIf="invitationType === 'A'"> {{invitationDataAccess.name}}</span>
            <span class="text-primary font-weight-600"
                  *ngIf="invitationType === 'T'"> {{invitationDataTable.name}}</span>
            <span class="text-primary font-weight-600"
                  *ngIf="invitationType === 'L'"> {{invitationDataList.name}}</span>
          </p>
        </div>

        <div class="display-flex flex-col">
          <div class="w-full">
            <div class="w-full" *ngIf="invitationDataTable !== null">
              <div class="display-flex align-items-start mb-2" *ngIf="invitationDataTable?.tableType">
                <i class="fa-light fa-champagne-glasses text-primary text-xs lh-16"></i>
                <p class="text-sm lh-16 my-0 text-gray-3 ml-2">{{invitationDataTable?.tableType}}</p>
              </div>
              <div class="display-flex align-items-start mb-2" *ngIf="invitationDataTable?.capacity > 0">
                <i class="fa-light fa-people-pants-simple text-primary text-xs lh-16"></i>
                <p class="text-sm lh-16 my-0 text-gray-3 ml-2">{{invitationDataTable?.capacity}}
                  <ng-container i18n>personas</ng-container>
                </p>
              </div>
              <div class="display-flex align-items-start mb-2" *ngIf="invitationDataTable?.consumerCredit > 0">
                <i class="fa-light fa-circle-dollar-to-slot text-primary text-xs lh-16"></i>
                <p class="text-sm lh-16 my-0 text-gray-3 ml-2">
                  <ng-container i18n>Crédito de consumo:</ng-container>
                  {{currency}}{{invitationDataTable?.consumerCredit | number:
                  '1.0-2'}}</p>
              </div>
            </div>
            <div class="display-flex align-items-center mb-2" *ngFor="let description of descriptions">
              <i class="fa-light fa-circle-check text-primary mr-2"></i>
              <p class="text-sm text-gray-3 font-light my-0 lh-16 vertical-middle">{{description}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="message__guest_authorized" *ngIf="guestAlreadyAuthorize">
      <i class="fal fa-info-circle text-info text-12xl mb-2"></i>
      <p class="mb-4 text-xl lh-26 text-gray-1 font-secondary font-weight-600">
              <span *ngIf="guestIsCustomer">
                {{userToAdd.name + ' ' + userToAdd.lastName }}
                <ng-container i18n>ya forma parte de este evento.</ng-container>
              </span>
        <span *ngIf="!guestIsCustomer">
                {{eventGuestRequest.document }}
          <ng-container i18n>ya forma parte de este evento.</ng-container>
              </span>
      </p>
    </div>
  </div>

  <ng-template pTemplate="footer" class="bid-modal__footer">
    <button
      pButton
      class="btn btn__secondary"
      (click)="cancel()"
      label="{{showingDataCustomerAndInvitation ? backLabel : cancelLabel}}"
    ></button>
    <button
      pButton
      class="btn btn__primary"
      (click)="checkCustomerGuest()"
      label="{{nextLabel}}"
      *ngIf="!showingDataCustomerAndInvitation"
    ></button>
    <button
      pButton
      class="btn btn__primary"
      (click)="submitCreateGuest()"
      label="{{addGuestLabel}}"
      *ngIf="(showingDataCustomerAndInvitation && !guestAlreadyAuthorize) && !userIsBlocked"
    ></button>
    <button
      pButton
      class="btn btn__primary"
      (click)="onHide()"
      label="{{finishLabel}}"
      *ngIf="(showingDataCustomerAndInvitation && guestAlreadyAuthorize) || userIsBlocked"
    ></button>
  </ng-template>

</p-dialog>

