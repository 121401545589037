import {Component, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {IDValidationRequest} from 'src/app/models/IDValidationRequest';
import {User} from 'src/app/models/user';
import {AuthService} from 'src/app/services/auth.service';
import {CountriesService} from 'src/app/services/countries.service';
import {InputValidationService} from 'src/app/services/input-validation.service';
import {SpinnerService} from 'src/app/services/spinner.service';
import {Countries} from 'src/app/models/Countries';
import {RegisterPayload} from 'src/app/models/RegisterForm';
import {IdType, IdTypeData} from '../../../../../../enum/idType';
import {Location} from '@angular/common';

@Component({
  selector: 'app-data-required',
  templateUrl: './data-required.component.html',
  styleUrls: ['./data-required.component.scss']
})
export class DataRequiredComponent implements OnInit {
  updateDataForm: UntypedFormGroup;
  language = 'es';
  formHasBeenSubmitted = false;
  dniValidation = null;
  countrySelected: any;
  isReadOnly = false;
  searchingCountries = false;
  idOptionTypes: Array<IdTypeData>;
  countries: Countries[] = [];
  idTypesArg: Array<IdTypeData> = [{
    name: 'DNI',
    value: IdType.DNI
  }, {
    name: $localize`Pasaporte`,
    value: IdType.PASSPORT
  }];

  idTypesROW = [{
    name: 'ID',
    value: IdType.ID
  }, {
    name: $localize`Pasaporte`,
    value: IdType.PASSPORT
  }];

  idHighlighted = '';
  placeHolderCountry = $localize`Seleccioná tu país*`;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private validateInputService: InputValidationService,
    private countriesService: CountriesService,
    private spinnerService: SpinnerService,
    private location: Location,
    private authService: AuthService,
    private router: Router) {
    this.updateDataForm = this.formBuilder.group({
      country: ['', Validators.required],
      originTypeId: ['', Validators.required],
      id: ['', Validators.required],
      idValidation: ['', Validators.required],
    });
  }

  get countryField(): AbstractControl {
    return this.updateDataForm.controls.country;
  }

  get typeIdField(): AbstractControl {
    return this.updateDataForm.controls.originTypeId;
  }

  get idField(): AbstractControl {
    return this.updateDataForm.controls.id;
  }

  get idValidationField(): AbstractControl {
    return this.updateDataForm.controls.idValidation;
  }

  ngOnInit(): void {

    this.countriesService.getAllCountries().subscribe((res: Countries[]) => {
      this.countries = res;
    });

    this.language = localStorage.getItem('current_locale');
    this.countrySelected = this.updateDataForm.controls.country.value;

    if (this.updateDataForm.controls.originTypeId.value === '') {
      this.updateDataForm.controls.originTypeId.patchValue(IdType.DNI);
    }

    if (this.updateDataForm.controls.country.value === '' || this.updateDataForm.controls.country.value === 'arg') {
      this.updateDataForm.controls.country.patchValue('arg');
      this.idOptionTypes = this.idTypesArg;
    } else {
      this.idOptionTypes = this.idTypesROW;
    }

    this.spinnerService.loadSpinner.next(false);

  }

  onIdFocus() {
    this.idHighlighted = 'highlighted';
  }

  onIdFocusOut() {
    this.idHighlighted = '';
  }

  onOptionTypeChange(e: string) {
    this.resetId();
  }

  onDropdownChange(e) {
    if (e.alpha3 === 'arg') {
      this.idOptionTypes = this.idTypesArg;
      this.updateDataForm.controls.originTypeId.patchValue(IdType.DNI);
    } else {
      this.idOptionTypes = this.idTypesROW;
      this.updateDataForm.controls.originTypeId.patchValue(IdType.ID);
    }

    this.resetId();
  }

  resetId() {
    this.updateDataForm.controls.id.patchValue('');
    this.updateDataForm.controls.idValidation.patchValue('');
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }

  verifyID(e) {
    if (this.updateDataForm.controls.country.value === 'arg' && this.updateDataForm.controls.originTypeId.value === IdType.DNI) {
      return this.onlyNumber(e);
    }
  }

  onlyNumber(event) {
    // tslint:disable-next-line:prefer-const
    const charCode = (event.which) ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }


  async saveForm() {
    this.formHasBeenSubmitted = true;
    this.spinnerService.loadSpinner.next(true);

    const isDNIValid = await this.validIDorDNI();
    const formIsValid = this.dataRequiredFormIsValid() && isDNIValid;

    if (formIsValid) {
      this.updateDataUser();
    } else {
      if (this.idField.value !== this.idValidationField.value) {
        this.idValidationField.setErrors({'IDdoesnotMatch': true});
      }
      this.spinnerService.loadSpinner.next(false);
    }
  }

  updateDataUser() {
    const logged = this.authService.isLoggedIn();

    if (logged) {
      this.authService.getCurrentUserData().subscribe({
        next: async (user: User) => {

          user.idType = this.typeIdField.value;
          user.document = this.idField.value;
          user.country = this.countryField.value.toUpperCase();

          const payload: RegisterPayload = user;

          this.authService.saveChangesUser(payload).subscribe({
            next: async () => {

              this.authService.getCurrentUserData().subscribe({
                next: async (u: User) => {
                  this.authService.updateUser(u);
                  this.location.back();
                }
              });
            },
            error: async (err) => {
              this.spinnerService.loadSpinner.next(false);
            }
          });
        }, error: async (err) => {
          this.spinnerService.loadSpinner.next(false);
        }
      });
    } else {
      this.router.navigate(['/home']);
    }
  }


  async validIDorDNI() {
    if (this.updateDataForm.controls.country.value === 'arg') {
      if (this.updateDataForm.controls.id.value.length >= 7 && this.updateDataForm.controls.id.value.length <= 9) {
        return this.validateDNIorID();
      } else {
        this.updateDataForm.controls.id.setErrors({'DNIcantChars': true});
        return false;
      }
    } else {
      if (this.updateDataForm.controls.country.valid && this.updateDataForm.controls.id.valid) {
        return this.validateDNIorID();
      } else {
        return false;
      }
    }
  }

  async validateDNIorID() {
    if (this.updateDataForm.controls.id.value === this.updateDataForm.controls.idValidation.value) {
      const payload: IDValidationRequest = {
        country: this.updateDataForm.controls.country.value.toUpperCase(),
        idType: this.updateDataForm.controls.originTypeId.value,
        document: this.updateDataForm.controls.id.value
      };
      const dniOrIdStatus = await this.validateDNIorIDFromBack(payload);
      if (dniOrIdStatus) {
        return true;
      } else {
        this.updateDataForm.controls.id.setErrors({'DNIAlreadyExist': true});
        return false;
      }
    } else {
      this.updateDataForm.controls.idValidation.setErrors({'DNIdoesnotMatch': true});
      return false;
    }
  }

  validateDNIorIDFromBack(idValidationRequest: IDValidationRequest): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let isValidID = false;
      this.authService.validateDNIorIDFromBack(idValidationRequest).subscribe({
        next: res => {
          isValidID = res;
          if (!isValidID) {
            this.spinnerService.loadSpinner.next(false);
            switch (idValidationRequest.idType) {
              case 'DNI':
                this.updateDataForm.controls.id.setErrors({'DNIAlreadyExist': true});
                break;
              case 'ID':
                this.updateDataForm.controls.id.setErrors({'IDAlreadyExist': true});
                break;
              case 'PASSPORT':
                this.updateDataForm.controls.id.setErrors({'PassportAlreadyExist': true});
                break;
              default:
                this.updateDataForm.controls.id.setErrors({'DNIAlreadyExist': true});
                break;
            }
          }
          resolve(res);
        },
        error: () => {
          this.updateDataForm.controls.id.setErrors({'ErrorOnIdVerification': true});
          reject(isValidID);
          this.spinnerService.loadSpinner.next(false);
        }
      });
    });
  }

  dataRequiredFormIsValid() {
    return this.countryField.valid && this.idValidationField.valid && this.idField.valid && this.idValidationField.valid &&
      this.idField.value === this.idValidationField.value;
  }

  backTo() {
    this.location.back();
  }
}
