import * as AOS from 'aos';
import {AfterContentChecked, Component, HostListener, OnInit} from '@angular/core';
import {EventForCustomer} from 'src/app/models/events/EventForCustomer';
import {EventService} from 'src/app/services/event.service';
import Masonry from 'masonry-layout';
import {Router} from '@angular/router';
import {SpinnerService} from '../../services/spinner.service';

@Component({
  selector: 'app-my-events',
  templateUrl: './my-events.component.html',
  styleUrls: ['./my-events.component.scss']
})
export class MyEventsComponent implements OnInit, AfterContentChecked {
  pendingEvents: Array<EventForCustomer>;
  hasEvents = true;
  loading = false;
  typePage = 'myEvents';
  showAlertDelayWithTransactions = false;
  showAlertCurrentEvents = false;
  getScreenWidth: number;

  constructor(
    private eventService: EventService,
    private spinnerSrv: SpinnerService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    AOS.init({
      duration: 800,
      once: true
    });
    this.getScreenWidth = window.innerWidth;
    const alertDelay = JSON.parse(sessionStorage.getItem('alert-delay'));
    if (alertDelay) {
      this.showAlertDelayWithTransactions = true;
    }
    this.pendingEvents = [];
    this.loading = true;
    this.eventService.getEventsByCustomer().subscribe(events => {
      this.pendingEvents = events;
      this.loading = false;
      const alertCurrentEvents = JSON.parse(sessionStorage.getItem('alert-current-events'));
      if (alertCurrentEvents && this.pendingEvents.length > 0) {
        this.showAlertCurrentEvents = true;
      }
    });

  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
  }

  ngAfterContentChecked() {
    if (this.getScreenWidth > 760) {
      if (document.getElementsByClassName('card').length) {
        this.masonryLayout();
      }
    }
  }

  watchHistory() {
    this.router.navigate(['/history']);
  }

  openMyEvents() {
    this.typePage = 'myEvents';
  }

  openBillboard() {
    this.router.navigate(['/']);
    this.typePage = 'billboard';
  }

  login() {
    this.router.navigate(['/login']);
  }

  closeAlertDelay() {
    sessionStorage.setItem('alert-delay', 'false');
    this.showAlertDelayWithTransactions = false;
  }

  closeAlertCurrentEvents() {
    sessionStorage.setItem('alert-current-events', 'false');
    this.showAlertCurrentEvents = false;
  }

  masonryLayout() {
    const masonry = new Masonry('.grid-events', {
      gutter: '.gutter-sizer',
      itemSelector: '.grid-item',
      percentPosition: true,
      horizontalOrder: true,
      transitionDuration: '0.1s'
    });
  }
}
