<div class="my-events__alert_delay_transactions" *ngIf="showAlertDelayWithTransactions">
  <div class="my-events__alert_delay_transactions-wrapper">
    <div class="flex">
      <i class="fas fa-info-circle text-gray-6 mr-2 icon__info"></i>
      <p class="my-0 text-sm lh-24 text-gray-6 align-self-center font-medium" i18n>
        Si adquiriste un evento, puede demorar hasta 5 minutos en aparecer aquí.
      </p>
    </div>
    <button class="alert-btn__close" (click)="closeAlertDelay()">
      <i class="fal fa-xmark-circle text-gray-6 icon__close"></i>
    </button>
  </div>
</div>
<div class="my-events__alert_current_events" *ngIf="showAlertCurrentEvents"
     [ngClass]="showAlertDelayWithTransactions ? 'no-margin-top' : ''">
  <div class="my-events__alert_current_events-wrapper">
    <div class="flex">
      <i class="fas fa-info-circle text-gray-6 mr-2 icon__info"></i>
      <p class="my-0 text-sm lh-24 text-gray-6 align-self-center font-medium" i18n>
        Ya contás con acceso autorizado para ingresar a estos eventos.
      </p>
    </div>
    <button class="alert-btn__close" (click)="closeAlertCurrentEvents()">
      <i class="fal fa-xmark-circle text-gray-6 icon__close"></i>
    </button>
  </div>
</div>
<section class="my-events">
  <div class="wrapper" [ngClass]="showAlertDelayWithTransactions ? 'no_padding_top': ''">
    <div class="my-events__header-mobile">
      <div class="flex items-center justify-start">
        <i class="fa-regular fa-calendar-star text-gray-1 icon__events"></i>
        <p class="title__my-events" i18n>Mis eventos</p>
      </div>
      <button class="btn btn__terciary flex" (click)="watchHistory()">
        <p class="text-sm my-0 lh-16 text-primary font-light ml-2" i18n>Historial</p>
      </button>
    </div>

    <div class="my-events__header">
      <div class="flex items-center justify-start">
        <i class="fa-regular fa-calendar-star text-gray-1 icon__events"></i>
        <p class="title__my-events" i18n>Mis eventos</p>
      </div>
      <button class="btn btn__terciary flex" (click)="watchHistory()">
        <i class="fa-light fa-clock-rotate-left text-primary text-sm mt-1"></i>
        <p class="text-base my-0 lh-24 text-primary font-medium ml-2" i18n>Ver historial</p>
      </button>
    </div>

    <div class="my-events__body" data-aos="fade-in">
      <section class="my-events__carrousel">
        <div class="grid-events">
          <div class="grid-item" *ngFor="let pEvent of pendingEvents">
            <card id="cardEvent" [event]="pEvent" [skeletonLoading]="loading"></card>
          </div>
          <div class="gutter-sizer"></div>
        </div>
        <div class="evenst__list--mobile">
          <card *ngFor="let pEvent of pendingEvents" [event]="pEvent" [skeletonLoading]="loading"></card>
        </div>
        <div class="my-events__empty" *ngIf="!loading && pendingEvents.length === 0">
          <div class="text-center">
            <img src="../../../assets/images/empty-events.png" class="icon-empty" alt="Vacio"/>
            <p class="text-2xl font-normal text-gray-35 lh-30 mt-8 mb-0" i18n>Todavía no tenés eventos</p>
          </div>
        </div>
      </section>
    </div>
  </div>
</section>
