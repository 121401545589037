<section class="change-password">
  <div class="wrapper">
    <div class="flex title-change-password">
      <i class="fal fa-key lh-24 text-gray-3 margin-right-8"></i>
      <p class="my-0 font-weight-500 font-secondary lh-24 text-gray-3" i18n>
        Cambiar contraseña
      </p>
    </div>
    <div class="container__form">
      <form class="password-form" [formGroup]="newPasswordForm">
        <bid-input-password
          i18n-label
          class="margin-bottom-40 display-block"
          [parentForm]="newPasswordForm"
          [page]="'password'"
          [controlName]="'oldPassword'"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [label]="'Contraseña actual'">
        </bid-input-password>
        <bid-input-password
          i18n-label
          class="margin-bottom-24 display-block"
          [page]="'password'"
          [parentForm]="newPasswordForm"
          [controlName]="'newPassword'"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [label]="'Contraseña nueva'"
          [passwordMatch]="passwordMatch"
          [showLabelRequirement]="true"
          >
        </bid-input-password>
        <bid-input-password
          i18n-label
          [parentForm]="newPasswordForm"
          [page]="'password'"
          [controlName]="'newPasswordRepeat'"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [label]="'Confirmar contraseña'"
          [passwordMatch]="passwordMatch">
        </bid-input-password>
      </form>
    </div>
  </div>
</section>

<app-nav-fixed
  [thereAreChanges]="thereAreChanges"
  [formLoaded]="formLoaded"
  (save)="savePassword()"
  (dontSave)="resetForm()">
</app-nav-fixed>
