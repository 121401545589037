<section class="complete_first_login" data-aos="fade-in">
  <div class="complete_first_login__container">
    <div class="card">
      <div class="title">
        <p class="font-secondary text-lg lh-28 font-medium text-gray-1 my-0" i18n>
          Ingresá tus datos para acceder a tu cuenta
        </p>
      </div>
      <form class="form" [formGroup]="completeDataForm" (ngSubmit)="submit()">
        <div
          class="field__item mb-6"
          [ngClass]="validateInputClass(completeDataForm, 'email', formHasBeenSubmitted)"
        >
          <div class="p-float-label">
            <input
              #email
              formControlName="email"
              id="email"
              type="text"
              pInputText
              (keydown.space)="$event.preventDefault()"
              class="p-inputtext p-component"
            />
            <label for="email" class="title-float" i18n>Email</label>
            <app-error-message
              [page]="'register'"
              [ctrl]="completeDataForm.get('email') | formControl"
              [formHasBeenSubmitted]="formHasBeenSubmitted"
            ></app-error-message>
          </div>
        </div>
        <div
          class="field__item mb-6"
          [ngClass]="validateInputClass(completeDataForm, 'name', formHasBeenSubmitted)"
        >
          <div class="p-float-label">
            <input
              #email
              formControlName="name"
              id="name"
              type="text"
              pInputText
              class="p-inputtext p-component"
            />
            <label for="name" class="title-float" i18n>Nombre*</label>
            <app-error-message
              [page]="'register'"
              [ctrl]="completeDataForm.get('name') | formControl"
              [formHasBeenSubmitted]="formHasBeenSubmitted"
            ></app-error-message>
          </div>
        </div>
        <div
          class="field__item mb-6"
          [ngClass]="validateInputClass(completeDataForm, 'lastName', formHasBeenSubmitted)"
        >
          <div class="p-float-label">
            <input
              #email
              formControlName="lastName"
              id="lastName"
              type="text"
              pInputText
              class="p-inputtext p-component"
            />
            <label for="lastName" class="title-float" i18n>Apellido*</label>
            <app-error-message
              [page]="'register'"
              [ctrl]="completeDataForm.get('lastName') | formControl"
              [formHasBeenSubmitted]="formHasBeenSubmitted"
            ></app-error-message>
          </div>
        </div>
        <div
          class="field__item mb-8"
          [ngClass]="validateInputClass(completeDataForm, 'phone', formHasBeenSubmitted)"
        >
          <div class="p-float-label">
            <input
              #email
              formControlName="phone"
              id="phone"
              type="text"
              pInputText
              class="p-inputtext p-component"
            />
            <label for="phone" class="title-float" i18n>Teléfono*</label>
            <app-error-message
              [page]="'register'"
              [ctrl]="completeDataForm.get('phone') | formControl"
              [formHasBeenSubmitted]="formHasBeenSubmitted"
            ></app-error-message>
          </div>
        </div>
        <fieldset class="field__checkbox mb-10"
                  [ngClass]="validateInputClass(completeDataForm, 'acceptTerms', formHasBeenSubmitted)">
          <div class="field">
            <input type="checkbox" id="check" class="checkbox" name="checkbox" formControlName="acceptTerms" #checkbox
                   required/>
            <label for="check" class="label">
              <ng-container i18n>Acepto los</ng-container>
              <a class="link__terms" routerLink="/terms-and-conditions">
                <ng-container i18n>términos y condiciones.</ng-container>
              </a>
            </label>
          </div>
        </fieldset>
        <button class="btn btn__md btn__login btn__primary" pButton type="submit" [disabled]="!completeDataForm.valid"
                i18n>
          Continuar
        </button>
      </form>
    </div>
    <div class="have__account">
      <p class="text-base font-normal lh-16 text-gray-1 my-0">
        <ng-container i18n>¿Ya tenés una cuenta?</ng-container>
        <a class="link" (click)="login()" i18n>Inicia sesión</a>
      </p>
    </div>
  </div>
</section>
