import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import {EventShortForCategorizedList} from '../../../../models/events/EventShortForCategorizedList';

@Component({
  selector: 'app-card-event',
  templateUrl: './card-event.component.html',
  styleUrls: ['./card-event.component.scss']
})
export class CardEventComponent implements OnInit {

  @Input() event?: EventShortForCategorizedList;
  @Input() loading: boolean;
  access = true;
  mediaUrl: string;
  currency = '$';

  dateFormat = $localize`dd MMM`;
  timeFormat = $localize`yy, HH:mm`;

  constructor(
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.mediaUrl = environment.mediaUrl;
    this.currency = this.event ? this.event.storeDataOnEvent.currency : '$';
  }

  async eventDetail(event?: EventShortForCategorizedList) {
    if (!this.loading) {
      if (event.hasAuthorizedAccess) {
        await this.router.navigate(['/my-events/event-detail/' + event.idEvent]);
      } else {
        await this.router.navigate(['detail-event/' + event.idEvent]);
      }
    }
  }
}
