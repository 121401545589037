<section class="my-events">
  <div class="my-events__header">
    <div class="back" *ngIf="!loading">
      <button class="btn btn__back color-primary" (click)="back()">
        <i class="fa-regular fa-arrow-left icon__back"></i>
        <span i18n>Volver</span>
      </button>
    </div>
    <div class="title">
      <i class="fa-regular fa-clock-rotate-left text-gray-1 icon__events"></i>
      <p class="title__my-events" i18n>Historial de eventos</p>
    </div>
  </div>
  <div class="wrapper">
    <div class="my-events__body">
      <section class="my-events__carrousel">
        <div class="grid-events">
          <div class="grid-item" *ngFor="let pEvent of historyEvents">
            <card [event]="pEvent" [skeletonLoading]="loading"></card>
          </div>
          <div class="gutter-sizer"></div>
        </div>
        <div class="events__list--mobile">
          <card *ngFor="let pEvent of historyEvents" [event]="pEvent" [skeletonLoading]="loading"></card>
        </div>
        <div class="my-events__empty" *ngIf="!loading && historyEvents.length === 0">
          <div class="text-center">
            <img src="../../../../assets/images/empty-events.png" class="icon-empty" alt="Empty image"/>
            <p class="text-2xl font-normal text-gray-35 lh-30 mt-8 mb-0" i18n>Todavía no tenés eventos</p>
          </div>
        </div>
      </section>
    </div>
  </div>
</section>
