<section class="login-container">
  <div *ngIf="!emailSent; else emailHasBeenSended" class="card">
    <div class="card__left" [ngClass]="{'single': !emailSent}">
      <div class="back-container margin-bottom-24 cursor-pointer" (click)="backToForgotPassword()">
        <i class="fa-light fa-arrow-left-long text-primary margin-right-8"></i>
        <span class="display-block font-size-12 text-gray-1 font-weight-400" i18n>
          Volver
        </span>
      </div>
      <span class="display-block font-secondary lg-24 text-gray-35 font-weight-600 forgot-password margin-bottom-16"
            i18n>
        Ingresá tus datos
      </span>
      <span class="display-block font-secondary lg-18 text-gray-35 font-weight-400 font-size-13 margin-bottom-24" i18n>
        Completa tus datos personales y te enviaremos un enlace para recuperar tu contraseña.
      </span>
      <form class="form" [formGroup]="recoverEmailDniForm">
        <bid-infinite-scroll
          *ngIf="allCountries && allCountries.length > 0"
          [parentForm]="recoverEmailDniForm"
          [formControlName]="'country'"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [items]="allCountries"
          [page]="'register'"
          [placeholder]="placeHolderCountry"
          [bindLabel]="'es'"
          [bindValue]="'alpha3'"
          (dropdownChanges)="onDropdownCountryChange($event)"
        ></bid-infinite-scroll>

        <app-bid-input-dni
          [type]="'text'"
          [min]="minDocumentLength"
          [max]="maxDocumentLength"
          [page]="'recoverPassword'"
          [parentForm]="recoverEmailDniForm"
          [dropdownControlName]="'originTypeId'"
          [dropdownOptions]="idOptionTypes"
          [dropdownOptionLabel]="'name'"
          [dropdownOptionValue]="'value'"
          [inputControlName]="'id'"
          [largeMobile]="true"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [bgLightDesktop]="true"
          (dropdownChange)="onOptionTypeChange($event)">
        </app-bid-input-dni>

        <button
          pButton
          type="submit"
          class="btn btn__md btn__login btn__primary card__recover-password"
          (click)="submitRecoverPassword()"
          [disabled]="formHasBeenSubmitted  && !recoverEmailDniForm.valid"
          i18n
        >Recuperar contraseña
        </button>
      </form>
    </div>
  </div>
  <ng-template #emailHasBeenSended class="card email-sended">
    <div class="card email-sended">
      <div class="card__left">
        <div class="back-container margin-bottom-24 cursor-pointer" (click)="backToLogin()">
          <i class="fa-light fa-arrow-left-long text-primary margin-right-8"></i>
          <span class="display-block font-size-12 text-gray-1 font-weight-400" i18n>
            Volver a Iniciar sesión
          </span>
        </div>
        <span class="display-block font-secondary lg-24 text-gray-1 font-weight-600 forgot-password margin-bottom-16"
              i18n>
          ¡Revisá tu casilla de correo!
        </span>
        <span class="display-block font-secondary lg-18 text-gray-35 font-weight-400 font-size-13 margin-bottom-16"
              i18n>
          Te enviamos un mail a {{ sendEmailTo }}. Por favor seguí las instrucciones para recuperar tu contraseña.
        </span>
        <span class="display-block font-secondary lg-18 text-gray-1 font-weight-400 font-size-13 margin-bottom-16" i18n>
          El link tendrá una validez de 30 minutos. Pasado ese tiempo, deberás enviarlo nuevamente.
        </span>
        <button
          pButton
          type="submit"
          class="btn btn__sm btn__login btn__secondary card__recover-password margin-top-16"
          (click)="resendEmail()"
          i18n
        >Enviar nuevamente
        </button>
      </div>

      <div class="card__right">
        <img class="card__mailbox--desktop" src="../../../assets/images/mail-box.png" alt="mail-box"/>
        <img class="card__mailbox--mobile" src="../../../assets/images/mail-box-mobile.png" alt="mail-box"/>
      </div>
    </div>
  </ng-template>
</section>
