import {Component, Input, OnInit} from '@angular/core';

import {UntypedFormControl} from '@angular/forms';
import {ValidationMessagesService} from 'src/app/services/validation-messages.service';


@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {
  @Input() formHasBeenSubmitted = false;
  @Input() page: string;
  @Input() ctrl: UntypedFormControl;
  @Input() customClass?: string;

  /* THIS CONFIG IS FOR CUSTOM TRANSLATE */
  @Input() customString = false;
  @Input() customKey = '';
  @Input() withoutIcon: boolean = false;
  validationMessages;


  constructor(private validationMessageService: ValidationMessagesService) {
  }

  ngOnInit(): void {
    this.validationMessages = this.validationMessageService.getValidationMessges();
  }

  shouldShowErrors(): boolean {
    if (this.customString && this.customKey !== '') {
      return true;
    } else if (this.ctrl &&
      (this.ctrl.invalid && this.formHasBeenSubmitted) ||
      (this.ctrl.touched && this.ctrl.dirty && this.ctrl.invalid)) {
      return true;
    }

    return false;
  }

  listOfErrors(): string[] {
    if (this.customString) {
      const errorList = [];
      if (this.customKey) {
        errorList.push(this.validationMessages[this.page][this.customKey]);
        return errorList;
      } else {
        // console.error('This component needs customKey when custom is TRUE');
      }
    } else {
      return Object.keys(this.ctrl.errors).map((err) => {
        return this.validationMessages[this.page][err];
      });
    }
  }
}
