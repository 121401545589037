<div class="modal" [ngClass]="showModal ? 'show__modal' : 'close__modal'" (click)="closeModal()">
  <div class="modal__container">
    <div class="modal__header">
      <button class="btn__close" (click)="closeModal()">
        <i class="fa-2xl fa-light fa-xmark icon__close"></i>
      </button>
    </div>
    <div class="modal__body">
      <div class="modal__image">
        <img [src]="image" class="image" alt="Imagen del evento"/>
      </div>
    </div>
  </div>
</div>
