import {Component, Input, OnInit} from '@angular/core';
import {EventsCategorized} from '../../../../models/events/EventsCategorized';

@Component({
  selector: 'app-grid-events',
  templateUrl: './grid-events.component.html',
  styleUrls: ['./grid-events.component.scss']
})
export class GridEventsComponent implements OnInit {

  @Input() allCategoriesEvents: EventsCategorized[];
  @Input() loading: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

}
