import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-modal-download-app',
  templateUrl: './modal-download-app.component.html',
  styleUrls: ['./modal-download-app.component.scss']
})
export class ModalDownloadAppComponent implements OnInit {

  @Input() title;
  @Input() content;
  @Input() twoBtns;
  @Input() contentBtn;
  @Input() isForDownload;
  @Input() linkToNavigate;
  @Input() showModal: boolean;
  @Output() closeButtonEvent = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  downloadApp() {
    if (this.isForDownload) {
      window.open(environment.downloadLink, '_blank');
    } else {
      window.open(this.linkToNavigate, '_blank');
    }
  }

  close() {
    this.showModal = false;
    this.closeButtonEvent.emit(this.showModal);
  }
}
