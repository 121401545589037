import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {UntypedFormControl} from '@angular/forms';
import {JSONStrings} from 'src/app/core/internalization/es';


@Component({
  selector: 'bid-input',
  templateUrl: './bid-input.component.html',
  styleUrls: ['./bid-input.component.scss']
})
export class BidInputComponent implements OnInit, AfterViewInit {

  @Input() label: string = 'Campo';
  @Input() required: boolean = false;
  @Input() onFocus: boolean = false;
  @Input() onError: boolean = false;
  @Input() readonly: boolean = false;
  @Input() success: boolean = false;
  @Input() type: string = 'text';
  @Input() name: string = '';
  @Input() hint: boolean = true;
  @Input() value = '';
  @Input() ctrl: UntypedFormControl;
  @Input() page: string = '';
  @Input() capitalize: boolean = false;
  @Input() lowerCase: boolean = false;
  @Input() uppercase: boolean = false;
  @Input() formHasBeenSubmitted: boolean = false;
  @Input() preventSpace: boolean = false;
  @Input() customClass: string = '';
  @Input() withIcon: boolean = true;
  @Output() data = new EventEmitter<string>();
  @Input() onFilled: boolean;
  @Input() maxlength: number = 50;
  @Input() customError: boolean = false;
  @Input() validate: boolean = false;
  @Input() bgLightMobile: boolean = false;
  @Input() bgLightDesktop: boolean = false;
  @Input() large: boolean = false;
  @Input() largeMobile: boolean = false;
  @Input() largeDesktop: boolean = false;
  @Input() medium: boolean = false;
  @ViewChild('input') input: ElementRef;

  helperText: string = '';
  /* THIS CONFIG IS FOR CUSTOM TRANSLATE */
  @Input() customString = false;
  @Input() customKey = '';

  constructor() {
  }

  ngOnInit(): void {
    this.filledInput();
  }

  ngAfterViewInit() {
    this.filledInput();
    this.shouldShowErrors();
    this.checkIsFocus();
  }

  onFocusInput() {
    this.onFocus = true;
    this.onError = false;
  }

  checkIsFocus() {
    if (this.onFocus) {
      this.onError = false;
      setTimeout(() => this.input.nativeElement.focus(), 500);
    }
  }

  onBlurInput() {
    this.onFocus = false;
    this.onError = false;
  }

  onChange(event) {
    this.value = event.target.value;
    this.filledInput();

    if (this.lowerCase) {
      this.value = event.target.value.toLowerCase();
      this.data.emit(this.value);
    } else {
      this.data.emit(this.value);
    }
  }

  filledInput() {
    if (this.value.length != 0) {
      this.onFilled = true;
      this.onError = false;
    } else {
      this.onFilled = false;
    }
    return this.onFilled;
  }

  shouldShowErrors(): boolean {
    if (this.ctrl != null) {
      if (this.ctrl.invalid && this.formHasBeenSubmitted) {
        this.onError = true;
      }
    } else {
      this.onError = this.customError;
    }
    return this.onError;
  }


  listOfErrors(): string[] {
    if (this.customString) {
      if (this.customKey) {
        return JSONStrings[this.page][this.customKey];
      } else {
        // console.error('This component needs customKey when custom is TRUE');
      }
    } else if (this.ctrl != null) {
      return Object.keys(this.ctrl.errors).map((err) => {
        return JSONStrings[this.page][err];
      });
    }
  }
}
