import * as AOS from 'aos';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {User} from 'src/app/models/user';
import {AuthService} from 'src/app/services/auth.service';
import {InputValidationService} from 'src/app/services/input-validation.service';
import {SpinnerService} from 'src/app/services/spinner.service';
import {IdType, IdTypeData} from '../../enum/idType';
import {Genders, GendersData} from '../../enum/Genders';
import {CountriesService} from '../../services/countries.service';
import {Subscription} from 'rxjs';
import {PhoneNumberInfo} from '../../models/PhoneNumberInfo';
import {maskType} from 'src/app/models/inputs/maskType';
import {JSONStrings} from 'src/app/core/internalization/es';
import {PhoneService} from 'src/app/services/phone.service';
import {IDValidationRequest} from '../../models/IDValidationRequest';
import {ProfilePayload} from 'src/app/models/ProfileForm';
import {Countries} from 'src/app/models/Countries';
import {CountryISO, PhoneNumberFormat, SearchCountryField} from 'ngx-intl-tel-input-gg';
import {MessageService} from 'primeng/api';
import {ToastService} from 'src/app/services/toast.service';
import {ModalActionComponent} from '../../share/components/modal-action/modal-action.component';
import {ModalController} from '../../controller/modal-controller';

const trimValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (control.value.startsWith(' ')) {
    return {
      trimError: {value: 'control has leading whitespace'}
    };
  }
  if (control.value.endsWith(' ')) {
    return {
      trimError: {value: 'control has trailing whitespace'}
    };
  }

  return null;
};

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  @ViewChild('mask') mask: ElementRef;
  userForm: UntypedFormGroup;
  formHasBeenSubmitted = false;
  user: User;
  errorContent: string;
  showErrorModal = false;
  isReadOnly = true;
  labelPhone = $localize`Teléfono*`;
  countrySelected: any;
  searchingCountries = false;
  idOptionTypes: Array<IdTypeData>;
  countries: Countries[] = [];
  language = 'es';
  idTypesArg: Array<IdTypeData> = [{
    name: 'DNI',
    value: IdType.DNI
  }, {
    name: $localize`Pasaporte`,
    value: IdType.PASSPORT
  }];
  idTypesROW = [{
    name: 'ID',
    value: IdType.ID
  }, {
    name: $localize`Pasaporte`,
    value: IdType.PASSPORT
  }];
  selectYourCountry = $localize`Seleccioná tu país*`;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Argentina, CountryISO.Brazil, CountryISO.Spain];
  search = $localize`Buscar`;
  sexString = $localize`Sexo`;
  genders: Array<GendersData>;
  gendersOptions: Array<GendersData> = [{
    name: $localize`Masculino`,
    value: Genders.MALE
  }, {
    name: $localize`Femenino`,
    value: Genders.FEMALE
  }, {
    name: $localize`No especifica`,
    value: Genders.OTHER
  }];
  formLoaded = false;
  locale: string;
  thereAreChanges = false;
  subs: Subscription[] = [];
  originalPhoneData: PhoneNumberInfo = null;
  MASKTYPE = maskType;
  helperText = '';
  label = 'Campo';
  required = false;
  onFocus = false;
  onError = false;
  readonly = false;
  success = false;
  hint = true;
  page = '';
  preventSpace = false;
  customClass = '';
  withIcon = true;
  onFilled = false;
  maxlength = '';
  customError = false;
  validate = false;
  bgLightMobile = false;
  bgLightDesktop = false;
  large = false;
  largeMobile = false;
  largeDesktop = false;
  dniOrIdStatus = false;
  /* THIS CONFIG IS FOR CUSTOM TRANSLATE */
  customString = false;
  customKey = '';
  genderReadOnly = false;
  birthDateDisabled = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private validateInputService: InputValidationService,
    private authService: AuthService,
    private countriesService: CountriesService,
    private spinnerSrv: SpinnerService,
    private phoneService: PhoneService,
    private messageService: MessageService,
    private toastService: ToastService,
    private modalCtrl: ModalController
  ) {
    this.userForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
          this.validateInputService.emailValidator(),
          trimValidator
        ],
      ],
      name: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: [''],
      country: ['', Validators.required],
      idType: ['', Validators.required],
      document: ['', Validators.required],
      birthdate: [
        '',
        [
          Validators.pattern('^(([012]{1})?[0-9]{1}|[3]?[01]{1})\/(([0]{1})?[0-9]{1}|[1]{1}?[012]{1})\/([12]{1}[09]{1}[0-9]{2})$')
        ]
      ],
      gender: [''],
    });
  }


  get maskField(): AbstractControl {
    return this.userForm.get('birthdate');
  }

  get idTypeField(): AbstractControl {
    return this.userForm.get('idType');
  }

  get documentField(): AbstractControl {
    return this.userForm.get('document');
  }

  get countryField(): AbstractControl {
    return this.userForm.get('country');
  }

  get genderField(): AbstractControl {
    return this.userForm.get('gender');
  }

  get birthdateField(): AbstractControl {
    return this.userForm.get('birthdate');
  }

  ngOnInit(): void {
    AOS.init({
      duration: 800,
      once: true
    });
    this.spinnerSrv.loadSpinner.next(true);
    this.locale = localStorage.getItem('current_locale') ? localStorage.getItem('current_locale') : 'es';
    if (this.authService.isLoggedIn()) {
      this.subs.push(
        this.authService.getCurrentUserData().subscribe({
          next: async u => {
            this.spinnerSrv.loadSpinner.next(false);
            this.user = u;
            if (u.blocked) {
              const modal = await this.createModalAction(
                'icon-warning.svg',
                $localize`Cuenta bloqueada`,
                $localize`Tu cuenta se encuentra bloqueada para operar. Si crees que se debe a un error, por favor comunicate con soporte.`,
                $localize`Aceptar`
              );
              const data = modal.onDidDismiss();
              if (data) {
                this.authService.logout();
              }
            }
            this.authService.updateUser(u);
            this.checkDisableInput(u);
            this.loadForm(this.user);
          },
          error: () => {
            this.spinnerSrv.loadSpinner.next(false);
          }
        })
      );
    }

    this.genders = this.gendersOptions;
    this.countriesService.getAllCountries().subscribe({
      next: (res: Countries[]) => {
        this.countries = res;
      }
    });

    this.maskField.valueChanges.subscribe(() => {
      this.filledInput();
      this.validateChanges();
    });

    this.genderField.valueChanges.subscribe(() => {
      this.validateChanges();
    });

    this.language = localStorage.getItem('current_locale');

    this.userForm.valueChanges.subscribe(() => {
      this.formHasBeenSubmitted = false;
    });
  }

  checkDisableInput(user) {
    if (user.birthdate !== null) {
      this.birthdateField.disable();
      this.birthDateDisabled = true;
    }

    if (user.idType !== null) {
      this.idTypeField.disable();
    }

    if (user.document !== null) {
      this.documentField.disable();
    }

    if (user.country !== null) {
      this.countryField.disable();
    }

    if (user.gender !== null) {
      this.genderField.disable();
      this.genderReadOnly = true;
    }
  }

  private async createModalAction(icon: string, title: string, content: string, textBtn: string) {
    const modal = await this.modalCtrl.create({
      component: ModalActionComponent,
      props: {
        icon,
        title,
        content,
        textBtn
      },
    });
    await modal.present();
    return modal;
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }

  onDropdownChange(e) {
    if (e.value === 'arg') {
      this.idOptionTypes = this.idTypesArg;
      this.userForm.controls.idType.patchValue(IdType.DNI);
    } else {
      this.idOptionTypes = this.idTypesROW;
      this.userForm.controls.idType.patchValue(IdType.ID);
    }

    this.resetId();
  }

  onOptionTypeChange(e: string) {
    this.resetId();
  }

  onOptionSexChange(e) {
    this.userForm.patchValue({
      gender: e.value
    });
    if (this.formLoaded) {
      this.validateChanges();
    }
  }

  resetId() {
    this.userForm.controls.document.patchValue('');
  }

  verifyID(e) {
    if (this.userForm.controls.country.value === 'arg' && this.userForm.controls.idType.value === IdType.DNI) {
      return this.onlyNumber(e);
    }
  }

  onlyNumber(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }

  async submitChanges() {

    if (this.userForm.valid) {
      this.formHasBeenSubmitted = true;
      this.spinnerSrv.loadSpinner.next(true);
      const payload = this.preparePayload();
      const isDNIValid = await this.validIDorDNI();
      if (isDNIValid) {
        if (this.userForm.get('email').value !== this.user.email) {
          const isValid: boolean = await this.isNewMailValid(
            this.userForm.get('email').value
          );
          if (isValid) {
            this.sendDataUser(payload);
          }
        } else {
          this.sendDataUser(payload);
        }
      } else {
        this.formHasBeenSubmitted = true;
        this.spinnerSrv.loadSpinner.next(false);
      }
    } else {
      this.formHasBeenSubmitted = true;
    }
  }

  preparePayload(): ProfilePayload {
    const form = this.userForm.getRawValue();
    form.email.trim();
    return {
      email: form.email,
      emailVerified: form.emailVerified,
      name: form.name,
      lastName: form.lastName,
      country: form.country.toUpperCase(),
      document: form.document,
      idType: form.idType,
      gender: form.gender ? form.gender : null,
      phoneNumberInfo: form.phone ? this.phoneService.getPhoneInfoParsed(form.phone) : null,
      birthdate: form.birthdate ? new Date(form.birthdate) : null
    };
  }

  sendDataUser(payload) {
    this.subs.push(
      this.authService.saveChangesUser(payload).subscribe({
        next: () => {
          this.subs.push(
            this.authService.getCurrentUserData().subscribe({
              next: (user: User) => {
                this.authService.updateUser(user);
                this.user = user;
                this.checkDisableInput(user);
                this.thereAreChanges = false;
                this.spinnerSrv.loadSpinner.next(false);
                this.messageService.add({
                  severity: 'success',
                  life: 5000,
                  detail: $localize`Datos guardados con éxito`
                });
              },
              error: () => {
                this.spinnerSrv.loadSpinner.next(false);
              }
            })
          );
        },
        error: (err) => {
          this.errorContent = $localize`Ocurrió un error al guardar los datos.`;
          this.spinnerSrv.loadSpinner.next(false);
          if (err.status === 404) {
            this.errorContent = $localize`Ocurrió un error al guardar los datos.`;
            this.showErrorModal = true;
          }
          if (err.status === 409) {
            this.userForm.controls.email.setErrors({EmailAlreadyExist: true});
          }
        }
      })
    );
  }

  async isNewMailValid(newEmail: string): Promise<boolean> {
    return this.authService.validateUserEmail(newEmail).toPromise().catch((err) => {
      if (err.status === 400) {
        this.userForm.controls.email.setErrors({EmailAlreadyExist: true});
        this.spinnerSrv.loadSpinner.next(false);
        return false;
      }
      if (err.status === 424) {
        this.userForm.controls.email.setErrors({InvalidEmail: true});
        this.spinnerSrv.loadSpinner.next(false);
        return false;
      }
    });
  }

  getDate(date: string): Date {
    if (date) {
      const dateData = date.split('/');
      return new Date(Number(dateData[2]), Number(dateData[1]) - 1, Number(dateData[0]));
    }
  }

  validateChanges() {
    if (this.formLoaded) {
      const birthdateData = this.user.birthdate !== null ? this.transformDateWithSlash(this.user.birthdate) : null;
      const form = this.userForm.getRawValue();
      const phone = form.phone && form.phone.internationalNumber && form.phone.internationalNumber
        .replace(form.phone.dialCode, '').replaceAll(' ', '').replaceAll('-', '').trim();

      const gender = form.gender === '' ? null : form.gender;
      const country = form.coutry === '' ? null : form.country;

      this.thereAreChanges = this.user.name !== form.name
        || this.user.lastName !== form.lastName
        || this.user.email !== form.email
        || this.user.phone !== phone
        || form.phone && this.user.isoCodePhone !== form.phone.countryCode?.toUpperCase()
        || birthdateData !== form.birthdate
        || this.user.gender !== gender
        || this.user.document !== form.document
        || this.user.country.toLowerCase() !== country
        || this.user.idType !== form.idType;
    }
  }

  transformDate(date) {
    const newDate = new Date(date);
    return ('0' + newDate.getDate()).slice(-2) + ('0' + newDate.getMonth()).slice(-2) + newDate.getFullYear();
  }

  transformDateWithSlash(date) {
    const newDate = new Date(date);
    return ('0' + newDate.getDate()).slice(-2) + '/' + ('0' + (newDate.getMonth() + 1)).slice(-2) + '/' + newDate.getFullYear();
  }

  loadForm(user) {
    this.userForm.patchValue({
      name: user.name,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      birthdate: user.birthdate !== null ? this.transformDateWithSlash(user.birthdate) : null,
      document: user.document,
      country: user.country !== null ? user.country.toLowerCase() : null,
    });

    if (this.userForm.controls.country.value === '' || this.userForm.controls.country.value === 'arg') {
      this.idOptionTypes = this.idTypesArg;
    } else {
      this.idOptionTypes = this.idTypesROW;
    }
    if (user.idType === null || user.idType === 'DNI') {
      this.userForm.controls.idType.patchValue(IdType.DNI);
    } else if (user.idType === 'ID') {
      this.userForm.controls.idType.patchValue(IdType.ID);
    } else if (user.idType === 'PASSPORT') {
      this.userForm.controls.idType.patchValue(IdType.PASSPORT);
    }
    if (user.gender !== null && user.gender === 'MALE') {
      this.userForm.controls.gender.patchValue(Genders.MALE);
    } else if (user.gender !== null && user.gender === 'FEMALE') {
      this.userForm.controls.gender.patchValue(Genders.FEMALE);
    } else if (user.gender !== null && user.gender === 'OTHER') {
      this.userForm.controls.gender.patchValue(Genders.OTHER);
    }

    this.countrySelected = this.userForm.controls.country.value;

    if (this.userForm.controls.idType.value === '') {
      this.userForm.controls.idType.patchValue(IdType.DNI);
    }

    if (this.userForm.controls.country.value === '' || this.userForm.controls.country.value === 'arg') {
      this.userForm.controls.country.patchValue('arg');
      this.idOptionTypes = this.idTypesArg;
    } else {
      this.idOptionTypes = this.idTypesROW;
    }
    this.spinnerSrv.loadSpinner.next(false);
    this.formLoaded = true;
  }

  resetForm() {
    this.originalPhoneData = new PhoneNumberInfo();
    this.originalPhoneData.number = this.user.phone !== null ? this.user.phone : null;
    this.originalPhoneData.internationalNumber = this.user.e164PhoneNumber !== null ? this.user.e164PhoneNumber : null;
    this.originalPhoneData.countryCode = this.user.isoCodePhone !== null ? this.user.isoCodePhone.toLowerCase() : null;
    const genderFound = this.gendersOptions.find(g => g.value === this.user.gender);
    let genderSet;
    if (genderFound !== null) {
      genderSet = genderFound;
    }
    this.userForm.patchValue({
      name: this.user.name,
      lastName: this.user.lastName,
      email: this.user.email,
      phone: this.originalPhoneData,
      birthdate: this.user.birthdate !== null ? this.transformDateWithSlash(this.user.birthdate) : null,
      gender: genderSet ? genderSet.value : null,
      document: this.user.document,
      country: this.user.country !== null ? this.user.country.toLowerCase() : null,
      idType: this.user.idType
    });
    this.thereAreChanges = false;
    this.formHasBeenSubmitted = false;
  }

  async validIDorDNI() {
    if (this.userForm.controls.country.value !== this.user.country.toLowerCase() || this.userForm.controls.idType.value !== this.user.idType
      || this.userForm.controls.document.value !== this.user.document) {
      if (this.userForm.controls.country.value === 'arg') {
        if (this.userForm.controls.document.value.length >= 7 && this.userForm.controls.document.value.length <= 9) {
          return this.validateDNIorID();
        } else {
          this.userForm.controls.document.setErrors({DNIcantChars: true});
          return false;
        }
      } else {
        if (this.userForm.controls.country.valid && this.userForm.controls.document.valid) {
          return this.validateDNIorID();
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  }

  async validateDNIorID() {
    const payload: IDValidationRequest = {
      country: this.userForm.controls.country.value.toUpperCase(),
      idType: this.userForm.controls.idType.value,
      document: this.userForm.controls.document.value
    };
    this.dniOrIdStatus = await this.validateDNIorIDFromBack(payload);
    if (this.dniOrIdStatus) {
      return true;
    } else {
      this.userForm.controls.document.setErrors({DNIAlreadyExist: true});
      return false;
    }
  }

  validateDNIorIDFromBack(idValidationRequest: IDValidationRequest): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let isValidID = false;
      this.authService.validateDNIorIDFromBack(idValidationRequest).subscribe({
        next: res => {
          this.spinnerSrv.loadSpinner.next(false);
          isValidID = res;
          if (!isValidID) {
            switch (idValidationRequest.idType) {
              case '0':
                this.userForm.controls.document.setErrors({DNIAlreadyExist: true});
                break;
              case '1':
                this.userForm.controls.document.setErrors({IDAlreadyExist: true});
                break;
              case '2':
                this.userForm.controls.document.setErrors({PassportAlreadyExist: true});
                break;
              default:
                this.userForm.controls.document.setErrors({DNIAlreadyExist: true});
                break;
            }
          }
          resolve(res);
        },
        error: () => {
          this.userForm.controls.document.setErrors({ErrorOnIdVerification: true});
          reject(isValidID);
        }
      });
    });
  }

  // Move to Helpers
  objectsHaveDifferences(first, second) {
    for (const key in first) {
      if (second.hasOwnProperty(key)) {
        if (first[key] !== second[key]) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }

  onActionErrorModal(e) {
    this.showErrorModal = false;
  }

  focusOutFn() {
    let email = this.userForm.get('email').value.trim();
    if (email.endsWith('.')) {
      email = email.slice(0, -1);
    }
    this.userForm.patchValue({
      email
    });
  }

  focusInFn() {
    this.formHasBeenSubmitted = false;
    this.userForm.get('email').setErrors({EmailAlreadyExist: false});
  }

  handleDataName(event) {
    this.userForm.get('name').setValue(event);
    if (this.formLoaded) {
      this.validateChanges();
    }
  }

  handleDataLastName(event) {
    this.userForm.get('lastName').setValue(event);
    if (this.formLoaded) {
      this.validateChanges();
    }
  }

  handleDataPhone(event) {
    this.userForm.get('phone').setValue(event);
    if (this.formLoaded) {
      this.validateChanges();
    }
  }

  handleDataEmail(event) {
    this.userForm.get('email').setValue(event);
    if (this.formLoaded) {
      this.validateChanges();
    }
  }

  handleDataDocument(event) {
    this.userForm.get('document').setValue(event);
    if (this.formLoaded) {
      this.validateChanges();
    }
  }

  ngOnDestroy() {
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }

  onFocusInput() {
    this.onFocus = true;
    this.onError = false;
  }

  onBlurInput() {
    this.onFocus = false;
    this.onError = false;
  }

  filledInput() {

    if (this.maskField.value && (this.maskField.value.length !== 0 || this.maskField.value !== null)) {
      this.onFilled = true;
      this.onError = false;
    } else {
      this.onFilled = false;
    }
    return this.onFilled;
  }

  shouldShowErrors(): boolean {
    if (this.maskField != null) {
      if (this.maskField.invalid && this.formHasBeenSubmitted) {
        this.onError = true;
      }
    } else {
      this.onError = this.customError;
    }
    return this.onError;
  }


  listOfErrors(): string[] {
    if (this.customString) {
      if (this.customKey) {
        return JSONStrings.profile[this.customKey];
      } else {
        // console.error('This component needs customKey when custom is TRUE');
      }
    } else if (this.maskField != null) {
      return Object.keys(this.maskField.errors).map((err) => {
        return JSONStrings.profile[err];
      });
    }
  }

  resendEmail() {
    this.authService.resendEmailToken().subscribe({
      next: () => {
        this.toastService.generateToast('success',
          $localize`Te enviamos un email de verificación`,
          $localize`Seguí las instrucciones desde tu casilla de correo, para activar tu cuenta.`);
      }, error: (err) => {
        this.toastService.generateToast('error',
          $localize`Error al enviar el email, intentelo nuevamente`,
          '');
      }
    });
  }
}
