import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-modal-must-signin',
  templateUrl: './modal-must-signin.component.html',
  styleUrls: ['./modal-must-signin.component.scss']
})
export class ModalMustSigninComponent implements OnInit {

  @Input() showModal;
  @Output() closeModal = new EventEmitter();
  @Output() actionModal = new EventEmitter();
  title = $localize`Iniciá sesión`;
  content = $localize`Para continuar con la compra debés iniciar sesión.`;
  textBtn = $localize`Iniciar sesión`;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  onCloseModal() {
    this.closeModal.emit();
  }

  onModalAction() {
    this.actionModal.emit();
  }

}
