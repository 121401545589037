<section class="buy-tickets">
  <div class="wrapper">
    <div class="back__container" *ngIf="!skeletonLoading">
      <button class="btn btn__back color-primary" (click)="back()">
        <i class="fa-regular fa-arrow-left icon__back"></i>
        <span i18n>Volver</span>
      </button>
    </div>

    <div class="card__container">
      <div class="card__header">
        <div class="hearder__info">
          <div class="event__image">
            <img [src]="environment.mediaUrl + eventData.image" class="image" [alt]="eventData.eventName"
                 *ngIf="!skeletonLoading"/>
            <div class="skeleton__image" *ngIf="skeletonLoading"></div>
          </div>
          <div class="event__data">
            <p class="text-lg text-gray-1 my-0 font-secondary font-semibold lh-16" *ngIf="!skeletonLoading">{{
              eventData.eventName }}</p>
            <p class="text-sm text-gray-3 mt-2 mb-0 font-secondary font-medium lh-24" *ngIf="!skeletonLoading">
              {{ (eventData.dateInit | localeDate:dateFormat | uppercase).replace(".", "") }} -
              {{ eventData.dateInit | localeDate:timeFormat }}
              <span i18n="Date range separator, it will be placed between the event start date and end date ">
                a
              </span>
              {{eventData.dateEnd | localeDate:'HH:mm'}}
            </p>
            <div class="skeleton__title" *ngIf="skeletonLoading"></div>
            <div class="skeleton__description" *ngIf="skeletonLoading"></div>
          </div>
        </div>
        <div class="next-step">
          <button class="btn btn__next" [disabled]="(totalPricing === 0 && !isFree) || (needBuyCode && !codeValidated)"
                  (click)="verifyUserData()" *ngIf="!skeletonLoading && !eventData.soldOut">
            <span class="text-base lh-24 text-gray-6 font-semibold" i18n>
              Continuar
            </span>
          </button>
          <div class="skeleton__btn" *ngIf="skeletonLoading"></div>
        </div>
      </div>
      <div class="card__body">

        <div class="title__tickets">
          <div>
            <p class="card__title" *ngIf="!skeletonLoading">
              <ng-container i18n>Elegí tu</ng-container>
              {{typeBuy === 'T' ? tableWordLocalized : ticketWordLocalized}}</p>
            <div class="skeleton__tickets" *ngIf="skeletonLoading"></div>
          </div>
          <div *ngIf="imageSectorPlan" class="text-primary cursor-pointer" (click)="showSectorPlan()">
            <i class="fa-light fa-map-location-dot margin-right-6"></i>
            <span class="font-size-12 line-height-16 font-weight-500" i18n>
              Plano de sectores
            </span>
          </div>
        </div>
        <div class="tickets__container" *ngIf="!skeletonLoading && typeBuy === 'A'">
          <app-ticket-card [skeletonLoading]="skeletonLoading" class="ticket-card" *ngFor="let ticket of tickets"
                           [ticket]="ticket" [table]="" [typeBuy]="typeBuy" [ticketSelectedId]="ticketSelectedId"
                           (ticketClick)="selectTicketEvent($event)" [eventData]="eventData"></app-ticket-card>
        </div>
        <div class="tickets__container" *ngIf="!skeletonLoading && typeBuy === 'T'">
          <app-ticket-card [skeletonLoading]="skeletonLoading" class="ticket-card" *ngFor="let table of tables"
                           [ticket]="" [table]="table" [typeBuy]="typeBuy" [ticketSelectedId]="ticketSelectedId"
                           (tableClick)="selectTableEvent($event)" [eventData]="eventData"></app-ticket-card>
        </div>
        <div class="tickets__container" *ngIf="skeletonLoading">
          <app-ticket-card [skeletonLoading]="skeletonLoading" class="ticket-card"
                           *ngFor="let ticket of skeletonTickets" [ticket]="ticket"></app-ticket-card>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="footer" *ngIf="(ticketSelected !== null || tableSelected !== null) && !skeletonLoading">
  <button class="btn btn__next" [disabled]="(totalPricing === 0 && !isFree) || (needBuyCode && !codeValidated)"
          (click)="verifyUserData()">
    <span class="text-base lh-24 text-gray-6"
          [ngClass]="totalPricing == 0 ? 'font-normal' : 'font-semibold' " i18n>Continuar</span>
  </button>
</div>

<app-modal-must-signin
  [showModal]="showModalActionLogin"
  (closeModal)="closeModalLogin($event)"
  (actionModal)="onActionToLogin()"
></app-modal-must-signin>

<app-modal-image [image]="imageSectorPlan" [showModal]="showImageSectorPlan"
                 (closeButtonEvent)="onCloseImageSectorPlan($event)">
</app-modal-image>

<app-modal-action [showModal]="showNeedVerifiedAccount" [title]="errorAccountTitle" [content]="errorAccountContent"
                  [textBtn]="'Aceptar'" [iconClass]="errorAccountIcon" [imageName]="errorAccountImage"
                  (closeButtonEvent)="onNeedVerifiedAccount($event)"
                  (action)="onNeedVerifiedAccount($event)"></app-modal-action>

<app-modal-action [showModal]="showErrorModal" [title]="errorTitle" [content]="errorContent" [textBtn]="'Aceptar'"
                  [iconClass]="errorIcon" (closeButtonEvent)="onActionErrorModal($event)"
                  (action)="onActionErrorModal($event)"></app-modal-action>
