import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, UntypedFormGroup, Validators} from '@angular/forms';
import {ContactRequest} from 'src/app/models/ContactRequest';
import {InputValidationService} from 'src/app/services/input-validation.service';
import {SupportService} from 'src/app/services/support.service';
import {environment} from 'src/environments/environment';
import {AuthService} from '../../../services/auth.service';
import { MessageService } from 'primeng/api';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-modal-support-email',
  templateUrl: './modal-support-email.component.html',
  styleUrls: ['./modal-support-email.component.scss']
})
export class ModalSupportEmailComponent {

  @Output() closeModal = new EventEmitter();
  showModal = true;
  cancelLabel = $localize`Cancelar`;
  acceptLabel = $localize`Enviar consulta`;
  supportForm: FormGroup;
  selectYourSupportType = $localize`Tipo de consulta`;
  formHasBeenSubmitted = false;
  maxEventDescriptionLength = 10;

  supportType = [
    {
      name: $localize`Quiero pedir un reembolso`,
      id: 'Quiero pedir un reembolso',
    },
    {
      name: $localize`No puedo validar mi identidad`,
      id: 'No puedo validar mi identidad',
    },
    {
      name: $localize`Quiero comprar mas accesos para un evento del cual soy invitado`,
      id: 'Quiero comprar mas accesos para un evento del cual soy invitado',
    },
    {
      name: $localize`Estoy teniendo problemas para agregar invitados`,
      id: 'Estoy teniendo problemas para agregar invitados',
    },
    {
      name: $localize`No puedo transferir un acceso que compre`,
      id: 'No puedo transferir un acceso que compre',
    },
    {
      name: $localize`Quiero que me contacte alguien comercial`,
      id: 'commercial',
    }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private validateInputService: InputValidationService,
    private supportService: SupportService,
    private authSrv: AuthService,
    private messageService: MessageService,
    private spinnerService: SpinnerService
  ) {
    this.supportForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
          this.validateInputService.emailValidator()
        ],
      ],
      name: ['', Validators.required],
      supportType: ['', Validators.required],
      message: ['', Validators.required]
    });

    if (this.authSrv.isAuthenticated()) {
      const email = JSON.parse(localStorage.getItem('user')).email;
      const name = JSON.parse(localStorage.getItem('user')).name;
      const lastName = JSON.parse(localStorage.getItem('user')).lastName;
      const completeName = name + ' ' + lastName;
      this.supportForm.patchValue({
        email,
        name: completeName
      });
    }
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }

  sendToSupport() {
    this.spinnerService.loadSpinner.next(true);
    this.formHasBeenSubmitted = true;
    if (this.supportForm.valid) {

      const payload: ContactRequest = {
        content: this.supportForm.controls.message.value,
        email: this.supportForm.controls.email.value,
        name: this.supportForm.controls.name.value,
        reason: this.supportForm.controls.supportType.value,
        tenant: environment.identification
      };

      this.supportService.sendSupportEmail(payload).subscribe({
        next: () => {
          this.spinnerService.loadSpinner.next(false);
          this.showEmailSendedMessage();
          this.closeModal.emit();
        },
        error: (err) => {
          this.spinnerService.loadSpinner.next(false);
        }
      });

    }

  }

  showEmailSendedMessage() {
    this.messageService.add({
      summary: 'Mensaje enviado con éxito',
      severity: 'success'
    });
    setTimeout(() => {
      this.messageService.clear();
    }, 2000);
  }

  onHide() {
    this.closeModal.emit();
  }

  handleDataEmail(e) {
    // console.log('handleDataEmail', e);
  }

  focusOutFn() {
    // console.log('focusoutfn');
  }

  onDropdownChange(e) {
    // console.log('onDropdownChange');
  }
}
