import {Injectable} from '@angular/core';
import {EventGuestRequest} from '../models/events/EventGuestRequest';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {DelegateOwnershipRequest} from '../models/events/DelegateOwnershipRequest';
import {UserRestrict} from '../models/UserRestrict';

@Injectable({
  providedIn: 'root'
})
export class GuestAccessService {

  constructor(
    private httpC: HttpClient
  ) {
  }

  addGuestAccess(accessId: number, eventGuestRequest: EventGuestRequest): Observable<void> {
    return this.httpC.post<void>(`${environment.billboardAGUrl}/cartelera/guests/access/web/${accessId}`, eventGuestRequest);
  }

  delegateOwnership(delegateOwnershipRequest: DelegateOwnershipRequest): Observable<UserRestrict> {
    return this.httpC.post<UserRestrict>(`${environment.billboardAGUrl}/cartelera/guests/access/delegate`, delegateOwnershipRequest);
  }
}
