<div class="skeleton__container">
  <div class="skeleton__large"></div>
  <div class="skeleton__medium mt-2"></div>
  <div class="skeleton__large mt-2"></div>
  <div class="skeleton__medium mt-2"></div>
  <div class="skeleton__large mt-2"></div>
  <div class="skeleton__medium mt-2"></div>
  <div class="skeleton__large mt-2"></div>
  <div class="skeleton__medium mt-2"></div>
  <div class="skeleton__large mt-2"></div>
  <div class="skeleton__medium mt-2"></div>
</div>