<main [ngClass]="withModalDownload ? 'pb-75' : ''">
  <app-card-info-event [eventData]="event" [skeletonLoading]="skeletonLoading"
                       [isTicketOnResale]="isOnResale"
                       (closeButton)="getDataCardComponent($event)"
                       (geoLocation)="goToGeolocation()"
                       (backButton)="back()"
                       [ticketUUID]="ticketUUID"></app-card-info-event>

  <!--  DETAIL WEB-->
  <section class="event-details">
    <div class="wrapper">
      <div class="event-details__description"
           [ngClass]="accessBought !== null && tableResponse !== null && listsResponse !== null ? 'full' : '' ">
        <p class="text-base font-normal lh-24 text-gray-2 my-0 pre-wrap event-description"
           *ngIf="!skeletonLoading" [innerHTML]="event?.eventDescription | linkify"></p>
        <app-skeleton-description *ngIf="skeletonLoading"></app-skeleton-description>
      </div>
      <!--  MANAGE INVITATION -->
      <div class="event-details__buy"
           *ngIf="(accessBought !== null ||tableResponse !== null || listsResponse !== null
           || uniqueInvitationResponse !== null) && event?.status !== eventStatus.CANCELED">
        <div class="ticket">
          <div class="ticket__header">
            <div class="ticket__type-detail">
              <i class="fa-solid mr-2 fa-ticket text-gray-3 text-xs"
                 *ngIf="typeInvitation === 'A' ||typeInvitation === 'U'"></i>
              <i class="fa-solid mr-2 fa-champagne-glasses text-gray-3 text-xs"
                 *ngIf="typeInvitation === 'T'"></i>
              <i class="fa-solid mr-2 fa-clipboard-list text-gray-3 text-xs"
                 *ngIf="typeInvitation === 'L'"></i>
              <p class="text-base mt-0 mb-0 font-secondary lh-16 text-gray-3 font-light">
                <ng-container i18n *ngIf="typeInvitation === 'A'">Ticket</ng-container>
                <ng-container i18n *ngIf="typeInvitation === 'T'">Combo/Mesa</ng-container>
                <ng-container i18n *ngIf="typeInvitation === 'L'">Lista</ng-container>
                <ng-container i18n *ngIf="typeInvitation === 'U'">Acceso por invitación</ng-container>
                <span class="text-primary font-semibold ml-1" *ngIf="typeInvitation === 'A'">{{ accessBought.name }}
                </span>
                <span class="text-primary font-semibold ml-1" *ngIf="typeInvitation === 'T'">{{ tableResponse.name }}
                </span>
                <span class="text-primary font-semibold ml-1" *ngIf="typeInvitation === 'L'">{{ listsResponse.name }}
                </span>
              </p>
            </div>
            <div class="badge__on-resale cursor-pointer" *ngIf="!canCustomerRemoveFromEvent() && isOnResale"
                 (click)="viewOnResale()">
              <i class="fas fa-money-bill-transfer text-warning font-size-12 lh-16"></i>
              <span class="text-warning font-size-10 lh-16 font-weight-600 uppercase" i18n>En reventa</span>
              <span class="text-primary font-size-10 lh-16 font-weight-600 uppercase text-view" i18n>Ver</span>
            </div>
            <div class="button__context-menu" (click)="openMenu()" *ngIf="canCustomerRemoveFromEvent()">
              <i class="fal fa-ellipsis text-primary font-size-18 lh-16"></i>
            </div>
            <div class="menu" *ngIf="viewMenu">
              <div class="row first" (click)="leaveEvent()">
                <i class="icon fa-light fa-person-to-door text-error"></i>
                <p class="text-sm lh-16 text-error font-normal my-0 margin-left-12 font-secondary" i18n>
                  Abandonar evento
                </p>
              </div>
            </div>
          </div>
          <div class="bid-badge bid-badge--bg-dark margin-top-16" *ngIf="sectorEvent">
            <i class="fa-light fa-door-open text-primary font-size-12 lh-16 margin-right-8"></i>
            <span class="font-size-14 lh-16">{{ sectorEvent.name }}</span>
          </div>
          <div class="ticket__body"
               [ngClass]="{'margin-top-24': (descriptions && descriptions.length > 0) || typeInvitation === 'T'}">
            <div class="flex items-start flex-col">
              <div class="flex items-start mb-2" *ngIf="typeInvitation === 'T' && tableResponse.tableType">
                <i class="fa-light fa-champagne-glasses text-primary text-xs lh-16"></i>
                <p class="text-sm lh-16 my-0 text-gray-3 ml-2">{{tableResponse.tableType}}</p>
              </div>
              <div class="flex items-start mb-2" *ngIf="typeInvitation === 'T' && tableResponse.capacity > 0">
                <i class="fa-light fa-people-pants-simple text-primary text-xs lh-16"></i>
                <p class="text-sm lh-16 my-0 text-gray-3 ml-3">{{tableResponse.capacity}}
                  <span i18n>personas</span>
                </p>
              </div>
              <div class="flex items-start mb-2" *ngIf="typeInvitation === 'T' && tableResponse.consumerCredit > 0">
                <i class="fa-light fa-circle-dollar-to-slot text-primary text-xs lh-16"></i>
                <p class="text-sm lh-16 my-0 text-gray-3 ml-2">
                  <span i18n>Crédito de consumo:</span>
                  {{currency}}{{tableResponse.consumerCredit | number:
                  '1.0-2'}}</p>
              </div>
              <div class="flex items-start mb-2" *ngFor="let description of descriptions">
                <i class="fa-light fa-circle-check text-primary text-xs lh-16"></i>
                <p class="text-sm lh-16 my-0 text-gray-3 ml-2">{{description}}</p>
              </div>
            </div>
            <div class="guests__list margin-top-24" [ngClass]="loadingData ? 'overlay__guests-list' : ''"
                 *ngIf="showListGuests()">
              <div class="logo__box" *ngIf="loadingData && !skeletonLoading">
                <div class="container__spinner">
                  <img src="../../../../assets/images/spinner.gif" class="logo" alt="spinner"/>
                </div>
              </div>
              <div class="flex align-items-center justify-content-start" *ngIf="typeInvitation !== 'U'">
                <div class="guest__avatars">
                  <div class="avatar__profile" *ngFor="let guest of guests.slice(0, 3);">
                    <img *ngIf="guest.customer?.image" src="{{ mediaUrl + guest.customer?.image }}"
                         class="profile-img" alt="profile"/>
                    <i *ngIf="!guest.customer?.image"
                       class="fal fa-user-circle text-gray-4 font-size-28 lh-32 align-self-center"></i>
                  </div>
                  <div class="more-avatars" *ngIf="guests.length > 3 && typeInvitation !== 'L'">+{{ guests.length - 3 }}</div>
                  <div class="more-avatars" *ngIf="guests.length > 3 && typeInvitation === 'L'">+{{ plussCount }}</div>
                </div>
                <div class="margin-left-16 flex justify-content-between w-full align-items-center"
                     *ngIf="accessBought || tableResponse || listsResponse">
                  <div class="flex">
                    <i class="fal fa-user text-gray-3 font-size-14 mr-2"></i>
                    <p class="font-size-13 lh-16 text-gray-3 font-light my-0" *ngIf="typeInvitation === 'A'">
                      {{ accessBought.quantity - accessBought.guests.length + ' ' }}
                      <ng-container i18n>lugares disponibles</ng-container>
                    </p>
                    <p class="font-size-13 lh-16 text-gray-3 font-light my-0" *ngIf="typeInvitation === 'T'">
                      {{ tableResponse.available + ' ' }}
                      <ng-container i18n>lugares disponibles</ng-container>
                    </p>
                    <p class="font-size-13 lh-16 text-gray-3 font-light my-0" *ngIf="typeInvitation === 'L'">
                      {{ listsResponse.available + ' ' }}
                      <ng-container i18n>lugares disponibles</ng-container>
                    </p>
                  </div>
                  <div
                    *ngIf="typeInvitation !== 'U' && (!checkIfListIsPrivate() || verifyAdminPermission()) && !user.blocked">
                    <button class="btn btn__terciary font-size-13 see-all" (click)="showAllGuests()" i18n>
                      Ver todo
                    </button>
                  </div>
                </div>
              </div>
              <div class="flex margin-top-24 w-full"
                   *ngIf="showAddGuestButton()">
                <button class="btn btn__md btn__secondary btn_minor-padding w-full margin-right-16"
                        [disabled]="loadingData" (click)="copyEventLink()" i18n>
                  Compartir evento
                </button>
                <button class="btn btn__md btn__primary btn_minor-padding w-full" (click)="addPeople()" i18n>
                  Agregar invitado
                </button>
              </div>
            </div>
          </div>
        </div>
        <button class="button__remove-transfer" (click)="openModalRemoveResale()"
                *ngIf="canShowResaleButton() && !isAlreadyIngress && !ticketAlreadyOnResale()"
                [disabled]="isResalePending()" [ngClass]="isResalePending() ? 'disabled' : ''">
          <div class="btn-container">
            <i
              class="fas fa-money-bill-transfer text-primary font-size-24 font-weight-900 line-height-40 margin-right-16">
            </i>
            <span class="font-size-18 lh-18 text-gray-1" i18n>Revender ticket</span>
          </div>
        </button>
        <button class="button__delegate-ownership" (click)="checkIfAccessNotUsed()"
                *ngIf="canDelegateOwnership() && !ticketAlreadyOnResale() && !isAlreadyIngress"
                [disabled]="isResalePending()" [ngClass]="isResalePending() ? 'disabled' : ''">
          <div class="btn-container">
            <i class="fas fa-hand-holding-box text-primary font-size-24 font-weight-900 line-height-40 margin-right-16">
            </i>
            <span class="font-size-18 lh-18 text-gray-1" i18n>Transferir evento</span>
          </div>
        </button>
        <button class="button__remove-transfer" (click)="removeFromResale()"
                *ngIf="ticketAlreadyOnResale()">
          <div class="btn-container">
            <i
              class="fas fa-money-bill-transfer text-primary font-size-24 font-weight-900 line-height-40 margin-right-16">
            </i>
            <span class="font-size-18 lh-18 text-gray-1" i18n>Quitar de reventa</span>
          </div>
        </button>
      </div>
      <div class="event-details__buy" *ngIf="event?.status === eventStatus.CANCELED">
        <div class="ticket">
          <div class="container__advice-canceled">
            <p class="text-gray-1 text-sm lh-16 font-secondary font-medium my-0">
              <i class="fas fa-exclamation-triangle text-error text-base mr-2"></i>
              <ng-container i18n>Este evento fue cancelado</ng-container>
            </p>
            <div class="mt-2">
              <p class="text-gray-35 lh-20 text-sm font-primary font-normal my-0" i18n>
                El evento fue cancelado por el organizador. Lamentamos los inconvenientes :(
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--  MOBILE VIEW-->
  <section class="event-details__mobile">
    <div class="event-details__buy" *ngIf="showEventDetails()">
      <div class="ticket">
        <div class="ticket__header" (click)="collapseExpandDetails()">
          <div class="ticket__type-detail">
            <i class="fa-solid mr-2 fa-ticket text-gray-3 text-xs"
               *ngIf="typeInvitation === 'A' ||typeInvitation === 'U'"></i>
            <i class="fa-solid mr-2 fa-champagne-glasses text-gray-3 text-xs"
               *ngIf="typeInvitation === 'T'"></i>
            <i class="fa-solid mr-2 fa-clipboard-list text-gray-3 text-xs"
               *ngIf="typeInvitation === 'L'"></i>
            <p class="text-base mt-0 mb-0 font-secondary lh-16 text-gray-3 font-light">
              <ng-container i18n *ngIf="typeInvitation === 'A'">Ticket</ng-container>
              <ng-container i18n *ngIf="typeInvitation === 'T'">Combo/Mesa</ng-container>
              <ng-container i18n *ngIf="typeInvitation === 'L'">Lista</ng-container>
              <ng-container i18n *ngIf="typeInvitation === 'U'">Acceso por invitación</ng-container>
              <span class="text-primary font-semibold ml-1" *ngIf="typeInvitation === 'A'">{{ accessBought.name }}
                </span>
              <span class="text-primary font-semibold ml-1" *ngIf="typeInvitation === 'T'">{{ tableResponse.name }}
                </span>
              <span class="text-primary font-semibold ml-1" *ngIf="typeInvitation === 'L'">{{ listsResponse.name }}
                </span>
            </p>
          </div>
          <div>
            <i *ngIf="typeInvitation !== 'U' && typeInvitation !== 'L'"
               class="fa-solid fa-chevron-down text-primary text-lg chevron-accordion"
               [ngClass]="isInvitedDetails ? 'rotate' : ''"></i>
          </div>
        </div>
        <div class="bid-badge bid-badge--bg-dark margin-top-16" *ngIf="sectorEvent">
          <i class="fa-light fa-door-open text-primary font-size-12 lh-16 margin-right-8"></i>
          <span class="font-size-14 lh-16">{{ sectorEvent.name }}</span>
        </div>
        <div class="ticket__body"
             [ngClass]="{'hidden' : !isInvitedDetails, 'margin-top-24': (descriptions && descriptions.length > 0)  || typeInvitation === 'T'}">
          <div class="flex items-start flex-col margin-top-24">
            <div class="flex items-start mb-2" *ngIf="typeInvitation === 'T' && tableResponse.tableType">
              <i class="fa-light fa-champagne-glasses text-primary text-xs lh-16"></i>
              <p class="text-sm lh-16 my-0 text-gray-3 ml-2">{{tableResponse.tableType}}</p>
            </div>
            <div class="flex items-start mb-2" *ngIf="typeInvitation === 'T' && tableResponse.capacity > 0">
              <i class="fa-light fa-people-pants-simple text-primary text-xs lh-16"></i>
              <p class="text-sm lh-16 my-0 text-gray-3 ml-3">{{tableResponse.capacity}}
                <span i18n>personas</span>
              </p>
            </div>
            <div class="flex items-start mb-2" *ngIf="typeInvitation === 'T' && tableResponse.consumerCredit > 0">
              <i class="fa-light fa-circle-dollar-to-slot text-primary text-xs lh-16"></i>
              <p class="text-sm lh-16 my-0 text-gray-3 ml-2">
                <span i18n>Crédito de consumo:</span>
                {{currency}}{{tableResponse.consumerCredit | number:
                '1.0-2'}}</p>
            </div>
            <div class="flex items-start mb-2"
                 *ngFor="let description of descriptions">
              <i class="fa-light fa-circle-check text-primary text-xs lh-16"></i>
              <p class="text-sm lh-16 my-0 text-gray-3 ml-2">{{description}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--  SPACE FOR GUESTS-->
  <section class="event-details__guests__mobile" [ngClass]="loadingData ? 'overlay__guests-list--mobile' : ''"
           *ngIf="typeInvitation !== 'A' || (typeInvitation === 'A' && accessBought.quantity > 1)">
    <div class="logo__box-mobile" *ngIf="loadingData && !skeletonLoading">
      <div class="container__spinner-mobile">
        <img src="../../../../assets/images/spinner.gif" class="logo__spinner-mobile" alt="spinner"/>
      </div>
    </div>
    <div class="event-details__buy" *ngIf="showEventDetails()">
      <div class="ticket">
        <div class="guests__list-mobile">
          <div class="flex align-items-center justify-content-start">
            <div class="guest__avatars">
              <div class="avatar__profile avatar-mobile" [ngClass]="guest.customer?.image ? 'has-image' : ''"
                   *ngFor="let guest of guests.slice(0, 3);">
                <img *ngIf="guest.customer?.image" src="{{ mediaUrl + guest.customer?.image }}"
                     class="profile-img img-mobile" alt="profile"/>
                <i *ngIf="!guest.customer?.image"
                   class="fal fa-user-circle text-gray-4 font-size-28 lh-32 align-self-center"></i>
              </div>
              <div  *ngIf="guests.length > 3 && typeInvitation !== 'L'" class="more-avatars more-mobile">
                +{{ guests.length - 3 < 100 ? guests.length - 3 : 99 }}
              </div>
              <div *ngIf="guests.length > 3 && typeInvitation === 'L'" class="more-avatars more-mobile">
                +{{ plussCount < 100 ? plussCount : 99 }}
              </div>

            </div>
            <div class="margin-left-16 flex justify-content-between w-full align-items-center"
                 *ngIf="accessBought || tableResponse || listsResponse">
              <div class="flex">
                <i class="fal fa-user text-gray-3 font-size-14 mr-2"></i>
                <p class="text-sm lh-16 text-gray-1 font-light my-0" *ngIf="typeInvitation === 'A'">
                  {{ accessBought.quantity - accessBought.guests.length + ' ' }}
                  <ng-container i18n>lugares disponibles</ng-container>
                </p>
                <p class="text-sm lh-16 text-gray-1 font-light my-0" *ngIf="typeInvitation === 'T'">
                  {{ tableResponse.available + ' ' }}
                  <ng-container i18n>lugares disponibles</ng-container>
                </p>
                <p class="text-sm lh-16 text-gray-1 font-light my-0" *ngIf="typeInvitation === 'L'">
                  {{ listsResponse.available + ' ' }}
                  <ng-container i18n>lugares disponibles</ng-container>
                </p>
              </div>
              <div
                *ngIf="typeInvitation !== 'U' && (!checkIfListIsPrivate() || verifyAdminPermission()) && !user.blocked">
                <button class="btn btn__terciary see-all" (click)="showAllGuests()" i18n>
                  Ver todo
                </button>
              </div>
            </div>
          </div>
          <div class="flex margin-top-24 w-full"
               *ngIf="!eventIsEnded && typeInvitation !== 'U' && verifyAdminPermission() && !isOnResale && !user.blocked">
            <button class="btn btn__md btn__secondary btn_minor-padding w-full margin-right-16" [disabled]="loadingData"
                    (click)="copyEventLink()" i18n>
              Compartir evento
            </button>
            <button class="btn btn__md btn__primary btn_minor-padding w-full"
                    [disabled]="limitHasBeenReached || loadingData" (click)="addPeople()" i18n>
              Agregar invitado
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="event-details__buy" *ngIf="event?.status === eventStatus.CANCELED">
      <div class="ticket">
        <div class="container__advice-canceled">
          <p class="text-gray-1 text-sm lh-16 font-secondary font-medium my-0">
            <i class="fas fa-exclamation-triangle text-error text-base mr-2"></i>
            <ng-container i18n>Este evento fue cancelado</ng-container>
          </p>
          <div class="mt-2">
            <p class="text-gray-35 lh-20 text-sm font-primary font-normal my-0" i18n>
              El evento fue cancelado por el organizador. Lamentamos los inconvenientes :(
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="container-mobile__buttons-transfer-resale margin-bottom-8">
    <div class="button__container"
         *ngIf="canShowResaleButton() && !ticketAlreadyOnResale() && !isAlreadyIngress && !skeletonLoading">
      <button class="btn btn__resale-event-mobile" (click)="openModalRemoveResale()" [disabled]="isResalePending()"
              [ngClass]="isResalePending() ? 'disabled' : ''">
        <span class="font-size-16 lh-18 text-gray-1 font-secondary font-weight-500" i18n>Revender ticket</span>
        <i class="fas fa-money-bill-transfer text-primary font-size-24 font-weight-900 line-height-40">
        </i>
      </button>
    </div>
    <div class="button__container" [ngClass]="canShowResaleButton() ? 'add-margin-top' : ''"
         *ngIf="canDelegateOwnership() && !ticketAlreadyOnResale() && !isAlreadyIngress && !skeletonLoading">
      <button class="btn btn__transfer-event-mobile" (click)="checkIfAccessNotUsed()"
              *ngIf="!skeletonLoading" [disabled]="isResalePending()" [ngClass]="isResalePending() ? 'disabled' : ''">
        <span class="font-size-16 lh-18 text-gray-1 font-secondary font-weight-500" i18n>Transferir evento</span>
        <i class="fas fa-hand-holding-box text-primary font-size-24 font-weight-900 line-height-40">
        </i>
      </button>
    </div>
    <div class="button__container" *ngIf="ticketAlreadyOnResale() && !skeletonLoading">
      <button class="btn btn__resale-event-mobile" (click)="removeFromResale()">
        <span class="font-size-16 lh-18 text-gray-1 font-secondary font-weight-500" i18n>Quitar de reventa</span>
        <i class="fas fa-money-bill-transfer text-primary font-size-24 font-weight-900 line-height-40">
        </i>
      </button>
    </div>
  </section>

  <section class="event-details__description__mobile">
    <div class="description__container mb-4" *ngIf="!skeletonLoading">
      <p class="text-base font-normal lh-22 text-gray-3 my-0 break-word">{{ event?.eventName }}</p>
      <p
        class="text-base font-normal lh-22 text-gray-3 my-0">{{ event?.dateInit | localeDate:'longDate' | titlecase }}</p>
      <div class="mini__divisor"></div>
      <p class="text-base font-normal lh-22 text-gray-3 my-0 pre-wrap"
         [innerHTML]="event?.eventDescription | linkify"></p>
    </div>
    <app-skeleton-description *ngIf="skeletonLoading"></app-skeleton-description>
    <button class="view-image" (click)="viewImage()" *ngIf="!skeletonLoading" i18n>Ver imagen del evento</button>
    <div class="skeleton__view-image" *ngIf="skeletonLoading"></div>
  </section>
  <section class="leave-event__mobile margin-bottom-8" *ngIf="canCustomerRemoveFromEvent()">
    <div class="leave-event__container" *ngIf="!skeletonLoading">
      <button class="btn btn__terciary btn__leave-event" (click)="leaveEvent()"
              *ngIf="!skeletonLoading">
        <i class="icon fa-light fa-person-to-door text-error"></i>
        <p class="text-sm lh-16 text-error font-normal my-0 margin-left-12 font-secondary" i18n>
          Abandonar evento
        </p>
      </button>
    </div>
    <div class="skeleton__view-image" *ngIf="skeletonLoading"></div>
  </section>

  <app-modal-image [image]="imageEvent" [showModal]="showImage" (closeButtonEvent)="getDataModalImage($event)">
  </app-modal-image>

</main>

<app-modal-add-guest (updateListGuests)="getInvitationData()">
</app-modal-add-guest>

<app-modal-must-signin
  [showModal]="showModalActionLogin"
  (closeModal)="closeModalLogin()"
  (actionModal)="onActionToLogin()"
></app-modal-must-signin>

<app-modal-show-guests (updateListGuests)="getInvitationData()"
                       (addNewGuestToEvent)="addPeople()">
</app-modal-show-guests>

<p-confirmDialog acceptButtonStyleClass="btn btn__remove" acceptLabel="Confirmar"
                 rejectLabel="Cancelar" rejectButtonStyleClass="btn btn__primary"
                 class="bid-modal bid-modal__warning--small dialog-leave-event">
</p-confirmDialog>

<app-modal-resale *ngIf="showModalResale && !loadingData" (closeModal)="onCloseModalResale($event)"
                  [ticketUUID]="ticketUUID" [type]="typeInvitation" [eventId]="event?.idEvent">
</app-modal-resale>
