import {TableTypeResponse} from './TableTypeResponse';
import {TicketTable} from './TicketTable';

export class TableSelected {
  total: number;
  table: TableTypeResponse;
  needBuyCode?: boolean;
  codeValidated?: boolean;
  ticket: TicketTable;
  free?: boolean;
}
