import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ExternalPlatform} from 'src/app/enum/ExternalPlatform';

@Component({
  selector: 'app-external-login',
  templateUrl: './external-login.component.html',
  styleUrls: ['./external-login.component.scss']
})
export class ExternalLoginComponent implements OnInit {

  @Output('externalLogin') externalLogin = new EventEmitter<ExternalPlatform>();
  EXTERNALLOGIN = ExternalPlatform;

  constructor() {
  }

  ngOnInit(): void {
  }

  doExternalLogin(platform: ExternalPlatform) {
    this.externalLogin.emit(platform);
  }

}
