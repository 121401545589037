<fieldset
  class="fieldset-input {{customClass}}"
  [ngClass]="{
    'focus': onFocus,
    'filled': onFilled,
    'error': shouldShowErrors(),
    'helper': helperText.length > 1,
    'disabled': readonly,
    'success': success,
    'validate': validate,
    'bg-light-in-mobile': bgLightMobile,
    'bg-light-in-desktop': bgLightDesktop,
    'size-large-mobile': largeMobile,
    'size-large-desktop': largeDesktop,
    'size-large': large,
    'size-medium': medium
  }"
>
  <div class="field__input">
    <label class="label" for="input">{{label}}</label>
    <input
      autocomplete="disabled"
      class="input"
      #input
      id="input"
      [name]="name"
      [type]="type"
      maxlength="maxlength"
      [required]="required"
      (focus)="onFocusInput()"
      (blur)="onBlurInput()"
      (keyup)="onChange($event)"
      [value]="value"
      (keydown.space)="preventSpace ? $event.preventDefault() : ''"
      [disabled]="readonly"
      [ngClass]="{
        'capitalize' : capitalize,
        'uppercase' : uppercase,
        'lowercase' : lowerCase
      }"
    />
    <i class="fa-solid fa-triangle-exclamation text-error text-icon" *ngIf="withIcon && shouldShowErrors()"></i>
    <i class="fa-solid fa-circle-check text-success text-icon" *ngIf="success"></i>
    <i class="fa-light fa-spinner-third spinner-icon" *ngIf="validate"></i>
  </div>
  <p class="hint" *ngIf="hint && shouldShowErrors()">
    <span *ngFor="let error of listOfErrors()">{{error}}</span>
  </p>
</fieldset>
