import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, UntypedFormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../../services/user.service';
import {InputValidationService} from '../../../../services/input-validation.service';
import {ToastService} from 'src/app/services/toast.service';
import {SpinnerService} from 'src/app/services/spinner.service';
import {Location} from '@angular/common';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  newPasswordForm: FormGroup;
  passwordsNotEquals = false;
  thereAreChanges = false;
  formLoaded = true;
  formHasBeenSubmitted = false;
  passwordMatch = true;

  constructor(
    public formBuilder: FormBuilder,
    private userSrv: UserService,
    private validateInputSrv: InputValidationService,
    private toastService: ToastService,
    private spinnerSrv: SpinnerService,
    private location: Location
  ) {
    this.newPasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.pattern('(?=\\w*\\d)(?=\\w*[A-Z])(?=\\w*[a-z])\\S{8,32}')]],
      newPasswordRepeat: ['', Validators.required]
    });
  }

  get oldPasswordField(): AbstractControl {
    return this.newPasswordForm.controls.oldPassword;
  }

  get newPasswordField(): AbstractControl {
    return this.newPasswordForm.controls.newPassword;
  }

  get newPasswordRepeatField(): AbstractControl {
    return this.newPasswordForm.controls.newPasswordRepeat;
  }

  ngOnInit() {

    this.newPasswordForm.valueChanges.subscribe(() => {
      this.thereAreChanges =
        this.newPasswordField.value.length > 0 || this.oldPasswordField.value.length > 0 || this.newPasswordRepeat.value.length > 0
          ? true : false;
    });

    this.newPasswordField.valueChanges.subscribe(res => {
      this.passwordMatch = true;
    });

    this.newPasswordRepeatField.valueChanges.subscribe(res => {
      this.passwordMatch = true;
    });
  }

  get newPassword() {
    return this.newPasswordForm.get('newPassword');
  }

  get newPasswordRepeat() {
    return this.newPasswordForm.get('newPasswordRepeat');
  }

  get oldPassword() {
    return this.newPasswordForm.get('oldPassword');
  }

  savePassword() {
    this.formHasBeenSubmitted = true;

    if (this.newPasswordField.value !== this.newPasswordRepeatField.value) {
      this.passwordMatch = false;
    }

    if (this.newPasswordForm.valid && this.passwordMatch) {
      this.submitNewPassword();
    }
  }

  submitNewPassword() {
    const payload = this.newPasswordForm.value;

    if (this.newPasswordForm.get('newPasswordRepeat').value === this.newPasswordForm.get('newPassword').value) {
      this.passwordsNotEquals = false;
      this.spinnerSrv.loadSpinner.next(true);
      this.userSrv.updatePassword(payload).subscribe({
        next: async () => {
          this.thereAreChanges = false;
          this.spinnerSrv.loadSpinner.next(false);
          this.resetForm();
          this.location.back();
          this.toastService.generateToast('success', $localize`Contraseña cambiada exitosamente`, '');
        },
        error: err => {
          this.spinnerSrv.loadSpinner.next(false);
          if (err.status === 409 || err.status === 401) {
            this.handleErrorMessage(err.status);
          }
        }
      });
    } else {
      this.passwordsNotEquals = true;
    }
  }

  handleErrorMessage(status) {
    const messages = {
      409: $localize`La nueva contraseña no puede ser igual a la anterior.`,
      401: $localize`La contraseña actual es incorrecta.`,
      406: $localize`Las contraseñas no coinciden.`
    };

    this.toastService.generateToast('error', messages[status], '');
  }

  fieldRequired(field: string | Array<string>) {
    return (this.newPasswordForm.get(field).invalid &&
      (this.newPasswordForm.get(field).touched || this.newPasswordForm.get(field).dirty)) ? 'required' : '';
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputSrv.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }

  resetForm() {
    this.newPasswordForm.reset();
  }

}
