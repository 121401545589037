import * as AOS from 'aos';

import {AfterContentChecked, Component, HostListener, OnInit} from '@angular/core';

import {EventForCustomer} from 'src/app/models/events/EventForCustomer';
import {EventService} from 'src/app/services/event.service';
import Masonry from 'masonry-layout';
import {Router} from '@angular/router';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, AfterContentChecked {

  historyEvents: Array<EventForCustomer>;
  pendingEvents: Array<EventForCustomer>;
  hasEvents: boolean = true;
  loading = false;
  typePage = 'myEvents';
  getScreenWidth: number;

  constructor(
    private eventService: EventService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getScreenWidth = window.innerWidth;
    this.historyEvents = [];
    this.loading = true;
    this.eventService.getHistoricalEvents().subscribe(events => {
      this.historyEvents = events;
      this.loading = false;
    });

    AOS.init({
      duration: 800,
    });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
  }

  ngAfterContentChecked() {
    if (this.getScreenWidth > 760) {
      if (document.getElementsByClassName('card').length) {
        this.masonryLayout();
      }
    }
  }

  login() {
    this.router.navigate(['/login']);
  }

  back() {
    this.router.navigate(['/my-events']);
  }

  masonryLayout() {
    const msnry = new Masonry('.grid-events', {
      gutter: '.gutter-sizer',
      itemSelector: '.grid-item',
      percentPosition: true,
      horizontalOrder: true,
      transitionDuration: '0.1s'
    });
  }
}
