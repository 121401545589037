import {AbstractControl, UntypedFormGroup} from '@angular/forms';
import {Injectable} from '@angular/core';
import { JSONStrings } from '../core/internalization/es';

@Injectable({
  providedIn: 'root',
})
export class InputValidationService {
  constructor() { }

  shouldShowErrors(field: AbstractControl, formHasBeenSubmitted: boolean, page = 'generic', customError = false, customKey = ''): Array<string> {
    if (field != null) {
      if (field.invalid && formHasBeenSubmitted) {
        return Object.keys(field.errors).map((err) => {
          return JSONStrings[page][err];
        });
      } else {
        return [];
      }
    } else if (customError) {
        return JSONStrings[page][customKey];
    } else {
      return [];
    }
  }
  
  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    const formCtrl = form.controls[fieldName];

    if (submitRegister) {
      if (formCtrl.valid || formCtrl.disabled) {
        if (formCtrl.touched) {
          return 'has-valid';
        }
      } else {
        return 'has-error';
      }
    } else if (formCtrl.dirty && formCtrl.touched) {
      return 'has-typing';
    } else {
      return '';
    }
  }

  onlyText(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (
      (charCode >= 65 && charCode <= 90) ||
      (charCode >= 97 && charCode <= 122) ||
      charCode === 32 ||
      charCode === 241 ||
      charCode === 209
    ) {
      return true;
    }
    return false;
  }

  dniText(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (
      (charCode >= 48 && charCode <= 57) ||
      (charCode >= 65 && charCode <= 90) ||
      (charCode >= 97 && charCode <= 122)
    ) {
      return true;
    }
    return false;
  }

  emailValidator() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return control.value && this.emailLength(control.value)
        ? {emailValidator: true}
        : null;
    };
  }

  private emailLength(value: string) {
    const [validPart] = value.split('@');
    return validPart.length > 64;
  }

  phoneValidator() {
    const validPhones = [
      /^\d{15}$/,
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/,
      /^\(?([0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/,
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/,
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{2})[-. ]?([0-9]{2})[-. ]?([0-9]{2})$/,
    ];
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return control.value && this.searchForMatch(validPhones, control.value)
        ? {phoneValidator: true}
        : null;
    };
  }

  private searchForMatch(validOnes: Array<any>, value: number) {
    const found = validOnes.find((v) => value.toString().match(v));
    return !found;
  }
}


