<div class="container">
  <h2 class="text-center title">{{ termsAndConditions?.title }} </h2>

  <div class="links-container">
    <div *ngFor="let term of termsAndConditions?.items">
      <a class="link cursor-pointer" (click)="scrollToElement(term.ancle)">{{ term.title }}</a>
    </div>
  </div>

  <div class="container-terms">
    <div id="{{ term.ancle }}" class="term" *ngFor="let term of termsAndConditions?.items">
      <h3 class="term-title">{{ term.title }}</h3>
      <div [innerHTML]="term.content" class="term-content"></div>
    </div>
  </div>
</div>
