import * as AOS from 'aos';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import {AuthService} from 'src/app/services/auth.service';
import {CustomValidationService} from 'src/app/services/custom-validation.service';
import {FooterService} from 'src/app/services/footer.service';
import {InputValidationService} from 'src/app/services/input-validation.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  recoverEmailForm: UntypedFormGroup;
  formHasBeenSubmitted = false;
  subs: Subscription[] = [];
  showInvalidEmail = false;
  emailSent = false;
  messageError = null;

  constructor(
    private router: Router,
    private customValidationService: CustomValidationService,
    private formBuilder: UntypedFormBuilder,
    private validateInputService: InputValidationService,
    private authService: AuthService,
    private footerService: FooterService
  ) {
    this.recoverEmailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        this.customValidationService.emailValidator()]],
    });
  }

  ngOnInit(): void {
    this.footerService.showFooter$.next(false);
    this.formHasBeenSubmitted = false;
    this.recoverEmailForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ]
    });
    AOS.init({
      duration: 800,
    });
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }

  submitRecoverPassword() {
    this.showInvalidEmail = false;
    this.formHasBeenSubmitted = true;
    if (this.recoverEmailForm.valid) {
      this.subs.push(this.authService.resetPassword(this.recoverEmailForm.controls.email.value).subscribe({
        next: () => {
          this.emailSent = true;
        }, error: async (err) => {
          if (err.status === 404) {
            await this.invalidEmail();
          }
          if (err.status === 409) {
            await this.accountBlockedError();
          }
        }
      }));
    }
  }

  goToRecoverWithDNI() {
    this.router.navigate(['recover-with-dni']);
  }

  async invalidEmail() {
    this.showInvalidEmail = true;
    this.recoverEmailForm.controls.email.setErrors({'emailNotFound': true});
  }

  async accountBlockedError() {
    this.showInvalidEmail = true;
    this.recoverEmailForm.controls.email.setErrors({'accountBlocked': true});
  }

  backToLogin() {
    this.router.navigate(['/login']);
  }

  resendEmail() {
    this.recoverEmailForm.controls.email.patchValue('');
    this.formHasBeenSubmitted = false;
    this.emailSent = false;
  }

  ngOnDestroy(): void {
    this.footerService.showFooter$.next(true);

    this.subs.forEach(res => {
      res.unsubscribe();
    });
  }

  focusOutFn() {
    let email = this.recoverEmailForm.get('email').value.trim();
    if (email.endsWith('.')) {
      email = email.slice(0, -1);
    }
    this.recoverEmailForm.patchValue({
      email
    });
  }

  handleDataEmail(event) {
    this.recoverEmailForm.get('email').setValue(event);
  }
}
