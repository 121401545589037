<div class="ui active centered inline loader"></div>
<div class="global-container background-home">
  <div class="container__invalid-token" *ngIf="invalidToken">
    <i class="fal fa-circle-info text-info text-12xl"></i>
    <p class="font-poppins text-gray-1 text-lg font-semibold lh-24 mt-8 mb-0">El tiempo de validación expiró</p>
    <p class="font-inter text-gray-35 text-sm lh-22 font-light mt-2 mb-0">Ingresá tu correo electrónico asociado a Black
        iD y te enviaremos un nuevo link de recuperación para cambiar tu contraseña.</p>
    <form [formGroup]="resendEmailForm">
        <div class="field__item" [ngClass]="
				validateInputClass(resendEmailForm, 'email', formHasBeenSubmitted)">
            <div class="p-float-label">
                <input id="email" autocomplete="false" formControlName="email" type="email" class="p-inputtext p-component"/>
                <label for="email" class="title-float">Email*</label>
                <app-error-message [page]="'forgot'" [ctrl]="resendEmailForm.get('email')"
                [formHasBeenSubmitted]="formHasBeenSubmitted"></app-error-message>
            </div>
        </div>
    <div class="error__login mt-4 mb-4" *ngIf="showError">
        <div class="icon__warning">
            <i class="fa-solid fa-triangle-exclamation fa-lg text-error mr-2"></i>
        </div>
        <span class="justify-content-center">{{ contentError }}</span>
        <div class="close">
            <i class="fa-light fa-xmark fa-xs text-gray-1" (click)="showError = false"></i>
        </div>
    </div>
        <button class="btn btn__md btn__primary" [disabled]="canSubmitResend()" (click)="resendEmailToken()">Enviar link de
        recuperación
        </button>
    </form>
    </div>
    <div class="form-container grid" *ngIf="!invalidToken">
    <p class="font-poppins text-gray-1 font-medium text-lg lh-24 my-0">{{textHeader}}</p>
    <!--<p-progressBar *ngIf="showSpinner" class="customProgress mt-4" mode="indeterminate"></p-progressBar>-->
    <div class="inputs-container mt-4" *ngIf="showInputs && changePass">
      <form [formGroup]="setNewPasswordForm">

        <bid-input-password
          i18n-label
          class="margin-bottom-24 display-block"
          [page]="'password'"
          [parentForm]="setNewPasswordForm"
          [controlName]="'newPassword'"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [label]="'Contraseña nueva'"
          [passwordMatch]="passwordMatch"
          [showLabelRequirement]="true"
          >
        </bid-input-password>
        <bid-input-password
          i18n-label
          [parentForm]="setNewPasswordForm"
          [page]="'password'"
          [controlName]="'newPasswordRepeat'"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [label]="'Confirmar contraseña'"
          [passwordMatch]="passwordMatch">
        </bid-input-password>
        <button class="btn btn__md btn__primary" (click)="changePassword()">{{customLabelSendPass}}</button>
      </form>
    </div>
  </div>
</div>
