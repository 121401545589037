<div class="banner__container" *ngIf="event">
  <div class="wrapper">
    <div class="banner" [ngClass]="this.event?.eventName.length > 12 ? 'big-title' : ''">
      <div class="column__content">
        <div class="producer">
          <div class="producer__pic">
            <img src="{{ mediaUrl + event?.storeDataOnEvent.logo }}" class="pic" alt=""/>
          </div>
          <p class="producer__name">{{event?.storeDataOnEvent.name}}</p>
        </div>
        <h1 class="event__name">{{event?.eventName}}</h1>
        <div class="event__description-container text-hypens ellipsis-three-lines break-word">
          <p class="event__description ellipsis-three-lines">{{event?.eventDescription}}</p>
        </div>
        <div class="detail__row-block">
          <div class="detail__row" *ngIf="event?.minorPrice">
            <i class="fa-light fa-ticket detail__icon"></i>
            <p class="detail__content">
              <ng-container i18n>Desde</ng-container>
              {{currency}}{{event?.minorPrice | number:'1.0-2'}}</p>
          </div>
          <div class="detail__row">
            <i class="fa-light fa-location-dot detail__icon"></i>
            <p class="detail__content">{{event?.address}}</p>
          </div>
        </div>
        <div class="actions">
          <button class="btn__date" [ngClass]="event?.soldOut ? 'disabled' : ''"
                  *ngFor="let event of mainEvents" (click)="goToDetailEvent(event)">
            <i class="fa-solid fa-calendar-lines mr-2"></i>
            {{event?.dateInit | date:'dd'}}
            <ng-container i18n> de</ng-container>
            {{' ' + event?.dateInit | date:' MMMM' | titlecase}}
            <div class="bid-badge bid-badge--red" *ngIf="event?.soldOut" i18n="Banner sold out tag">
              SOLD OUT
            </div>
          </button>
        </div>
      </div>
      <div class="column__image">
        <img src="{{mediaUrl + event?.image}}" class="image" alt=""/>
      </div>
    </div>
  </div>
</div>
