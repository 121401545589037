<p-toast></p-toast>
<app-navbar (modalMyQr)="showModalMyQr($event)"
            (modalInvitationCode)="showModalInvitationCode()"
            (displayMainMenu)="displayMainMenu($event)"
            (openSupportModal)="onOpenSupportModal($event)"
            *ngIf="!isInMaintenance"
></app-navbar>

<app-spinner [showSpinner]="showSpinner"></app-spinner>

<div class="container" [ngClass]="{'hideNav': !showNav}">
  <router-outlet></router-outlet>
</div>

<app-footer *ngIf="showNav"></app-footer>

<app-modal-support-email *ngIf="showSupportModal" (closeModal)="onCloseModal($event)"></app-modal-support-email>
<app-modal-invitation-code></app-modal-invitation-code>
<app-modal-action 
  (action)="onModalActionPrimaryButton($event)"
  (actionSecondButton)="onModalActionSecondaryButton($event)"
></app-modal-action>
<app-modal-qr></app-modal-qr>
<app-modal-change-language></app-modal-change-language>

<app-main-menu (modalInvitationCode)="showModalInvitationCode()"
               (modalLanguage)="showModalLanguage($event)"></app-main-menu>
