import * as AOS from 'aos';

import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';

import {AccessRequest} from 'src/app/models/AccessRequest';
import {AccessService} from 'src/app/services/access.service';
import {AccessTypeResponse} from 'src/app/models/accessTypeResponse';
import {AuthService} from 'src/app/services/auth.service';
import {EventForCustomer} from 'src/app/models/events/EventForCustomer';
import {EventService} from 'src/app/services/event.service';
import {EventStatusResponse} from '../../../../models/events/EventStatusResponse';
import {PaymentMethods} from 'src/app/enum/PaymentMethods';
import {PaymentService} from 'src/app/services/payment.service';
import {TableRequest} from 'src/app/models/TableRequest';
import {TableSelected} from 'src/app/models/TableSelected';
import {TableService} from 'src/app/services/table.service';
import {TableTypeResponse} from 'src/app/models/TableTypeResponse';
import {TicketSelected} from 'src/app/models/TicketSelected';
import {User} from 'src/app/models/user';
import {environment} from 'src/environments/environment';
import {SpinnerService} from '../../../../services/spinner.service';
import {PromotionPersist} from '../../../../models/PromotionPersist';
import { VerificationAccount } from 'src/app/models/VerificationAccount';
import { ModalActionComponent } from 'src/app/share/components/modal-action/modal-action.component';
import { ModalController } from 'src/app/controller/modal-controller';

@Component({
  selector: 'app-buy-ticket',
  templateUrl: './buy-ticket.component.html',
  styleUrls: ['./buy-ticket.component.scss']
})
export class BuyTicketComponent implements OnInit {
  tableWordLocalized = $localize`Combo/Mesa`;
  ticketWordLocalized = $localize`Ticket`;
  eventData: EventForCustomer;
  eventStatusResponse: EventStatusResponse;
  totalPricing = 0;
  environment = environment;
  ticketSelected: AccessTypeResponse;
  tableSelected: TableTypeResponse;
  skeletonLoading = true;
  ticketAmount: any;
  priceTotal: number;
  ticketSelectedId = null;
  subs = [];
  skeletonTickets = [1, 2, 3];
  tickets: AccessTypeResponse[];
  tables: TableTypeResponse[];
  showModal = false;
  idEvent = null;
  typeBuy = null;
  user: User;
  needBuyCode = false;
  codeValidated = false;
  code: string = null;
  showSpinner = false;
  imageSectorPlan: string = null;
  showImageSectorPlan = false;
  dateFormat = $localize`dd MMM yyyy`;
  timeFormat = $localize`HH:mm`;
  showModalActionLogin = false;
  isFree = false;
  showNeedVerifiedAccount = false;
  errorAccountTitle = '';
  errorAccountContent = '';
  errorAccountIcon = '';
  errorAccountImage = '';
  showErrorModal = false;
  errorTitle = '';
  errorContent = '';
  errorIcon: string = null;

  constructor(
    private router: Router,
    private paymentService: PaymentService,
    private eventService: EventService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private accessSrv: AccessService,
    private tableSrv: TableService,
    private spinnerSrv: SpinnerService,
    private modalCtrl: ModalController
  ) {
    this.route.paramMap.subscribe({
      next: (params: ParamMap) => {
        const id = params.get('id');
        const type = params.get('type');
        const code = params.get('code');
        if (id) {
          this.idEvent = id;
        }
        if (type) {
          this.typeBuy = type;
        }
        if (code) {
          this.code = code;
        }
      }
    });
  }

  async ngOnInit() {
    // finish data required loading
    this.spinnerSrv.loadSpinner.next(false);
    this.tickets = [];
    await this.authService.checkUserLogged();
    this.user = JSON.parse(localStorage.getItem('user'));
    this.subs.push(
      this.eventService.getEventById(this.idEvent).subscribe({
        next: (event: EventForCustomer) => {
          this.eventData = event;
          if (this.eventData.sectorPlan) {
            this.imageSectorPlan = environment.mediaUrl + this.eventData.sectorPlan;
          }
          this.tickets = event.accessTypes;
          this.tickets.sort((a: AccessTypeResponse, b: AccessTypeResponse) => {
            return b.price - a.price;
          });
          this.tables = event.tableTypes;
          this.tables.sort((a: TableTypeResponse, b: TableTypeResponse) => {
            return b.price - a.price;
          });
          setTimeout(() => {
            this.skeletonLoading = false;
          }, 1000);
        },
        error: (err) => {
          if (err.status === 404) {
            this.router.navigate(['/event-not-found']);
          }
        }
      })
    );
    this.subs.push(
      this.eventService.getStatusEventOnBuyById(this.idEvent).subscribe({
        next: (eventStatusResp: EventStatusResponse) => {
          this.eventStatusResponse = eventStatusResp;
          if (!this.eventStatusResponse.isEnabledForWebSelling) {
            this.back();
          }
        },
        error: (err) => {
          if (err.status === 404) {
            this.router.navigate(['/event-not-found']);
          }
        }
      })
    );
    AOS.init({
      duration: 800,
    });
  }

  selectTicketEvent(ticket: TicketSelected) {
    if (ticket !== null) {
      this.ticketSelected = ticket.ticket;
      this.totalPricing = ticket.total;
      this.ticketSelectedId = ticket.ticket.idAccessType;
      this.ticketAmount = ticket.amountTickets;
      this.needBuyCode = ticket.needBuyCode;
      this.codeValidated = ticket.codeValidated;
      this.isFree = ticket.free;
      localStorage.setItem('ticketData', JSON.stringify(ticket.ticket));
    } else {
      this.ticketSelected = null;
      this.totalPricing = 0;
      this.ticketSelectedId = null;
      this.ticketAmount = null;
      this.needBuyCode = false;
      this.codeValidated = false;
      this.isFree = false;
    }
  }

  selectTableEvent(table: TableSelected) {
    if (table !== null) {
      this.tableSelected = table.table;
      this.totalPricing = table.total;
      this.ticketSelectedId = table.table.idTableType;
      this.needBuyCode = table.needBuyCode;
      this.codeValidated = table.codeValidated;
      this.isFree = table.free;
      localStorage.setItem('ticketData', JSON.stringify(table.table));
    } else {
      this.tableSelected = null;
      this.totalPricing = 0;
      this.ticketSelectedId = null;
      this.needBuyCode = false;
      this.codeValidated = false;
      this.isFree = false;
    }
  }

  openModalLogin(e) {
    this.showModalActionLogin = true;
  }

  goCheckout() {
    if (this.typeBuy === 'A') {
      const data: AccessRequest = {
        accessTypeId: this.ticketSelected.idAccessType,
        quantity: this.ticketAmount,
        eventId: this.eventData.idEvent,
        amountTotal: this.totalPricing,
        ticketName: this.ticketSelected.name,
        methodPayment: PaymentMethods.CREDIT_CARD,
        type: this.typeBuy,
        owner: null,
        paymentToken: null,
        promoId: null,
        free: this.isFree
      };
      // SAVE ON OBSERVABLE
      this.accessSrv.initPayment();
      this.accessSrv.saveFistStepDataTicket(data);
      // SAVE LOCAL STORAGE
      this.setLocalStorageAndNavigate(data);
    } else if (this.typeBuy === 'T') {
      const data: TableRequest = {
        tableTypeId: this.tableSelected.idTableType,
        name: this.tableSelected.name,
        eventId: this.eventData.idEvent,
        capacity: this.tableSelected.maxCapacity,
        amountTotal: this.totalPricing,
        methodPayment: PaymentMethods.CREDIT_CARD,
        type: this.typeBuy,
        owner: this.user.id_customer,
        paymentToken: null,
        promoId: null,
        free: this.isFree
      };
      // SAVE ON OBSERVABLE
      this.tableSrv.initPaymentTable();
      this.tableSrv.saveFistStepDataTable(data);
      // SAVE LOCAL STORAGE
      this.setLocalStorageAndNavigate(data);
    }
    // }
  }

  private setLocalStorageAndNavigate(data: AccessRequest | TableRequest) {
    localStorage.setItem('ticketState', JSON.stringify(data));
    if (this.code !== null) {
      this.router.navigate(['/checkout-ticket/' + this.idEvent + '/' + this.typeBuy + '/code/' + this.code]);
    } else {
      this.router.navigate(['/checkout-ticket/' + this.idEvent + '/' + this.typeBuy]);
    }
  }

  showSectorPlan() {
    this.showImageSectorPlan = true;
  }

  onCloseImageSectorPlan(e) {
    this.showImageSectorPlan = false;
  }

  onActionToLogin() {
    if (this.code !== null) {
      const promotionPersist: PromotionPersist = new PromotionPersist();
      promotionPersist.buyPage = true;
      promotionPersist.eventId = this.idEvent;
      promotionPersist.buyType = this.typeBuy;
      promotionPersist.promoCode = this.code;
      sessionStorage.setItem('promotionPersist', JSON.stringify(promotionPersist));
    } else if (this.idEvent !== null) {
      localStorage.setItem('lastDetailEvent', this.idEvent);
    }
    this.router.navigate(['/login']);
  }

  closeModalLogin(data) {
    if (data === false) {
      this.showModalActionLogin = false;
    }
  }


  verifyUserData() {
    this.spinnerSrv.loadSpinner.next(true);
    const logged = this.authService.isLoggedIn();
    if (logged) {
      this.authService.getAccountStatus().subscribe({
        next: async (res: VerificationAccount) => {
          if (!res.documentVerified || !res.emailVerified || res.blocked) {
            await this.handleAccountStatus(res);
            return;
          }
          this.authService.getCurrentUserData().subscribe({
            next: (user: User) => {
              this.spinnerSrv.loadSpinner.next(false);
              if (user.document !== null && user.document !== '0' && user.document !== '') {
                this.goCheckout();
              } else {
                this.router.navigate(['data-required']);
              }
            }, error: () => {
              this.spinnerSrv.loadSpinner.next(false);
            }
          });
        },
        error: (err) => {
          this.handleErrorAccountStatus(err);
        }
      });      
    } else {
      this.back();
    }
  }

  private async handleAccountStatus(res: VerificationAccount) {
    if (res.blocked) {
      const modal = await this.createModalAction(
        'icon-warning.svg',
        $localize`Cuenta bloqueada`,
        $localize`Tu cuenta se encuentra bloqueada para operar. Si crees que se debe a un error, por favor comunicate con soporte.`,
        $localize`Aceptar`
      );
      const data = modal.onDidDismiss();
      if (data) {
        this.authService.logout();
      }
    } else {
      if (!res.documentVerified && !res.emailVerified) {
        // invalid DNI and EMAIL
        const modal = await this.createModalAction(
          'fa-thin fa-triangle-exclamation',
          $localize`Validá tu email, tu DNI y continuá con el pago.`,
          $localize`Seguí las instrucciones desde tu casilla de correos, y validá tu identidad a continuación.`,
          $localize`Aceptar`
        );
        const data = modal.onDidDismiss();
        if (data) {
          await this.router.navigate(['data-required']);
        }
      } else if (!res.documentVerified) {
        // invalid DNI
        const modal = await this.createModalAction(
          'invalid-dni.svg',
          $localize`Validá tu DNI y continuá con el pago.`,
          $localize`Para continuar con la compra validá tu identidad a continuación.`,
          $localize`Aceptar`
        );
        const data = modal.onDidDismiss();
        if (data) {
          await this.router.navigate(['data-required']);
        }
      } else {
        // invalid EMAIL
        this.errorAccountTitle = $localize`Validá tu email y continuá con el pago.`;
        this.errorAccountContent = $localize`Seguí las instrucciones desde tu casilla de correos, para activar tu cuenta.`;
        this.errorAccountImage = '';
        this.errorAccountIcon = 'fa-thin fa-triangle-exclamation';
        this.showNeedVerifiedAccount = true;
      }
      this.spinnerSrv.loadSpinner.next(false);

    }
  }

  private handleErrorAccountStatus(err) {
    this.errorTitle = $localize`Error`;
    if (err.status === 404) {
      this.errorContent = $localize`Usuario invalido.`;
    } else {
      this.errorContent = $localize`Ha ocurrido un error, por favor volvé a intentarlo.`;
    }
    this.showErrorModal = true;
    this.spinnerSrv.loadSpinner.next(false);
  }

  private async createModalAction(icon: string, title: string, content: string, textBtn: string) {
    const modal = await this.modalCtrl.create({
      component: ModalActionComponent,
      props: {
        icon,
        title,
        content,
        textBtn
      },
    });
    await modal.present();
    return modal;
  }

  onNeedVerifiedAccount(e) {
    this.showNeedVerifiedAccount = false;
  }

  onActionErrorModal(e) {
    this.showErrorModal = false;
  }

  back() {
    if (this.code !== null) {
      this.router.navigate(['/detail-event/' + this.idEvent + '/code/' + this.code]);
    } else {
      this.router.navigate(['/detail-event/' + this.idEvent]);
    }
  }
}
