import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';
import {IdType} from '../../../enum/idType';
import {ErrorService} from 'src/app/services/error.service';

@Component({
  selector: 'app-bid-input-dni',
  templateUrl: './bid-input-dni.component.html',
  styleUrls: ['./bid-input-dni.component.scss']
})
export class BidInputDniComponent implements OnInit, AfterViewInit {

  @Input() parentForm: UntypedFormGroup;
  @Input() formHasBeenSubmitted = false;
  @Input() page;
  @Input() onFocus = false;
  @Input() onError = false;
  @Input() readonly = false;
  @Input() preventSpace = false;
  @Input() customClass = '';
  @Input() withIcon = true;
  @Input() onFilled = false;
  @Input() customError = false;
  @Input() validate = false;
  @Input() bgLightMobile = false;
  @Input() bgLightDesktop = false;
  @Input() large = false;
  @Input() largeMobile = false;
  @Input() largeDesktop = false;
  @Input() sendAsNumber = false;
  @Input() hint = true;
  @Input() success = false;
  @Input() pattern = '[A-Za-z0-9]{6,20}';
  @ViewChild('input') input: ElementRef;
  helperText = '';

  // configuration counter
  @Input() showCounter = false;
  @Input() maxCounter = 50;

  /* THIS CONFIG IS FOR CUSTOM TRANSLATE */
  @Input() customString = false;
  @Input() customKey = '';
  @Input() required = false;

  // dropdown config
  @Input() dropdownControlName: string;
  @Input() dropdownOptions;
  @Input() dropdownOptionLabel: string;
  @Input() dropdownOptionValue: string;

  // input config
  @Input() inputControlName: string;
  @Input() min = 4;
  @Input() max = 20;
  @Input() type = 'text';

  @Output() inputChange = new EventEmitter();
  @Output() dropdownChange = new EventEmitter();
  isHighlighted: boolean;
  listOfErrors: Array<string> = [];

  constructor(private errorService: ErrorService) {
  }

  get inputDropdownField(): AbstractControl {
    return this.parentForm.get(this.dropdownControlName);
  }

  get inputField(): AbstractControl {
    return this.parentForm.get(this.inputControlName);
  }

  ngOnInit(): void {
    this.inputField.valueChanges.subscribe(() => this.filledInput());
  }

  ngAfterViewInit() {
    this.filledInput();
    this.shouldShowErrors();
    this.checkIsFocus();
  }

  onDropdownChange(e) {
    this.dropdownChange.emit();
  }

  onOptionTypeChange(e) {
    this.dropdownChange.emit(e);
  }

  checkIsFocus() {
    if (this.onFocus) {
      this.onError = false;
      setTimeout(() => this.input.nativeElement.focus(), 500);
    }
  }

  verifyID(e) {
    if (this.inputDropdownField.value === IdType.DNI) {
      return this.onlyNumber(e);
    }
  }

  onIdFocus() {
    this.isHighlighted = true;
  }

  onInputFocusOut() {
    this.isHighlighted = false;
    this.inputChange.emit();

    if (this.inputDropdownField.value !== IdType.DNI) {
      this.inputField.patchValue(this.inputField.value.replace(/[^a-zA-Z0-9]/g, ''));
    } else {
      this.inputField.patchValue(this.inputField.value.replace(/[^0-9]/g, ''));
    }

    if (this.sendAsNumber) {
      this.inputField.patchValue(parseInt(this.inputField.value, 10));
    }
  }

  onlyNumber(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  onBlurInput() {
    this.onFocus = false;
    this.onError = false;
  }

  filledInput() {
    if (this.inputField.value !== '' && this.inputField.value !== ' ' && this.inputField.value != null) {
      this.onFilled = true;
      this.onError = false;
    } else {
      this.onFilled = false;
    }
    return this.onFilled;
  }

  shouldShowErrors(): string[] {
    this.listOfErrors = this.errorService.shouldShowErrors(this.inputField, this.formHasBeenSubmitted, this.page);
    return this.listOfErrors;
  }
}
