import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ModalActionType } from 'src/app/models/ModalActionType';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-modal-generic',
  templateUrl: './modal-generic.component.html',
  styleUrls: ['./modal-generic.component.scss']
})
export class ModalGenericComponent implements OnInit {

  @Input() title = '';
  @Input() content = '';
  @Input() textBtn = '';
  @Input() textSecondBtn = '';
  @Input() type: ModalActionType = ModalActionType.info;
  @Input() imageName: string = null;
  @Input() iconClass: string = null;
  @Input() firstButtonClass = 'btn__md btn__primary';
  @Input() secondButtonClass = 'btn__md btn__secondary';
  @Input() showHeader = true;
  @Input() invertBtnsMobile = false;
  @Input() invertBtnsDesktop = false;
  @Output() closeButtonEvent = new EventEmitter();
  @Output() action = new EventEmitter();
  @Output() actionSecondButton = new EventEmitter();

  MODALACTIONTYPE = ModalActionType;

  constructor() { }

  ngOnInit(): void { }

  closeModal() {
    //this.cleanActionModal();
    this.closeButtonEvent.emit();
  }

  onAction() {
    this.action.emit();
  }

  onActionSecondButton() {
    this.actionSecondButton.emit();
  }

}
