import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationMessagesService {

  JSONStrings = {
    login: {
      invalid: $localize`Campo inválido`,
      pattern: $localize`Email inválido`,
      required: $localize`Campo obligatorio`
    },
    forgot: {
      invalid: $localize`Campo inválido`,
      pattern: $localize`Email inválido`,
      required: $localize`Campo obligatorio`
    },
    profile: {
      invalid: $localize`Campo inválido`,
      pattern: $localize`Email inválido`,
      required: $localize`Campo obligatorio`
    },
    register: {
      cuitValidator: $localize`CUIT inválido`,
      required: $localize`Campo obligatorio`,
      pattern: $localize`El campo es inválido`,
      invalid: $localize`Campo inválido`,
      phone: $localize`Número de teléfono inválido`,
      minlength: $localize`Cantidad de caracteres inválida`,
      validatePhoneNumber: $localize`Número de teléfono inválido`,
      EmailAlreadyExist: $localize`El correo electrónico ya se encuentra registrado`,
      InvalidEmail: $localize`El correo electrónico es inválido.`,
      DNIAlreadyExist: $localize`Ya existe una cuenta registrada con este DNI`,
      IDAlreadyExist: $localize`Ya existe una cuenta registrada con este ID`,
      PassportAlreadyExist: $localize`Ya existe una cuenta registrada con este Pasaporte`,
      IDdoesnotMatch: $localize`Los ID deben coincidir`,
      DNIdoesnotMatch: $localize`Los DNI deben coincidir`,
      ErrorOnIdVerification: $localize`Error al validar el dni`,
      PasswordNotMatch: $localize`Las contraseñas deben coincidir`,
      DNIcantChars: $localize`El DNI debe tener entre 7 y 9 dígitos`,
      lenghtPassword: $localize`La contraseña debe contener al menos 8 dígitos`,
      invalidPasswordPattern: $localize`La contrañase debe contener letras y números y al menos 8 dígitos`
    },
    recoverPassword: {
      invalid: $localize`Campo inválido`,
      pattern: $localize`Email inválido`,
      required: $localize`Campo obligatorio`
    }
  };

  constructor() {
  }

  getValidationMessges() {
    return this.JSONStrings;
  }
}
