import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {SupportService} from 'src/app/services/support.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

  @Output() modalInvitationCode = new EventEmitter<boolean>();
  @Output() modalLanguage = new EventEmitter<boolean>();
  @ViewChild('myAccount') first;
  @ViewChild('ingressCode') ingressCode;
  @ViewChild('myEvents') myEvents;
  @ViewChild('configurationAccount') configurationAccount;
  @ViewChild('language') language;
  @ViewChild('support') support;
  @ViewChild('logout') logout;
  viewMenu: boolean;
  logged = false;

  constructor(
    private router: Router,
    private authSrv: AuthService,
    private supportService: SupportService
  ) {
  }

  ngOnInit(): void {
    document.addEventListener('click', this.closeMenus.bind(this));
    this.authSrv.showMainMenu.subscribe({
      next: (res: {
        state: boolean,
        logged: boolean
      }) => {
        this.viewMenu = res.state;
        this.logged = res.logged;
      }
    });
  }

  closeMenus(event: any): void {
    if (this.isLogin()) {
      if (!this.ingressCode?.nativeElement.contains(event.target)
        && !this.myEvents?.nativeElement.contains(event.target)
        && !this.language?.nativeElement.contains(event.target)
        && !this.support?.nativeElement.contains(event.target)
        && !this.logout?.nativeElement.contains(event.target)
        && !this.first?.nativeElement.contains(event.target)
        && !this.configurationAccount?.nativeElement.contains(event.target)) {
      }
    }
  }

  openModalEventCode() {
    if (this.logged) {
      this.modalInvitationCode.emit(true);
      document.body.style.position = 'fixed';
    }
  }

  openModalLanguageMobile() {
    if (this.logged) {
      this.modalLanguage.emit(true);
      document.body.style.position = 'fixed';
    }
  }

  contactSupport() {
    //window.location.href = 'mailto:soporte@blackid.app';
    this.supportService.openSupportModal.next(true);
    document.body.style.position = '';
  }

  async logOut() {
    this.authSrv.logout();
    this.viewMenu = false;
    document.body.style.position = '';
  }

  goToProfile() {
    this.router.navigate(['/profile']);
    document.body.style.position = '';
  }

  goToConfigurations() {
    this.router.navigate(['/configurations']);
    document.body.style.position = '';
  }

  goToMyEvents() {
    this.router.navigate(['/my-events']);
    document.body.style.position = '';
  }

  isLogin() {
    return !(this.router.url === '/login' || this.router.url === '/signup' || this.router.url === '/forgot-password');
  }

}
