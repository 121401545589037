<div class="modal" [ngClass]="showModal ? 'show__modal' : 'close__modal'">
  <div class="modal__container">
    <div class="modal__header">
      <div class="btn btn__secondary btn__sm" *ngIf="typeInfoSelected === 'A'" (click)="buy()" i18n>
        Comprar ticket
      </div>
      <div class="btn btn__secondary btn__sm" *ngIf="typeInfoSelected === 'T'" (click)="buy()" i18n>
        Comprar combo/mesa
      </div>

      <div class="title">
        <div *ngIf="typeInfoSelected === 'A'" class="text-gray-1">
          <i class="fa-solid fa-ticket margin-right-8 font-size-14"></i>
          <span class="font-size-18 line-height-24 font-weight-600 font-secondary" i18n>
            Tipos de tickets
          </span>
        </div>
        <div *ngIf="typeInfoSelected === 'T'" class="text-gray-1">
          <i class="fa-solid fa-champagne-glasses margin-right-8 font-size-14"></i>
          <span class="font-size-18 line-height-24 font-weight-600 font-secondary" i18n>
            Tipos de combos/mesas
          </span>
        </div>
      </div>
      <button class="btn__close" (click)="closeModal()">
        <i class="fa-regular fa-xmark icon__close"></i>
      </button>
    </div>
    <div class="border"></div>
    <div class="modal__body">
      <div class="tickets__container" *ngIf="!skeletonLoading && typeInfoSelected === 'A'">
        <app-ticket-card [skeletonLoading]="skeletonLoading" class="ticket-card" *ngFor="let ticket of tickets"
                         [ticket]="ticket" [table]="" [typeBuy]="typeInfoSelected"
                         [eventData]="eventData"></app-ticket-card>
      </div>
      <div class="tickets__container" *ngIf="!skeletonLoading && typeInfoSelected === 'T'">
        <app-ticket-card [skeletonLoading]="skeletonLoading" class="ticket-card" *ngFor="let table of tables"
                         [ticket]="" [table]="table" [typeBuy]="typeInfoSelected"
                         [eventData]="eventData"></app-ticket-card>
      </div>
      <div class="tickets__container" *ngIf="skeletonLoading">
        <app-ticket-card [skeletonLoading]="skeletonLoading" class="ticket-card"
                         *ngFor="let ticket of skeletonTickets" [ticket]="ticket"></app-ticket-card>
      </div>
    </div>
  </div>
</div>
