<p-dialog [(visible)]="showModal" (onHide)="onHide()" [draggable]="false" [resizable]="false" [modal]="true"
          class="bid-modal resale">
  <ng-template pTemplate="header" class="bid-modal__header">
  </ng-template>

  <div class="bid-modal__content">
    <div class="resale__title">
      <ng-container i18n>Serás dirigido a la plataforma de reventa segura de tickets.</ng-container>
    </div>
    <div class="resale__items">
      <div class="item">
        <i class="fa-regular fa-circle-check text-primary"></i>
        <div class="description">
          <ng-container i18n>Mientras el ticket se encuentre en Reventa, no podrá ser transferido o utilizado para
            ingresar al evento.
          </ng-container>
        </div>
      </div>
      <div class="item">
        <i class="fa-regular fa-circle-check text-primary"></i>
        <div class="description">
          <ng-container i18n>Al avanzar en el proceso de reventa quedan automáticamente bloqueadas las funcionalidades
            de reventa y transferencia de acceso hasta que se complete de manera exitosa el proceso o al pasar 20
            minutos desde que se cancelo el mismo.
          </ng-container>
        </div>
      </div>
      <div class="item">
        <i class="fa-regular fa-circle-check text-primary"></i>
        <div class="description">
          <ng-container i18n>Podrás quitar el ticket de Reventa cuando lo desees.
          </ng-container>
        </div>
      </div>
      <div class="item">
        <i class="fa-regular fa-circle-check text-primary"></i>
        <div class="description">
          <ng-container i18n>En caso de no haberse revendido, el ticket saldrá de reventa y estará disponible para
            utilizar o transferir, 1 hora antes de comenzado el evento, de forma automática.
          </ng-container>
        </div>
      </div>
    </div>
  </div>


  <ng-template pTemplate="footer" class="bid-modal__footer">
    <button
      pButton
      class="btn btn__secondary"
      (click)="cancel()"
      label="{{ btnCancel }}"
    ></button>
    <button
      pButton
      class="btn btn__primary desktop"
      (click)="onContinueResale()"
      label="{{ btnContinueDesktop }}"
    ></button>
    <button
      pButton
      class="btn btn__primary mobile"
      (click)="onContinueResale()"
      label="{{ btnContinueMobile }}"
    ></button>
  </ng-template>

</p-dialog>
