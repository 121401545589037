import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {TableResponse} from '../../../../../models/TableResponse';
import {Subscription} from 'rxjs';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {IdType, IdTypeData} from '../../../../../enum/idType';
import {UserRestrict} from '../../../../../models/UserRestrict';
import {EventGuestRequest} from '../../../../../models/events/EventGuestRequest';
import {EventService} from '../../../../../services/event.service';
import {TableGuestService} from '../../../../../services/table-guest.service';
import {SpinnerService} from '../../../../../services/spinner.service';
import {MessageService} from 'primeng/api';
import {UserService} from '../../../../../services/user.service';
import {EventGuestService} from '../../../../../services/event-guest.service';
import {environment} from '../../../../../../environments/environment';
import {EventForCustomer} from '../../../../../models/events/EventForCustomer';
import {AccessResponse} from '../../../../../models/AccessResponse';
import {GuestListResponse} from '../../../../../models/GuestListResponse';
import {ListGuestsService} from '../../../../../services/list-guests.service';
import {GuestAccessService} from '../../../../../services/guest-access.service';
import {EnvironmentService} from 'src/app/services/environment.service';
import {Countries} from 'src/app/models/Countries';
import {CountriesService} from 'src/app/services/countries.service';

@Component({
  selector: 'app-modal-add-guest',
  templateUrl: './modal-add-guest.component.html',
  styleUrls: ['./modal-add-guest.component.scss']
})
export class ModalAddGuestComponent implements OnInit, OnDestroy {
  @Output() updateListGuests = new EventEmitter<boolean>();
  showModal = false;
  invitationDataAccess: AccessResponse = null;
  invitationDataTable: TableResponse = null;
  invitationDataList: GuestListResponse = null;
  invitationType = '';
  eventId = null;
  event: EventForCustomer;
  descriptions: string[] = null;
  headerPopup: string;
  headerSubtitle: string;
  subs: Subscription[] = [];
  maxDocumentLength: number;
  minDocumentLength: number;
  formHasBeenSubmitted = false;
  newGuestForm: UntypedFormGroup;
  maxTableReferenceLength: number;
  allCountries;
  isReadOnly = false;
  idOptionTypes: Array<IdTypeData>;
  showingDataCustomerAndInvitation = false;
  guestIsCustomer = false;
  userToAdd: UserRestrict = null;
  eventGuestRequest: EventGuestRequest = null;
  guestAlreadyAuthorize = false;
  mediaUrl: string;
  eventName: string;
  currency = '$';
  selectYourCountry = $localize`Seleccioná tu país*`;
  backLabel = $localize`Volver`;
  cancelLabel = $localize`Cancelar`;
  nextLabel = $localize`Continuar`;
  addGuestLabel = $localize`Agregar invitado`;
  finishLabel = $localize`Listo`;
  designAdminLabel = $localize`Designar como administrador`;
  eventIsEnded = false;
  language = 'es';
  whiteName = '';
  placeHolderCountry = $localize`Seleccioná tu país*`;
  userIsBlocked = false;
  idPattern = '[0-9]{6,20}';
  inputType = 'number';

  idTypesArg: Array<IdTypeData> = [{
    name: $localize`DNI`,
    value: IdType.DNI
  }, {
    name: $localize`Pasaporte`,
    value: IdType.PASSPORT
  }];

  idTypesROW = [{
    name: $localize`ID`,
    value: IdType.ID
  }, {
    name: $localize`Pasaporte`,
    value: IdType.PASSPORT
  }];

  constructor(
    private eventSrv: EventService,
    private formBuilder: UntypedFormBuilder,
    private tableGuestsSrv: TableGuestService,
    private accessGuestSrv: GuestAccessService,
    private listGuestSrv: ListGuestsService,
    private spinnerSrv: SpinnerService,
    private messageSrv: MessageService,
    private userSrv: UserService,
    private eventGuestSrv: EventGuestService,
    private environmentService: EnvironmentService,
    private countriesService: CountriesService
  ) {
    this.whiteName = this.environmentService.getBrandName();
    this.language = localStorage.getItem('current_locale');
    this.maxDocumentLength = environment.maxDniLength;
    this.minDocumentLength = environment.minDniLength;
    this.maxTableReferenceLength = environment.maxTableReferenceLength;
    this.newGuestForm = this.formBuilder.group({
      document: [null, [Validators.required, Validators.minLength(this.minDocumentLength), Validators.maxLength(this.maxDocumentLength)]],
      documentType: [IdType.DNI, [Validators.required]],
      country: ['arg', [Validators.required]],
      reference: [null, [Validators.maxLength(this.maxTableReferenceLength)]],
      tableAdmin: [false],
      sector: [null],
      willBeAdmin: [false]
    });
    this.idOptionTypes = this.idTypesArg;
    this.mediaUrl = environment.mediaUrl;
    this.formHasBeenSubmitted = false;
  }

  get documentField(): AbstractControl {
    return this.newGuestForm.controls.document;
  }

  get documentTypeField(): AbstractControl {
    return this.newGuestForm.controls.documentType;
  }

  get countryField(): AbstractControl {
    return this.newGuestForm.controls.country;
  }

  get referenceField(): AbstractControl {
    return this.newGuestForm.controls.reference;
  }

  get tableAdminField(): AbstractControl {
    return this.newGuestForm.controls.tableAdmin;
  }

  ngOnInit(): void {
    this.inputType = 'number';
    this.subs.push(
      this.eventSrv.showModalAddGuest.subscribe({
        next: (res: {
          state: boolean,
          invitationDataAccess: AccessResponse,
          invitationDataTable: TableResponse,
          invitationDataList: GuestListResponse,
          event: EventForCustomer,
          invitationType: string
        }) => {
          document.body.style.position = 'fixed';
          this.event = res.event;
          this.invitationType = res.invitationType;
          this.formHasBeenSubmitted = false;
          this.headerPopup = $localize`Agregar invitado a`;
          let type = '';
          if (res.invitationType === 'A') {
            type = $localize`Ticket `;
            this.invitationDataAccess = res.invitationDataAccess;
            this.eventId = this.invitationDataAccess.eventId;
            this.descriptions = this.invitationDataAccess.descriptions;
            this.headerSubtitle = `${type} <span class="invitationName"> ${this.invitationDataAccess.name}</span>`;
            this.referenceField.patchValue(
              (this.invitationDataAccess.name.length > environment.maxTableReferenceLength)
                ? this.invitationDataAccess.name.slice(0, environment.maxTableReferenceLength - 1)
                : this.invitationDataAccess.name
            );
          } else if (res.invitationType === 'T') {
            type = $localize`Combo/Mesa `;
            this.invitationDataTable = res.invitationDataTable;
            this.eventId = this.invitationDataTable.eventId;
            this.descriptions = this.invitationDataTable.descriptions;
            this.headerSubtitle = `${type} <span class="invitationName"> ${this.invitationDataTable.name}</span>`;
            this.referenceField.patchValue(
              (this.invitationDataTable.name.length > environment.maxTableReferenceLength)
                ? this.invitationDataTable.name.slice(0, environment.maxTableReferenceLength - 1)
                : this.invitationDataTable.name
            );
          } else if (res.invitationType === 'L') {
            type = $localize`Lista `;
            this.invitationDataList = res.invitationDataList;
            this.eventId = this.invitationDataList.eventId;
            this.headerSubtitle = type + `<span class="invitationName"> ${this.invitationDataList.name}</span>`;
            this.referenceField.patchValue(
              (this.invitationDataList.name.length > environment.maxTableReferenceLength)
                ? this.invitationDataList.name.slice(0, environment.maxTableReferenceLength - 1)
                : this.invitationDataList.name
            );
          }
          this.eventName = res.event.eventName;
          this.currency = res.event.storeDataOnEvent.currency;
          this.showModal = res.state;
        }
      })
    );

    this.subs.push(
      this.countriesService.getAllCountries().subscribe((res: Array<Countries>) => {
        this.allCountries = res;
      })
    );

    this.newGuestForm.valueChanges.subscribe(res => {
      this.formHasBeenSubmitted = false;
    });
  }

  onDropdownChange(e) {
    if (e.alpha3 === 'arg' || e.alpha3 === 'ARG') {
      this.idOptionTypes = this.idTypesArg;
      this.newGuestForm.controls.documentType.patchValue(IdType.DNI);
      this.inputType = 'number';
    } else {
      this.idOptionTypes = this.idTypesROW;
      this.newGuestForm.controls.documentType.patchValue(IdType.ID);
      this.inputType = 'text';
    }
    this.resetId();
  }

  onDropdownOptionChange(e: string) {
    this.resetId();
    if (e === IdType.DNI) {
      this.idPattern = '[0-9]{6,20}';
      this.inputType = 'number';
    } else {
      this.idPattern = '[A-Za-z0-9]{6,20}';
      this.inputType = 'text';
    }
  }

  resetId() {
    this.newGuestForm.controls.document.patchValue('');
  }

  cleanForm() {
    this.resetId();
    this.newGuestForm.controls.sector.patchValue(null);
    this.newGuestForm.controls.country.patchValue('arg');
    this.newGuestForm.controls.documentType.patchValue(IdType.DNI);
    this.newGuestForm.controls.tableAdmin.patchValue(false);
  }

  cancel() {
    if (this.showingDataCustomerAndInvitation) {
      this.showingDataCustomerAndInvitation = false;
      this.userToAdd = null;
      this.guestAlreadyAuthorize = false;
      this.userIsBlocked = false;
    } else {
      this.onHide();
    }
  }

  onHide() {
    this.cleanForm();
    this.showModal = false;
    this.showingDataCustomerAndInvitation = false;
    this.guestAlreadyAuthorize = false;
    this.formHasBeenSubmitted = false;
    this.userToAdd = null;
    this.idOptionTypes = this.idTypesArg;
    this.userIsBlocked = false;
    document.body.style.position = '';
  }

  checkCustomerGuest() {
    this.formHasBeenSubmitted = true;
    if (this.newGuestForm.valid) {
      this.spinnerSrv.loadSpinner.next(true);
      this.eventGuestRequest = new EventGuestRequest();
      this.eventGuestRequest.document = this.documentField.value;
      this.eventGuestRequest.documentType = this.documentTypeField.value;
      this.eventGuestRequest.country = this.countryField.value.toUpperCase();
      this.eventGuestRequest.reference =
        (this.referenceField.value.length > environment.maxTableReferenceLength)
          ? this.referenceField.value.slice(0, environment.maxTableReferenceLength - 1)
          : this.referenceField.value;
      if (this.invitationType === 'L') {
        this.eventGuestRequest.listAdmin = this.tableAdminField.value;
      } else {
        this.eventGuestRequest.tableAdmin = this.tableAdminField.value;
      }
      this.checkIfCustomerAndGuestExist(this.eventGuestRequest);
    }
  }

  checkIfCustomerAndGuestExist(eventGuestRequest: EventGuestRequest) {
    this.userSrv.getUserRestrictByDocument(
      eventGuestRequest.document, eventGuestRequest.documentType, eventGuestRequest.country).subscribe({
      next: (customer: UserRestrict) => {
        this.guestIsCustomer = true;
        if (!customer.isBlocked) {
          this.userToAdd = customer;
          this.checkIfAlreadyGuestOfEvent();
        } else {
          this.userIsBlocked = true;
          this.showingDataCustomerAndInvitation = true;
          this.spinnerSrv.loadSpinner.next(false);
        }
      },
      error: (err) => {
        if (err.status === 404) {
          this.checkIfAlreadyGuestOfEvent();
          this.guestIsCustomer = false;
        }
      }
    });
  }

  checkIfAlreadyGuestOfEvent() {
    this.guestAlreadyAuthorize = false;
    this.eventGuestSrv.checkGuestExistence(
      this.eventGuestRequest.document,
      this.eventGuestRequest.documentType,
      this.eventGuestRequest.country,
      this.eventId).subscribe({
      next: () => {
        this.spinnerSrv.loadSpinner.next(false);
        this.showingDataCustomerAndInvitation = true;
      },
      error: (err) => {
        this.spinnerSrv.loadSpinner.next(false);
        if (err.status === 404) {
          this.showingDataCustomerAndInvitation = true;
          this.guestAlreadyAuthorize = true;
        }
      }
    });
  }

  submitCreateGuest() {
    this.formHasBeenSubmitted = true;
    this.spinnerSrv.loadSpinner.next(true);
    if (this.invitationType === 'A') {
      this.accessGuestSrv.addGuestAccess(this.invitationDataAccess.idAccess, this.eventGuestRequest).subscribe({
        next: () => {
          this.updateListGuests.emit(true);
          this.showModal = false;
          document.body.style.position = '';
          this.spinnerSrv.loadSpinner.next(false);
          this.formHasBeenSubmitted = false;
          this.showToastSuccess();
        },
        error: (err) => {
          this.spinnerSrv.loadSpinner.next(false);
          this.handleErrorAddAccessGuest(err);
          this.formHasBeenSubmitted = false;
        }
      });
    } else if (this.invitationType === 'T') {
      this.tableGuestsSrv.addTableGuest(this.invitationDataTable.idTable, this.eventGuestRequest).subscribe({
        next: () => {
          this.updateListGuests.emit(true);
          this.showModal = false;
          document.body.style.position = '';
          this.spinnerSrv.loadSpinner.next(false);
          this.formHasBeenSubmitted = false;
          this.showToastSuccess();
        },
        error: (err) => {
          this.spinnerSrv.loadSpinner.next(false);
          this.handleErrorAddTableGuest(err);
          this.formHasBeenSubmitted = false;
        }
      });
    } else if (this.invitationType === 'L') {
      this.listGuestSrv.addListGuest(this.invitationDataList.idGuestList, this.eventGuestRequest).subscribe({
        next: () => {
          this.updateListGuests.emit(true);
          this.showModal = false;
          document.body.style.position = '';
          this.spinnerSrv.loadSpinner.next(false);
          this.formHasBeenSubmitted = false;
          this.showToastSuccess();
        },
        error: (err) => {
          this.spinnerSrv.loadSpinner.next(false);
          this.handleErrorAddListGuest(err.status);
          this.formHasBeenSubmitted = false;
        }
      });
    }
  }

  showToastSuccess() {
    let detailMessage;
    if (this.guestIsCustomer) {
      detailMessage = this.userToAdd.name + ' ' + this.userToAdd.lastName + $localize` se sumó correctamente a ` + this.eventName + '.';
    } else {
      detailMessage = this.eventGuestRequest.document + $localize` se sumó correctamente a ` + this.eventName + '.';
    }
    this.messageSrv.add({
      summary: $localize`Invitado agregado correctamente`,
      detail: detailMessage,
      severity: 'success'
    });
    setTimeout(() => {
      this.messageSrv.clear();
    }, 5000);
  }

  handleErrorAddAccessGuest(err) {
    if (err.status === 400) {
      this.messageSrv.add({
        detail:
          $localize`El evento ya no existe o se ha cancelado.`,
        severity: 'error'
      });
    } else if (err.status === 403) {
      this.messageSrv.add({
        detail:
          $localize`El invitado ya es parte del evento.`,
        severity: 'warning'
      });
    } else if (err.status === 404) {
      this.messageSrv.add({
        detail:
          $localize`El acceso ya no existe.`,
        severity: 'error'
      });
    } else if (err.status === 409) {
      this.messageSrv.add({
        detail:
          $localize`Se ha alcanzado la capacidad máxima permitida para este ticket.`,
        severity: 'warning'
      });
    }
    setTimeout(() => {
      this.messageSrv.clear();
    }, 5000);
  }

  handleErrorAddTableGuest(err) {
    if (err.status === 400) {
      this.messageSrv.add({
        detail:
          $localize`El evento ya no existe o se ha cancelado.`,
        severity: 'error'
      });
    } else if (err.status === 403) {
      this.messageSrv.add({
        detail:
          $localize`El invitado ya es parte del evento.`,
        severity: 'error'
      });
    } else if (err.status === 404) {
      this.messageSrv.add({
        detail:
          $localize`El combo o la mesa ya no existe.`,
        severity: 'error'
      });
    } else if (err.status === 406) {
      this.messageSrv.add({
        detail:
          $localize`El invitado ya es parte de un acceso.`,
        severity: 'error'
      });
    } else if (err.status === 409) {
      this.messageSrv.add({
        detail:
          $localize`Capacidad máxima alcanzada.`,
        severity: 'warning'
      });
    } else if (err.status === 417) {
      this.messageSrv.add({
        detail:
          $localize`El invitado ya es parte del combo o mesa.`,
        severity: 'error'
      });
    }
    setTimeout(() => {
      this.messageSrv.clear();
    }, 5000);
  }

  handleErrorAddListGuest(errorCode) {
    let msg = '';
    switch (errorCode) {
      case 400:
        msg = $localize`El evento ya no existe o se ha cancelado.`;
        break;
      case 404:
        msg = $localize`La lista ya no existe.`;
        break;
      case 403:
        msg = $localize`La persona ya es parte del evento.`;
        break;
      case 409:
        msg = $localize`Se ha alcanzado la capacidad máxima de personas en esta lista.`;
        break;
      default:
        break;
    }
    this.messageSrv.add({severity: 'error', summary: '', detail: msg});
    setTimeout(() => {
      this.messageSrv.clear();
    }, 5000);
  }

  ngOnDestroy() {
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }

}
