import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {EnvironmentService} from 'src/app/services/environment.service';
import {FooterService} from 'src/app/services/footer.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  date = new Date().getFullYear();
  url: string;
  showFooter = true;
  hideOnDesktop = false;
  logoName = '';
  environment = environment;

  constructor(
    private footerService: FooterService,
    private environmentSrv: EnvironmentService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.logoName = this.environmentSrv.getBrandLogo();
    this.url = this.environmentSrv.getBrandUrlLink();

    this.footerService.showFooter$.subscribe({
      next: res => {
        this.showFooter = res;
      }
    });

    this.router.events.subscribe({
      next: (data) => {
        if (data instanceof NavigationEnd) {
          this.hideOnDesktop = this.onUrlChange(data.url);
        }
      }
    });
  }

  onUrlChange(url) {
    if (url.includes('/buy-ticket')
      || url.includes('/checkout-ticket')
      || url.includes('/success-purchase')
      || url.includes('/data-required')) {
      return true;
    }
  }

}
