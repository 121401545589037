export enum IdType {
  DNI = 'DNI',
  ID = 'ID',
  PASSPORT = 'PASSPORT'
}

export interface IdTypeData {
  name: string;
  value: IdType;
}
