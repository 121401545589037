import * as AOS from 'aos';

import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';

import {AccessRequest} from 'src/app/models/AccessRequest';
import {AccessService} from 'src/app/services/access.service';
import {EventForCustomer} from 'src/app/models/events/EventForCustomer';
import {EventService} from 'src/app/services/event.service';
import {PaymentService} from 'src/app/services/payment.service';
import {PaymentState} from 'src/app/enum/PaymentState';
import {SpinnerService} from 'src/app/services/spinner.service';
import {TableService} from 'src/app/services/table.service';
import {TrxProviderIdRequest} from 'src/app/models/TrxProviderIdRequest';
import {environment} from 'src/environments/environment';
import {AuthService} from '../../../../../../services/auth.service';
import {EnvironmentService} from 'src/app/services/environment.service';
import {AccessTypeResponse} from '../../../../../../models/accessTypeResponse';
import {TableTypeResponse} from '../../../../../../models/TableTypeResponse';
import {TrxStateResponse} from '../../../../../../models/TrxStateResponse';
import {User} from '../../../../../../models/user';

@Component({
  selector: 'app-success-purchase',
  templateUrl: './success-purchase.component.html',
  styleUrls: ['./success-purchase.component.scss']
})
export class SuccessPurchaseComponent implements OnInit {

  assignTickets = false;
  currentState: PaymentState = PaymentState.pending;
  PaymentState = PaymentState;
  event: EventForCustomer;
  currentPayment: AccessRequest;
  currentTransaction: AccessRequest;
  transactionState: PaymentState;
  showErrorModal = false;
  errorTitle = '';
  errorContent = '';
  statusTransactionReturn: string = null;
  transactionIdReturn: string = null;
  provider = 'mobbex';
  environment = environment;
  dateFormat = $localize`dd MMM`;
  timeFormat = $localize`HH:mm`;
  temporalFinalState = PaymentState.pending.toString();
  currency = '$';
  isFree = false;
  cameFromApp = false;
  logoName = '';
  accessData: AccessTypeResponse = null;
  tableData: TableTypeResponse = null;
  typeBuy = null;
  clientId = null;

  constructor(
    private router: Router,
    private routerActive: ActivatedRoute,
    private paymentService: PaymentService,
    private eventService: EventService,
    private spinnerService: SpinnerService,
    private accessSrv: AccessService,
    private tableSrv: TableService,
    private authSrv: AuthService,
    private environmentService: EnvironmentService
  ) {
    this.routerActive.paramMap.subscribe((params) => {
      let status: string;
      let transactionId: string;
      const prov = params.get('prov');
      const platform = params.get('platform');
      const clientId = params.get('clientId');
      if (prov === 'mp') {
        status = params.get('collection_status');
        transactionId = params.get('reference');
        this.provider = 'mercadoPago';
      } else if (prov === 'mob') {
        status = params.get('status');
        transactionId = params.get('transactionId');
        this.provider = 'mobbex';
      } else if (prov === 'stripe') {
        status = 'processing';
        transactionId = params.get('session_id');
        this.provider = 'stripe';
      }
      if (status) {
        this.statusTransactionReturn = status;
      }
      if (platform) {
        this.cameFromApp = true;
      }
      if (transactionId) {
        this.transactionIdReturn = transactionId;
      }
      if (clientId) {
        this.clientId = clientId;
      }
    });
  }

  async ngOnInit() {
    this.typeBuy = localStorage.getItem('typeBuy');
    this.logoName = this.environmentService.getBrandLogo();
    await this.authSrv.checkUserLogged();
    this.spinnerService.loadSpinner.next(true);
    this.currentTransaction = JSON.parse(localStorage.getItem('ticketState'));
    if (this.typeBuy === 'A') {
      this.accessData = JSON.parse(localStorage.getItem('ticketData'));
    } else if (this.typeBuy === 'T') {
      this.tableData = JSON.parse(localStorage.getItem('ticketData'));
    }
    this.isFree = this.currentTransaction ? this.currentTransaction.free : false;
    this.routerActive.queryParams.subscribe(param => {
      let idEvent;
      if (param.platform) {
        this.cameFromApp = true;
        idEvent = param.platform;
      } else {
        idEvent = localStorage.getItem('eventId');
      }
      if (param.clientId) {
        this.clientId = param.clientId;
      } else {
        const user: User = JSON.parse(localStorage.getItem('user'));
        this.clientId = user.id_customer;
      }
      this.eventService.getEventById(idEvent).subscribe({
        next: (event: EventForCustomer) => {
          this.event = event;
          this.currency = event.storeDataOnEvent.currency;
          if (!this.isFree && param.prov === 'mp') {
            this.transactionIdReturn = param.reference;
            this.statusTransactionReturn = param.collection_status;
            this.provider = 'mercadoPago';
            if (this.transactionIdReturn !== null && this.statusTransactionReturn !== null) {
              if (param.collection_status === 'approved') {
                this.spinnerService.loadSpinner.next(false);
                this.completePurchase();
              } else {
                this.spinnerService.loadSpinner.next(false);
                this.statusTransactionReturn = param.collection_status;
                this.checkTrxState();
              }
            }
          } else if (!this.isFree && param.prov === 'mob') {
            this.transactionIdReturn = param.transactionId;
            this.statusTransactionReturn = param.status;
            this.provider = 'mobbex';
            if (this.transactionIdReturn !== null && this.statusTransactionReturn !== null) {
              if (param.status === '200') {
                this.spinnerService.loadSpinner.next(false);
                this.completePurchase();
              } else {
                this.statusTransactionReturn = param.status;
                this.spinnerService.loadSpinner.next(false);
                this.checkTrxState();
              }
            }
          } else if (!this.isFree && param.prov === 'stripe') {
            this.transactionIdReturn = param.session_id;
            this.statusTransactionReturn = 'processing';
            this.provider = 'stripe';
            if (this.transactionIdReturn !== null) {
                this.statusTransactionReturn = 'processing';
                this.spinnerService.loadSpinner.next(false);
                this.checkTrxState();
            }
          } else {
            this.temporalFinalState = PaymentState.approved;
            this.currentState = PaymentState.approved;
            this.spinnerService.loadSpinner.next(false);
          }
        }
      });
    });
    this.spinnerService.loadSpinner.next(false);

    AOS.init({
      duration: 800,
    });
  }

  getTotalTransaction(price) {
    const promoDiscountPercentage = Number(localStorage.getItem('promoDiscount'));
    let commissionPercentage = 10;
    if (this.typeBuy === 'A') {
      commissionPercentage = this.event.storeDataOnEvent.accessPercentage;
    } else if (this.typeBuy === 'T') {
      commissionPercentage = this.event.storeDataOnEvent.tablePercentage;
    }
    if (!promoDiscountPercentage) {
      const serviceCharge = (price / 100) * commissionPercentage;
      return (price + serviceCharge);
    } else {
      const promoDiscount = (price / 100) * promoDiscountPercentage;
      const serviceCharge = ((price - promoDiscount) / 100) * commissionPercentage;
      return (price - promoDiscount + serviceCharge);
    }
  }

  checkTrxState() {
    let totalTime = 0;
    const intervalCheck = setInterval(async () => {
      if (this.temporalFinalState === PaymentState.pending && totalTime <= 295000) {
        this.paymentService.getPaymentStatus(this.transactionIdReturn, this.provider, this.event.storeId.toString()).subscribe({
          next: (res: TrxStateResponse) => {
            this.temporalFinalState = res.state;
          },
          error: (err) => {
            this.errorTitle = 'Error';
            if (err.status === 400) {
              this.errorContent = 'Ocurrió un error al consultar el estado de tu compra.';
            }
            this.showErrorModal = true;
          }
        });
        totalTime = totalTime + 5000;
      } else {
        clearInterval(intervalCheck);
        if (this.temporalFinalState === PaymentState.approved) {
          this.currentState = PaymentState.approved;
        } else if (this.temporalFinalState === PaymentState.rejected) {
          this.currentState = PaymentState.rejected;
        } else {
          this.currentState = PaymentState.pending;
        }
      }
    }, 5000);
  }

  completePurchase() {
    const payload: TrxProviderIdRequest = {
      provider: this.provider,
      providerTrxId: this.transactionIdReturn,
      commerceId: this.event.storeId.toString()
    };
    this.paymentService.completePurchase(payload, this.clientId).subscribe({
      next: () => {
        this.temporalFinalState = PaymentState.approved;
        this.currentState = PaymentState.approved;
        this.spinnerService.loadSpinner.next(false);
      },
      error: () => {
        this.checkTrxState();
      }
    });
  }

  async returnToBuy() {
    if (this.cameFromApp) {
      this.returnToApp();
    } else {
      if (this.typeBuy === 'A') {
        this.accessSrv.initPayment();
      } else if (this.typeBuy === 'T') {
        this.tableSrv.initPaymentTable();
      }
      const idEvent = localStorage.getItem('eventId');
      await this.router.navigate(['/buy-ticket/' + idEvent + '/' + this.typeBuy]);
      localStorage.removeItem('ticketState');
      localStorage.removeItem('ticketData');
    }
  }

  async myEvents() {
    await this.router.navigate(['/my-events']);
    localStorage.removeItem('ticketState');
    localStorage.removeItem('ticketData');
  }

  async goToEvent() {
    await this.router.navigate(['/my-events/event-detail/' + this.event?.idEvent]);
    localStorage.removeItem('ticketState');
    localStorage.removeItem('ticketData');
  }

  onActionErrorModal(e) {
    this.showErrorModal = false;
  }

  returnToApp() {
    window.location.href = environment.linkToReturnToApp;
    localStorage.removeItem('ticketState');
    localStorage.removeItem('ticketData');
  }

  refresh() {
    window.location.reload();
  }
}
