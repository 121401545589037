export let JSONStrings = {
  login: {
    invalid: $localize`Campo inválido`,
    pattern: $localize`Email inválido`,
    required: $localize`Campo obligatorio`
  },
  forgot: {
    invalid: $localize`Campo inválido`,
    pattern: $localize`Email inválido`,
    required: $localize`Campo obligatorio`
  },
  profile: {
    invalid: $localize`Campo inválido`,
    pattern: $localize`Campo inválido`,
    required: $localize`Campo obligatorio`
  },
  register: {
    cuitValidator: $localize`CUIT inválido`,
    required: $localize`Campo obligatorio`,
    pattern: $localize`El campo posee caracteres invalidos`,
    invalid: $localize`Campo inválido`,
    minlength: $localize`Cantidad de caracteres inválida`,
    validatePhoneNumber: $localize`Número de teléfono inválido`,
    EmailAlreadyExist: $localize`El correo electrónico ya se encuentra registrado`,
    InvalidEmail: $localize`El correo electrónico no es valido`,
    DNIAlreadyExist: $localize`Ya existe una cuenta registrada con este DNI`,
    IDAlreadyExist: $localize`Ya existe una cuenta registrada con este ID`,
    DNIdoesnotMatch: $localize`Los DNI deben coincidir`,
    PasswordNotMatch: $localize`Las contraseñas deben coincidir`,
    DNIcantChars: $localize`El DNI debe tener entre 7 y 9 dígitos`,
    lenghtPassword: $localize`La contraseña debe contener al menos 8 dígitos`,
    invalidPasswordPattern: $localize`La contrañase debe contener letras y números y al menos 8 dígitos`
  },
  recoverPassword: {
    invalid: $localize`Campo inválido`,
    pattern: $localize`Email inválido`,
    required: $localize`Campo obligatorio`,
    emailNotFound: $localize`Alguno de los datos ingresados es incorrecto o inexistente!.`,
    dniNotFound: $localize`Alguno de los datos ingresados es incorrecto o inexistente!.`,
    accountBlocked: $localize`La cuenta se encuentra bloqueada para operar. Si crees que se debe a un error comunicate con soporte.`
  },
  modalAddGuest: {
    invalid: $localize`Campo inválido`,
    required: $localize`Campo obligatorio`,
    pattern: $localize`Documento inválido`,
    minlength: $localize`Cantidad de caracteres inválida`,
  },
  modalDelegateOwnership: {
    invalid: $localize`Campo inválido`,
    required: $localize`Campo obligatorio`,
    pattern: $localize`Documento inválido`,
    minlength: $localize`Cantidad de caracteres inválida`,
  },
  generic: {
    invalid: $localize`Campo inválido`,
    required: $localize`Campo obligatorio`,
    minlength: $localize`Cantidad de caracteres inválida.`,
    maxLength: $localize`Cantidad de caracteres inválida.`
  },
  password: {
    pattern: $localize`La contraseña no es correcta.`,
    invalid: $localize`Campo inválido`,
    required: $localize`Campo obligatorio`,
    minlength: $localize`Cantidad de caracteres inválida.`,
    maxLength: $localize`Cantidad de caracteres inválida.`,
    passwordsMatchRepeat: $localize`Las contraseñas no coinciden`,
    confirmedValidator: $localize`Las contraseñas deben coincidir`
  }
};
