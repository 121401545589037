import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {IdType, IdTypeData} from 'src/app/enum/idType';
import {Countries} from 'src/app/models/Countries';
import {RecoverPasswordDniResponse} from 'src/app/services/RecoverPasswordDniRespo';
import {AuthService} from 'src/app/services/auth.service';
import {CountriesService} from 'src/app/services/countries.service';
import {FooterService} from 'src/app/services/footer.service';
import {SpinnerService} from 'src/app/services/spinner.service';
import {environment} from 'src/environments/environment';
import {RecoverPasswordRequest} from '../../models/RecoverPasswordRequest';
import {$localize} from '@angular/localize/init';

@Component({
  selector: 'app-recover-password-dni',
  templateUrl: './recover-password-dni.component.html',
  styleUrls: ['./recover-password-dni.component.scss']
})
export class RecoverPasswordDniComponent implements OnInit, OnDestroy {

  recoverEmailDniForm: UntypedFormGroup;
  formHasBeenSubmitted = false;
  subs: Subscription[] = [];
  emailSent = false;
  allCountries = null;
  placeHolderCountry = $localize`Seleccioná tu país*`;
  idHighlighted: string;
  emailHasBeenSent = false;
  sendEmailTo: string;

  minDocumentLength: number;
  maxDocumentLength: number;

  idOptionTypes: Array<IdTypeData>;
  idTypesArg: Array<IdTypeData> = [{
    name: 'DNI',
    value: IdType.DNI
  }, {
    name: $localize`Pasaporte`,
    value: IdType.PASSPORT
  }];

  idTypesROW = [{
    name: 'ID',
    value: IdType.ID
  }, {
    name: $localize`Pasaporte`,
    value: IdType.PASSPORT
  }];
  showError = false;
  messageError = null;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private footerService: FooterService,
    private countriesService: CountriesService,
    private spinnerSrv: SpinnerService
  ) {
    this.minDocumentLength = environment.minDocumentLength;
    this.maxDocumentLength = environment.maxDocumentLength;

    this.recoverEmailDniForm = this.formBuilder.group({
      country: ['', [Validators.required]],
      originTypeId: ['', [Validators.required]],
      id: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.footerService.showFooter$.next(false);
    this.formHasBeenSubmitted = false;
    this.getAllCountries();
    if (this.recoverEmailDniForm.controls.originTypeId.value === '') {
      this.recoverEmailDniForm.controls.originTypeId.patchValue(IdType.DNI);
    }
    if (this.recoverEmailDniForm.controls.country.value === '' || this.recoverEmailDniForm.controls.country.value === 'arg') {
      this.recoverEmailDniForm.controls.country.patchValue('arg');
      this.idOptionTypes = this.idTypesArg;
    } else {
      this.idOptionTypes = this.idTypesROW;
    }
  }

  getAllCountries() {
    this.spinnerSrv.loadSpinner.next(true);
    this.subs.push(
      this.countriesService.getAllCountries().subscribe((res: Array<Countries>) => {
        this.allCountries = res;
        this.spinnerSrv.loadSpinner.next(false);
      })
    );
  }

  onDropdownCountryChange(e: Countries) {
    this.showError = false;
    if (e.alpha3 === 'arg') {
      this.idOptionTypes = this.idTypesArg;
      this.recoverEmailDniForm.controls.originTypeId.patchValue(IdType.DNI);
    } else {
      this.idOptionTypes = this.idTypesROW;
      this.recoverEmailDniForm.controls.originTypeId.patchValue(IdType.ID);
    }
    this.resetId();
  }

  resetId() {
    this.recoverEmailDniForm.controls.id.patchValue('');
    this.recoverEmailDniForm.controls.idValidation.patchValue('');
  }

  onOptionTypeChange(e: string) {
    this.showError = false;
    this.resetId();
  }

  submitRecoverPassword() {
    this.showError = false;
    this.formHasBeenSubmitted = true;
    const payload = this.generatePayload();
    if (this.recoverEmailDniForm.valid) {
      this.spinnerSrv.loadSpinner.next(true);
      this.subs.push(
        this.authService.resetPasswordWithDni(payload).subscribe({
          next: (res: RecoverPasswordDniResponse) => {
            this.sendEmailTo = res.email;
            this.emailSent = true;
            this.spinnerSrv.loadSpinner.next(false);
          },
          error: (err) => {
            this.spinnerSrv.loadSpinner.next(false);
            if (err.status === 404) {
              this.recoverEmailDniForm.controls.id.setErrors({'dniNotFound': true});
            }
            if (err.status === 409) {
              this.recoverEmailDniForm.controls.id.setErrors({'accountBlocked': true});
            }
          }
        })
      );
    }
  }

  generatePayload(): RecoverPasswordRequest {
    const recoverRequest = new RecoverPasswordRequest();
    recoverRequest.document = this.recoverEmailDniForm.controls.id.value;
    recoverRequest.idType = this.recoverEmailDniForm.controls.originTypeId.value;
    recoverRequest.country = this.recoverEmailDniForm.controls.country.value.toUpperCase();
    return recoverRequest;
  }

  backToForgotPassword() {
    this.router.navigate(['forgot-password']);
  }

  backToLogin() {
    this.router.navigate(['/login']);
  }

  resendEmail() {
    this.submitRecoverPassword();
  }

  ngOnDestroy(): void {
    this.footerService.showFooter$.next(true);
    this.subs.forEach(res => {
      res.unsubscribe();
    });
  }
}
