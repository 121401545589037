import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {JSONStrings} from 'src/app/core/internalization/es';

@Component({
  selector: 'bid-infinite-scroll',
  templateUrl: './bid-infinite-scroll.component.html',
  styleUrls: ['./bid-infinite-scroll.component.scss']
})
export class BidInfiniteScrollComponent implements OnInit {

  @Input() formControlName;
  @Input() parentForm;
  @Input() items;
  @Input() bufferSize = 20;
  @Input() numberOfItemsFromEndBeforeFetchingMore = 5;
  @Input() placeholder = '';
  @Input() bindLabel = '';
  @Input() bindValue = '';
  @Input() hint: boolean = false;
  @Input() page;
  @Input() formHasBeenSubmitted = false;
  @Output() dropdownChanges = new EventEmitter;

  dropdownIsOpen = false;
  control;
  customString = false;
  customKey = false;
  customError = false;
  itemsBuffer;
  notFoundText = $localize`Texto no encontrado`;
  searchText = $localize`Escriba aquí`;
  placeholderText: string;
  lastSelection;

  get ctrl(): AbstractControl {
    if (this.formControlName) {
      return this.parentForm.get(this.formControlName);
    }
  }

  ngOnInit(): void {
    this.placeholderText = this.placeholder;
    this.itemsBuffer = this.items.slice(0, this.bufferSize);

    this.ctrl.valueChanges.subscribe({
      next: () => {
        this.hint = !this.ctrl.value;
      }
    });
  }

  onOpen(e) {
    this.dropdownIsOpen = true;
    this.lastSelection = this.ctrl.value;
    this.ctrl.reset();
    this.placeholderText = this.searchText;
  }

  onClose(e) {
    this.dropdownIsOpen = false;
    if (!this.ctrl.value ||
      this.ctrl.value.lenght === 0) {
      this.placeholderText = this.placeholder;
    } else {
      this.placeholderText = this.searchText;
    }
  }

  onScrollToEnd() {
    this.fetchMore();
  }

  onScroll({end}) {
    if (this.items.length <= this.itemsBuffer.length) {
      return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.itemsBuffer.length) {
      this.fetchMore();
    }
  }

  private fetchMore() {
    const len = this.itemsBuffer.length;
    const more = this.items.slice(len, this.bufferSize + len);
    this.itemsBuffer = this.itemsBuffer.concat(more);
  }

  onDropdownChange(e) {
    this.dropdownChanges.emit(e);
  }

  shouldShowErrors(): boolean {
    let onError = false;

    if (this.dropdownIsOpen) {
      return false;
    }

    if (this.ctrl != null) {
      if (this.ctrl.invalid && this.formHasBeenSubmitted) {
        onError = true;
      }
    } else {
      onError = this.customError;
    }
    return onError;
  }

  listOfErrors(): string[] {
    if (this.customString) {
      if (this.customKey) {
        return JSONStrings[this.page][this.customKey];
      } else {
        // console.error('This component needs customKey when custom is TRUE');
      }
    } else if (this.ctrl != null) {
      return Object.keys(this.ctrl.errors).map((err) => {
        return JSONStrings[this.page][err];
      });
    }
  }

}
