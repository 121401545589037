import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {EventGuestRequest} from '../models/events/EventGuestRequest';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {EventGuest} from '../models/events/EventGuest';
import {DelegateOwnershipRequest} from '../models/events/DelegateOwnershipRequest';
import {UserRestrict} from '../models/UserRestrict';

@Injectable({
  providedIn: 'root'
})
export class TableGuestService {

  constructor(
    private httpC: HttpClient
  ) {
  }

  public addTableGuest(tableId: number, eventGuestRequest: EventGuestRequest): Observable<EventGuest> {
    const httpHeaders = new HttpHeaders().set('Accept', 'application/json');
    const origin = 'manual';
    let httpParams = new HttpParams();
    httpParams = httpParams.set('origin', origin);
    return this.httpC.post<EventGuest>(environment.billboardAGUrl + `/cartelera/guests/table/web/${tableId}`, eventGuestRequest, {
      headers: httpHeaders,
      params: httpParams
    });
  }

  delegateOwnership(delegateOwnershipRequest: DelegateOwnershipRequest): Observable<UserRestrict> {
    return this.httpC.post<UserRestrict>(`${environment.billboardAGUrl}/cartelera/guests/table/delegate`, delegateOwnershipRequest);
  }
}
