<div class="support">
  <p class="title" i18n>Envianos tu consulta!</p>
  <p class="subtitle" i18n>Responderemos a la brevedad</p>
  <div class="bid-modal__content">
    <form action="" [formGroup]="supportForm">
      <div
        class="field__item  margin-top-32"
        [ngClass]="validateInputClass(supportForm, 'email', formHasBeenSubmitted)"
      >
        <div class="p-float-label">
          <input type="text"
                 formControlName="email"
                 id="email"
                 class="p-inputtext"
          >
          <label for="email" class="title-float" i18n>Email*</label>
          <app-error-message
            [page]="'register'"
            [ctrl]="supportForm.get('email') | formControl"
            [formHasBeenSubmitted]="formHasBeenSubmitted"
          ></app-error-message>
        </div>
      </div>

      <div
        class="field__item  margin-top-32"
        [ngClass]="validateInputClass(supportForm, 'name', formHasBeenSubmitted)"
      >
        <div class="p-float-label">
          <input type="text"
                 formControlName="name"
                 id="name"
                 class="p-inputtext"
                 pattern="^[\u00F1\u00D1A-Za-zÀ-ÿ _]*[\u00F1\u00D1A-Za-zÀ-ÿ][\u00F1\u00D1A-Za-zÀ-ÿ _]*$"
          >
          <label for="name" class="title-float" i18n>Nombre*</label>
          <app-error-message
            [page]="'register'"
            [ctrl]="supportForm.get('name') | formControl"
            [formHasBeenSubmitted]="formHasBeenSubmitted"
          ></app-error-message>
        </div>
      </div>

      <div
        class="field__item  margin-top-32"
        [ngClass]="validateInputClass(supportForm, 'supportType', formHasBeenSubmitted)"
      >
        <div class="p-float-label">
          <p-dropdown
            id="dropdown"
            class="bid-dropdown"
            [filter]="false"
            [showClear]="true"
            [virtualScrollItemSize]="16"
            [optionLabel]="'name'"
            [optionValue]="'id'"
            [placeholder]="selectYourSupportType"
            [options]="supportType"
            formControlName="supportType"
          ></p-dropdown>
          <label for="dropdown" class="title-float" i18n>Tipo de consulta*</label>
          <app-error-message
            [page]="'register'"
            [ctrl]="supportForm.get('supportType') | formControl"
            [formHasBeenSubmitted]="formHasBeenSubmitted"
          ></app-error-message>
        </div>
      </div>

      <div class="field__item margin-top-32 textarea"
           [ngClass]="validateInputClass(supportForm, 'message', formHasBeenSubmitted)">
        <div class="p-float-label">
            <textarea
              name="message"
              id="message"
              formControlName="message"
              cols="30"
              rows="10"
            ></textarea>
          <label for="message" class="title-float" i18n>Mensaje*</label>
          <app-error-message
            [page]="'register'"
            [ctrl]="supportForm.get('message') | formControl"
            [formHasBeenSubmitted]="formHasBeenSubmitted"
          ></app-error-message>
        </div>
      </div>
    </form>

    <button
      pButton
      class="btn btn__primary"
      (click)="sendToSupport()"
      label="{{acceptLabel}}"
    ></button>
  </div>
</div>

