import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-modal-image',
  templateUrl: './modal-image.component.html',
  styleUrls: ['./modal-image.component.scss']
})
export class ModalImageComponent implements OnInit {

  @Input() image;
  @Input() showModal: boolean;
  @Output() closeButtonEvent = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.showModal = false;
    this.closeButtonEvent.emit(this.showModal);
  }
}
