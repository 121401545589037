import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {AccessTypeResponse} from 'src/app/models/accessTypeResponse';
import {EventForCustomer} from 'src/app/models/events/EventForCustomer';
import {TableTypeResponse} from 'src/app/models/TableTypeResponse';
import {AuthService} from 'src/app/services/auth.service';
import {EventService} from 'src/app/services/event.service';

@Component({
  selector: 'app-modal-info-type',
  templateUrl: './modal-info-type.component.html',
  styleUrls: ['./modal-info-type.component.scss']
})
export class ModalInfoTypeComponent implements OnInit, OnChanges {

  @Input() showModal: boolean;
  @Input() eventId: number;
  @Input() typeInfoSelected: string;
  @Output() closeButtonEvent = new EventEmitter();
  @Output() showLoginModal = new EventEmitter();
  @Output() action = new EventEmitter();
  @Output() closeAndNavigateTo = new EventEmitter();

  eventData: EventForCustomer;
  tickets = [];
  subs = [];
  tables = [];
  skeletonLoading = false;
  skeletonTickets = [1, 2];

  constructor(
    private eventService: EventService,
    private authService: AuthService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.tickets = [];
    this.skeletonLoading = true;
    this.subs.push(
      this.eventService.getEventById(this.eventId).subscribe({
        next: (event: EventForCustomer) => {
          this.eventData = event;
          this.tickets = event.accessTypes;
          this.tickets.sort((a: AccessTypeResponse, b: AccessTypeResponse) => {
            return b.price - a.price;
          });
          this.tables = event.tableTypes;
          this.tables.sort((a: TableTypeResponse, b: TableTypeResponse) => {
            return b.price - a.price;
          });
          setTimeout(() => {
            this.updateCardHeight();
            this.skeletonLoading = false;
          }, 1000);
        },
        error: (err) => {
          if (err.status === 404) {
            this.router.navigate(['/event-not-found']);
          }
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showModal && changes.showModal.currentValue === true) {
      this.updateCardHeight();
    }
  }

  updateCardHeight() {
    setTimeout(() => {
      const collectionCards = document.getElementsByClassName('card');

      let maxHeight = 0;
      Array.from(collectionCards).forEach((cardHTML) => {
        if (cardHTML.clientHeight > maxHeight) {
          maxHeight = cardHTML.clientHeight;
        }
      });

      Array.from(collectionCards).forEach((cardHTML) => {
        cardHTML.setAttribute('style', 'height: ' + maxHeight + 'px');
        cardHTML.setAttribute('style', 'min-height: ' + maxHeight + 'px');
      });
    }, 100);
  }

  buy() {
    const logged = this.authService.isLoggedIn();
    const guestLogged = this.authService.isGuestLoggedIn();
    if (!logged || guestLogged) {
      this.showLoginModal.next();
    } else {
      this.closeAndNavigateTo.emit(this.typeInfoSelected);
    }
  }

  closeModal() {
    this.closeButtonEvent.emit();
  }

}
