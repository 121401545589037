import {Component, Input, OnInit} from '@angular/core';

import {EventForCustomer} from 'src/app/models/events/EventForCustomer';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {EventStatus} from '../../../../enum/EventStatus';

@Component({
  selector: 'card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() skeletonLoading: boolean;
  @Input() event: EventForCustomer;
  mediaUrl: string;
  eventStatus = EventStatus;

  constructor(
    private router: Router
  ) {
    this.mediaUrl = environment.mediaUrl;
  }

  ngOnInit(): void {
  }

  detailEvent(): void {
    this.router.navigate(['/my-events/event-detail/' + this.event.idEvent]);
  }
}
