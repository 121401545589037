import {Modal, ModalResponse} from './modal';
import {ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector, ViewChild} from '@angular/core';

export interface ModalCreateOptions {
  component: any,
  props?: any
}

@Injectable({
  providedIn: 'root'
})
export class ModalController {
  @ViewChild('body') private viewContainer;
  componentRef: ComponentRef<any>;
  private dismissCallback: (value?: any) => ModalResponse | void;

  constructor(
    private injector: Injector,
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
  }

  create(opts: ModalCreateOptions): Modal {
    this.componentRef = this.componentFactoryResolver.resolveComponentFactory(opts.component).create(this.injector);
    const modal = this.componentRef.instance;
    Object.keys(opts.props).forEach(key => {
      modal[key] = opts.props[key];
    });
    this.appRef.attachView(this.componentRef.hostView);
    const htmlDiv = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLDivElement;
    document.body.appendChild(htmlDiv);
    return new Modal(htmlDiv, this.componentRef);
  }

  dismiss(): void {
    this.dismissEvent(null);
  }

  private dismissEvent(data: any): void {
    if (this.dismissCallback) {
      this.dismissCallback({data});
    }
    this.componentRef.destroy();
  }
}
