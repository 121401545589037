import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EventGuest} from '../models/events/EventGuest';
import {EventGuestRequest} from '../models/events/EventGuestRequest';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ListGuestsService {

  constructor(
    private httpC: HttpClient
  ) {
  }

  public addListGuest(glistId: number, eventGuestRequest: EventGuestRequest): Observable<EventGuest> {
    return this.httpC.post<EventGuest>(environment.billboardAGUrl + `/cartelera/guests/list/web/${glistId}`, eventGuestRequest);
  }
}
