<section class="profile">
  <div class="wrapper">
    <div class="profile__header" data-aos="fade-in">
      <div class="header-container">
        <div class="title-container">
          <i class="far fa-user text-gray-3 text-lg lh-24"></i>
          <p class="title__my-info" i18n>Mis datos</p>
        </div>
      </div>
    </div>


    <div class="profile__card" data-aos="fade-in" *ngIf="formLoaded">
      <!-- HEADER MOBILE -->
      <div class="profile__header--mobile" data-aos="fade-in">
        <div class="header-container">
          <div class="title-container">
            <i class="far fa-user text-gray-3 text-lg lh-24"></i>
            <p class="title__my-info" i18n>Mis datos</p>
          </div>
          <div class="verification-account-mobile">
            <div class="bid-badge bid-badge--bg-gray-6" *ngIf="!user.emailVerified" i18n>SIN VERIFICAR</div>
            <div class="bid-badge bid-badge--bg-green" *ngIf="user.emailVerified" i18n>VERIFICADA</div>
          </div>
        </div>
      </div>

      <!-- RESEND BUTTON MOBILE MOBILE -->
      <div class="resend-verification-mobile-container" *ngIf="!user.emailVerified && !user.blocked">
        <div class="text-primary line-height-24 cursor-pointer resend-verification-mobile" (click)="resendEmail()">
          <i class="fa-regular fa-user-check margin-right-4"></i>
          <span i18n>Reenviar email de verificación</span>
        </div>
      </div>

      <div class="container__form">
        <form class="form" [formGroup]="userForm">
          <!-- VERIFICATION DESKTOP -->
          <div class="verification-account-desktop">
            <div class="verification-account-header" *ngIf="!user.emailVerified">
              <div><span class="bid-badge bid-badge--bg-gray-6" i18n>SIN VERIFICAR</span></div>
              <div class="text-primary font-weight-400 font-size-14 line-height-24 cursor-pointer"
                   *ngIf="!user.emailVerified" (click)="resendEmail()">
                <i class="fa-regular fa-user-check margin-right-4"></i>
                <span i18n>Reenviar email de verificación</span>
              </div>
            </div>
            <div class="verification-account-header" *ngIf="user.emailVerified">
              <div class="bid-badge bid-badge--bg-green" i18n>VERIFICADA</div>
            </div>
          </div>
          <div
            class="text-gray-1 font-size-32 line-height-32 font-weight-600 user-name">{{ user.name }} {{ user.lastName }}</div>
          <bid-input
            label="Email*"
            i18n-label
            type="email"
            name="email"
            page="profile"
            [large]="true"
            customClass="bg-light-in-mobile margin-bottom-0"
            [preventSpace]="true"
            [value]="userForm.get('email').value"
            [ctrl]="userForm.get('email') | formControl"
            [formHasBeenSubmitted]="formHasBeenSubmitted"
            (data)="handleDataEmail($event)"
            (focusout)="focusOutFn()"
            (focusin)="focusInFn()"
          ></bid-input>
          <p class="text-email" *ngIf="
            !formHasBeenSubmitted ||
            (formHasBeenSubmitted && userForm.get('email').valid)" i18n>
            Al modificar el email deberás verificarlo nuevamente.</p>
          <app-error-message
            [customClass]="'add_botton_space'"
            [withoutIcon]="true"
            [page]="'register'"
            [ctrl]="userForm.get('email') | formControl"
            [formHasBeenSubmitted]="formHasBeenSubmitted"
          ></app-error-message>
          <bid-input
            label="Nombre*"
            i18n-label
            type="text"
            name="name"
            page="profile"
            customClass="bg-light-in-mobile"
            [capitalize]="true"
            [large]="true"
            [value]="userForm.get('name').value"
            [formHasBeenSubmitted]="formHasBeenSubmitted"
            [ctrl]="userForm.get('name') | formControl"
            (data)="handleDataName($event)"
          ></bid-input>

          <bid-input
            label="Apellido*"
            i18n-label
            type="text"
            name="lastName"
            page="profile"
            customClass="bg-light-in-mobile"
            [capitalize]="true"
            [large]="true"
            [value]="userForm.get('lastName').value"
            [formHasBeenSubmitted]="formHasBeenSubmitted"
            [ctrl]="userForm.get('lastName') | formControl"
            (data)="handleDataLastName($event)"
          ></bid-input>

          <fieldset
            class="fieldset-input {{customClass}} birthdate"
            [ngClass]="{
              'focus': onFocus,
              'filled': onFilled,
              'error': shouldShowErrors(),
              'helper': helperText.length > 1,
              'disabled': birthDateDisabled,
              'success': success,
              'validate': validate,
              'bg-light-in-mobile': bgLightMobile,
              'bg-light-in-desktop': bgLightDesktop,
              'size-large-mobile': largeMobile,
              'size-large-desktop': largeDesktop,
              'size-large': large
            }"
          >
            <div class="field__input">
              <label class="label" i18n>Fecha de nacimiento*</label>
              <p-inputMask #mask formControlName="birthdate" mask="99/99/9999"
                           (onFocus)="onFocusInput()"
                           (onBlur)="onBlurInput()"
              ></p-inputMask>

              <i class="fa-solid fa-triangle-exclamation text-error text-icon"
                 *ngIf="withIcon && shouldShowErrors()"></i>
              <i class="fa-solid fa-circle-check text-success text-icon" *ngIf="success"></i>
              <i class="fa-light fa-spinner-third spinner-icon" *ngIf="validate"></i>
            </div>
            <p class="hint" *ngIf="hint && shouldShowErrors()">
              <span *ngFor="let error of listOfErrors()">{{error}}</span>
            </p>
          </fieldset>

          <bid-dropdown
            [label]="selectYourCountry"
            name="name"
            page="register"
            [largeMobile]="true"
            [readonly]="isReadOnly"
            [large]="true"
            [loading]="searchingCountries"
            [parentForm]="userForm"
            [ctrl]="userForm.get('country') | formControl"
            [ctrlName]="'country'"
            [formHasBeenSubmitted]="formHasBeenSubmitted"
            [options]="countries"
            [optionLabel]="language"
            [optionValue]="'alpha3'"
            (data)="onDropdownChange($event)"
          ></bid-dropdown>

          <div
            class="field__item fusion-dropdown item-margin-32"
            [ngClass]="validateInputClass(userForm, 'document', formHasBeenSubmitted)"
          >
            <div class="p-float-label">
              <p-dropdown
                id="dropdown"
                class="bid-dropdown fusion"
                [filter]="false"
                [showClear]="false"
                [optionLabel]="'name'"
                [optionValue]="'value'"
                [options]="idOptionTypes"
                [formControlName]="'idType'"
                (ngModelChange)="validateChanges()"
                (onChange)="onOptionTypeChange($event)"
              ></p-dropdown>

              <input type="text"
                     formControlName="document"
                     id="document"
                     (keypress)="verifyID($event)"
                     (ngModelChange)="validateChanges()"
                     class="p-inputtext dropdown"
              >
            </div>
            <app-error-message
              [page]="'register'"
              [ctrl]="userForm.get('document') | formControl"
              [formHasBeenSubmitted]="formHasBeenSubmitted"
            ></app-error-message>
          </div>

          <div class="field__item item-margin-32"
               [ngClass]="validateInputClass(userForm, 'phone', formHasBeenSubmitted)">
            <ngx-intl-tel-input
              [cssClass]="'int-phone'"
              [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true"
              [searchCountryFlag]="true"
              [enablePlaceholder]="true"
              [customPlaceholder]="labelPhone"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
              [selectFirstCountry]="true"
              [selectedCountryISO]="CountryISO.Argentina"
              [maxLength]="15"
              [phoneValidation]="true"
              [separateDialCode]="true"
              [searchCountryPlaceholder]="search"
              [numberFormat]="PhoneNumberFormat.National"
              name="phone"
              formControlName="phone"
              (ngModelChange)="validateChanges()"
            >
            </ngx-intl-tel-input>
            <p class="text-email" *ngIf="!formHasBeenSubmitted || (formHasBeenSubmitted && userForm.get('phone').valid)"
               i18n>Escribir n° de característica sin el 0 y sin el 15</p>
            <app-error-message
              [page]="'register'"
              [ctrl]="userForm.get('phone') | formControl"
              [formHasBeenSubmitted]="formHasBeenSubmitted"
            ></app-error-message>
          </div>

          <bid-dropdown
            [label]="sexString"
            page="register"
            [largeMobile]="true"
            [readonly]="genderReadOnly"
            [large]="true"
            [loading]="searchingCountries"
            [parentForm]="userForm"
            [ctrl]="userForm.get('gender') | formControl"
            [ctrlName]="'gender'"
            [formHasBeenSubmitted]="formHasBeenSubmitted"
            [options]="genders"
            [optionLabel]="'name'"
            [optionValue]="'value'"
            (data)="onOptionSexChange($event)"
          ></bid-dropdown>

        </form>
      </div>
    </div>
  </div>
</section>

<app-nav-fixed
  [thereAreChanges]="thereAreChanges"
  [formLoaded]="formLoaded"
  (save)="submitChanges()"
  (dontSave)="resetForm()">
</app-nav-fixed>

<app-modal-action [showModal]="showErrorModal" [title]="errorContent" [content]="''" [textBtn]="'Aceptar'"
                  [iconClass]="'fa-thin fa-triangle-exclamation'" (closeButtonEvent)="onActionErrorModal($event)"
                  (action)="onActionErrorModal($event)"></app-modal-action>
