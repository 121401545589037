import {Injectable} from '@angular/core';
import {AngularFireRemoteConfig} from '@angular/fire/compat/remote-config';

@Injectable({
  providedIn: 'root'
})
export class FirebaseRemoteConfigService {

  data = {};

  constructor(
    private remoteConfig: AngularFireRemoteConfig
  ) {
  }

  async loadConfig() {
    const keysToGet = ['qrKey'];

    const configPromises = [];

    keysToGet.forEach(key => {
      configPromises.push(this.getConfigKey(key));
    });

    await Promise.all(configPromises);

    localStorage.setItem('firebaseRemoteConfig', JSON.stringify(this.data));
  }

  async getConfigKey(key: string) {
    const resultValueKeyFb = await this.getString(key);

    if (typeof resultValueKeyFb !== 'object') {
      this.data[key] = resultValueKeyFb;
    }
  }

  async getSignQRKey(): Promise<string> {
    const resultValueKeyFb = JSON.parse(localStorage.getItem('firebaseRemoteConfig'));
    return resultValueKeyFb.qrKey;
  }

  async getString(key: any) {
    await this.remoteConfig.fetch();
    await this.remoteConfig.activate();

    return this.remoteConfig.getString(key);
  }

  async getNumber(key: any) {
    await this.remoteConfig.fetch();
    await this.remoteConfig.activate();

    return this.remoteConfig.getNumber(key);
  }

  async getBoolean(key: any) {
    await this.remoteConfig.fetch();
    await this.remoteConfig.activate();

    return this.remoteConfig.getBoolean(key);
  }
}
