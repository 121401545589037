import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import { ModalActionOptions } from 'src/app/models/ModalActionOptions';
import { ModalActionType } from 'src/app/models/ModalActionType';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-modal-action',
  templateUrl: './modal-action.component.html',
  styleUrls: ['./modal-action.component.scss']
})
export class ModalActionComponent implements OnInit {

  @Input() showModal: boolean;
  @Input() title = '';
  @Input() content = '';
  @Input() textBtn = '';
  @Input() textSecondBtn = '';
  @Input() imageName: string = null;
  @Input() iconClass: string = null;
  @Input() firstButtonClass = 'btn__md btn__primary';
  @Input() secondButtonClass = 'btn__md btn__secondary';
  @Input() showHeader = true;
  @Input() invertBtnsMobile = false;
  @Input() invertBtnsDesktop = false;
  @Output() closeButtonEvent = new EventEmitter();
  @Output() action = new EventEmitter();
  @Output() actionSecondButton = new EventEmitter();

  // this key is the modal identifier for call on app component
  key: string;
  MODALACTIONTYPE = ModalActionType;
  type: ModalActionType = ModalActionType.info;

  constructor(
    private modalService: ModalService
  ) {
  }

  ngOnInit(): void {
    this.modalService.actionModal$.subscribe((modalOptions: ModalActionOptions) => {
      this.showModal = modalOptions.showModal;
      this.title = modalOptions.title;
      this.textBtn = modalOptions.textBtn;

      if (modalOptions.type) {                this.type =               modalOptions.type; }
      if (modalOptions.content) {             this.content =            modalOptions.content; }
      if (modalOptions.key) {                 this.key =                modalOptions.key; }
      if (modalOptions.textSecondBtn) {       this.textSecondBtn =      modalOptions.textSecondBtn; }
      if (modalOptions.imageName) {           this.imageName =          modalOptions.imageName; }
      if (modalOptions.iconClass) {           this.iconClass =          modalOptions.iconClass; }
      if (modalOptions.firstButtonClass) {    this.firstButtonClass =   modalOptions.firstButtonClass; }
      if (modalOptions.secondButtonClass) {   this.secondButtonClass =  modalOptions.secondButtonClass; }
      if (modalOptions.showHeader) {          this.showHeader =         modalOptions.showHeader; }
      if (modalOptions.showHeader) {          this.showHeader =         modalOptions.showHeader; }
      if (modalOptions.invertBtnsMobile) {    this.invertBtnsMobile =   modalOptions.invertBtnsMobile; }
      if (modalOptions.invertBtnsDesktop) {   this.invertBtnsDesktop =  modalOptions.invertBtnsDesktop; }
    });
  }

  closeModal() {
    this.showModal = false;
    this.cleanActionModal();
    this.closeButtonEvent.emit(this.showModal);
  }

  onAction() {
    this.showModal = false;
    this.key ? this.action.emit(this.key) : this.action.emit();
  }

  onActionSecondButton() {
    this.showModal = false;
    this.cleanActionModal();
    this.key ? this.actionSecondButton.emit(this.key) :  this.actionSecondButton.emit();
  }

  cleanActionModal() {
    this.type =               null;
    this.content =            null;
    this.key =                null;
    this.textSecondBtn =      '';
    this.imageName =          null;
    this.iconClass =          null;
    this.firstButtonClass =   'btn__md btn__primary';
    this.secondButtonClass =  'btn__md btn__secondary';
    this.showHeader =         null;
    this.invertBtnsMobile =   null;
    this.invertBtnsDesktop =  null;
  }

}
