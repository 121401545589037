import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';
import {IdType, IdTypeData} from 'src/app/enum/idType';
import {Countries} from 'src/app/models/Countries';
import {CountriesService} from 'src/app/services/countries.service';
import {InputValidationService} from 'src/app/services/input-validation.service';
import {SpinnerService} from 'src/app/services/spinner.service';

@Component({
  selector: 'app-signup-step-two',
  templateUrl: './signup-step-two.component.html',
  styleUrls: ['./signup-step-two.component.scss']
})
export class SignupStepTwoComponent implements OnInit {

  @Input() parentForm: UntypedFormGroup;
  @Input() stepHasBeenSubmitted: boolean = false;
  @Input() errorDNIorIDKey: string = '';
  @Input() dniOrIdStatus: boolean = false;
  language = 'es';
  formHasBeenSubmitted = false;
  dniValidation = null;
  countrySelected: any;
  isReadOnly = false;
  searchingCountries = false;
  idOptionTypes: Array<IdTypeData>;
  countries: Array<Countries> = [];
  countriesSplit: Array<Array<Countries>>;
  countriesOnDropdown: Array<Countries> = [];
  bufferSize = 20;
  numberOfItemsFromEndBeforeFetchingMore = 5;
  loading = false;
  countryBuffer: Array<Countries> = [];
  idTypesArg: Array<IdTypeData> = [{
    name: $localize`DNI`,
    value: IdType.DNI
  }, {
    name: $localize`Pasaporte`,
    value: IdType.PASSPORT
  }];
  idTypesROW = [{
    name: $localize`ID`,
    value: IdType.ID
  }, {
    name: $localize`Pasaporte`,
    value: IdType.PASSPORT
  }];
  placeHolderCountry = $localize`Seleccioná tu país*`;
  idHighlighted = '';

  constructor(
    private validateInputService: InputValidationService,
    private countriesService: CountriesService,
    private spinnerService: SpinnerService) {

  }

  ngOnInit(): void {
    // spinner for countries - don't remove
    this.spinnerService.loadSpinner.next(true);

    this.language = localStorage.getItem('current_locale');

    if (this.parentForm.controls.originTypeId.value === '') {
      this.parentForm.controls.originTypeId.patchValue(IdType.DNI);
    }

    if (this.parentForm.controls.country.value === '' || this.parentForm.controls.country.value === 'arg') {
      this.parentForm.controls.country.patchValue('arg');
      this.idOptionTypes = this.idTypesArg;
    } else {
      this.idOptionTypes = this.idTypesROW;
    }

    this.getCountries();
  }

  get idField(): AbstractControl {
    return this.parentForm.get('id');
  }

  get idValidationField(): AbstractControl {
    return this.parentForm.get('idValidation');
  }

  getCountries() {
    this.countriesService.getAllCountries().subscribe({
        next: (res: Countries[]) => {
          this.countries = res;
          this.spinnerService.loadSpinner.next(false);
        },
        error: (er) => {
          this.spinnerService.loadSpinner.next(false);
        }
      }
    );
  }

  onIdFocus() {
    this.idHighlighted = 'highlighted';
  }

  onIdFocusOut() {
    this.idHighlighted = '';
  }

  onOptionTypeChange(e: string) {
    this.resetId();
  }

  onDropdownCountryChange(e: Countries) {
    if (e.alpha3 === 'arg') {
      this.idOptionTypes = this.idTypesArg;
      this.parentForm.controls.originTypeId.patchValue(IdType.DNI);
    } else {
      this.idOptionTypes = this.idTypesROW;
      this.parentForm.controls.originTypeId.patchValue(IdType.ID);
    }
    this.resetId();
  }

  resetId() {
    this.parentForm.controls.id.patchValue('');
    this.parentForm.controls.idValidation.patchValue('');
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }

  verifyID(e) {
    if (this.parentForm.controls.country.value === 'arg' && this.parentForm.controls.originTypeId.value === IdType.DNI) {
      return this.onlyNumber(e);
    }
  }

  onlyNumber(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  onKeyUpDocument() {
    let newString = this.idField.value.replace(/[^a-zA-Z0-9 ]/g, '');
    newString = newString.split(' ').join('');
    this.idField.patchValue(newString);
  }

  onKeyUpIdValid() {
    let newString = this.idValidationField.value.replace(/[^a-zA-Z0-9 ]/g, '');
    newString = newString.split(' ').join('');
    this.idValidationField.patchValue(newString);
  }


}
