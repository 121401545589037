<div class="carrousel__container" *ngIf="allCategoriesEvents || !loading">
  <section data-aos="fade-in" *ngFor="let category of allCategoriesEvents" class="carrousel">
    <div class="wrapper">
      <div class="carrousel__header">
        <p class="font-secondary text-lg lh-24 text-gray-2 font-normal my-0" *ngIf="!loading">{{category.name}}</p>
        <div class="skeleton__header" *ngIf="loading"></div>
      </div>
      <swiper class="carrousel__events" [pagination]="false" [config]="config" (swiper)="onSwiper($event)"
              (slideChange)="onSlideChange()">
        <ng-template swiperSlide *ngFor="let event of category.eventForCustomerList">
          <app-card-event [event]="event" [loading]="loading"></app-card-event>
        </ng-template>
      </swiper>
    </div>
  </section>
</div>

<div class="carrousel__container" *ngIf="!allCategoriesEvents || loading">
  <section class="carrousel" data-aos="fade-in">
    <div class="wrapper">
      <div class="carrousel__header">
        <div class="skeleton__header" *ngIf="loading"></div>
      </div>
      <swiper class="carrousel__events" [pagination]="false" [config]="configSkeleton">
        <ng-template swiperSlide>
          <app-card-event [loading]="loading"></app-card-event>
        </ng-template>
        <ng-template swiperSlide>
          <app-card-event [loading]="loading"></app-card-event>
        </ng-template>
        <ng-template swiperSlide>
          <app-card-event [loading]="loading"></app-card-event>
        </ng-template>
        <ng-template swiperSlide>
          <app-card-event [loading]="loading"></app-card-event>
        </ng-template>
        <ng-template swiperSlide>
          <app-card-event [loading]="loading"></app-card-event>
        </ng-template>
      </swiper>
    </div>
  </section>
  <section class="carrousel" data-aos="fade-in" data-aos-delay="600">
    <div class="wrapper">
      <div class="carrousel__header">
        <div class="skeleton__header" *ngIf="loading"></div>
      </div>
      <swiper class="carrousel__events" [pagination]="false" [config]="configSkeleton">
        <ng-template swiperSlide>
          <app-card-event [loading]="loading"></app-card-event>
        </ng-template>
        <ng-template swiperSlide>
          <app-card-event [loading]="loading"></app-card-event>
        </ng-template>
        <ng-template swiperSlide>
          <app-card-event [loading]="loading"></app-card-event>
        </ng-template>
        <ng-template swiperSlide>
          <app-card-event [loading]="loading"></app-card-event>
        </ng-template>
        <ng-template swiperSlide>
          <app-card-event [loading]="loading"></app-card-event>
        </ng-template>
      </swiper>
    </div>
  </section>

</div>
