import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UserRestrict} from '../models/UserRestrict';
import {environment} from '../../environments/environment';
import {ChangePasswordRequest} from '../models/ChangePasswordRequest';
import { DeletePayload } from '../models/DeletePayload';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient
  ) {
  }

  getUserRestrictByDocument(document: string, documentType: string, country: string): Observable<UserRestrict> {
    return this.http.get<UserRestrict>(environment.apiAGUrl + `/customer/by_dni/restrict/${document}/${documentType}/${country}`);
  }

  updatePassword(payload: ChangePasswordRequest) {
    const {id_customer} = JSON.parse(localStorage.getItem('user'));
    return this.http.post(`${environment.apiAGUrl}/customer/changePasswordLogged/${id_customer}`, payload);
  }

  deleteAccount(deletePayload: DeletePayload): Observable<any> {
    const {id_customer} = JSON.parse(localStorage.getItem('user'));
    return this.http.post<any>(`${environment.apiAGUrl}/customer/delete/${id_customer}`, deletePayload);
  }

}
