<div class="w-full flex items-center justify-start margin-top-40" *ngIf="validationCodeResult !== 1">
  <i class="fa-light fa-badge-percent text-gray-1"></i>
  <p class="font-secondary text-lg my-0 text-gray-1 lh-32 font-normal margin-left-8" i18n>
    ¿Tenés código promocional?</p>
</div>
<div class="promotional__container" *ngIf="validationCodeResult !== 1">
  <div class="flex align-items-start justify-content-start container__input">
    <bid-input
      label="Ingresá tu código"
      i18n-label
      type="text"
      customClass="margin-bottom-0"
      [hint]="false"
      [preventSpace]="true"
      [value]="codePromo"
      [uppercase]="true"
      [medium]="true"
      [validate]="validating"
      [customError]="validationErrorResult"
      [formHasBeenSubmitted]="formHasBeenSubmitted"
      (data)="handleDataCode($event)"
      [onFilled]="false"
    ></bid-input>

    <div class="input__feedback--desktop">
      <p class="feedback" *ngIf="!validating && validationCodeResult === 2" i18n>El código no es válido.</p>
    </div>
  </div>
  <div class="input__btn">
    <button class="btn btn__md btn__code btn__secondary desktop" (click)="validateRRPPCode()"
            [disabled]="promoCode === '' || validating"
            i18n="Validate button label for desktop">
      Validar código
    </button>
    <button class="btn btn__md btn__code btn__secondary mobile" (click)="validateRRPPCode()"
            [disabled]="promoCode === '' || validating"
            i18n="Validate button label for mobile">
      Validar
    </button>
  </div>
</div>
<div class="input__feedback--mobile">
  <p class="feedback" *ngIf="!validating && validationCodeResult === 2" i18n>El código no es válido.</p>
</div>
<div class="w-full flex align-items-center margin-top-32" *ngIf="validationCodeResult === 1">
  <i class="fa-light fa-badge-percent text-gray-1 margin-right-8"></i>
  <p class="font-secondary text-base font-weight-400 text-gray-1 lh-32 my-0">
    <span i18n>El código </span>
    <strong>{{promoName}}</strong>
    <span i18n> ya se encuentra aplicado.</span>
  </p>
</div>
