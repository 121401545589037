import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import QRCodeStyling from 'qr-code-styling';
import {Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../services/auth.service';
import {SpinnerService} from '../../../services/spinner.service';
import {User} from '../../../models/user';
import {InfoQR} from '../../../models/InfoQr';
import {QrService} from '../../../services/qr.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-modal-qr',
  templateUrl: './modal-qr.component.html',
  styleUrls: ['./modal-qr.component.scss']
})
export class ModalQrComponent implements OnInit, AfterViewInit {
  @ViewChild('personalQr', {static: false}) personalQr: ElementRef;
  showModal = false;
  profile: User;
  infoQR: InfoQR;
  qrCode: any;
  isLoading: boolean;
  subs: Subscription[] = [];
  jwt = null;
  mediaUrl = environment.mediaUrl;

  constructor(
    private authSrv: AuthService,
    private spinnerSrv: SpinnerService,
    private qrSrv: QrService,
    private router: Router
  ) {
  }

  async ngOnInit() {
    this.authSrv.showModalMyQr.subscribe({
      next: async (res: {
        state: boolean
      }) => {
        this.showModal = res.state;
        document.getElementById('personalQr').innerHTML = '';
        this.profile = JSON.parse(localStorage.getItem('user'));
        await this.checkIfCanShowQr();
      }
    });
    this.mediaUrl = environment.mediaUrl;
    this.infoQR = new InfoQR();
  }

  async ngAfterViewInit() {
  }

  close() {
    this.showModal = false;
    if (document.body) {
      document.body.style.position = '';
    }
  }

  async checkIfCanShowQr() {
    this.spinnerSrv.loadSpinner.next(true);
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.document === null || user.document === '' || user.document === '0') {
      await this.router.navigate(['data-required']);
      this.spinnerSrv.loadSpinner.next(false);
    } else {
      const data = await this.qrSrv.generateQR();
      this.generateQR(data);
      document.getElementById('personalQr').getElementsByTagName('canvas')[0].style.width = '100%';
    }
  }

  generateQR(data) {
    this.qrCode = new QRCodeStyling({
      width: 310,
      height: 310,
      margin: 0,
      data,
      image: 'assets/images/logo-qr-short.png',
      qrOptions: {
        typeNumber: 0,
        mode: 'Byte',
        errorCorrectionLevel: 'H',
      },
      imageOptions: {
        hideBackgroundDots: true,
        imageSize: 0.5,
        margin: 4,
      },
      dotsOptions: {
        type: 'dots',
        color: '#000000',
      },
      backgroundOptions: {
        color: '#ffffff',
      },
      cornersSquareOptions: {
        type: 'extra-rounded',
        color: '#000000',
      },
      cornersDotOptions: {
        type: 'dot',
        color: '#000000',
      },
    });
    const container = document.getElementById('personalQr');
    this.qrCode.append(container);
  }
}
