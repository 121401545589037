import * as AOS from 'aos';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Component, OnDestroy, OnInit} from '@angular/core';

import {AccessTypeResponse} from '../../../models/accessTypeResponse';
import {AlertService} from 'src/app/services/alert-service.service';
import {AuthService} from 'src/app/services/auth.service';
import {CodeService} from '../../../services/code.service';
import {EventForCustomer} from 'src/app/models/events/EventForCustomer';
import {EventService} from 'src/app/services/event.service';
import {EventStatus} from '../../../enum/EventStatus';
import {EventStatusResponse} from '../../../models/events/EventStatusResponse';
import {Location} from '@angular/common';
import {Promotion} from '../../../models/Promotion';
import {PromotionService} from '../../../services/promotion.service';
import {SpinnerService} from 'src/app/services/spinner.service';
import {TableTypeResponse} from '../../../models/TableTypeResponse';
import {environment} from 'src/environments/environment';
import {Meta} from '@angular/platform-browser';
import {PromotionPersist} from '../../../models/PromotionPersist';
import {MentaService} from '../../../services/menta.service';
import {forkJoin} from 'rxjs';
import {VerificationAccount} from '../../../models/VerificationAccount';
import {User} from '../../../models/user';

@Component({
  selector: 'app-detail-event',
  templateUrl: './detail-event.component.html',
  styleUrls: ['./detail-event.component.scss']
})
export class DetailEventComponent implements OnInit, OnDestroy {
  event: EventForCustomer;
  eventStatusResponse: EventStatusResponse;
  showImage: boolean;
  skeletonLoading = true;
  imageEvent = '';
  marker: any;
  showModalActionLogin = false;
  idEvent = null;
  minPriceTable = null;
  minPriceAccess = null;
  withModalDownload = false;
  modal: any = null;
  code = null;
  target: string = null;
  showInfoTypeTicketModal = false;
  typeInfoSelected: string;
  logged = false;
  guestLogged = false;
  withCode = false;
  promotion: Promotion = null;
  showIncorrectPromoCodeModal = false;
  currency = '$';
  hasAuthorizedAccess = false;
  showNeedVerifiedEmail = false;
  showErrorModal = false;
  errorTitle = '';
  errorContent = '';
  showNeedVerifiedAccount = false;
  errorAccountTitle = '';
  errorAccountContent = '';
  errorAccountIcon = '';
  errorAccountImage = '';
  errorIcon: string = null;

  constructor(
    private router: Router,
    private eventService: EventService,
    private spinnerService: SpinnerService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private alertSrv: AlertService,
    private codeSrv: CodeService,
    private location: Location,
    private promoSrv: PromotionService,
    private metaService: Meta,
    private mentaSrv: MentaService
  ) {
    this.route.paramMap.subscribe(async (params: ParamMap) => {
      const id = params.get('id');
      const code = params.get('code');
      if (id) {
        this.idEvent = id;
      }
      if (code) {
        this.withCode = true;
        this.code = code;
      }
    });
  }

  async ngOnInit() {
    // finish spinner data required
    this.spinnerService.loadSpinner.next(false);
    this.scrollToTop();
    await this.authService.checkUserLogged();
    this.logged = this.authService.isLoggedIn();
    if (this.logged) {
      forkJoin({
        pendingEventsIds: await this.eventService.getEventsIdsByCustomer()
      }).subscribe({
        next: (res) => {
          this.checkCustomerIsOnBillboardEvent(
            res.pendingEventsIds
          );
          if (this.hasAuthorizedAccess) {
            this.router.navigate(['/my-events/event-detail/' + this.idEvent]);
          }
        }
      });
    }
    await this.getDataEvent();
    if (this.code) {
      const promotionPersist: PromotionPersist = new PromotionPersist();
      promotionPersist.detailPage = true;
      promotionPersist.eventId = this.idEvent;
      promotionPersist.promoCode = this.code;
      sessionStorage.setItem('promotionPersist', JSON.stringify(promotionPersist));
    }

    AOS.init({
      duration: 800,
      once: true
    });
  }

  checkCustomerIsOnBillboardEvent(
    pendingEvents: Array<number>
  ) {
    const eventExist = pendingEvents.find(id => id === Number(this.idEvent));
    if (eventExist) {
      this.hasAuthorizedAccess = true;
    }
  }

  ngOnDestroy() {
    if (this.modal) {
      const elements = document.getElementsByClassName('show__modal');
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
    }
  }

  async getDataEvent() {
    this.eventService.getEventById(this.idEvent).subscribe({
      next: (event: EventForCustomer) => {
        this.setMetaData(event);
        this.event = event;
        this.currency = event.storeDataOnEvent.currency;
        this.imageEvent = environment.mediaUrl + this.event.image;
        this.skeletonLoading = false;
      },
      error: (err) => {
        if (err.status === 404) {
          this.router.navigate(['/event-not-found']);
        }
      }
    });
    let eventStatusResponse: EventStatusResponse;
    eventStatusResponse = await this.eventService.getStatusEventById(this.idEvent).toPromise();
    if (eventStatusResponse) {
      this.eventStatusResponse = eventStatusResponse;
      if (this.eventStatusResponse.isEnabledForWebSelling) {
        const validEvent = this.validateEvent();
        if (validEvent) {
          if (this.code != null) {
            await this.getTarget(this.idEvent, this.eventStatusResponse.storeId, this.code).then(target => {
              if (target !== null) {
                if (this.logged && window.history.state.navigationId === 1) {
                  this.goToTickets(target);
                }
              } else {
                this.showIncorrectPromoCodeModal = true;
              }
            });
          }
          this.spinnerService.loadSpinner.next(false);
          // await this.showModalDownload(true);
        } else {
          await this.router.navigate(['/event-not-found']);
        }
      } else if (!this.eventStatusResponse.isEnabledForWebSelling) {
        await this.showModalUnavailableSelling();
      }
    }
  }

  setMetaData(event: EventForCustomer) {
    this.metaService.addTags(
      [
        {
          itemprop: 'name',
          content: event.eventName
        },
        {
          itemprop: 'description',
          content: event.eventDescription
        },
        {
          itemprop: 'image',
          content: environment.mediaUrl + event.image
        },
        {
          property: 'og:url',
          content: this.router.url
        },
        {
          property: 'og:image',
          content: environment.mediaUrl + event.image
        },
        {
          property: 'og:description',
          content: event.eventDescription
        },
        {
          property: 'og:title',
          content: event.eventName
        },
        {
          property: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          property: 'twitter:title',
          content: event.eventName
        },
        {
          property: 'twitter:description',
          content: event.eventDescription
        },
        {
          property: 'twitter:image',
          content: environment.mediaUrl + event.image
        }
      ]
    );
  }

  async goToTicketOnResale() {
    this.spinnerService.loadSpinner.next(true);
    const logged = this.authService.isLoggedIn();
    const guestLogged = this.authService.isGuestLoggedIn();
    if (!logged || guestLogged) {
      this.spinnerService.loadSpinner.next(false);
      this.showModalActionLogin = true;
    } else {
      this.authService.getAccountStatus().subscribe({
        next: async (res: VerificationAccount) => {
          if (!res.documentVerified || !res.emailVerified) {
            if (!res.documentVerified && !res.emailVerified) {
              // invalid DNI and EMAIL
              this.errorAccountTitle = $localize`Validá tu email, tu DNI y continuá hacia la reventa.`;
              this.errorAccountContent = $localize`Seguí las instrucciones desde tu casilla de correos, y luego tu identidad desde la App.`;
              this.errorAccountImage = '';
              this.errorAccountIcon = 'fa-thin fa-triangle-exclamation';
            } else if (!res.documentVerified) {
              // invalid DNI
              this.errorAccountTitle = $localize`Validá tu DNI y continuá hacia la reventa.`;
              this.errorAccountContent = $localize`Para continuar con la compra, validá tu identidad desde la App.`;
              this.errorAccountImage = 'invalid-dni.svg';
              this.errorAccountIcon = '';
            } else {
              // invalid EMAIL
              this.errorAccountTitle = $localize`Validá tu email y continuá hacia la reventa.`;
              this.errorAccountContent = $localize`Seguí las instrucciones desde tu casilla de correos, para activar tu cuenta.`;
              this.errorAccountImage = '';
              this.errorAccountIcon = 'fa-thin fa-triangle-exclamation';
            }
            this.spinnerService.loadSpinner.next(false);
            this.showNeedVerifiedAccount = true;
          } else if (res.documentVerified && res.emailVerified) {
            const returnUrl = environment.brandParams.logo.urlLink + '/detail-event/' + this.idEvent;
            const urlResale = await this.mentaSrv.getResaleLink(this.event.eventUUID, returnUrl);
            if (urlResale.data.data) {
              window.location.href = urlResale.data.data;
            }
          }
        },
        error: (err) => {
          console.error(err);
          this.errorTitle = $localize`Error`;
          if (err.status === 404) {
            this.errorContent = $localize`Usuario invalido.`;
          } else {
            this.errorContent = $localize`Ha ocurrido un error, por favor volvé a intentarlo.`;
          }
          this.showErrorModal = true;
          this.spinnerService.loadSpinner.next(false);
        }
      });
    }
  }

  onNeedVerifiedAccount(e) {
    this.showNeedVerifiedAccount = false;
  }

  onActionErrorModal(e) {
    this.showErrorModal = false;
  }

  async getTarget(eventId, storeId, code): Promise<string> {
    await this.resolvePromotionByEventIdAndCode(parseInt(eventId, 10), storeId, code).then((p) => {
      this.promotion = p;
      if (this.promotion && this.promotion.eventTableActive && !this.promotion.eventTicketActive) {
        this.target = 'T';
      } else if (this.promotion && this.promotion.eventTicketActive && !this.promotion.eventTableActive) {
        this.target = 'A';
      } else if (this.promotion && this.promotion.eventTicketActive && this.promotion.eventTableActive) {
        this.target = 'G';
      } else {
        this.target = null;
      }
    });
    return this.target;
  }

  validateEvent() {
    return this.eventStatusResponse
      && this.eventStatusResponse.status !== EventStatus.FINALIZED
      && this.eventStatusResponse.status !== EventStatus.CANCELED
      && this.eventStatusResponse.verified
      && this.eventStatusResponse.showOnBillboard;
  }

  canShowResaleButton() {
    return this.event?.allowResale
      && this.event?.hasTicketsOnResale
      && this.event?.status === EventStatus.PENDING
      && (this.event?.dateInit - ((new Date()).getTime()) > 3600000);
  }

  async goToTickets(type: string) {
    let typeMatchesCodeTarget = false;
    if (this.target) {
      const codeValidForTables = this.target.includes('T');
      const codeValidForTickets = this.target.includes('A');
      typeMatchesCodeTarget = (type === 'T' && codeValidForTables) || (type === 'A' && codeValidForTickets) || (type === 'G');
    }
    let destination: string;
    if (type === 'B' || type === 'G') {
      destination = 'billboard/detail-event/' + this.idEvent;
    } else {
      if (this.event) {
        if (type === 'T' && this.event.tableTypes.length > 0) {
          destination = 'buy-ticket/' + this.idEvent + '/' + type;
        } else if (type === 'A' && this.event.accessTypes.length > 0) {
          destination = 'buy-ticket/' + this.idEvent + '/' + type;
        } else {
          destination = 'billboard/detail-event/' + this.idEvent;
        }
      }
    }
    if (this.code && typeMatchesCodeTarget) {
      destination = destination + '/code/' + this.code;
    }
    await this.router.navigate([destination]);
  }

  async resolvePromotionByEventIdAndCode(eventId: number, storeId: number, code: string): Promise<Promotion> {
    const promotion = await this.promoSrv.getPromotionByCodeEventAndStoreId(code, storeId, eventId).toPromise();
    if (promotion && promotion.active) {
      return promotion;
    } else {
      this.showIncorrectPromoCodeModal = true;
    }
    return;
  }

  showInfoModal(type: string) {
    this.showInfoTypeTicketModal = true;
    this.typeInfoSelected = type;
  }

  onCloseButtonEvent() {
    this.showInfoTypeTicketModal = false;
  }

  async showModalDownload(showModal) {
    this.withModalDownload = true;
    let title;
    let content;
    let contentBtn;
    let twoBtns;
    let isForDownload;
    let linkToNavigate;
    title = $localize`¡Descargate Black iD en tu celular!`;
    content = $localize`Comprá y gestioná tus tickets para eventos, desde la App y comenzá a vivir una experiencia distinta.`;
    contentBtn = $localize`Descargar Black iD App`;
    twoBtns = true;
    isForDownload = true;
    linkToNavigate = null;
    this.modal = await this.alertSrv.modalDownloadApp(title, content, twoBtns, contentBtn, isForDownload, linkToNavigate, showModal);
    await this.modal.onDidDismiss();
  }

  async showModalUnavailableSelling() {
    const title = $localize`Venta exclusiva desde Black id App`;
    const content = $localize`Los accesos para este evento pueden adquirirse únicamente desde Black id App.`;
    const iconClass = 'fal fa-mobile-notch text-12xl text-info';
    this.modal = await this.alertSrv.modalStoreNotAvailableSellingForWeb(title, content, iconClass, true);
    await this.modal.onDidDismiss();
  }

  getMinPriceTable() {
    const tablesWithSoldOuts = this.event.tableTypes.filter(tab => !tab.soldOut);
    let tables = tablesWithSoldOuts;
    if (tables.length > 1) {
      tables = tablesWithSoldOuts.sort((a: TableTypeResponse, b: TableTypeResponse) =>
        a.price > b.price ? 1 : -1
      );
    }
    return tables.length > 0 ? tables[0].price : null;
  }

  getMinPriceAccess() {
    const accessWithSoldOuts = this.event.accessTypes.filter(acc => !acc.soldOut);
    let access = accessWithSoldOuts;
    if (access.length > 1) {
      access = accessWithSoldOuts.sort((a: AccessTypeResponse, b: AccessTypeResponse) =>
        a.price > b.price ? 1 : -1
      );
    }
    return access.length > 0 ? access[0].price : null;
  }

  buyTicket(type?: string) {
    const logged = this.authService.isLoggedIn();
    if (logged) {
      this.authService.getCurrentUserData().subscribe({
        next: (user: User) => {
          if (user.document !== null && user.document !== '0' && user.document !== '') {
            this.finishBuyTicket(type);
          } else {
            this.router.navigate(['data-required']);
          }
        }
      });
    } else {
      this.finishBuyTicket(type);
    }
  }

  async finishBuyTicket(type?: string) {
    const logged = this.authService.isLoggedIn();
    const guestLogged = this.authService.isGuestLoggedIn();
    if (!logged || guestLogged) {
      this.openModalLogin(null);
    } else {
      if (!this.eventStatusResponse.isEnabledForWebSelling) {
        await this.showModalUnavailableSelling();
        return;
      }

      if (this.code !== null &&
          this.promotion !== null &&
          (this.promotion.eventTableActive || this.promotion.eventTicketActive) &&
          (type === 'T' && this.promotion && this.promotion.eventTableActive) || (type === 'A' &&  this.promotion && this.promotion.eventTicketActive)) {
          this.router.navigate(['/buy-ticket/' + this.idEvent + '/' + type + '/code/' + this.code]);
      } else {
          this.router.navigate(['/buy-ticket/' + this.idEvent + '/' + type]);
      }
    }
  }

  openModalLogin(e) {
    this.showModalActionLogin = true;
  }

  onActionToLogin() {
    if (this.code !== null) {
      const promotionPersist: PromotionPersist = new PromotionPersist();
      promotionPersist.detailPage = true;
      promotionPersist.eventId = this.idEvent;
      promotionPersist.promoCode = this.code;
      sessionStorage.setItem('promotionPersist', JSON.stringify(promotionPersist));
    } else if (this.idEvent !== null) {
      localStorage.setItem('lastDetailEvent', this.idEvent);
    }

    this.router.navigate(['/login']);
  }

  onShowLoginModal() {
    this.showInfoTypeTicketModal = false;
    this.showModalActionLogin = true;
  }

  viewImage() {
    this.showImage = !this.showImage;
  }

  getDataModalImage(data) {
    if (data === false) {
      this.showImage = false;
    }
  }

  closeModalLogin() {
    this.showModalActionLogin = false;
  }

  back(event) {
    this.router.navigate(['/']);
  }

  goToGeolocation(event) {
    window.open('https://www.google.com/maps/search/?api=1&query=' + this.event.latitude + ',' + this.event.longitude, '_blank');
  }

  getDataCardComponent(data) {
    this.showImage = data;
  }

  onActionIncorrectPromoCodeModal() {
    this.showIncorrectPromoCodeModal = false;
    this.router.navigate(['/detail-event/', this.idEvent]);
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
  }
}
