import {Injectable} from '@angular/core';
import {initializeMenta} from '@mentatickets/ts-sdk';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ResaleLinkResponse} from '../models/events/ResaleLinkResponse';
import {currentLocale} from '../../locale/i18n';
import {LoginTrust, SDKOptionsMenta} from '../models/events/SDKOptionsMenta';

@Injectable({
  providedIn: 'root'
})
export class MentaService {

  mentaTickets: any;

  constructor(
    private httpC: HttpClient
  ) {
  }

  initializeMenta() {
    this.mentaTickets = initializeMenta(environment.mentaTicketPublicKey);
  }

  async getResaleLink(externalReferenceEventId: string, url: string) {
    this.mentaTickets = await initializeMenta(environment.mentaTicketPublicKey);
    const {email} = JSON.parse(localStorage.getItem('user'));
    const sdkOptionsMenta = new SDKOptionsMenta();
    sdkOptionsMenta.language = currentLocale;
    sdkOptionsMenta.returnUrl = url;
    const loginTrust = new LoginTrust();
    loginTrust.user = email;
    loginTrust.withMethod = 'email';
    sdkOptionsMenta.loginTrust = loginTrust;
    return await this.mentaTickets.generateResaleUrl(externalReferenceEventId, null, sdkOptionsMenta);
  }

  publishTicketOnResale(ticketUuid: string, type: string): Observable<ResaleLinkResponse> {
    return this.httpC.get<ResaleLinkResponse>(environment.billboardAGUrl + `/cartelera/resale/init-resale/${ticketUuid}/${type}`);
  }

  async getLinkTicketDetails(externalReferenceTicketId: string, url: string) {
    const {email} = JSON.parse(localStorage.getItem('user'));
    const sdkOptionsMenta = new SDKOptionsMenta();
    sdkOptionsMenta.language = currentLocale;
    sdkOptionsMenta.returnUrl = url;
    const loginTrust = new LoginTrust();
    loginTrust.user = email;
    loginTrust.withMethod = 'email';
    sdkOptionsMenta.loginTrust = loginTrust;
    return await this.mentaTickets.viewSaleDetailsLink(externalReferenceTicketId, sdkOptionsMenta);
  }

  removeTicketOfResale(ticketUuid: string): Observable<void> {
    return this.httpC.delete<void>(environment.billboardAGUrl + `/cartelera/resale/remove/${ticketUuid}`);
  }
}
