import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {InvitationCodeRequest} from '../models/InvitationCode';
import {Observable} from 'rxjs';
import {EventGuestResponse} from '../models/EventGuestResponse';

@Injectable({
  providedIn: 'root'
})
export class EventGuestService {

  constructor(
    private httpC: HttpClient
  ) {
  }

  addGuestCode(idCustomer: number, invitationCodeRequest: InvitationCodeRequest): Observable<EventGuestResponse> {
    return this.httpC.post<EventGuestResponse>(environment.billboardAGUrl + `/cartelera/guests/web/invitation/${idCustomer}`,
      invitationCodeRequest);
  }

  checkGuestExistence(customerDocument: string, documentType: string, country: string, eventId: number) {
    return this.httpC.get<any>(environment.billboardAGUrl + `/cartelera/guests/web/${customerDocument}/${documentType}/${country}/event/${eventId}`);
  }

  deleteGuest(guestId: number) {
    return this.httpC.delete<any>(environment.billboardAGUrl + `/cartelera/guests/web/${guestId}?fromApp=true`);
  }

  leaveEvent(idEvent: number): Observable<void> {
    const {id_customer} = JSON.parse(localStorage.getItem('user'));
    return this.httpC.delete<void>(environment.billboardAGUrl + `/cartelera/web/events/${id_customer}/event/${idEvent}`);
  }
}
