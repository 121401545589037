import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';
import {InputValidationService} from 'src/app/services/input-validation.service';
import {LoginService} from 'src/app/services/login.service';
import {$localize} from '@angular/localize/init';

@Component({
  selector: 'app-signup-step-one',
  templateUrl: './signup-step-one.component.html',
  styleUrls: ['./signup-step-one.component.scss']
})
export class SignupStepOneComponent implements OnInit {

  @Input() parentForm: UntypedFormGroup;
  @Input() isExternalSignup = false;
  @Input() stepHasBeenSubmitted = false;
  @Input() passwordMatch: boolean = true;
  formHasBeenSubmitted = false;
  passVisible = false;
  confirmPassVisible = false;
  errorMessages = {
    email: [
      {type: 'required', message: $localize`El email es requerido`},
      {type: 'pattern', message: $localize`Ingresa un email válido`},
      {type: 'EmailAlreadyExist', message: $localize`El correo electrónico ya se encuentra registrado`},
      {type: 'InvalidEmail', message: $localize`El correo electrónico es inválido.`}
    ],
  };
  readOnly = false;
  labelInputPassword = $localize`Contraseña nueva`;
  labelInputRepeatPassword = $localize`Repetir contraseña nueva`;
  hashPassword = true;
  hashConfirmPassword = true;

  constructor(
    private validateInputService: InputValidationService,
    private loginService: LoginService,
  ) {
  }

  ngOnInit() {}

  get nameField(): AbstractControl {
    return this.parentForm.get('name');
  }

  get lastNameField(): AbstractControl {
    return this.parentForm.get('lastName');
  }

  get emailField(): AbstractControl {
    return this.parentForm.get('email');
  }

  get passwordField(): AbstractControl {
    return this.parentForm.get('password');
  }

  get confirmPasswordField(): AbstractControl {
    return this.parentForm.get('confirmPassword');
  }

  clearEmail() {
    let email = this.parentForm.controls.email.value.trim();
    if (email.endsWith('.')) {
      email = email.slice(0, -1);
      email = email.trim();
    }
    this.parentForm.patchValue({
      email
    });
  }


  emailHasError() {
    return this.stepHasBeenSubmitted && this.parentForm.controls.email.invalid
      && this.parentForm.controls.email.value.length > 0;
  }

  emailHasEmptyError() {
    return this.stepHasBeenSubmitted && this.parentForm.controls.email.invalid
      && this.parentForm.controls.email.value.length === 0;
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }


}
