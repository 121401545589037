<div class="modal" [ngClass]="showModal ? 'show__modal' : 'close__modal'">
  <div class="modal__container">
    <div class="modal__body">
      <span
        class="display-block font-secondary text-center font-size-20 lh-26 font-weight-600 text-gray-1 margin-bottom-8"
        i18n>Condiciones y Políticas</span>
      <span class="display-block font-size-14 lh-20 text-center font-weight-400 text-gray-3 margin-bottom-24" i18n>Por último, leé los términos. Si estás de acuerdo, aceptalos y continuá.</span>
      <form action="" [formGroup]="parentForm">

        <fieldset class="field__checkbox margin-bottom-8"
                  [ngClass]="validateInputClass(parentForm, 'acceptTerms', formHasBeenSubmitted)">
          <div class="field">
            <div>
              <input type="checkbox" id="checkboxAcceptTerms" class="checkbox" name="checkbox"
                     formControlName="acceptTerms"
                     #checkbox required/>
              <label for="checkboxAcceptTerms" class="label"></label>
            </div>
            <div class="margin-left-16">
              <label for="checkboxAcceptTerms" class="label">
                <div class="margin-bottom-8 font-size-14 font-weight-400 lh-24" i18n>Al registrarme acepto:</div>
                <a class="display-block font-size-14 font-weight-500 lh-24 margin-bottom-8 text-primary"
                   routerLink="/terms-and-conditions" target="_blank" i18n>Términos y Condiciones de Uso.</a>
                <a class="display-block font-size-14 font-weight-500 lh-24 text-primary"
                   routerLink="/privacy-policies" target="_blank" i18n>Políticas de privacidad.</a>
              </label>
            </div>
          </div>
        </fieldset>

        <fieldset class="field__checkbox margin-bottom-40"
                  [ngClass]="validateInputClass(parentForm, 'acceptEmails', formHasBeenSubmitted)">
          <div class="field">
            <div>
              <input type="checkbox" id="checkboxAcceptEmails" class="checkbox" name="checkbox"
                     formControlName="acceptEmails"
                     #checkbox required/>
              <label for="checkboxAcceptEmails" class="label"></label>
            </div>
            <div class="margin-left-16">
              <label for="checkboxAcceptEmails" class="label">
                <div class="font-size-14 font-weight-400 lh-24" i18n>Acepto recibir mails con notificaciones sobre
                  eventos y
                  novedades.
                </div>
              </label>
            </div>
          </div>
        </fieldset>
      </form>
      <div class="footer-buttons">
        <button class="btn btn__md btn__secondary btn-back" (click)="backToMyData()" i18n>Volver</button>
        <button class="btn btn__md btn__primary btn-signup"
                (click)="finishSignUp()" [disabled]="!parentForm.controls.acceptTerms.value" i18n>Registrarme
        </button>
      </div>

    </div>
  </div>
</div>
