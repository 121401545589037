<div class="data-required">
  <div class="back-desktop">
    <div class="back-button-container cursor-pointer" (click)="backTo()">
      <i class="fa-light fa-arrow-left-long text-primary margin-right-8 back-row"></i>
      <span class="font-weight-500 font-size-14 lh-24 text-primary back-text" i18n>
        Volver
      </span>
    </div>
  </div>

  <div class="card__content-step">

    <div class="container data-required__card">
      <span
        class="display-block font-secondary font-size-20 lh-24 font-weight-600 text-gray-1 margin-bottom-8 title-data"
        i18n>
        Completá estos datos!
      </span>
      <div class="display-block margin-bottom-32 font-size-14 lh-20 font-weight-700 text-gray-3">
        <span class="font-weight-400" i18n>
          Para continuar con tu compra sólo necesitamos uno datos más!
        </span>
      </div>

      <form [formGroup]="updateDataForm">
        <bid-infinite-scroll
          *ngIf="countries.length > 0"
          [parentForm]="updateDataForm"
          [formControlName]="'country'"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [items]="countries"
          [page]="'register'"
          [placeholder]="placeHolderCountry"
          [bindLabel]="'es'"
          [bindValue]="'alpha3'"
          (dropdownChanges)="onDropdownChange($event)"
        ></bid-infinite-scroll>

        <div
          class="field__item fusion-dropdown margin-top-32 {{ idHighlighted }}"
          [ngClass]="validateInputClass(updateDataForm, 'id', formHasBeenSubmitted)">
          <div class="p-float-label">
            <p-dropdown
              id="dropdown"
              class="bid-dropdown fusion"
              [filter]="false"
              [showClear]="false"
              [optionLabel]="'name'"
              [optionValue]="'value'"
              [options]="idOptionTypes"
              [formControlName]="'originTypeId'"
              (onChange)="onOptionTypeChange($event)"
            ></p-dropdown>

            <input type="text"
                   formControlName="id"
                   id="id"
                   (focus)="onIdFocus()"
                   (focusout)="onIdFocusOut()"
                   (keydown)="verifyID($event)"
                   class="p-inputtext dropdown"
            >
          </div>
          <app-error-message
            [page]="'register'"
            [ctrl]="updateDataForm.get('id') | formControl"
            [formHasBeenSubmitted]="formHasBeenSubmitted"
          ></app-error-message>
        </div>

        <div
          class="field__item  margin-top-32"
          [ngClass]="validateInputClass(updateDataForm, 'idValidation', formHasBeenSubmitted)"
        >
          <div class="p-float-label">
            <input type="text"
                   id="idValidation"
                   formControlName="idValidation"
                   (keypress)="verifyID($event)"
                   class="p-inputtext"
            >
            <label for="idValidation" class="title-float">
              <ng-container i18n>Repetir</ng-container>
              <span *ngIf="updateDataForm.get('originTypeId').value === 'dni'">DNI</span>
              <span *ngIf="updateDataForm.get('originTypeId').value === 'passport'">Passport</span>
              <span *ngIf="updateDataForm.get('originTypeId').value === 'id'">ID</span>
            </label>
            <app-error-message
              [page]="'register'"
              [ctrl]="updateDataForm.get('idValidation') | formControl"
              [formHasBeenSubmitted]="formHasBeenSubmitted"
            ></app-error-message>
          </div>
        </div>
      </form>

      <div class="card__btn-footer margin-top-48">
        <button class="btn btn__md btn__primary btn-continuar" (click)="saveForm()">
          <span class="display-flex">
            <ng-container i18n>Continuar</ng-container>
            <i class="fa-solid fa-chevron-right"></i>
          </span>
        </button>
      </div>

    </div>
  </div>
</div>
