import { Injectable } from '@angular/core';
import { PhoneNumberInfo } from '../models/PhoneNumberInfo';

@Injectable({
  providedIn: 'root'
})
export class PhoneService {

  constructor() { }

  getPhoneInfoParsed(phoneData) {
    const phoneNumberInfoParsed = new PhoneNumberInfo();
    phoneNumberInfoParsed.number = phoneData.internationalNumber.replace(phoneData.dialCode, '')
      .replaceAll(' ', '').replaceAll('-', '').trim(); 
    phoneNumberInfoParsed.internationalNumber = phoneData.internationalNumber;
    phoneNumberInfoParsed.nationalNumber = phoneData.nationalNumber;
    phoneNumberInfoParsed.e164Number = phoneData.internationalNumber.replaceAll('-', '').replaceAll(' ', '').trim();
    phoneNumberInfoParsed.countryCode = phoneData.countryCode.toUpperCase();
    phoneNumberInfoParsed.dialCode = phoneData.dialCode;
    return phoneNumberInfoParsed;
  }
}
