<section class="configurations">
  <div class="wrapper">
    <div class="flex title-configurations">
      <i class="fal fa-gear font-size-18 lh-24 text-gray-3 margin-right-8"></i>
      <p class="font-size-22 my-0 font-weight-500 font-secondary lh-24 text-gray-3" i18n>
        Configuración
      </p>
    </div>
    <div class="button__change-password" (click)="changePassword()">
      <div class="container__button-internal">
        <div class="flex align-items-center">
          <i class="fal fa-key text-primary font-size-18 lh-24 margin-right-16"></i>
          <p class="font-size-16 my-0 font-weight-300 font-secondary lh-24 text-gray-1" i18n>
            Cambiar contraseña
          </p>
        </div>
        <i class="fal fa-chevron-right text-primary font-size-18 lh-24 margin-right-16"></i>
      </div>
    </div>
    <div class="button__delete-account" (click)="deleteAccount()">
      <div class="container__button-internal">
        <div class="flex align-items-center">
          <i class="fal fa-user-slash text-error font-size-18 lh-24 margin-right-16"></i>
          <p class="font-size-16 my-0 font-weight-300 font-secondary lh-24 text-error" i18n>
            Eliminar cuenta
          </p>
        </div>
        <i class="fal fa-chevron-right text-transparent font-size-18 lh-24 margin-right-16"></i>
      </div>
    </div>
  </div>
  <div class="wrapper-mobile">
    <div class="flex title-configurations__mobile">
      <i class="fal fa-gear font-size-18 lh-24 text-gray-3 margin-right-8"></i>
      <p class="my-0 font-weight-500 font-secondary lh-24 text-gray-3" i18n>
        Configuración
      </p>
    </div>
    <div class="button__change-password__mobile" (click)="changePassword()">
      <div class="container__button-internal">
        <div class="flex align-items-center">
          <i class="fal fa-key text-primary font-size-18 lh-24 margin-right-16"></i>
          <p class="font-size-16 my-0 font-weight-300 font-secondary lh-24 text-gray-1" i18n>
            Cambiar contraseña
          </p>
        </div>
        <i class="fal fa-chevron-right text-primary font-size-18 lh-24 margin-right-16"></i>
      </div>
    </div>
    <div class="button__delete-account__mobile" (click)="deleteAccount()">
      <div class="container__button-internal">
        <div class="flex align-items-center">
          <i class="fal fa-user-slash text-error font-size-18 lh-24 margin-right-16"></i>
          <p class="font-size-16 my-0 font-weight-300 font-secondary lh-24 text-error" i18n>
            Eliminar cuenta
          </p>
        </div>
        <i class="fal fa-chevron-right text-transparent font-size-18 lh-24 margin-right-16"></i>
      </div>
    </div>
  </div>
</section>

<app-modal-generic
  *ngIf="showModalDeleteAccount"
  [type]="MODALACTIONTYPE.warning"
  [title]="configModalDeleteAccount.title"
  [content]="configModalDeleteAccount.content"
  [textBtn]="configModalDeleteAccount.textBtn"
  [textSecondBtn]="configModalDeleteAccount.textSecondBtn"
  [invertBtnsDesktop]="true"
  [invertBtnsMobile]="true"
  [firstButtonClass]="'btn__md btn__primary font-size-14'"
  [secondButtonClass]="'btn__md btn__remove font-size-14'"
  (closeButtonEvent)="onCloseDeleteModal($event)"
  (action)="onActionDeleteModal($event)"
  (actionSecondButton)="onActionSecBtnDeleteModal($event)"
></app-modal-generic>
