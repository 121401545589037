<section class="hero" [style.background-image]="'url('+ environment.mediaUrl + eventData?.image +')'">
  <div class="wrapper">
    <div class="back">
      <button class="btn btn__back color-primary margin-bottom-16" (click)="back()">
        <i class="fa-regular fa-arrow-left icon__back"></i>
        <span i18n>Volver</span>
      </button>
    </div>
    <div class="event-details">
      <div class="event-detail__image">
        <img src="{{environment.mediaUrl + eventData?.image}}" (click)="viewImage()"
             *ngIf="!skeletonLoading && eventData?.storeDataOnEvent" class="event-image" itemprop="image" alt=""/>
        <div class="skeleton__image" *ngIf="skeletonLoading"></div>
      </div>
      <div class="event-details__info">
        <div class="w-full">
          <div class="producer">
            <div class="producer__pic">
              <img src="{{environment.mediaUrl + eventData?.storeDataOnEvent.logo}}" class="pic"
                   *ngIf="!skeletonLoading && eventData?.storeDataOnEvent" [alt]="eventData?.storeDataOnEvent.name"/>
              <div class="pic__skeleton" *ngIf="skeletonLoading"></div>
            </div>
            <p class="producer__name" *ngIf="!skeletonLoading && eventData?.storeDataOnEvent">
              {{eventData?.storeDataOnEvent.name}}
            </p>
            <div class="producer__skeleton" *ngIf="skeletonLoading"></div>
          </div>
          <h1 class="event-detail__title" itemprop="name">{{eventData?.eventName}}</h1>
          <div class="skeleton__title" *ngIf="skeletonLoading"></div>
        </div>
        <div class="w-full">
          <div class="w-full flex items-center">
            <div class="flex items-center justify-start" *ngIf="!skeletonLoading && eventData?.dateInit">
              <i class="fa-light fa-calendar-lines text-primary mr-2"></i>
              <p class="text-sm lh-24 text-gray-2 my-0"
                 [ngClass]="eventData.status === eventStatus.CANCELED ? 'strikethrough' : ''">
                <span class="uppercase">{{ (eventData?.dateInit | localeDate:dateFormat).replace(".", "")}} </span>
                <ng-container
                  i18n="Date range separator, it will be placed between the event start date and event end date"> al
                </ng-container>
                <span
                  class="uppercase"> {{ ' ' + (eventData?.dateEnd | localeDate:dateFormat).replace(".", "") }}</span>
              </p>
            </div>
            <div class="flex items-center justify-start ml-hour" *ngIf="!skeletonLoading && eventData?.dateInit">
              <i class="fa-light fa-clock text-primary mr-2"></i>
              <p class="text-sm lh-24 text-gray-2 my-0"
                 [ngClass]="eventData.status === eventStatus.CANCELED ? 'strikethrough' : ''">{{ eventData?.dateInit | localeDate:'HH:mm' }}
                <ng-container
                  i18n="Event time range separator, it will be placed between the event start time and event end time">
                  a
                </ng-container>
                {{ ' ' + eventData?.dateEnd | localeDate:'HH:mm' }}</p>
              <div class="skeleton__date mt-2" *ngIf="skeletonLoading"></div>
            </div>
            <div *ngIf="skeletonLoading">
              <div class="skeleton__date--name"></div>
              <div class="skeleton__date mt-2"></div>
            </div>
            <div class="ml-hour" *ngIf="skeletonLoading">
              <div class="skeleton__date--name"></div>
              <div class="skeleton__date mt-2"></div>
            </div>
          </div>
          <div class="flex items-center justify-start mt-2 cursor-pointer" (click)="geo()"
               *ngIf="!skeletonLoading && eventData?.address">
            <i class="fa-light fa-location-dot text-primary mr-2"></i>
            <p class="text-sm lh-24 text-gray-2 my-0"
               [ngClass]="eventData.status === eventStatus.CANCELED ? 'strikethrough' : ''">{{ eventData?.address }}</p>
          </div>
          <div class="mt-4" *ngIf="skeletonLoading">
            <div class="skeleton__date--name"></div>
            <div class="skeleton__date mt-2"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="details__chip-soldOut">
      <div class="bid-badge bid-badge--red bid-badge--soldout"
           *ngIf="eventData?.soldOut && eventData?.status !== eventStatus.CANCELED && !skeletonLoading">
        <i class="fa-solid fa-circle-exclamation text-gray-6 mr-"></i>
        <ng-container i18n>SOLD OUT</ng-container>
      </div>
      <div class="bid-badge bid-badge--red bid-badge--soldout"
           *ngIf="eventData?.status === eventStatus.CANCELED && !skeletonLoading">
        <ng-container i18n>CANCELADO</ng-container>
      </div>
    </div>
  </div>
</section>

<div class="card__mobile">
  <div class="card__image">
    <div #img class="image__event" [style.background-image]="'url('+ environment.mediaUrl + eventData?.image +')'"
         *ngIf="!skeletonLoading && eventData?.image"></div>
    <div class="skeleton__image-event" *ngIf="skeletonLoading"></div>
    <div class="card__details--info">
      <button class="btn btn__back color-gray-1" (click)="back()">
        <i class="fa-regular fa-arrow-left icon__back"></i>
      </button>
      <div class="w-full">
        <div class="w-full flex align-items-center justify-content-between">
          <div class="w-full flex items-center justify-start">
            <div class="place__image">
              <img src="{{environment.mediaUrl + eventData?.storeDataOnEvent.logo}}" class="image__place"
                   *ngIf="!skeletonLoading && eventData?.storeDataOnEvent" [alt]="eventData?.storeDataOnEvent.name">
              <div class="skeleton__image" *ngIf="skeletonLoading"></div>
            </div>
            <p class="font-secondary text-base lh-16 text-gray-1 font-semibold uppercase ml-10 my-0"
               *ngIf="!skeletonLoading && eventData?.storeDataOnEvent">
              {{eventData?.storeDataOnEvent.name}}</p>
            <div class="skeleton__place ml-3" *ngIf="skeletonLoading"></div>
          </div>
        </div>
        <div class="break-word">
          <h1 class="title__event break-word"
              *ngIf="!skeletonLoading && eventData?.eventName">{{ eventData?.eventName }}</h1>
          <div class="skeleton__title mt-4" *ngIf="skeletonLoading"></div>
        </div>
      </div>
      <div class="details__chip-soldOut">
        <div class="bid-badge bid-badge--red bid-badge--soldout"
             *ngIf="eventData?.soldOut && eventData?.status !== eventStatus.CANCELED && !skeletonLoading && !isTicketOnResale">
          <i class="fa-solid fa-circle-exclamation text-gray-6 mr-"></i>
          <ng-container i18n>SOLD OUT</ng-container>
        </div>
        <div class="bid-badge bid-badge--red bid-badge--soldout"
             *ngIf="eventData?.status === eventStatus.CANCELED && !skeletonLoading && !isTicketOnResale">
          <ng-container i18n>CANCELADO</ng-container>
        </div>
        <div class="badge__on-resale cursor-pointer" *ngIf="isTicketOnResale" (click)="viewOnResale()">
          <i class="fas fa-money-bill-transfer text-warning font-size-12 lh-16"></i>
          <span class="text-warning font-size-10 lh-16 font-weight-600 uppercase" i18n>En reventa</span>
          <span class="text-primary font-size-10 lh-16 font-weight-600 uppercase" i18n>Ver</span>
        </div>
      </div>
    </div>
  </div>

  <div class="card__container">
    <div class="card__details-event">
      <div class="w-full flex items-start justify-start" [ngClass]="skeletonLoading ? 'mt-8' : ''">
        <div *ngIf="skeletonLoading">
          <div class="skeleton__date--name"></div>
          <div class="skeleton__date mt-2"></div>
        </div>
        <div class="ml-hour" *ngIf="skeletonLoading">
          <div class="skeleton__date--name"></div>
          <div class="skeleton__date mt-2"></div>
        </div>
        <div class="flex items-center justify-start" *ngIf="!skeletonLoading && eventData?.dateInit">
          <i class="fa-light fa-calendar-lines text-primary mr-2"></i>
          <p class="text-sm lh-24 text-gray-2 my-0"
             [ngClass]="eventData.status === eventStatus.CANCELED ? 'strikethrough' : ''">
            <span class="uppercase">
            {{ (eventData?.dateInit | localeDate:'dd MMM').replace(".", "")}}</span>
            <ng-container
              i18n="Date range separator, it will be placed between the event start date and event end date"> al
            </ng-container>
            <span
              class="uppercase">{{ ' ' + (eventData?.dateEnd | localeDate:'dd MMM').replace(".", "") }}</span></p>
        </div>
        <div class="flex items-center justify-start ml-hour" *ngIf="!skeletonLoading && eventData?.dateInit">
          <i class="fa-light fa-clock text-primary mr-2"></i>
          <p class="text-sm lh-24 text-gray-2 my-0"
             [ngClass]="eventData.status === eventStatus.CANCELED ? 'strikethrough' : ''">
            {{ eventData?.dateInit | localeDate:'HH:mm' }}
            <ng-container
              i18n="Event time range separator, it will be placed between the event start time and event end time"> a
            </ng-container>
            {{ ' ' + eventData?.dateEnd | localeDate:'HH:mm' }}</p>
          <div class="skeleton__date mt-2" *ngIf="skeletonLoading"></div>
        </div>
      </div>
      <div *ngIf="skeletonLoading" class="mt-6">
        <div class="skeleton__date--name"></div>
        <div class="skeleton__address mt-2"></div>
      </div>
      <div class="flex items-center justify-start mt-2" *ngIf="!skeletonLoading && eventData?.address" (click)="geo()">
        <i class="fa-light fa-location-dot text-primary mr-2"></i>
        <p class="text-sm lh-24 text-gray-2 my-0"
           [ngClass]="eventData.status === eventStatus.CANCELED ? 'strikethrough' : ''">
          {{ eventData?.address }}
        </p>
      </div>
    </div>
  </div>
</div>
