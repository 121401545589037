<span class="display-block font-secondary font-size-18 lh-24 font-weight-500 text-gray-1 margin-bottom-8" i18n>Indicá tu país y tu ID ó Pasaporte</span>
<span class="display-block margin-bottom-32 font-size-14 lh-20 font-weight-700 text-gray-3">
  <span class="font-weight-700" i18n>Ingresá tu país y pasaporte real. </span>
  <span class="font-weight-400" i18n>Validar tu identidad te permitirá acceder a los eventos!</span>
</span>

<form [formGroup]="parentForm">
  <bid-infinite-scroll
    *ngIf="countries.length > 0"
    [parentForm]="parentForm"
    [formControlName]="'country'"
    [formHasBeenSubmitted]="stepHasBeenSubmitted"
    [items]="countries"
    [page]="'register'"
    [placeholder]="placeHolderCountry"
    [bindLabel]="'es'"
    [bindValue]="'alpha3'"
    (dropdownChanges)="onDropdownCountryChange($event)"
  ></bid-infinite-scroll>

  <div
    class="field__item fusion-dropdown margin-top-32 {{ idHighlighted }}"
    [ngClass]="validateInputClass(parentForm, 'id', stepHasBeenSubmitted)"
  >
    <div class="p-float-label">
      <p-dropdown
        id="dropdown"
        class="bid-dropdown fusion"
        [filter]="false"
        [showClear]="false"
        [optionLabel]="'name'"
        [optionValue]="'value'"
        [options]="idOptionTypes"
        [formControlName]="'originTypeId'"
        (onChange)="onOptionTypeChange($event)"
      ></p-dropdown>

      <input [type]="parentForm.controls.country.value === 'arg' ? 'number': 'text'"
             formControlName="id"
             id="id"
             (focus)="onIdFocus()"
             (focusout)="onIdFocusOut()"
             (keypress)="verifyID($event)"
             (keyup)="onKeyUpDocument()"
             class="p-inputtext dropdown"
      >
    </div>
    <app-error-message
      [page]="'register'"
      [ctrl]="parentForm.get('id') | formControl"
      [formHasBeenSubmitted]="stepHasBeenSubmitted"
    ></app-error-message>
  </div>

  <div
    class="field__item  margin-top-32"
    [ngClass]="validateInputClass(parentForm, 'idValidation', stepHasBeenSubmitted)"
  >
    <div class="p-float-label">
      <input [type]="parentForm.controls.country.value === 'arg' ? 'number': 'text'"
             id="idValidation"
             formControlName="idValidation"
             (keypress)="verifyID($event)"
             (keyup)="onKeyUpIdValid()"
             class="p-inputtext">
      <label for="idValidation" class="title-float">
        <ng-container i18n>Repetir</ng-container>
        <span *ngIf="parentForm.get('originTypeId').value === 'dni'">DNI</span>
        <span *ngIf="parentForm.get('originTypeId').value === 'passport'">Passport</span>
        <span *ngIf="parentForm.get('originTypeId').value === 'id'">ID</span>
      </label>
      <app-error-message
        [page]="'register'"
        [ctrl]="parentForm.get('idValidation') | formControl"
        [formHasBeenSubmitted]="stepHasBeenSubmitted"
      ></app-error-message>
    </div>
  </div>
</form>
