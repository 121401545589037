export class PromotionPersist {
  eventDetailPage: boolean = false;
  detailPage: boolean = false;
  showQR: boolean = false;
  eventId: string;
  promoCode: string;
  buyPage: boolean;
  checkoutPage: boolean;
  comeFromRegistration: boolean;
  buyType: string;
  invitationType: boolean = false;
}
