import {Injectable} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CustomValidationService {

  constructor() {
  }

  emailValidator() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return control.value && this.emailLength(control.value)
        ? {emailValidator: true}
        : null;
    };
  }

  private emailLength(value: string) {
    const [validPart] = value.split('@');
    return validPart.length > 64;
  }

  phoneValidator() {
    const validPhones = [
      /^\d{15}$/,
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/,
      /^\(?([0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/,
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/,
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{2})[-. ]?([0-9]{2})[-. ]?([0-9]{2})$/,
    ];
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return control.value && this.searchForMatch(validPhones, control.value)
        ? {phoneValidator: true}
        : null;
    };
  }

  private searchForMatch(validOnes: Array<any>, value: number) {
    const found = validOnes.find((v) => value.toString().match(v));
    return !found;
  }
}
