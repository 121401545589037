import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {JSONStrings} from 'src/app/core/internalization/es';

@Component({
  selector: 'bid-dropdown',
  templateUrl: './bid-dropdown.component.html',
  styleUrls: ['./bid-dropdown.component.scss']
})
export class BidDropdownComponent implements OnInit, AfterViewInit {

  @Input() label: string = 'Campo';
  @Input() required: boolean = false;
  @Input() onFocus: boolean = false;
  @Input() onError: boolean = false;
  @Input() readonly: boolean = false;
  @Input() success: boolean = false;
  @Input() type: string = 'text';
  @Input() name: string = '';
  @Input() hint: boolean = true;
  @Input() options: any;
  @Input() ctrl: UntypedFormControl;
  @Input() ctrlName: string;
  @Input() page: string = '';
  @Input() capitalize: boolean = false;
  @Input() lowerCase: boolean = false;
  @Input() uppercase: boolean = false;
  @Input() formHasBeenSubmitted: boolean = false;
  @Input() preventSpace: boolean = false;
  @Input() customClass: string = '';
  @Input() withIcon: boolean = true;
  @Input() onFilled: boolean = false;
  @Input() maxlength: number;
  @Input() minlength: number;
  @Input() customError: boolean = false;
  @Input() validate: boolean = false;
  @Input() bgLightMobile: boolean = false;
  @Input() bgLightDesktop: boolean = false;
  @Input() large: boolean = false;
  @Input() largeMobile: boolean = false;
  @Input() largeDesktop: boolean = false;
  @Input() optionLabel: string = 'name';
  @Input() optionValue: string = '';
  @Input() parentForm: UntypedFormGroup;
  @Input() loading: any;
  @Input() customString = false;
  @Input() customKey = '';
  @Input() hasVirtualScroll = false;

  @Output() data = new EventEmitter<string>();
  @ViewChild('input') input: ElementRef;
  // Dropdown
  value: any;
  selectedOption: boolean = true;
  dropdown: any;
  helperText: string = '';

  /* THIS CONFIG IS FOR CUSTOM TRANSLATE */
  constructor() {
  }

  ngOnInit(): void {
    if (this.parentForm.controls[this.ctrlName].value !== '') {
      this.onFocus = true;
      this.onFilled = true;
    }
  }

  ngAfterViewInit() {
    this.filledInput();
    this.shouldShowErrors();
    this.checkIsFocus();
  }

  onFocusInput() {
    this.onFocus = true;
    this.onError = false;
  }

  checkIsFocus() {
    if (this.onFocus) {
      this.onError = false;
    }
  }

  onBlurInput() {
    this.onFocus = false;
    this.onError = false;
    this.onFilled = this.value?.length > 0;
  }

  onChange(event) {
    this.filledInput();
    this.selectOption(event);
    this.data.emit(event);
  }

  filledInput() {
    if (this.value?.length > 1 && !this.onFilled) {
      this.onFilled = true;
      this.onError = false;
    }
  }

  shouldShowErrors(): boolean {
    if (this.ctrl != null) {
      if (this.ctrl.invalid && this.formHasBeenSubmitted) {
        this.onError = true;
      }
    } else {
      this.onError = this.customError;
    }
    return this.onError;
  }

  listOfErrors(): string[] {
    if (this.customString) {
      if (this.customKey) {
        return JSONStrings[this.page][this.customKey];
      } else {
        // console.error('This component needs customKey when custom is TRUE');
      }
    } else if (this.ctrl != null) {
      return Object.keys(this.ctrl.errors).map((err: string) => {
        return JSONStrings[this.page][err];
      });
    }
  }

  selectOption(event) {
    this.onFilled = true;
    this.onFocus = false;
  }
}
