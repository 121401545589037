import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Sector} from '../models/events/Sector';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SectorService {

  constructor(
    private http: HttpClient
  ) {
  }

  getSectors(storeId: number): Observable<Array<Sector>> {
    return this.http.get<Array<Sector>>(`${environment.billboardAGUrl}/cartelera/sectors/${storeId}`);
  }
}
