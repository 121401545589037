import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { InputValidationService } from 'src/app/services/input-validation.service';

@Component({
  selector: 'app-signup-finish-step-modal',
  templateUrl: './signup-finish-step-modal.component.html',
  styleUrls: ['./signup-finish-step-modal.component.scss']
})
export class SignupFinishStepModalComponent implements OnInit {

  @Input() parentForm: UntypedFormGroup;
  @Input() showModal = false;
  @Output('acceptTerms') acceptTerms = new EventEmitter<boolean>();
  formHasBeenSubmitted = false;

  constructor(private validateInputService: InputValidationService) { }

  ngOnInit(): void {
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }

  backToMyData() {
    this.acceptTerms.emit(false);
  }

  finishSignUp(){
    this.acceptTerms.emit(true);
  }


}
