import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {CodeType} from '../enum/CodeType';
import {TicketTypeResponseApp} from '../models/TicketTypeResponseApp';

@Injectable({
  providedIn: 'root'
})
export class CodeService {

  constructor(
    private httpC: HttpClient
  ) {
  }

  public getCodeType(code: string): Observable<CodeType> {
    return this.httpC.get<CodeType>(environment.billboardAGUrl + `/cartelera/app/codes/code-type/${code}`);
  }

  public validateBuyCode(code: string, idTicket: number): Observable<TicketTypeResponseApp> {
    if (idTicket) {
      return this.httpC.get<TicketTypeResponseApp>(environment.billboardAGUrl + `/cartelera/app/codes/validate-buy-code/${code}?origin=BILLBOARD_WEB&idTicket=${idTicket}`);
    } else {
      return this.httpC.get<TicketTypeResponseApp>(environment.billboardAGUrl + `/cartelera/app/codes/validate-buy-code/${code}?origin=BILLBOARD_WEB`);
    }
  }
}
