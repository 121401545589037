export class SDKOptionsMenta {
  language: string;
  returnUrl: string;
  loginTrust: LoginTrust;
}

export class LoginTrust {
  user: string;
  withMethod: 'email' | 'phone';
}
