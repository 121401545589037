import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {TrxProviderIdRequest} from '../models/TrxProviderIdRequest';
import {Observable} from 'rxjs';
import {TrxStateResponse} from '../models/TrxStateResponse';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {


  constructor(private httpC: HttpClient) {
  }

  getPaymentStatus(providerTrxId: string, provider: string, commerceId: string): Observable<TrxStateResponse> {
    return this.httpC.get<TrxStateResponse>(environment.billboardAGUrl + `/cartelera/trx/getTrxSpecificState/${providerTrxId}/${provider}/${commerceId}`);
  }

  completePurchase(payload: TrxProviderIdRequest, customerId) {
    return this.httpC.patch<any>(environment.billboardAGUrl + `/cartelera/trx/completePurchase/${customerId}`, payload);
  }
}