import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {EventShortForCategorizedList} from '../../../../models/events/EventShortForCategorizedList';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  @Input() mainEvents: EventShortForCategorizedList[];
  @Input() skeletonLoading;
  environment = environment;
  coverImage: string = null;
  event: EventShortForCategorizedList;
  mediaUrl: string;
  currency = '$';

  constructor(
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.mediaUrl = environment.mediaUrl;
    this.event = this.mainEvents[0];
    this.currency = this.event.storeDataOnEvent.currency;
  }

  goToDetailEvent(event: EventShortForCategorizedList) {
    if (event.hasAuthorizedAccess) {
      this.router.navigate(['/my-events/event-detail/' + event.idEvent]);
    } else {
      this.router.navigate(['detail-event/' + event.idEvent]);
    }
  }

}
