<main>
  <app-banner data-aos="fade-in" [mainEvents]="mainEvents" *ngIf="mainEvents && mainEvents.length"></app-banner>

  <section class="billboard" *ngIf="typePage === 'billboard'">
    <div class="wrapper">
      <div class="carrousel-events" data-aos="fade-in"
           [ngClass]="{'only-carrousel': mainEvents && mainEvents.length === 0}">
        <app-carrousel-events [allCategoriesEvents]="allCategoriesEvents" [loading]="loading"></app-carrousel-events>
      </div>
      <div class="grid-events" data-aos="fade-in">
        <app-grid-events [allCategoriesEvents]="allCategoriesEvents" [loading]="loading"></app-grid-events>
      </div>
    </div>
  </section>
</main>

<app-modal-account-unverified-reminder 
  *ngIf="showUnverifiedAccountReminderModal"
  (action)="onActionUnverifiedModalReminder()"
  (cancelAction)="showUnverifiedAccountReminderModal = false"
></app-modal-account-unverified-reminder>

<app-modal-must-signin
  [showModal]="showModalActionLogin"
  (closeModal)="closeModalLogin()"
  (actionModal)="onActionToLogin()"
></app-modal-must-signin>
