import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Promotion} from '../models/Promotion';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  constructor(
    private httpC: HttpClient
  ) {
  }

  getViaCode(storeId: number, code: string, buyType: string, eventId?: number): Observable<Promotion> {
    if (eventId) {
      return this.httpC.get<Promotion>(`${environment.apiAGUrl}/promotion/${storeId}/code/${code}/type/${buyType}?eventId=${eventId}`);
    } else {
      return this.httpC.get<Promotion>(`${environment.apiAGUrl}/promotion/${storeId}/code/${code}/type/${buyType}`);
    }
  }

  getPromotionListByStoreId(storeId: number, eventId: number): Observable<Promotion[]> {
    return this.httpC.get<Promotion[]>(`${environment.apiAGUrl}/promotion/${storeId}?eventId=${eventId}`);
  }

  getPromotionByCodeEventAndStoreId(code: string, storeId: number, eventId: number): Observable<Promotion> {
    return this.httpC.get<Promotion>(`${environment.apiAGUrl}/promotion/${storeId}/code/${code}?eventId=${eventId}`);
  }
}
