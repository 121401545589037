import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {InputValidationService} from 'src/app/services/input-validation.service';

@Component({
  selector: 'bid-input-password',
  templateUrl: './bid-input-password.component.html',
  styleUrls: ['./bid-input-password.component.scss']
})
export class BidInputPasswordComponent implements OnInit, OnChanges {

  @Input() label: string = $localize`Campo`;
  @Input() placeholder: string = $localize`Escriba aquí`;
  @Input() required: boolean = false;
  @Input() onFocus: boolean = false;
  @Input() onError: boolean = false;
  @Input() readonly: boolean = false;
  @Input() bgLightMobile: boolean = false;
  @Input() bgLightDesktop: boolean = false;
  @Input() large: boolean = false;
  @Input() largeMobile: boolean = false;
  @Input() largeDesktop: boolean = false;
  @Input() hint: boolean = true;
  @Input() helperText: string = '';
  @Input() customClass: string = '';
  @Input() maxlength: number = 320;
  @Input() minlength: number = 1;
  @Input() formHasBeenSubmitted;
  @Input() page = 'generic';
  @Input() withIcon: boolean = true;
  @Input() showLabelRequirement = false;
  @Input() showCurrentPasswordError = false;
  @Input() passwordMatch = true;
  @Output() data = new EventEmitter<string>();
  onFilled: boolean = false;
  hintText: string = '';
  @Input() controlName: string;
  hashPassword: boolean = true;
  @Input() customError: boolean = false;
  /* THIS CONFIG IS FOR CUSTOM TRANSLATE */
  @Input() customString = false;
  @Input() customKey = '';
  @Input() parentForm: FormGroup;
  listOfErrors: Array<string> = [];

  constructor(
    private inputValidationService: InputValidationService
  ) {
  }

  get controlField(): AbstractControl {
    return this.parentForm.get(this.controlName);
  }

  ngOnInit(): void {
    this.parentForm.controls[this.controlName].valueChanges.subscribe({
      next: () => {
        this.shouldShowErrors();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formHasBeenSubmitted && changes.formHasBeenSubmitted.currentValue) {
      this.shouldShowErrors();
    }
  }

  showShowRequirement() {
    return this.onFocus || (this.listOfErrors.length === 0 && this.passwordMatch);
  }

  onFocusInput() {
    this.onFocus = true;
  }

  onBlurInput() {
    this.onFocus = false;
    this.onFilled = this.controlField.value?.length !== 0;
  }

  onChange(event) {
    if (this.controlField.value?.length > 0 && !this.onFilled) {
      this.onFilled = true;
    }
  }

  shouldShowErrors(): string[] {
    this.listOfErrors = this.inputValidationService.shouldShowErrors(
      this.parentForm.controls[this.controlName],
      this.formHasBeenSubmitted, this.page
    );
    return this.listOfErrors;
  }
}
