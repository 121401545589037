import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DeletePayload} from 'src/app/models/DeletePayload';
import {ModalActionType} from 'src/app/models/ModalActionType';
import {AuthService} from 'src/app/services/auth.service';
import {ToastService} from 'src/app/services/toast.service';
import {UserService} from 'src/app/services/user.service';

@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.scss']
})
export class ConfigurationsComponent implements OnInit {

  showModalDeleteAccount = false;

  MODALACTIONTYPE = ModalActionType;

  configModalDeleteAccount = {
    title: $localize`¿Deseas eliminar tu cuenta?`,
    content: $localize`Al eliminar tu cuenta, se eliminarán tus datos y ya no podás acceder a ella.`,
    textBtn: $localize`No eliminar`,
    textSecondBtn: $localize`Eliminar mi cuenta`
  };

  constructor(
    private router: Router,
    private userService: UserService,
    private toastService: ToastService,
    private authSrv: AuthService
  ) {
  }

  ngOnInit() {
  }

  changePassword() {
    this.router.navigate(['/configurations/change-password']);
  }

  deleteAccount() {
    this.showModalDeleteAccount = true;
  }

  onCloseDeleteModal(e) {
    this.showModalDeleteAccount = false;
  }

  onActionDeleteModal(e) {
    this.showModalDeleteAccount = false;
  }

  onActionSecBtnDeleteModal(e) {
    const payload: DeletePayload = {
      reasonDelete: '',
      contentDelete: ''
    };

    this.userService.deleteAccount(payload).subscribe({
      next: async () => {
        this.showModalDeleteAccount = false;
        await this.authSrv.cleanLocalStorageAndLogInGuest();
        this.toastService.generateToast('success', $localize`Cuenta eliminada exitosamente`, '');
        await this.router.navigate(['/']);
      }, error: () => {
        this.toastService.generateToast('error', $localize`Ha ocurrido un error.`, $localize`Intentalo nuevamente más tarde`);
        this.showModalDeleteAccount = false;
      }
    });
  }

}
