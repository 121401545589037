import * as AOS from 'aos';
import {Component, Input, OnInit} from '@angular/core';
import {SwiperOptions} from 'swiper';
import {EventsCategorized} from '../../../../models/events/EventsCategorized';

@Component({
  selector: 'app-carrousel-events',
  templateUrl: './carrousel-events.component.html',
  styleUrls: ['./carrousel-events.component.scss']
})
export class CarrouselEventsComponent implements OnInit {
  @Input() allCategoriesEvents: EventsCategorized[];
  @Input() loading: boolean;
  config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 16,
    navigation: false,
    initialSlide: 0,
    centeredSlides: false,
    pagination: {clickable: true},
    speed: 500,
    shortSwipes: true,
    preloadImages: true,
    scrollbar: {draggable: true},
    loop: false,
    allowSlideNext: true,
    allowTouchMove: true,
  };
  configSkeleton: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 16,
    navigation: false,
    initialSlide: 0,
    centeredSlides: false,
    pagination: false,
    speed: 500,
    shortSwipes: false,
    preloadImages: true,
    scrollbar: false,
    loop: false,
    allowSlideNext: false,
    allowTouchMove: false,
  };

  constructor() {
  }

  ngOnInit(): void {
    this.config = {
      slidesPerView: 'auto',
      spaceBetween: 16,
      navigation: false,
      initialSlide: 0,
      centeredSlides: false,
      pagination: false,
      speed: 500,
      shortSwipes: true,
      preloadImages: true,
      scrollbar: {draggable: true},
      loop: false,
      allowSlideNext: true,
      allowTouchMove: true,
    };
    AOS.init({
      duration: 800,
      once: true
    });
  }

  onSwiper(event) {
  }

  onSlideChange() {
  }
}
