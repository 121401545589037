<fieldset
  class="fieldset-input"
  [formGroup]="parentForm"
  [ngClass]="{
    'has-error': shouldShowErrors()}"
>
    <ng-select
        class="custom"
        [items]="itemsBuffer"
        [virtualScroll]="true"
        [bindLabel]="bindLabel"
        [placeholder]="placeholderText"
        [formControlName]="formControlName"
        [bindValue]="bindValue"
        [notFoundText]="notFoundText"
        (scroll)="onScroll($event)"
        (scrollToEnd)="onScrollToEnd()"
        (open)="onOpen($event)"
        (close)="onClose($event)"
        (change)="onDropdownChange($event)">
    </ng-select>
    <p class="field__item--error" *ngIf="hint && shouldShowErrors()">
        <span *ngFor="let error of listOfErrors()">{{error}}</span>
      </p>
</fieldset>