<fieldset
  class="fieldset-input {{customClass}}"
  [formGroup]="parentForm"
  [ngClass]="{
    'focus': onFocus,
    'filled': onFilled,
    'error': (listOfErrors.length > 0 || showCurrentPasswordError || !passwordMatch) && formHasBeenSubmitted,
    'helper': helperText.length > 1,
    'disabled': readonly,
    'bg-light-in-mobile': bgLightMobile,
    'bg-light-in-desktop': bgLightDesktop,
    'size-large-mobile': largeMobile,
    'size-large-desktop': largeDesktop,
    'size-large': large
  }"
>
  <div class="field__input">
    <label class="label" for="input">{{label}}</label>
    <input
      autocomplete="off"
      class="input"
      [maxLength]="maxlength"
      [minLength]="minlength"
      [required]="required"
      (focus)="onFocusInput()"
      (blur)="onBlurInput()"
      (keyup)="onChange($event)"
      formControlName="{{ controlName }}"
      [type]="hashPassword ? 'password' : 'text'"
      (keydown.space)="$event.preventDefault()"
    />
    <div class="show-password">
      <i class="fa-light fa-eye-slash" *ngIf="hashPassword" (click)="hashPassword = false"></i>
      <i class="fa-light fa-eye" *ngIf="!hashPassword" (click)="hashPassword = true"></i>
    </div>
  </div>

  <p class="hint" *ngIf="showCurrentPasswordError" i18n>
    La contraseña no cumple con las condiciones.
  </p>

  <p class="hint error" *ngIf="formHasBeenSubmitted && !passwordMatch && controlField.value.length !== 0 else formError" i18n>
    Las contraseñas no coinciden
  </p>
  <ng-template #formError>
    <p class="hint" *ngIf="hint && listOfErrors.length > 0">
      <span>{{ listOfErrors[0] }}</span>
    </p>
  </ng-template>

  <p class="info" *ngIf="showLabelRequirement">
    <span>
      La contraseña debe contener:
      <ul class="password-requirements">
        <li>Entre 8 y 32 caracteres (sin caracteres especiales)</li>
        <li>Al menos una mayúscula y una minúscula</li>
        <li>Un número</li>
      </ul>
    </span>
  </p>
</fieldset>
