import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-modal-not-selling-web',
  templateUrl: './modal-not-selling-web.component.html',
  styleUrls: ['./modal-not-selling-web.component.scss']
})
export class ModalNotSellingWebComponent implements OnInit {

  @Input() showModal: boolean;
  @Input() title: string = '';
  @Input() content: string = '';
  @Input() imageName: string = '';
  @Input() iconClass: string = '';
  @Input() showHeader: boolean = false;
  @Input() twoBtns: boolean = true;
  @Output() closeButtonEvent = new EventEmitter();
  @Output() action = new EventEmitter();

  constructor(
    private router: Router
  ) {
  }

  ngOnInit(): void {
    if (!this.imageName && !this.iconClass) {
      this.imageName = 'info.svg';
    }
  }

  closeModal() {
    this.showModal = false;
    this.closeButtonEvent.emit(this.showModal);
  }

  downloadApp() {
    window.open(environment.downloadLink, '_blank');
  }

}
