<main class="wrapper">
  <div class="ups">
    <img src="../../../assets/images/img-ups.svg" class="img-ups" alt="Not found"/>
    <div class="ups__content">
      <h1 class="title">
        <ng-container i18n>No pudimos encontrar</ng-container>
        <br>
        <ng-container i18n>el evento que buscabas</ng-container>
      </h1>
      <p class="description" i18n>Intentá nuevamente más tarde.</p>
      <a href="/" class="btn btn__primary" i18n>Ir a la Cartelera</a>
    </div>
  </div>
</main>
