import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {ContactRequest} from '../models/ContactRequest';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  openSupportModal = new Subject();

  constructor(private httpC: HttpClient) {
  }

  public sendSupportEmail(contact: ContactRequest) {
    return this.httpC.post<void>(environment.apiAGUrl + `/contact/billboard`, contact);
  }

}
