import {Component, OnInit} from '@angular/core';
import {LegalsFormat} from '../../../models/LegalsFormat';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  termsAndConditions: LegalsFormat;

  constructor() {
  }

  async ngOnInit() {
    const {default: data} = await import(`src/assets/legales/${environment.termsAndConditionsFile}`);
    this.termsAndConditions = data;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  scrollToElement(elementID): void {
    const $element = document.getElementById(elementID);
    $element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

}
