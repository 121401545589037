import {Locale, setLocale, supportedLocales} from '../../../../locale/i18n';
import {NavigationEnd, Router} from '@angular/router';
import {AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';

import {AuthService} from 'src/app/services/auth.service';
import {EventForCustomer} from 'src/app/models/events/EventForCustomer';
import {EventService} from 'src/app/services/event.service';
import {EventsCategorized} from 'src/app/models/events/EventsCategorized';
import {User} from 'src/app/models/user';
import {environment} from 'src/environments/environment';
import {SpinnerService} from 'src/app/services/spinner.service';
import {EventInfoForLanding} from '../../../models/EventDataForLanding';
import {EnvironmentService} from 'src/app/services/environment.service';
import {BreakpointObserver} from '@angular/cdk/layout';
import {LanguageResponse} from '../../../models/Language';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit {
  @ViewChild('dropdown') dropdown;
  @ViewChild('searchBar') searchBar;
  @ViewChild('btnProfile') btnProfile;
  @Output() emitSearch = new EventEmitter<string>();
  @Output() modalMyQr = new EventEmitter<boolean>();
  @Output() displayMainMenu = new EventEmitter<boolean>();
  @Output() modalInvitationCode = new EventEmitter<boolean>();
  @Output() openSupportModal = new EventEmitter<boolean>();
  url: string;
  logged = false;
  viewMenu = false;
  languageMenu = false;
  userData: User;
  environment = environment;
  mediaUrl: string;
  showSignInBtn = true;
  currentUrl: string;
  textSearch = '';
  search: boolean;
  originalAllCategoriesEvents: Array<EventsCategorized> = [];
  allCategoriesEvents: Array<EventsCategorized> = [];
  event: EventForCustomer;
  loading: boolean;
  guestLogged: boolean;
  showSearchBar = true;
  resultSearch: EventInfoForLanding[] = [];
  eventIds: number[] = [];
  searching = false;
  dateFormat: string = $localize`dd MMM`;
  locales: Locale[] = supportedLocales;
  showButtonEventCode = false;
  showButtonMyQR = false;
  logoName = '';
  click = 0;
  showingQr = false;
  showNav = true;

  constructor(
    private router: Router,
    private authService: AuthService,
    private eventSrv: EventService,
    private spinnerService: SpinnerService,
    private environmentService: EnvironmentService,
    private breakpointObserver: BreakpointObserver,
    private navbarService: NavbarService
  ) {
    this.authService.checkLogged.subscribe({
      next: (state: boolean) => {
        this.guestLogged = this.authService.isGuestLoggedIn();
        if (!state) {
          this.logged = false;
          this.showSignInBtn = true;
          this.showButtonEventCode = true;
          this.showButtonMyQR = true;
        } else {
          this.logged = true;
        }
      }
    });

    this.navbarService.showNavbar.subscribe((res: boolean) => {
      this.showNav = res;
    });
  }

  async ngOnInit(): Promise<void> {
    this.viewMenu = false;
    this.logoName = this.environmentService.getBrandLogo();
    this.url = this.environmentService.getBrandUrlLink();
    document.addEventListener('click', this.closeMenus.bind(this));
    this.router.events.subscribe({
      next: (data) => this.onUrlChange(data)
    });
    this.mediaUrl = environment.mediaUrl;
    this.logged = !!JSON.parse(localStorage.getItem('isLoggedIn'));
    this.userData = JSON.parse(localStorage.getItem('user'));
    this.guestLogged = this.authService.isGuestLoggedIn();
    if (this.logged && !this.guestLogged) {
      this.showButtonEventCode = true;
      this.showButtonMyQR = true;
      this.getEventIds();
    }
  }

  ngAfterViewInit(): void {
    this.reloadUserData();
  }

  onUrlChange(data): void {
    this.logged = JSON.parse(localStorage.getItem('isLoggedIn'));
    if (data instanceof NavigationEnd) {
      this.currentUrl = data.url;
      if (this.currentUrl === '/login') {
        this.showSignInBtn = false;
      }
      if (this.currentUrl !== '/login' && this.logged) {
        this.reloadUserData();
      }
      if (this.blackListForSearchbar(this.currentUrl)) {
        this.showSearchBar = false;
      }
      this.showButtonEventCode = !this.blackListForEventCode(this.currentUrl);
      this.showButtonMyQR = !this.logged || !this.blackListForMyQRButton(this.currentUrl);
    }
  }

  openModalEventCode() {
    if (this.logged) {
      this.modalInvitationCode.emit(true);
      document.body.style.position = 'fixed';
    }
  }

  openModalMyQr() {
    if (this.logged) {
      this.modalMyQr.emit(true);
      this.showingQr = true;
      document.body.style.position = 'fixed';
    }
  }

  blackListForSearchbar(url: string) {
    if (url.includes('/buy-ticket')
      || url.includes('/checkout-ticket')
      || url.includes('/success-purchase')
      || url.includes('/data-required')
      || url.includes('/history')
      || url.includes('/my-events')
      || url.includes('/event-detail')
      || url.includes('/recover-with-dni')
      || url.includes('/support')
      || url.includes('/profile')
      || url.includes('/configurations')) {
      return true;
    }
  }

  blackListForEventCode(url: string) {
    return (url !== '' && url !== '/' && url !== '/my-events' && !url.includes('billboard/0/code'));
  }

  blackListForMyQRButton(url: string) {
    return (url.includes('forgot-password')
      || url.includes('signup')
      || url.includes('complete-first-login')
      || url.includes('login')
      || url.includes('confirm-registration')
      || url.includes('data-required')
    );
  }

  closeMenus(event: any): void {
    if (this.isLogin()) {
      if (!this.searchBar.nativeElement.contains(event.target)) { // check click origin
        if (this.dropdown) {
          this.dropdown.nativeElement.style.display = 'none';
        }
      }
      if (this.viewMenu && !this.btnProfile?.nativeElement.contains(event.target)) {
        this.viewMenu = false;
        document.body.style.position = '';
        this.displayMainMenu.emit(false);
      }
    }
  }

  openResults(): void {
    if ((this.textSearch !== '' && this.textSearch.length > 2)
      && this.resultSearch.length > 0 && this.dropdown.nativeElement.style.display === 'none') {
      this.search = true;
      this.dropdown.nativeElement.style.display = 'initial';
    }
  }

  reloadUserData(): void {
    this.logged = JSON.parse(localStorage.getItem('isLoggedIn'));
    if (this.logged) {
      this.userData = JSON.parse(localStorage.getItem('user'));
    }
  }

  async login() {
    sessionStorage.setItem('lastURLBeforeLogin', this.router.url);
    await this.router.navigate(['/login']);
  }

  openMenu(): void {
    this.viewMenu = !this.viewMenu;
    const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 768px)');
    if (isSmallScreen) {
      document.body.style.position = this.viewMenu ? 'fixed' : '';
    }
    this.displayMainMenu.emit(this.viewMenu);
  }

  openLanguageMenu(): void {
    this.languageMenu = !this.languageMenu;
  }

  contactSupport() {
    this.openSupportModal.emit(true);
  }

  searchEvents(search) {
    if (search !== null && search !== '') {
      if (this.textSearch) {
        this.searching = true;
        this.search = true;
        this.resultSearch = [];
        if (this.textSearch.length > 2) {
          setTimeout(() => {
            this.eventSrv.getEventsBySearch(this.textSearch).subscribe({
              next: (evs: EventInfoForLanding[]) => {
                this.resultSearch = this.checkCustomerIsOnBillboardEvent(evs, this.eventIds);
                this.searching = false;
              }
            });
          }, 100);
        } else {
          this.resultSearch = [];
          this.searching = false;
        }
      } else {
        this.search = false;
        this.searching = false;
      }
    } else {
      this.search = false;
      this.searching = false;
      this.resultSearch = [];
      this.textSearch = '';
    }
  }

  getEventIds() {
    this.eventSrv.getEventsIdsByCustomer().subscribe({
      next: (ids: number []) => {
        this.eventIds = ids;
      }
    });
  }

  checkCustomerIsOnBillboardEvent(
    eventsInfo: Array<EventInfoForLanding>,
    eventIds: Array<number>
  ): Array<EventInfoForLanding> {
    eventsInfo.forEach((event: EventInfoForLanding) => {
      const eventExist = eventIds.filter(id => id === event.eventId);
      if (eventExist.length > 0) {
        event.hasAuthorizedAccess = true;
      }
    });
    return eventsInfo;
  }

  eventDetail(event: EventInfoForLanding) {
    if (event.hasAuthorizedAccess) {
      this.router.navigate(['/my-events/event-detail/' + event.eventId]).then(() => {
        window.location.reload();
      });
      this.search = false;
      this.textSearch = '';
    } else {
      this.router.navigate(['/detail-event/' + event.eventId]).then(() => {
        window.location.reload();
      });
      this.search = false;
      this.textSearch = '';
    }
  }

  isLogin() {
    return !(this.router.url === '/login' || this.router.url === '/signup' || this.router.url === '/forgot-password');
  }

  changeLocale(locale: Locale) {
    if (localStorage.getItem('user')) {
      this.spinnerService.loadSpinner.next(true);
      this.authService.setLanguage(locale.name).subscribe({
        next: (res: LanguageResponse) => {
          this.authService.getCurrentUserData().subscribe({
            next: (user: User) => {
              user.language = res.language;
              this.authService.updateUser(user);
              setLocale(res.language);
              this.spinnerService.loadSpinner.next(false);
            }
          });
        },
        error: () => {
          this.spinnerService.loadSpinner.next(false);
        }
      });
    } else {
      setLocale(locale.name);
    }
  }
}
