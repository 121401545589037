import {Observable, Subject} from 'rxjs';

import {EventDetailForCustomer} from '../models/events/EventDetailForCustomer';
import {EventForCustomer} from '../models/events/EventForCustomer';
import {EventsCategorized} from '../models/events/EventsCategorized';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {EventStatusResponse} from '../models/events/EventStatusResponse';
import {EventInfoForLanding} from '../models/EventDataForLanding';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  showModalAddGuest = new Subject();
  showModalGuests = new Subject();
  showModalEventCode = new Subject();
  showModalDelegateOwnershipEventMobile = new Subject();

  constructor(
    private http: HttpClient
  ) {
  }

  public getEventsByCustomer(): Observable<EventForCustomer[]> {
    const {id_customer} = JSON.parse(localStorage.getItem('user'));
    return this.http.get<EventForCustomer[]>(
      `${environment.billboardAGUrl}/cartelera/web/events/pending/${id_customer}`
    );
  }

  public getHistoricalEvents(): Observable<EventForCustomer[]> {
    const {id_customer} = JSON.parse(localStorage.getItem('user'));
    return this.http.get<EventForCustomer[]>(
      `${environment.billboardAGUrl}/cartelera/web/events/history/${id_customer}`
    );
  }

  public getEventsIdsByCustomer(): Observable<number[]> {
    const {id_customer} = JSON.parse(localStorage.getItem('user'));
    return this.http.get<number[]>(
      `${environment.billboardAGUrl}/cartelera/web/events/ids/by_customer/${id_customer}`
    );
  }

  public getEventById(eventId): Observable<EventForCustomer> {
    return this.http.get<EventForCustomer>(
      `${environment.billboardAGUrl}/cartelera/web/events/by_id/${eventId}`
    );
  }

  public getEventData(eventId): Observable<EventDetailForCustomer> {
    const {id_customer} = JSON.parse(localStorage.getItem('user'));
    return this.http.get<EventDetailForCustomer>(
      `${environment.billboardAGUrl}/cartelera/web/events/details/${id_customer}/event/${eventId}`
    );
  }

  getEventDataPaginated(eventId: number, pageNumber: number, size: number): Observable<EventDetailForCustomer> {
    const {id_customer} = JSON.parse(localStorage.getItem('user'));

    return this.http.get<EventDetailForCustomer>(
      `${environment.billboardAGUrl}/cartelera/web/events/details_new/${id_customer}/event/${eventId}?pageNumber=${pageNumber}&size=${size}`
    );
  }

  getAllEventsCategorizedByTenant(): Observable<EventsCategorized[]> {
    return this.http.get<EventsCategorized[]>(
      `${environment.billboardAGUrl}/cartelera/web/events/categorized`
    );
  }

  public getStatusEventById(eventId: number): Observable<EventStatusResponse> {
    return this.http.get<EventStatusResponse>(
      `${environment.billboardAGUrl}/cartelera/web/events/status/by_id/${eventId}`
    );
  }

  public getStatusEventOnBuyById(eventId: number): Observable<EventStatusResponse> {
    return this.http.get<EventStatusResponse>(
      `${environment.billboardAGUrl}/cartelera/web/events/status-on-buy/by_id/${eventId}`
    );
  }

  public getEventsBySearch(search: string): Observable<EventInfoForLanding[]> {
    return this.http.get<EventInfoForLanding[]>(
      `${environment.billboardAGUrl}/cartelera/web/events/search-on-web?search=${search}`
    );
  }
}
