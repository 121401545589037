<div class="card skeleton" *ngIf="skeletonLoading" xmlns="http://www.w3.org/1999/html">
  <div class="wrapper">
    <div class="card__head">
      <div class="ticket__name">
        <div class="skeleton__title"></div>
      </div>
    </div>

    <div class="card__body">
      <div class="description__container">
        <div class="skeleton__description--large mb-2"></div>
        <div class="skeleton__description--medium mb-2"></div>
        <div class="skeleton__description--large"></div>
      </div>
    </div>
  </div>
</div>

<div class="card" *ngIf="!skeletonLoading && ticket && typeBuy === 'A'"
     [ngClass]="{'active': isTicketSelected(ticket), 'disabled_card': !isTicketAvailable(ticket)}">
  <div class="wrapper">
    <div class="card__head" (click)="clickCardOnTicket(ticket, true)">
      <div class="ticket__name margin-right-16">
        <p
          class="font-secondary lh-24 text-lg my-0 font-semibold"
          [ngClass]="{
            'text-primary' : isTicketSelected(ticket),
            'text-gray-1': !isTicketSelected(ticket),
            'text-gray-4' : !isTicketAvailable(ticket)
          }"
        >{{ ticket.name }}</p>
      </div>
      <div class="ticket__select" *ngIf="isTicketAvailable(ticket)">
        <p class="text-lg lh-24 my-0 font-secondary text-gray-3" *ngIf="!ticket.free"
           [ngClass]="{'font-semibold' : isTicketSelected(ticket), 'font-normal': !isTicketSelected(ticket)}">
          {{currency}}{{ ticket.price | number:'1.0-2' }}
        </p>
        <p class="text-lg lh-24 my-0 font-secondary text-gray-3" *ngIf="ticket.free"
           [ngClass]="{'font-semibold' : isTicketSelected(ticket), 'font-normal': !isTicketSelected(ticket)}" i18n>
          Free Pass
        </p>
        <i class="fa-light fa-circle check__inactive" *ngIf="!isTicketSelected(ticket)"></i>
        <i class="fa-solid fa-circle-check check__active" *ngIf="isTicketSelected(ticket)"></i>
      </div>
      <div class="ticket__soldout" *ngIf="!isTicketAvailable(ticket)">
        <div>
          <span class="badge__soldout" i18n>SOLD OUT</span>
        </div>
      </div>
    </div>
    <div class="card__body">
      <div class="description__container">
        <div class="row__description" *ngFor="let description of ticket.descriptions">
          <i class="fa-light fa-circle-check check-benefit" [ngClass]="
          !isTicketAvailable(ticket) ? 'text-gray-4' : 'text-primary'"></i>
          <p class="text-sm font-normal lh-24 my-0" [ngClass]="
            !isTicketAvailable(ticket) ? 'text-gray-4' : 'text-gray-3'">
            {{ description.value }}</p>
        </div>
      </div>
      <div class="count__people" *ngIf="isTicketSelected(ticket) && !ticket.free">
        <div class="counter">
          <div class="counter__info">
            <p class="text-sm lh-16 text-gray-1 my-0 font-medium font-secondary" i18n>Cant. de Personas</p>
            <p class="text-xs lh-16 text-primary mt-2 mb-0 font-normal">{{ getMaxQuantityToBuy() }}
              <span i18n>restantes</span>
            </p>
          </div>
          <div class="counter__button">
            <button class="btn__counter" (click)="decrementQuantity()">
              <i class="fa-light fa-minus"></i>
            </button>
            <p class="text-lg lh-16 text-primary font-medium my-0">{{ counterTicket }}</p>
            <button class="btn__counter" (click)="incrementQuantity()">
              <i class="fa-light fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="separator__mobile" *ngIf="isTicketSelected(ticket) && !ticket.free">
        <img src="../../../../../../../assets/images/separator.svg" class="separator" alt="separator"/>
      </div>
      <div class="count__people--mobile" *ngIf="isTicketSelected(ticket) && !ticket.free">
        <div class="counter__info">
          <p class="text-xs lh-16 text-primary mt-2 mb-0 font-normal">{{getMaxQuantityToBuy()}}
            <span i18n>lugares disponibles</span>
          </p>
          <p class="text-base lh-16 text-gray-1 my-0 font-medium font-secondary" i18n>
            Cant. de Personas
          </p>
        </div>
        <div class="counter__button mobile">
          <button class="btn__counter" (click)="decrementQuantity()">
            <i class="fa-light fa-minus"></i>
          </button>
          <p class="text-lg lh-16 text-primary font-medium my-0">{{ counterTicket }}</p>
          <button class="btn__counter" (click)="incrementQuantity()">
            <i class="fa-light fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="card__overlay" *ngIf="ticket.needBuyCode && showOverlayCode && isTicketSelected(ticket)">
    <div class="card__overlay-header">
      <i class="fa-light fa-lock-alt text-gray-1"></i>
      <p class="lh-24 text-lg font-secondary text-gray-1 my-0 ml-2" i18n>Código de compra</p>
    </div>
    <div>
      <p class="lh-16 text-sm text-gray-35 my-0 mt-2 mb-0" i18n>
        Para adquirir este ticket debes ingresar el código de compra.
      </p>
    </div>
    <div class="card__overlay-body">
      <div class="promotional__container">
        <form [formGroup]="codeForm" class="flex w-full">
          <div class="input__promotional">
            <input type="text" pInputText class="input" id="code" placeholder="Código de compra"
                   formControlName="buyCode"
                   (keydown.space)="$event.preventDefault()"
                   i18n-placeholder="Purchase code placeholder" maxLength="8"/>
            <div class="input__state">
              <i class="fa-solid fa-circle-check text-success"
                 *ngIf="!validatingCode && validationCodeResult === ValidationCodeResult.valid"></i>
              <i class="fa-solid fa-triangle-exclamation text-error"
                 *ngIf="!validatingCode &&  validationCodeResult === ValidationCodeResult.invalid"></i>
              <i class="fa-solid fa-spinner-third spinner" *ngIf="validatingCode"></i>
            </div>
          </div>
          <div class="input__feedback--desktop align-items-center">
            <p class="feedback" *ngIf="!validatingCode &&  validationCodeResult === ValidationCodeResult.invalid &&
            !codeForm?.get('buyCode').hasError('cannotContainSpace')" i18n>
              El código no es válido.
            </p>
            <p class="feedback align-items-center" *ngIf="codeForm?.get('buyCode').hasError('cannotContainSpace')"
               i18n="Buy code contain space">
              El código no puede contener espacios.</p>
          </div>

          <div class="input__btn">
            <div class="input__feedback--mobile">
              <p class="feedback" *ngIf="!validatingCode &&  validationCodeResult === ValidationCodeResult.invalid &&
                  !codeForm?.get('buyCode').hasError('cannotContainSpace')" i18n>
                El código no es válido.
              </p>
              <p class="feedback" *ngIf="codeForm?.get('buyCode').hasError('cannotContainSpace')" i18n>
                El código no puede contener espacios.
              </p>
            </div>
            <button class="btn btn__md btn__code btn__secondary desktop" (click)="validateBuyCode()"
                    [disabled]="codeForm.invalid || validatingCode || validationCodeResult === ValidationCodeResult.valid"
                    i18n>
              Validar código
            </button>
            <button class="btn btn__md btn__code btn__secondary mobile" (click)="validateBuyCode()"
                    [disabled]="codeForm.invalid || validatingCode"
                    *ngIf="validationCodeResult === ValidationCodeResult.none &&
                  !codeForm?.get('buyCode').hasError('cannotContainSpace')" i18n>
              Validar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="card" *ngIf="!skeletonLoading && table && typeBuy === 'T'"
     [ngClass]="{'active': isTableSelected(table), 'disabled_card': !isTableAvailable(table)}">
  <div class="wrapper">
    <div class="card__head" (click)="clickCardOnTable(table, true)">
      <div class="ticket__name margin-right-16">
        <p
          class="font-secondary lh-24 text-lg my-0 font-semibold"
          [ngClass]="{'text-primary' : isTableSelected(table), 'text-gray-1': !isTableSelected(table), 'text-gray-4' : !isTableAvailable(table)}"
        >{{ table.name }}</p>
      </div>
      <div class="ticket__select" *ngIf="isTableAvailable(table)">
        <p class="text-lg lh-24 my-0 font-secondary text-gray-3" *ngIf="!table.free"
           [ngClass]="{'font-semibold' : isTableSelected(table), 'font-normal': !isTableSelected(table)}">
          {{currency}} {{ table.price | number:'1.0-2' }}</p>
        <p class="text-lg lh-24 my-0 font-secondary text-gray-3" *ngIf="table.free"
           [ngClass]="{'font-semibold' : isTableSelected(table), 'font-normal': !isTableSelected(table)}" i18n>
          Free Pass
        </p>
        <i class="fa-light fa-circle check__inactive" *ngIf="!isTableSelected(table)"></i>
        <i class="fa-solid fa-circle-check check__active" *ngIf="isTableSelected(table)"></i>
      </div>
      <div class="ticket__soldout" *ngIf="!isTableAvailable(table)">
        <div>
          <span class="badge__soldout" i18n>SOLD OUT</span>
        </div>
      </div>
    </div>
    <div class="card__body">
      <div class="description__container">
        <div class="row__description" *ngIf="table.consumerCredit > 0 && table.consumerCredit != null">
          <i class="fa-light fa-circle-dollar-to-slot check-benefit" [ngClass]="
          !isTableAvailable(table) ? 'text-gray-4' : 'text-primary'"></i>
          <p class="text-sm font-normal lh-24 my-0" [ngClass]="
            !isTableAvailable(table) ? 'text-gray-4' : 'text-gray-3'">
            <ng-container i18n>Crédito digital:</ng-container>
            {{currency}}{{ table.consumerCredit | number:'1.0-2' }}</p>
        </div>
        <div class="row__description" *ngIf="table.maxCapacity">
          <i class="fa-light fa-people-pants-simple check-benefit" [ngClass]="
          !isTableAvailable(table) ? 'text-gray-4' : 'text-primary'"></i>
          <p class="text-sm font-normal lh-24 my-0" [ngClass]="
            !isTableAvailable(table) ? 'text-gray-4' : 'text-gray-3'">
            {{ table.maxCapacity }}
            <ng-container i18n>Personas</ng-container>
          </p>
        </div>
        <div class="row__description" *ngFor="let description of table.descriptions">
          <i class="fa-light fa-circle-check check-benefit" [ngClass]="
          !isTableAvailable(table) ? 'text-gray-4' : 'text-primary'"></i>
          <p class="text-sm font-normal lh-24 my-0" [ngClass]="
            !isTableAvailable(table) ? 'text-gray-4' : 'text-gray-3'">
            {{ description.value }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="card__overlay" *ngIf="table.needBuyCode && showOverlayCode && isTableSelected(table)">
    <div class="card__overlay-header">
      <i class="fa-light fa-lock-alt text-gray-1"></i>
      <p class="lh-24 text-lg font-secondary text-gray-1 my-0 ml-2" i18n>Código de compra</p>
    </div>
    <div>
      <p class="lh-16 text-sm text-gray-35 my-0 mt-2 mb-0" i18n>
        Para adquirir este combo o mesa debes ingresar el código de compra.
      </p>
    </div>
    <div class="card__overlay-body">
      <div class="promotional__container">
        <form [formGroup]="codeForm" class="flex w-full">
          <div class="w-full flex align-items-center justify-content-start">
            <div class="input__promotional">
              <div class="input__promotional">
                <input type="text" pInputText class="input" id="code" placeholder="Código de compra"
                       formControlName="buyCode"
                       (keydown.space)="$event.preventDefault()"
                       i18n-placeholder="Purchase code placeholder" maxLength="8"/>
                <div class="input__state">
                  <i class="fa-solid fa-circle-check text-success"
                     *ngIf="!validatingCode && validationCodeResult === ValidationCodeResult.valid"></i>
                  <i class="fa-solid fa-triangle-exclamation text-error"
                     *ngIf="!validatingCode &&  validationCodeResult === ValidationCodeResult.invalid"></i>
                  <i class="fa-solid fa-spinner-third spinner" *ngIf="validatingCode"></i>
                </div>
              </div>
            </div>
            <div class="input__feedback--desktop align-items-center">
              <p class="feedback" *ngIf="!validatingCode &&  validationCodeResult === ValidationCodeResult.invalid &&
            !codeForm?.get('buyCode').hasError('cannotContainSpace')" i18n>
                El código no es válido.
              </p>
              <p class="feedback align-items-center" *ngIf="codeForm?.get('buyCode').hasError('cannotContainSpace')"
                 i18n="Buy code contain space">
                El código no puede contener espacios.</p>
            </div>
          </div>
          <div class="input__btn">
            <div class="input__feedback--mobile">
              <p class="feedback" *ngIf="!validatingCode &&  validationCodeResult === ValidationCodeResult.invalid &&
                  !codeForm?.get('buyCode').hasError('cannotContainSpace')"
                 i18n="Buy code validation feedback for mobile">
                El código no es válido.</p>
              <p class="feedback" *ngIf="codeForm?.get('buyCode').hasError('cannotContainSpace')" i18n>
                El código no puede contener espacios.
              </p>
            </div>
            <button class="btn btn__md btn__code btn__secondary desktop" (click)="validateBuyCode()"
                    [disabled]="codeForm.invalid || validatingCode || validationCodeResult === ValidationCodeResult.valid"
                    i18n="Buy code validation button for desktop">
              Validar código
            </button>
            <button class="btn btn__md btn__code btn__secondary mobile" (click)="validateBuyCode()"
                    [disabled]="codeForm.invalid || validatingCode"
                    *ngIf="validationCodeResult === ValidationCodeResult.none && !codeForm?.get('buyCode').hasError('cannotContainSpace')"
                    i18n="Buy code validation button for mobile">Validar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
