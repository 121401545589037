<p-dialog [(visible)]="showModal" (onHide)="onHide()" [draggable]="false" [resizable]="false" [modal]="true"
          class="bid-modal modal-show-guests">
  <ng-template pTemplate="header" class="bid-modal__header">
    <div class="filling-space"></div>
    <div class="display-flex flex-col justify-content-center align-items-center">
      <p class="bid-modal__header--headtitle" i18n>Invitados</p>
      <div class="bid-modal__header--title" [innerHTML]="headerSubtitle"></div>
      <div class="bid-modal__subheader--title">
        <ng-container i18n>Disponibles</ng-container>
        {{ ' ' + available}}
        <ng-container i18n> de</ng-container>
        {{ ' ' + maxCapacity}}
      </div>
    </div>
  </ng-template>


    <div class="scroll-container"
      infinite-scroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false"
      (scrolled)="onScroll($event)"
      >
      <div class="guest" *ngFor="let guest of guests" [ngClass]="isLastIndex(guest) ? 'with-border' : ''">
        <div class="left-content" [ngClass]="setShowDeleteConfirmation(guest?.idEventGuest) ? 'overlay' : ''">
          <div class="avatar__profile avatar-mobile" [ngClass]="guest.customer?.image ? 'has-image' : ''">
            <img *ngIf="guest.customer?.image" src="{{ mediaUrl + guest.customer?.image }}"
                class="profile-img img-mobile" alt="profile"/>
            <i *ngIf="!guest.customer?.image"
                class="fal fa-user-circle text-gray-4 font-size-28 lh-32 align-self-center"></i>
            <div class="check-ingress" *ngIf="guest.ingressed">
              <i class="fa-solid fa-circle-check text-success"></i>
            </div>
          </div>
          <p class="font-secondary text-base font-light text-gray-3 lh-24 margin-left-16 my-0"
            *ngIf="guest.name && guest.lastName">
            {{capitalizeGuestName(guest.name)}} {{capitalizeGuestName(guest.lastName)}}
          </p>
          <p class="font-secondary text-base font-light text-gray-3 lh-24 margin-left-16 my-0" *ngIf="!guest.name">
            <ng-container i18n>Invitado</ng-container>
            <span class="text-sm font-light text-gray-35 lh-24 ml-2"
                  *ngIf="customerIsTableAdmin || customerIsTableOwner || customerIsAccessOwner || customerIsListAdmin">
                {{ ' ' + guest.document}}
              </span>
          </p>
          <span class="ml-2 font-bold text-xxs" *ngIf="(this.invitationType === 'T' && guest.tableAdmin && guest.idEventGuest !== invitationDataTable.owner)
              || this.invitationType === 'L' && guest.listAdmin">
              <span class="tag" i18n>ADMIN</span>
            </span>
          <span class="ml-2 font-bold text-xxs"
                *ngIf="(this.invitationType === 'A' && guest.idEventGuest === invitationDataAccess.owner)
                || (this.invitationType === 'T' && guest.idEventGuest === invitationDataTable.owner)">
              <span class="tag" i18n>OWNER</span>
            </span>
        </div>
        <div class="right-content" *ngIf="!setShowDeleteConfirmation(guest?.idEventGuest) && !isEventFinalizedOrCanceled">
          <button (click)="openDeleteConfirmation(guest)" class="btn btn__terciary btn__trash"
                  *ngIf="(customerIsAccessOwner || customerIsTableAdmin || customerIsTableOwner || customerIsListAdmin)
                    && (invitationType !== 'L' && customerGuest.idEventGuest !== guest.idEventGuest)">
            <i class="fa-light fa-trash-alt text-xl lh-24 text-primary"></i>
          </button>
          <button (click)="openDeleteConfirmation(guest)" class="btn btn__terciary btn__trash"
            *ngIf="(customerIsAccessOwner || customerIsTableAdmin || customerIsTableOwner || customerIsListAdmin)
              && (invitationType === 'L' && hasAuthority && (customerGuest === undefined || customerGuest && customerGuest.customerId !== guest.customerId))">
            <i class="fa-light fa-trash-alt text-xl lh-24 text-primary"></i>
          </button>
        </div>
        <div class="right-content__delete" *ngIf="setShowDeleteConfirmation(guest?.idEventGuest)">
          <div class="container__delete-guest align-items-center">
            <p class="font-secondary font-size-14 line-height-24 text-gray-1 margin-right-24 my-0" i18n>
              ¿Eliminar?
            </p>
            <button (click)="submitDeleteGuest(guest)"
                    class="btn btn__remove button-yes margin-right-8" i18n>
              Si
            </button>
            <button (click)="cancelDelete()" class="btn btn__primary button-no" i18n>
              No
            </button>
          </div>

        </div>
      </div>
    </div>

  <ng-template pTemplate="footer" class="bid-modal__footer">
    <ng-container *ngIf="!isEventFinalizedOrCanceled && verifyAdminPermission()">
      <button
        pButton
        class="btn btn__secondary"
        [disabled]="!verifyAdminPermission()"
        (click)="cancel()"
        label="{{cancelLabel}}"
      ></button>
      <button
        pButton
        class="btn btn__primary"
        [disabled]="limitHasBeenReached || isEventFinalizedOrCanceled || !verifyAdminPermission()"
        (click)="addNewGuest()"
        label="{{addGuestLabel}}"
      ></button>
    </ng-container>
  </ng-template>

</p-dialog>

