import {AlertService} from './alert-service.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import {AuthService} from 'src/app/services/auth.service';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {Injectable} from '@angular/core';
import {AuthProvider, FacebookAuthProvider, GoogleAuthProvider, OAuthProvider} from 'firebase/auth';
import {ExternalLoginRequest} from '../models/ExternalLoginRequest';
import firebase from 'firebase/compat';
import {ExternalPlatform} from '../enum/ExternalPlatform';
import {environment} from '../../deployment/qa/environment.qa';
import {User} from '../models/user';
import {PromotionPersist} from '../models/PromotionPersist';
import {EventService} from './event.service';
import {ModalActionComponent} from '../share/components/modal-action/modal-action.component';
import {ModalController} from '../controller/modal-controller';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  firstLoginData$ = new Subject<ExternalLoginRequest>();
  redirectTo: string;
  subs: Subscription[] = [];

  buyPage = false;
  checkoutPage = false;
  idEvent = null;
  buyType = null;
  ticketId = null;
  withCode = false;
  promoCode = null;
  detailPage = false;
  cameFromRegistrationPage = false;
  promotionPersist: PromotionPersist = null;
  showQR = false;
  invitationType = false;

  constructor(
    private fireAuth: AngularFireAuth,
    private authService: AuthService,
    private router: Router,
    private alertSrv: AlertService,
    private route: ActivatedRoute,
    private eventService: EventService,
    private modalCtrl: ModalController
  ) {
    this.route.queryParamMap.subscribe(queryParamMap => {
      this.redirectTo = queryParamMap.get('redirectTo');
    });
  }

  externalLoginOnBrowser(platform: ExternalPlatform) {
    let provider: AuthProvider;
    switch (platform) {
      case ExternalPlatform.GOOGLE:
        provider = new GoogleAuthProvider();
        break;
      case ExternalPlatform.FACEBOOK:
        provider = new FacebookAuthProvider();
        break;
      case ExternalPlatform.APPLE:
        const oAuhtProvider = new OAuthProvider('apple.com');
        oAuhtProvider.addScope('email');
        oAuhtProvider.addScope('name');
        provider = oAuhtProvider;
        break;
      default:
        return;
    }

    this.fireAuth
      .signInWithPopup(provider)
      .then(async (success) => {
        await this.onFirebaseAuthSuccess(success, platform);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async onFirebaseAuthSuccess(userCredentials: firebase.auth.UserCredential, provider: ExternalPlatform) {
    const currentUser = await this.fireAuth.currentUser;
    const profile: any = userCredentials.additionalUserInfo.profile;

    let name = null;
    let lastName = null;
    if (provider === ExternalPlatform.GOOGLE) {
      name = profile.given_name ? profile.given_name : '';
      lastName = profile.family_name ? profile.family_name : '';
    } else if (provider === ExternalPlatform.FACEBOOK) {
      name = profile.first_name ? profile.first_name : '';
      lastName = profile.last_name ? profile.last_name : '';
    } else if (provider === ExternalPlatform.APPLE) {
      name = profile.name ? profile.name.firstName : '';
      lastName = profile.name ? profile.name.lastName : '';
    }

    const externalUserData: ExternalLoginRequest = {
      idToken: await currentUser.getIdToken(),
      name,
      lastName,
      email: profile.email,
      emailVerified: true,
      pictureUrl: null,
      externalId: userCredentials.user.uid,
      provider,
      tenant: environment.identification
    };

    this.subs.push(
      this.authService.isMailAvailable({email: externalUserData.email}).subscribe(
        async (isAvailable) => {
          // no account
          if (isAvailable) {
            await (await this.fireAuth.currentUser).delete();
            await this.completeAccountData(externalUserData);
          } else {
            await (await this.fireAuth.currentUser).delete();
            this.loginExternalUser(externalUserData);
          }
        },
        async (err) => {
          await this.alertSrv.alertMailValidationIsNotAvailable();
        }
      )
    );
  }

  loginExternalUser(loginRequest: ExternalLoginRequest) {
    this.analyzedPromo();
    this.subs.push(
      this.authService.externalLogin(loginRequest).subscribe({
        next: async (res: User) => {
          // tslint:disable-next-line: no-string-literal
          if (res.blocked) {
            const modal = await this.createModalAction(
              'icon-warning.svg',
              $localize`Cuenta bloqueada`,
              $localize`Tu cuenta se encuentra bloqueada para operar. Si crees que se debe a un error, por favor comunicate con soporte.`,
              $localize`Aceptar`
            );
          } else {
            if (res.jwt) {
              localStorage.setItem('isLoggedIn', 'true');
              sessionStorage.setItem('guestLogged', 'false');
              sessionStorage.removeItem('token');
              sessionStorage.setItem('alert-delay', 'true');
              sessionStorage.setItem('alert-current-events', 'true');
              const lastDetailEvent = JSON.parse(localStorage.getItem('lastDetailEvent'));

              if (this.promotionPersist){
                if ( this.showQR) {
                  this.router.navigate(['/home/show']);
                } else if (this.detailPage) {
                  this.eventService.getEventsIdsByCustomer().subscribe(async (ids) => {
                    if (ids && ids.length > 0) {
                      const eventIdFound = ids.find(id => id === Number(this.idEvent));
                      if (eventIdFound != null) {
                        await this.router.navigate(['/my-events/event-detail/' + this.idEvent]);
                      }
                    }
                    if (this.promoCode !== null) {
                      await this.router.navigate(['/detail-event/' + this.idEvent + '/code/' + this.promoCode]);
                    } else {
                      await this.router.navigate(['/detail-event/' + this.idEvent]);
                    }
                  });
                } else if (this.invitationType) {
                  await this.router.navigate(['/billboard/0/code/' + this.promoCode]);
                } 
              } else {
                if (lastDetailEvent) {
                  this.idEvent = lastDetailEvent ? lastDetailEvent : null;
                  await this.router.navigate(['/detail-event/' + this.idEvent]);
                } else {
                  await this.router.navigate(['/']);
                }
              }              

              const lastURLBeforeLogin = sessionStorage.getItem('lastURLBeforeLogin');
              if (lastURLBeforeLogin) {
                await this.router.navigateByUrl(lastURLBeforeLogin);
              }
            }
          }
        },
        error: async (err) => {
        }
      })
    );
  }

  private async createModalAction(icon: string, title: string, content: string, textBtn: string) {
    const modal = await this.modalCtrl.create({
      component: ModalActionComponent,
      props: {
        icon,
        title,
        content,
        textBtn
      },
    });
    await modal.present();
    return modal;
  }

  analyzedPromo() {
    const existPromotionPersist = sessionStorage.getItem('promotionPersist');
    if (existPromotionPersist) {
      this.promotionPersist = JSON.parse(sessionStorage.getItem('promotionPersist'));
      this.detailPage = this.promotionPersist.detailPage ? this.promotionPersist.detailPage : false;
      this.buyPage = this.promotionPersist.buyPage ? this.promotionPersist.buyPage : false;
      this.checkoutPage = this.promotionPersist.checkoutPage ? this.promotionPersist.checkoutPage : false;
      this.cameFromRegistrationPage = this.promotionPersist.comeFromRegistration ? this.promotionPersist.comeFromRegistration : false;
      this.idEvent = this.promotionPersist.eventId ? this.promotionPersist.eventId : null;
      this.buyType = this.promotionPersist.buyType ? this.promotionPersist.buyType : null;
      this.promoCode = this.promotionPersist.promoCode ? this.promotionPersist.promoCode : null;
      this.showQR = this.promotionPersist.showQR ? this.promotionPersist.showQR : null;
      this.invitationType = this.promotionPersist.invitationType ? this.promotionPersist.invitationType : null;
    }
  }

  async completeAccountData(externalUserData: ExternalLoginRequest) {
    this.firstLoginData$.next(externalUserData);
    // this.router.navigateByUrl(`complete-first-login${this.redirectTo ? `?redirectTo=${this.redirectTo}` : ""}`);
    await this.router.navigate(['signup'], {state: {data: externalUserData}});
  }
}
