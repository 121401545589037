import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Countries} from '../models/Countries';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  constructor(private http: HttpClient) {
  }

  public getAllCountries(): Observable<Countries[]> {
    return this.http.get<Countries[]>(
      `${environment.apiAGUrl}/customer/countries-list`
    );
  }
}
