import * as AOS from 'aos';
import {InputValidationService} from 'src/app/services/input-validation.service';
import {LoginService} from 'src/app/services/login.service';
import {ExternalPlatform} from 'src/app/enum/ExternalPlatform';
import {ExternalLoginRequest} from 'src/app/models/ExternalLoginRequest';
import {forkJoin, Subscription} from 'rxjs';
import {SpinnerService} from 'src/app/services/spinner.service';
import {AuthService} from 'src/app/services/auth.service';
import {RegisterPayload} from 'src/app/models/RegisterForm';
import {IDValidationRequest} from 'src/app/models/IDValidationRequest';
import {signupSteps} from 'src/app/models/signupSteps';
import {FooterService} from 'src/app/services/footer.service';
import {EventService} from 'src/app/services/event.service';
import {PromotionPersist} from '../../models/PromotionPersist';
import {Countries} from 'src/app/models/Countries';
import {environment} from 'src/environments/environment';
import {CountryISO} from 'ngx-intl-tel-input-gg';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {User} from '../../models/user';

const trimValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (control.value.startsWith(' ')) {
    return {
      'trimError': {value: 'control has leading whitespace'}
    };
  }
  if (control.value.endsWith(' ')) {
    return {
      'trimError': {value: 'control has trailing whitespace'}
    };
  }
  return null;
};

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit, OnDestroy {
  signupForm: UntypedFormGroup;
  maxSteps = 2;
  currentStepNumber = signupSteps.one;
  formHasBeenSubmitted: boolean = false;
  isExternalSignup = false;
  stepHasBeenSubmitted = {
    1: false,
    2: false
  };
  emailHasBeenSubmitted = false;
  subs: Array<Subscription> = [];
  externalUserData: { name: string, lastName: string, email: string, externalId: string };
  detailPage = false;
  cameFromRegistrationPage = false;
  buyPage = false;
  checkoutPage = false;
  idEvent = null;
  buyType = null;
  ticketId = null;
  promoCode = null;
  messageErrorStep = '';
  preferredCountries: Array<CountryISO>;
  showConfirmEmailModal: boolean;
  messageConfirmEmail: string;
  showFormError: boolean;
  showErrorOnSignup: boolean;
  emailStatus = false;
  dniOrIdStatus = false;
  errorEmailKey: string;
  errorDNIorIDKey: string;
  errorPasswordKey: string;
  loadingStep = false;
  signupSteps = signupSteps;
  countries: Countries[] = [];
  failSignupModal = {
    title: $localize`Algo falló durante tu registro`,
    textBtn: $localize`En otro momento`,
    textSecondBtn: $localize`Intentar nuevamente`

  };
  successSignupModal = {
    title: $localize`Cuenta creada con éxito`,
    textBtn: $localize`Iniciar sesión`,
  };
  submitting = false;
  promotionPersist: PromotionPersist = null;
  isShowingTermsAndConditionsModal = false;
  externalProvider: ExternalPlatform;
  passwordMatch = true;
  showQR = false;
  invitationType = false;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private validateInputService: InputValidationService,
    private loginService: LoginService, // TODO CHANGE NAME LOGIN SERVICE TO EXTERNALPLATFORMSERVICE,
    private spinnerService: SpinnerService,
    private authService: AuthService,
    private footerService: FooterService,
    private eventService: EventService
  ) {
  }

  get nameField(): AbstractControl {
    return this.signupForm.get('name');
  }

  get lastNameField(): AbstractControl {
    return this.signupForm.get('lastName');
  }

  get emailField(): AbstractControl {
    return this.signupForm.get('email');
  }

  get passwordField(): AbstractControl {
    return this.signupForm.get('password');
  }

  get confirmPasswordField(): AbstractControl {
    return this.signupForm.get('confirmPassword');
  }

  get countryField(): AbstractControl {
    return this.signupForm.get('country');
  }

  get originTypeIdField(): AbstractControl {
    return this.signupForm.get('originTypeId');
  }

  get idField(): AbstractControl {
    return this.signupForm.get('id');
  }

  get idValidationField(): AbstractControl {
    return this.signupForm.get('idValidation');
  }

  async ngOnInit() {
    AOS.init({
      duration: 800,
      once: true
    });
    this.signupForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
          this.validateInputService.emailValidator(),
          trimValidator
        ],
      ],
      emailVerified: [false],
      name: ['', Validators.required],
      lastName: ['', Validators.required],
      country: ['', Validators.required],
      originTypeId: ['', Validators.required],
      id: ['', [Validators.required, Validators.minLength(environment.minDniLength), Validators.maxLength(environment.maxDniLength)]],
      idValidation: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern('(?=\\w*\\d)(?=\\w*[A-Z])(?=\\w*[a-z])\\S{8,32}')]],
      confirmPassword: ['', Validators.required],
      acceptTerms: ['', Validators.required],
      acceptEmails: ['', Validators.required]
    });

    if (history && history.state.data) {
      this.setAsExternalSignup();

      const externalUser: ExternalLoginRequest = history.state.data;
      this.externalProvider = externalUser.provider;

      this.signupForm.patchValue({
        name: externalUser.name,
        lastName: externalUser.lastName,
        email: externalUser.email,
        password: externalUser.externalId.substring(0, 31),
        confirmPassword: externalUser.externalId.substring(0, 31),
      });

      this.authService.validateUserEmail(this.signupForm.controls.email.value).subscribe({
        next: isValid => {
          if (!isValid) {
            this.footerService.showFooter$.next(false);
            this.emailField.setErrors({'InvalidEmail': true});
          } else {
            this.footerService.showFooter$.next(false);
            this.emailField.disable();
            this.passwordField.disable();
            this.confirmPasswordField.disable();
          }
        },
        error: (err) => {
          this.footerService.showFooter$.next(false);
          if (err.status === 400) {
            this.signupForm.controls.email.setErrors({'EmailAlreadyExist': true});
          }
          if (err.status === 424) {
            this.signupForm.controls.email.setErrors({'InvalidEmail': true});
          }
        }
      });

      if (externalUser.name === '' || externalUser.lastName === '') {
        this.currentStepNumber = signupSteps.one;
      } else {
        this.currentStepNumber = signupSteps.two;
      }
    } else {
      this.footerService.showFooter$.next(false);
    }
    const existPromotionPersist = sessionStorage.getItem('promotionPersist');
    if (existPromotionPersist) {
      this.promotionPersist = JSON.parse(sessionStorage.getItem('promotionPersist'));
      this.detailPage = this.promotionPersist.detailPage;
      this.buyPage = this.promotionPersist.buyPage;
      this.checkoutPage = this.promotionPersist.checkoutPage;
      this.cameFromRegistrationPage = this.promotionPersist.comeFromRegistration;
      this.idEvent = this.promotionPersist.eventId ? this.promotionPersist.eventId : null;
      this.buyType = this.promotionPersist.buyType ? this.promotionPersist.buyType : null;
      this.promoCode = this.promotionPersist.promoCode ? this.promotionPersist.promoCode : null;
      this.showQR = this.promotionPersist.showQR ? this.promotionPersist.showQR : null;
      this.invitationType = this.promotionPersist.invitationType ? this.promotionPersist.invitationType : null;
    }
    this.signupForm.valueChanges.subscribe(res => {
      this.messageErrorStep = '';
      this.errorEmailKey = '';
      this.errorDNIorIDKey = '';

    });

    this.passwordField.valueChanges.subscribe(res => {
      this.passwordMatch = true;
    });

    this.confirmPasswordField.valueChanges.subscribe(res => {
      this.passwordMatch = true;
    });
  }

  firstStepIsValid() {
    if (this.isExternalSignup && this.emailField.disable) {
      return this.nameField.valid
        && this.lastNameField.valid
        && this.emailField.disabled
        && this.passwordField.disabled
        && this.confirmPasswordField.disabled
        && this.passwordField.value === this.confirmPasswordField.value;
    } else {
      return this.nameField.valid
        && this.lastNameField.valid
        && this.emailField.valid
        && this.passwordField.valid
        && this.confirmPasswordField.valid
        && this.passwordField.value === this.confirmPasswordField.value;
    }
  }

  secondStepIsValid() {
    return this.countryField.valid && this.idValidationField.valid && this.idField.valid && this.idValidationField.valid &&
      this.idField.value === this.idValidationField.value;
  }

  setAsExternalSignup() {
    this.isExternalSignup = true;
  }

  cleanExternalSignup() {
    this.isExternalSignup = false;
  }

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.Argentina, CountryISO.Brazil];
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }

  async nextStep() {
    this.spinnerService.loadSpinner.next(true);
    this.stepHasBeenSubmitted[this.currentStepNumber] = true;
    switch (this.currentStepNumber) {
      case signupSteps.one:


        if (this.passwordField.value !== this.confirmPasswordField.value) {
          this.passwordMatch = false;
        }

        const firstStepIsValid = this.firstStepIsValid() && this.passwordMatch;

        this.authService.validateUserEmail(this.signupForm.controls.email.value).subscribe({
          next: isValid => {
            this.spinnerService.loadSpinner.next(false);
            if (!isValid) {
              this.emailField.setErrors({'InvalidEmail': true});
            } else if (isValid && firstStepIsValid) {
              this.loadingStep = false;
              this.currentStepNumber = signupSteps.two;
              this.messageErrorStep = '';
            } else {
              if (this.passwordField.value !== this.confirmPasswordField.value) {
                this.confirmPasswordField.setErrors({'PasswordNotMatch': true});
              }
              this.loadingStep = false;
            }
          },
          error: (err) => {
            this.spinnerService.loadSpinner.next(false);
            if (err.status === 400) {
              this.signupForm.controls.email.setErrors({'EmailAlreadyExist': true});
            }
            if (err.status === 424) {
              this.signupForm.controls.email.setErrors({'InvalidEmail': true});
            }
          }
        });
        break;
      case signupSteps.two:
        this.spinnerService.loadSpinner.next(true);

        const id = this.idField.value.trim();
        this.idField.patchValue(id);
        const idValidation = this.idValidationField.value.trim();
        this.idValidationField.patchValue(idValidation);

        const isDNIValid = await this.validIDorDNI();
        this.spinnerService.loadSpinner.next(false);

        if (this.secondStepIsValid() && isDNIValid) {
          this.isShowingTermsAndConditionsModal = true;
        } else {
          if (this.idField.value !== this.idValidationField.value) {
            this.idValidationField.setErrors({'IDdoesnotMatch': true});
          }
        }
        break;
    }
  }

  async validIDorDNI() {
    if (this.signupForm.controls.country.value === 'arg') {
      if (this.signupForm.controls.id.value.length >= 7 && this.signupForm.controls.id.value.length <= 9) {
        return this.validateDNIorID();
      } else {
        this.signupForm.controls.id.setErrors({'DNIcantChars': true});
        return false;
      }
    } else {
      if (this.signupForm.controls.country.valid && this.signupForm.controls.id.valid) {
        return this.validateDNIorID();
      } else {
        return false;
      }
    }
  }

  async validateDNIorID() {
    if (this.signupForm.controls.id.value === this.signupForm.controls.idValidation.value) {
      const payload: IDValidationRequest = {
        country: this.signupForm.controls.country.value.toUpperCase(),
        idType: this.signupForm.controls.originTypeId.value,
        document: this.signupForm.controls.id.value
      };
      this.dniOrIdStatus = await this.validateDNIorIDFromBack(payload);
      if (this.dniOrIdStatus) {
        return true;
      } else {
        this.signupForm.controls.id.setErrors({'DNIAlreadyExist': true});
        return false;
      }
    } else {
      this.signupForm.controls.idValidation.setErrors({'DNIdoesnotMatch': true});
      return false;
    }
  }

  onActionToLogin() {
    this.router.navigate(['/login']);
  }

  validateEmailFromBack(email: string) {
    this.authService.validateUserEmail(email).subscribe({
      next: isValid => {
        if (!isValid) {
          this.emailField.setErrors({'InvalidEmail': true});
          return false;
        } else {
          return isValid;
        }
      },
      error: (err) => {
        if (err.status === 400) {
          this.signupForm.controls.email.setErrors({'EmailAlreadyExist': true});
        }
        if (err.status === 424) {
          this.signupForm.controls.email.setErrors({'InvalidEmail': true});
        }
        return false;
      }
    });
  }

  validateDNIorIDFromBack(idValidationRequest: IDValidationRequest): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let isValidID = false;
      this.authService.validateDNIorIDFromBack(idValidationRequest).subscribe({
        next: res => {
          this.spinnerService.loadSpinner.next(false);
          isValidID = res;
          if (!isValidID) {
            switch (idValidationRequest.idType) {
              case 'DNI':
                this.signupForm.controls.id.setErrors({'DNIAlreadyExist': true});
                break;
              case 'ID':
                this.signupForm.controls.id.setErrors({'IDAlreadyExist': true});
                break;
              case 'PASSPORT':
                this.signupForm.controls.id.setErrors({'PassportAlreadyExist': true});
                break;
              default:
                this.signupForm.controls.id.setErrors({'DNIAlreadyExist': true});
                break;
            }
          }
          resolve(res);
        },
        error: () => {
          this.signupForm.controls.id.setErrors({'ErrorOnIdVerification': true});
          reject(isValidID);
        }
      });
    });
  }

  onFinishSteps(termHasBeenAccepted) {
    if (termHasBeenAccepted) {
      if (this.firstStepIsValid()) {
        if (this.secondStepIsValid()) {
          this.finishSignUp();
        } else {
          this.currentStepNumber = signupSteps.two;
        }
      } else {
        this.currentStepNumber = signupSteps.one;
      }
    } else {
      this.isShowingTermsAndConditionsModal = false;
    }
  }

  finishSignUp() {
    this.submitting = true;
    const form = this.signupForm.getRawValue();
    form.email.trim();
    this.spinnerService.loadSpinner.next(true);
    const payload: RegisterPayload = {
      email: form.email,
      emailVerified: form.emailVerified,
      name: form.name,
      lastName: form.lastName,
      country: form.country.toUpperCase(),
      password: form.password,
      isExternalSignup: this.isExternalSignup,
      externalProvider: this.externalProvider ? this.externalProvider : ExternalPlatform.BLACKID,
      notificationEmail: form.acceptEmails,
      document: form.id,
      idType: form.originTypeId,
      tenant: environment.identification
    };
    this.authService.register(payload).subscribe({
      next: async (res: User) => {
        if (res && res['jwt']) {
          sessionStorage.setItem('guestLogged', 'false');
          sessionStorage.removeItem('token');
          localStorage.setItem('user', JSON.stringify(res));
          localStorage.setItem('BE-JWT', res.jwt);
          localStorage.setItem('isLoggedIn', 'true');
          if (this.showQR) {
            await this.router.navigate(['/home/show']);
          } else if (this.checkoutPage) {
            await this.router.navigate(['/checkout-ticket/' + this.idEvent + '/' + this.buyType]);
          } else if (this.buyPage) {
            this.redirectToBuyPage();
          } else if (this.detailPage) {
            await this.redirectToEventPage();
          } else if (this.invitationType) {
              await this.router.navigate(['/billboard/0/code/' + this.promoCode]);
          } else {
            await this.router.navigate(['/']);
          }
          this.submitting = false;
          this.spinnerService.loadSpinner.next(false);
          this.showConfirmEmailModal = true;
          this.messageConfirmEmail =
            $localize`Te enviamos un email de verificación. Seguí las instrucciones desde tu casilla de correo, para activar tu cuenta.`;
        }
      },
      error: async (err) => {
        this.spinnerService.loadSpinner.next(false);
        this.showFormError = true;
        this.submitting = false;
        this.showModalErrorSignupAndRestartFlow();
      }
    });
  }

  getDate(date: string): Date {
    const dateData = date.split('/');
    return new Date(Number(dateData[2]), Number(dateData[1]) - 1, Number(dateData[0]));
  }

  public async redirectToEventPage() {
    this.eventService.getEventsIdsByCustomer().subscribe(async (ids) => {
      if (ids && ids.length > 0) {
        const eventIdFound = ids.find(id => id === Number(this.idEvent));
        if (eventIdFound != null) {
          await this.router.navigate(['/my-events/event-detail/' + this.idEvent]);
        }
      }
      if (this.promoCode !== null) {
        await this.router.navigate(['/billboard/detail-event/' + this.idEvent + '/code/' + this.promoCode]);
      } else {
        await this.router.navigate(['/billboard/detail-event/' + this.idEvent]);
      }
    });
  }

  public redirectToBuyPage() {
    this.subs.push(
      forkJoin({
        pendingEventsIds: this.eventService.getEventsIdsByCustomer()
      }).subscribe({
        next: (res) => {
          const eventIdFound = res.pendingEventsIds.find(id => id === Number(this.idEvent));
          if (eventIdFound != null) {
            this.router.navigate(['/my-events/event-detail/' + this.idEvent]);
          } else {
            if (this.promoCode !== null) {
              this.router.navigate(['/buy-ticket/' + this.idEvent + '/' + this.buyType + '/code/' + this.promoCode]);
            }
            this.router.navigate(['/buy-ticket/' + this.idEvent + '/' + this.buyType]);
          }
        }
      })
    );
  }

  showModalErrorSignupAndRestartFlow() {
    this.showErrorOnSignup = true;
  }

  onCloseErrorOnSignupModal() {
    this.router.navigate(['/login']);
  }

  onCloseErrorOnSignupTryAgainModal() {
    this.showErrorOnSignup = false;
    this.currentStepNumber = signupSteps.one;

    // tslint:disable-next-line:forin
    for (const prop in this.stepHasBeenSubmitted) {
      this.stepHasBeenSubmitted[prop] = false;
    }

    this.messageErrorStep = '';
    this.resetInputEmail();
  }

  resetInputEmail() {
    this.signupForm.controls.password.reset();
    this.signupForm.controls.confirmPassword.reset();
  }

  backTo() {
    // back to login
    if (this.currentStepNumber === signupSteps.one) {
      this.router.navigate(['/login']);
    } else {
      if (this.currentStepNumber === this.signupSteps.two) {
        this.currentStepNumber = this.signupSteps.one;
      }
      this.stepHasBeenSubmitted[this.currentStepNumber] = false;
    }
  }

  onExternalSignup(platform: ExternalPlatform) {
    this.loginService.externalLoginOnBrowser(platform);
    this.loginService.firstLoginData$.subscribe((externalUser: ExternalLoginRequest) => {
      this.setAsExternalSignup();
      this.signupForm.controls.emailVerified.patchValue(externalUser.emailVerified);
      this.externalProvider = externalUser.provider;
      this.signupForm.patchValue({
        name: externalUser.name,
        lastName: externalUser.lastName,
        email: externalUser.email,
        password: externalUser.externalId.substring(0, 31),
        confirmPassword: externalUser.externalId.substring(0, 31)
      });

      this.authService.validateUserEmail(this.signupForm.controls.email.value).subscribe({
        next: isValid => {
          if (!isValid) {
            this.footerService.showFooter$.next(false);
            this.emailField.setErrors({'InvalidEmail': true});
          } else {
            this.footerService.showFooter$.next(false);
            this.emailField.disable();
            this.passwordField.disable();
            this.confirmPasswordField.disable();
          }
        },
        error: (err) => {
          this.footerService.showFooter$.next(false);
          if (err.status === 400) {
            this.signupForm.controls.email.setErrors({'EmailAlreadyExist': true});
          }
          if (err.status === 424) {
            this.signupForm.controls.email.setErrors({'InvalidEmail': true});
          }
        }
      });

      if (externalUser.name === '' || externalUser.lastName === '') {
        this.currentStepNumber = signupSteps.one;
      } else {
        this.currentStepNumber = signupSteps.two;
      }
    });
  }

  ngOnDestroy(): void {
    this.footerService.showFooter$.next(true);
    this.subs.forEach(res => {
      res.unsubscribe();
    });
  }

}
