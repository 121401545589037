<div class="modal" *ngIf="showModal">
  <div class="modal__container">
    <div class="modal__header" *ngIf="showHeader">
      <button class="btn__close" (click)="closeModal()">
        <i class="fa-regular fa-xmark icon__close"></i>
      </button>
    </div>
    <div class="modal__body">
      <ng-container *ngIf="iconClass"><i class="icon {{iconClass}} text-warning font-size-72"></i></ng-container>
      <ng-container *ngIf="imageName"><img src="../../../assets/images/{{imageName}}" class="icon-info" alt=""
                                           *ngIf="!iconClass"></ng-container>
      <ng-container *ngIf="!iconClass && !imageName">
        <ng-container [ngSwitch]="type">
          <div *ngSwitchCase="MODALACTIONTYPE.info"><i class="fa-regular fa-circle-info icon text-info font-size-72"></i></div>
          <div *ngSwitchCase="MODALACTIONTYPE.warning"><i class="fa-regular fa-triangle-exclamation icon text-warning font-size-72"></i></div>
          <div *ngSwitchCase="MODALACTIONTYPE.error"><i class="fa-regular fa-xmark icon text-error font-size-72"></i></div>
        </ng-container>
      </ng-container>

      <p class="font-secondary text-xl lh-26 text-gray-1 font-semibold mt-6"
        [ngClass]="!content ? 'margin-bottom-32' : 'mb-2'"
      >{{ title }}</p>
      <div class="description" *ngIf="content">
        <p class="text-sm lh-20 font-primary text-gray-35 mt-0 mb-8">{{ content }}</p>
      </div>
      <div class="btns" 
        [ngClass]="{
        'invert-btns-mobile': invertBtnsMobile, 
        'invert-btns-desktop': invertBtnsDesktop, 
        'has-second-button': textSecondBtn}"
      >
        <button class="btn {{ firstButtonClass }}" (click)="onAction()">{{ textBtn }}</button>
        <button class="btn {{ secondButtonClass }}" *ngIf="textSecondBtn !== ''"
                (click)="onActionSecondButton()">{{ textSecondBtn }}</button>
      </div>
    </div>
  </div>
</div>
