import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-nav-fixed',
  templateUrl: './nav-fixed.component.html',
  styleUrls: ['./nav-fixed.component.scss']
})
export class NavFixedComponent {

  @Input() thereAreChanges: boolean = false;
  @Input() formLoaded: boolean = false;
  @Output() dontSave = new EventEmitter();
  @Output() save = new EventEmitter();

  resetForm() {
    this.dontSave.emit();
  }

  submitChanges() {
    this.save.emit();
  }
}
