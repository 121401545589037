import {ComponentRef, Injectable} from '@angular/core';

export interface ModalResponse {
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class Modal {
  componentRef: ComponentRef<any>;
  htmlElement: HTMLDivElement;
  private dismissCallback: (value?: any) => ModalResponse | void;

  constructor(div: HTMLDivElement, ref: ComponentRef<any>) {
    this.componentRef = ref;
    this.htmlElement = div;
    this.componentRef.instance.closeButtonEvent = {
      emit: ($event) => this.dismissEvent($event)
    };
  }

  present(): void {
    this.componentRef.instance.showModal = true;
  }

  dismiss(): void {
    this.componentRef.instance.closeModal();
  }

  private dismissEvent(data: any): void {
    if (this.dismissCallback) {
      this.dismissCallback({data});
    }
    this.componentRef.destroy();
  }

  onDidDismiss(): Promise<ModalResponse | void> {
    return new Promise<ModalResponse | void>((resolve) => {
      this.dismissCallback = resolve;
    });
  }
}
