import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, UntypedFormGroup, Validators} from '@angular/forms';
import {ContactRequest} from 'src/app/models/ContactRequest';
import {FooterService} from 'src/app/services/footer.service';
import {InputValidationService} from 'src/app/services/input-validation.service';
import {SupportService} from 'src/app/services/support.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  showModal = true;
  cancelLabel = $localize`Cancelar`;
  acceptLabel = $localize`Enviar consulta`;
  supportForm: FormGroup;
  selectYourSupportType = $localize`Tipo de consulta`;
  formHasBeenSubmitted = false;
  maxEventDescriptionLength = 10;

  supportType = [{
    id: 'Comercial',
    name: 'Comercial'
  },
    {
      id: 'Error',
      name: 'Error'
    },
    {
      id: 'Sugerencia',
      name: 'Sugerencia'
    }];

  constructor(
    private formBuilder: FormBuilder,
    private validateInputService: InputValidationService,
    private supportService: SupportService,
    private footerService: FooterService
  ) {
    this.supportForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
          this.validateInputService.emailValidator()
        ],
      ],
      name: ['', Validators.required],
      supportType: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.footerService.showFooter$.next(false);
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }

  sendToSupport() {
    this.formHasBeenSubmitted = true;
    if (this.supportForm.valid) {

      const payload: ContactRequest = {
        content: this.supportForm.controls.message.value,
        email: this.supportForm.controls.email.value,
        name: this.supportForm.controls.name.value,
        reason: this.supportForm.controls.supportType.value,
        tenant: environment.identification
      };

      this.supportService.sendSupportEmail(payload).subscribe({
        next: () => {
          this.supportForm.reset();
        },
        error: (err) => {
          console.log(err);
        }
      });

    }
  }

}
