import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {EventForCustomer} from '../../../../../models/events/EventForCustomer';
import {environment} from 'src/environments/environment';
import {EventStatus} from '../../../../../enum/EventStatus';
import {MentaService} from '../../../../../services/menta.service';

@Component({
  selector: 'app-card-info-event',
  templateUrl: './card-info-event.component.html',
  styleUrls: ['./card-info-event.component.scss']
})
export class CardInfoEventComponent implements OnInit {

  @Input() eventData: EventForCustomer;
  @Input() reminder = false;
  @Input() skeletonLoading;
  @Input() isTicketOnResale;
  @Input() ticketUUID: string = null;
  @Output() closeButton = new EventEmitter();
  @Output() backButton = new EventEmitter();
  @Output() geoLocation = new EventEmitter();

  dateFormat = $localize`dd MMM`;
  showModal = false;
  environment = environment;
  eventStatus = EventStatus;

  constructor(
    private mentaSrv: MentaService
  ) {
  }

  ngOnInit(): void {
  }

  viewImage() {
    this.showModal = true;
    this.closeButton.emit(this.showModal);
  }

  back() {
    this.backButton.emit();
  }

  geo() {
    this.geoLocation.emit();
  }

  async viewOnResale() {
    const returnUrl = environment.brandParams.logo.urlLink + '/my-events/event-detail/' + this.eventData.idEvent;
    const urlDetailTicket = await this.mentaSrv.getLinkTicketDetails(this.ticketUUID, returnUrl);
    if (urlDetailTicket.data) {
      window.location.href = urlDetailTicket.data.data;
    }
  }
}
