import {BillboardComponent} from './billboard/billboard.component';
import {BuyTicketComponent} from './billboard/detail-event/buy-ticket/buy-ticket.component';
import {CardComponent} from './my-events/components/card/card.component';
import {CardEventComponent} from './billboard/components/card-event/card-event.component';
import {CardInfoEventComponent} from './billboard/detail-event/components/card-info-event/card-info-event.component';
import {CarrouselEventsComponent} from './billboard/components/carrousel-events/carrousel-events.component';
import {CheckoutTicketComponent} from './billboard/detail-event/buy-ticket/checkout-ticket/checkout-ticket.component';
import {CommonModule} from '@angular/common';
import {CompleteFirstLoginComponent} from './complete-first-login/complete-first-login/complete-first-login.component';
import {ConfirmRegistrationComponent} from './login/components/confirm-registration/confirm-registration.component';
import {DetailEventComponent} from './billboard/detail-event/detail-event.component';
import {DownloadAppComponent} from './download-app/download-app.component';
import {EmailReminderComponent} from './signup/email-reminder/email-reminder.component';
import {EventDetailComponent} from './my-events/event-detail/event-detail.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GridEventsComponent} from './billboard/components/grid-events/grid-events.component';
import {HistoryComponent} from './my-events/history/history.component';
import {HttpClientModule} from '@angular/common/http';
import {LoginComponent} from './login/login.component';
import {ModalFiltersComponent} from './billboard/components/modal-filters/modal-filters.component';
import {MyEventsComponent} from './my-events/my-events.component';
import {NavbarComponent} from './billboard/components/navbar/navbar.component';
import {NgModule} from '@angular/core';
import {NotFoundComponent} from './not-found/not-found.component';
import {NotFoundEventComponent} from './not-found-event/not-found-event.component';
import {ProfileComponent} from './profile/profile.component';
import {PromotionalCodeComponent} from '../share/components/promotional-code/promotional-code.component';
import {ShareModule} from '../share/share.module';
import {SignupComponent} from './signup/signup.component';
import {SkeletonDescriptionComponent} from './billboard/detail-event/components/skeleton-description/skeleton-description.component';
import {SuccessPurchaseComponent} from './billboard/detail-event/buy-ticket/checkout-ticket/success-purchase/success-purchase.component';
import {SwiperModule} from 'swiper/angular';
import {TicketCardComponent} from './billboard/detail-event/buy-ticket/components/ticket-card/ticket-card.component';
import {BannerComponent} from './billboard/components/banner/banner.component';
import {NavbarMobileComponent} from './billboard/components/navbar-mobile/navbar-mobile.component';
import {ExternalLoginComponent} from './login/components/external-login/external-login.component';
import {DownloadQrComponent} from './login/components/download-qr/download-qr.component';
import {DropdownModule} from 'primeng/dropdown';
import {InputMaskModule} from 'primeng/inputmask';
import {SignupStepOneComponent} from './signup/components/signup-step-one/signup-step-one.component';
import {SignupStepTwoComponent} from './signup/components/signup-step-two/signup-step-two.component';
import {SignupFinishStepModalComponent} from './signup/components/signup-finish-step-modal/signup-finish-step-modal.component';
import {DialogModule} from 'primeng/dialog';
import {ToastModule} from 'primeng/toast';
import {ButtonModule} from 'primeng/button';
import {CheckboxModule} from 'primeng/checkbox';
import {ModalShowGuestsComponent} from './my-events/event-detail/components/modal-show-guests/modal-show-guests.component';
import {DataRequiredComponent} from './billboard/detail-event/buy-ticket/checkout-ticket/data-required/data-required.component';
import {ModalAddGuestComponent} from './my-events/event-detail/components/modal-add-guest/modal-add-guest.component';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ModalInfoTypeComponent} from './billboard/detail-event/components/modal-info-type/modal-info-type.component';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input-gg';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {SupportComponent} from './support/support.component';
import {ModalResaleComponent} from './my-events/event-detail/components/modal-resale/modal-resale.component';
import {RecoverPasswordDniComponent} from './recover-password-dni/recover-password-dni.component';
import {ChangePasswordComponent} from './configurations/pages/change-password/change-password.component';
import { SetNewPasswordComponent } from './set-new-password/set-new-password.component';
import { TermsAndConditionsComponent } from './terms/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPoliciesComponent } from './terms/privacy-policies/privacy-policies.component';
import {RouterModule} from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [
    BillboardComponent,
    DetailEventComponent,
    BuyTicketComponent,
    CheckoutTicketComponent,
    LoginComponent,
    SignupComponent,
    ProfileComponent,
    MyEventsComponent,
    EventDetailComponent,
    SuccessPurchaseComponent,
    CardInfoEventComponent,
    TicketCardComponent,
    CardComponent,
    ForgotPasswordComponent,
    SkeletonDescriptionComponent,
    PromotionalCodeComponent,
    ConfirmRegistrationComponent,
    EmailReminderComponent,
    NavbarComponent,
    CarrouselEventsComponent,
    CardEventComponent,
    ModalFiltersComponent,
    CompleteFirstLoginComponent,
    HistoryComponent,
    NotFoundComponent,
    DownloadAppComponent,
    NotFoundEventComponent,
    GridEventsComponent,
    BannerComponent,
    NavbarMobileComponent,
    ExternalLoginComponent,
    DownloadQrComponent,
    ExternalLoginComponent,
    DownloadQrComponent,
    ExternalLoginComponent,
    DownloadQrComponent,
    SignupStepOneComponent,
    SignupStepTwoComponent,
    SignupFinishStepModalComponent,
    ModalShowGuestsComponent,
    DataRequiredComponent,
    ModalAddGuestComponent,
    ModalInfoTypeComponent,
    ModalResaleComponent,
    RecoverPasswordDniComponent,
    SupportComponent,
    ChangePasswordComponent,
    SetNewPasswordComponent,
    TermsAndConditionsComponent,
    PrivacyPoliciesComponent
  ],
  imports: [
    CommonModule,
    ShareModule,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DropdownModule,
    InputMaskModule,
    DialogModule,
    ToastModule,
    ButtonModule,
    CheckboxModule,
    InputSwitchModule,
    NgxIntlTelInputModule,
    ConfirmDialogModule,
    RouterModule,
    InfiniteScrollModule
  ],
  exports: [
    PromotionalCodeComponent,
    ModalAddGuestComponent
  ]
})
export class PagesModule {
}
