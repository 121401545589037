import * as AOS from 'aos';

import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import {AuthService} from 'src/app/services/auth.service';
import {InputValidationService} from 'src/app/services/input-validation.service';
import {LoginService} from 'src/app/services/login.service';
import {Subscription} from 'rxjs';
import {environment} from 'src/environments/environment';
import {ExternalLoginRequest} from '../../../models/ExternalLoginRequest';
import {AlertService} from '../../../services/alert-service.service';
import {User} from '../../../models/user';

@Component({
  templateUrl: './complete-first-login.component.html',
  styleUrls: ['./complete-first-login.component.scss']
})
export class CompleteFirstLoginComponent implements OnInit {
  completeDataForm: UntypedFormGroup;
  formHasBeenSubmitted = false;
  subs: Subscription[] = [];
  redirectTo: string;
  accountData: ExternalLoginRequest = {
    idToken: '',
    externalId: '',
    email: '',
    emailVerified: false,
    name: '',
    lastName: '',
    pictureUrl: '',
    provider: 0
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private validateInputService: InputValidationService,
    private authService: AuthService,
    private loginSrv: LoginService,
    private alertSrv: AlertService
  ) {
    this.route.queryParamMap.subscribe({
      next: (queryParams: ParamMap) => {
        this.redirectTo = queryParams.get('redirectTo');
      }
    });

    if (!this.loginSrv.firstLoginData$) {
      this.router.navigate(['login']);
      return;
    }
  }

  ngOnInit(): void {
    this.loginSrv.firstLoginData$.subscribe({
      next: (data) => {
        this.accountData = data;
        this.initForm();
      }
    });

    AOS.init({
      duration: 800,
    });
  }

  initForm() {
    this.completeDataForm = this.formBuilder.group({
      idToken: [
        this.accountData.idToken,
        [
          Validators.required
        ],
      ],
      email: [
        {value: this.accountData.email, disabled: false},
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
          this.validateInputService.emailValidator(),
        ],
      ],
      emailVerified: [
        this.accountData.emailVerified,
        [
          Validators.required
        ],
      ],
      name: [
        {value: this.accountData.name, disabled: false},
        [
          Validators.required,
          Validators.pattern(
            '^[\u00F1A-Za-z _]*[\u00F1A-Za-z][\u00F1A-Za-z _]*$'
          ),
        ],
      ],
      lastName: [
        {value: this.accountData.lastName, disabled: false},
        [
          Validators.required,
          Validators.pattern(
            '^[\u00F1A-Za-z _]*[\u00F1A-Za-z][\u00F1A-Za-z _]*$'
          ),
        ],
      ],
      phone: [
        '',
        [Validators.required, this.validateInputService.phoneValidator()],
      ],
      provider: [
        this.accountData.provider,
        [Validators.required]
      ],
      externalId: [
        this.accountData.externalId,
        [
          Validators.required
        ],
      ],
      acceptTerms: [
        false,
        [Validators.required]
      ]
    });
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }

  login() {
    this.router.navigate(['login']);
  }

  validForm() {
    const {acceptTerms} = this.completeDataForm.controls;
    return acceptTerms.value && this.completeDataForm.valid;
  }

  submit() {
    if (!this.validForm()) {
      this.formHasBeenSubmitted = true;
      return;
    }
    const loginRequest: ExternalLoginRequest = this.completeDataForm.value;

    this.subs.push(this.authService.externalLogin(loginRequest).subscribe({
      next: async (res: User) => {
        // tslint:disable-next-line: no-string-literal
        if (res['jwt']) {
          this.authService.updateUser(res);
          localStorage.setItem('isLoggedIn', 'true');
          sessionStorage.setItem('guestLogged', 'false');
          if (this.redirectTo) {
            await this.router.navigateByUrl(this.redirectTo);
          } else {
            await this.router.navigate(['/']);
          }
        }
      },
      error: async (err) => {
        const alert = await this.alertSrv.invalidCredentials();
        await alert.onDidDismiss();
      }
    }));
  }
}
