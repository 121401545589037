import {BidInputComponent} from './components/bid-input/bid-input.component';
import {BidInputPasswordComponent} from './components/bid-input-password/bid-input-password.component';
import {CommonModule} from '@angular/common';
import {CreditCardComponent} from './components/credit-card/credit-card.component';
import {CreditCardNumberFormatterPipe} from './pipes/credit-card-number-formatter.pipe';
import {ErrorMessageComponent} from './components/error-message/error-message.component';
import {FlipCardOnClickDirective} from './directives/flip-card-on-click.directive';
import {FormErrorMessageComponent} from './components/form-error-message/form-error-message.component';
import {HighlightSearchPipe} from './pipes/highlight-search.pipe';
import {LocaleDatePipe} from './pipes/locale-date.pipe';
import {MaskDateCardPipe} from './pipes/mask-date-card.pipe';
import {ModalActionComponent} from './components/modal-action/modal-action.component';
import {ModalDownloadAppComponent} from './components/modal-download-app/modal-download-app.component';
import {ModalImageComponent} from './components/modal-image/modal-image.component';
import {NgModule} from '@angular/core';
import {ModalNotSellingWebComponent} from './components/modal-not-selling-web/modal-not-selling-web.component';
import {DropdownModule} from 'primeng/dropdown';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModalMustSigninComponent} from './components/modal-must-signin/modal-must-signin.component';
import {BidInputmaskComponent} from './components/bid-inputmask/bid-inputmask.component';
import {BidInputDniComponent} from './components/bid-input-dni/bid-input-dni.component';
import {LinkifyPipe} from './pipes/linkify.pipe';
import {ModalInvitationCodeComponent} from './components/modal-invitation-code/modal-invitation-code.component';
import {ToastModule} from 'primeng/toast';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {InputMaskModule} from 'primeng/inputmask';
import {ModalQrComponent} from './components/modal-qr/modal-qr.component';
import {ModalChangeLanguageComponent} from './components/modal-change-language/modal-change-language.component';
import {MainMenuComponent} from './components/main-menu/main-menu.component';
import {ModalDelegateOwnershipComponent} from './components/modal-delegate-ownership/modal-delegate-ownership.component';
import {BidDropdownComponent} from './components/bid-dropdown/bid-dropdown.component';
import {
  ModalDelegateOwnershipMobileComponent
} from './components/modal-delegate-ownership-mobile/modal-delegate-ownership-mobile.component';
import {BidInfiniteScrollComponent} from './components/bid-infinite-scroll/bid-infinite-scroll.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {BidTextareaComponent} from './components/bid-textarea/bid-textarea.component';
import {ModalSupportEmailComponent} from './components/modal-support-email/modal-support-email.component';
import {NavFixedComponent} from './components/nav-fixed/nav-fixed.component';
import {ModalGenericComponent} from './components/modal-generic/modal-generic.component';
import {
  ModalAccountUnverifiedReminderComponent
} from './components/modal-account-unverified-reminder/modal-account-unverified-reminder.component';
import {FormControlPipe} from './pipes/form-control.pipe';
import {InputTextareaModule} from 'primeng/inputtextarea';


@NgModule({
  declarations: [
    ModalActionComponent,
    ModalImageComponent,
    CreditCardComponent,
    CreditCardNumberFormatterPipe,
    FlipCardOnClickDirective,
    ErrorMessageComponent,
    FormErrorMessageComponent,
    MaskDateCardPipe,
    LocaleDatePipe,
    ModalDownloadAppComponent,
    HighlightSearchPipe,
    BidInputComponent,
    BidInputPasswordComponent,
    BidDropdownComponent,
    ModalNotSellingWebComponent,
    ModalMustSigninComponent,
    BidInputmaskComponent,
    BidInputDniComponent,
    BidDropdownComponent,
    LinkifyPipe,
    ModalInvitationCodeComponent,
    ModalQrComponent,
    ModalChangeLanguageComponent,
    MainMenuComponent,
    ModalSupportEmailComponent,
    BidTextareaComponent,
    ModalDelegateOwnershipComponent,
    ModalDelegateOwnershipMobileComponent,
    BidInfiniteScrollComponent,
    NavFixedComponent,
    ModalGenericComponent,
    ModalAccountUnverifiedReminderComponent,
    FormControlPipe
  ],
  imports: [
    CommonModule,
    DropdownModule,
    ReactiveFormsModule,
    ToastModule,
    DialogModule,
    ButtonModule,
    InputTextModule,
    FormsModule,
    InputMaskModule,
    NgSelectModule,
    InputTextareaModule
  ],
  exports: [
    ModalActionComponent,
    ModalImageComponent,
    CreditCardComponent,
    CreditCardNumberFormatterPipe,
    FlipCardOnClickDirective,
    ErrorMessageComponent,
    FormErrorMessageComponent,
    MaskDateCardPipe,
    LocaleDatePipe,
    HighlightSearchPipe,
    BidInputComponent,
    BidInputPasswordComponent,
    ModalNotSellingWebComponent,
    ModalMustSigninComponent,
    BidInputmaskComponent,
    BidInputDniComponent,
    LinkifyPipe,
    ModalInvitationCodeComponent,
    ModalQrComponent,
    ModalChangeLanguageComponent,
    ModalSupportEmailComponent,
    BidDropdownComponent,
    BidTextareaComponent,
    MainMenuComponent,
    ModalDelegateOwnershipMobileComponent,
    BidInfiniteScrollComponent,
    NavFixedComponent,
    ModalGenericComponent,
    ModalAccountUnverifiedReminderComponent,
    FormControlPipe
  ],

})
export class ShareModule {
}
