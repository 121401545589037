<div class="container">
  <div class="wrapper">
    <img src="../../../assets/images/phone-cloud.png" class="phone" alt=""/>
    <h3 class="title" i18n>Descargate Black iD App y disfrutá gratis de todas nuestras funciones.</h3>

    <div class="card-qr">
      <div class="col-qr">
        <img src="../../../assets/images/qr.png" class="qr" alt=""/>
      </div>
      <div class="col-info">
        <p class="description" i18n>
          Escaneá el QR con tu celular, descargate la App y disfrutá una experiencia distinta.
        </p>
        <p class="available" i18n>Disponible en</p>
        <div class="stores">
          <a href="https://apps.apple.com/us/app/black-id/id1574342062" class="btn-store mr-2">
            <img src="../../../assets/images/app-store.svg" class="store" alt=""/>
          </a>
          <a href="https://play.google.com/store/apps/details?id=ar.com.blackid.blackid" class="btn-store">
            <img src="../../../assets/images/google-store.svg" class="store" alt=""/>
          </a>
        </div>
      </div>
    </div>

    <a href="https://blackid.com.ar/#/app/download-qr" class="btn btn__md btn__primary btn__download" i18n>
      Descargar Black iD App
    </a>
  </div>
</div>
