import {Component, Input, OnInit} from '@angular/core';
import {EventForCustomer} from '../../../models/events/EventForCustomer';
import {ModalController} from '../../../controller/modal-controller';
import {IdType, IdTypeData} from '../../../enum/idType';
import {UserRestrict} from '../../../models/UserRestrict';
import {EventGuestRequest} from '../../../models/events/EventGuestRequest';
import {environment} from '../../../../environments/environment';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Countries} from '../../../models/Countries';
import {CountriesService} from '../../../services/countries.service';
import {UserService} from '../../../services/user.service';
import {EventGuestService} from '../../../services/event-guest.service';
import {GuestAccessService} from '../../../services/guest-access.service';
import {DelegateOwnershipRequest} from '../../../models/events/DelegateOwnershipRequest';
import {TableGuestService} from '../../../services/table-guest.service';
import {AccessResponse} from '../../../models/AccessResponse';
import {TableResponse} from '../../../models/TableResponse';
import {Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {SpinnerService} from 'src/app/services/spinner.service';

@Component({
  selector: 'app-modal-delegate-ownership',
  templateUrl: './modal-delegate-ownership.component.html',
  styleUrls: ['./modal-delegate-ownership.component.scss']
})
export class ModalDelegateOwnershipComponent implements OnInit {
  @Input() showModal: boolean;
  @Input() typeInvitation: string;
  @Input() accessId: number;
  @Input() tableId: number;
  @Input() accessData: AccessResponse;
  @Input() tableData: TableResponse;
  @Input() currency: string;
  @Input() event: EventForCustomer;
  idOptionTypes: Array<IdTypeData>;
  showingDataCustomerAndInvitation = false;
  guestIsCustomer = false;
  userToAdd: UserRestrict = null;
  eventGuestRequest: EventGuestRequest = null;
  guestAlreadyAuthorize = false;
  maxDocumentLength: number;
  minDocumentLength: number;
  formHasBeenSubmitted = false;
  mediaUrl: string;
  delegateOwnershipForm: UntypedFormGroup;
  allCountries;
  selectYourCountry = $localize`Seleccioná tu país*`;
  btnContinue = $localize`Continuar`;
  btnTransferEvent = $localize`Transferir evento`;
  btnBack = $localize`Volver`;
  btnReady = $localize`Listo`;
  btnCancel = $localize`Cancelar`;
  errorCustomer = false;
  submitting = false;
  errorSubmit = false;
  errorViaCode: string = null;
  placeHolderCountry = $localize`Seleccioná tu país*`;
  userIsBlocked = false;
  idPattern = '[0-9]{6,20}';
  inputType = 'number';

  idTypesArg: Array<IdTypeData> = [{
    name: 'DNI',
    value: IdType.DNI
  }, {
    name: 'Pasaporte',
    value: IdType.PASSPORT
  }];

  idTypesROW = [{
    name: 'ID',
    value: IdType.ID
  }, {
    name: 'Pasaporte',
    value: IdType.PASSPORT
  }];

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: UntypedFormBuilder,
    private countriesSrv: CountriesService,
    private userSrv: UserService,
    private eventGuestSrv: EventGuestService,
    private guestAccessSrv: GuestAccessService,
    private tableGuestSrv: TableGuestService,
    private router: Router,
    private messageSrv: MessageService,
    private spinnerSrv: SpinnerService
  ) {
    this.maxDocumentLength = environment.maxDniLength;
    this.minDocumentLength = environment.minDniLength;
  }

  ngOnInit(): void {
    this.mediaUrl = environment.mediaUrl;
    this.delegateOwnershipForm = this.formBuilder.group({
      document: [null, Validators.required],
      country: [null, Validators.required],
      documentType: [null, Validators.required]
    });
    this.getAllCountries();
    if (this.delegateOwnershipForm.controls.documentType.value === null) {
      this.delegateOwnershipForm.controls.documentType.patchValue(IdType.DNI);
    }
    if (this.delegateOwnershipForm.controls.country.value === null || this.delegateOwnershipForm.controls.country.value === 'arg') {
      this.delegateOwnershipForm.controls.country.patchValue('arg');
      this.idOptionTypes = this.idTypesArg;
    } else {
      this.idOptionTypes = this.idTypesROW;
    }

    this.documentField.valueChanges.subscribe({
      next: () => {
        this.errorCustomer = false;
      }
    });
  }

  getAllCountries() {
    this.countriesSrv.getAllCountries().subscribe((res: Array<Countries>) => {
      this.allCountries = res;
      /*this spinner is executed on event detail*/
      this.spinnerSrv.loadSpinner.next(false);
    });
  }

  get documentField(): AbstractControl {
    return this.delegateOwnershipForm.controls.document;
  }

  get documentTypeField(): AbstractControl {
    return this.delegateOwnershipForm.controls.documentType;
  }

  get countryField(): AbstractControl {
    return this.delegateOwnershipForm.controls.country;
  }

  onDropdownChange(e) {
    if (e.alpha3 === 'arg') {
      this.idOptionTypes = this.idTypesArg;
      this.delegateOwnershipForm.controls.documentType.patchValue(IdType.DNI);
    } else {
      this.idOptionTypes = this.idTypesROW;
      this.delegateOwnershipForm.controls.documentType.patchValue(IdType.ID);
    }
    this.resetId();
  }

  onDropdownOptionChange(e) {
    this.resetId();
    if (e.value === IdType.DNI) {
      this.idPattern = '[0-9]{6,20}';
      this.inputType = 'number';
    } else {
      this.idPattern = '[A-Za-z0-9]{6,20}';
      this.inputType = 'text';
    }
  }

  resetId() {
    this.delegateOwnershipForm.controls.document.patchValue('');
  }

  checkCustomerGuest() {
    this.formHasBeenSubmitted = true;
    if (this.delegateOwnershipForm.valid) {
      this.submitting = true;
      this.errorSubmit = false;
      this.errorCustomer = false;
      this.eventGuestRequest = new EventGuestRequest();
      this.eventGuestRequest.document = this.documentField.value;
      this.eventGuestRequest.documentType = this.documentTypeField.value;
      this.eventGuestRequest.country = this.countryField.value.toUpperCase();
      this.checkIfCustomerAndGuestExist(this.eventGuestRequest);
    }
  }

  checkIfCustomerAndGuestExist(eventGuestRequest: EventGuestRequest) {
    this.spinnerSrv.loadSpinner.next(true);
    this.userSrv.getUserRestrictByDocument(
      eventGuestRequest.document, eventGuestRequest.documentType, eventGuestRequest.country).subscribe({
      next: (customer: UserRestrict) => {
        this.userToAdd = customer;
        this.guestIsCustomer = true;
        this.userIsBlocked = customer.isBlocked;
        if (!customer.isBlocked) {
          this.checkIfAlreadyGuestOfEvent();
        } else {
          this.showingDataCustomerAndInvitation = true;
          this.spinnerSrv.loadSpinner.next(false);
        }
      },
      error: (err) => {
        this.submitting = false;
        if (err.status === 404) {
          this.guestIsCustomer = false;
          this.errorCustomer = true;
          this.spinnerSrv.loadSpinner.next(false);
        }
      }
    });
  }

  checkIfAlreadyGuestOfEvent() {
    this.guestAlreadyAuthorize = false;
    this.eventGuestSrv.checkGuestExistence(
      this.eventGuestRequest.document,
      this.eventGuestRequest.documentType,
      this.eventGuestRequest.country,
      this.event.idEvent).subscribe({
      next: () => {
        this.submitting = false;
        this.showingDataCustomerAndInvitation = true;
        this.spinnerSrv.loadSpinner.next(false);
      },
      error: (err) => {
        this.submitting = false;
        if (err.status === 404) {
          this.showingDataCustomerAndInvitation = true;
          this.guestAlreadyAuthorize = true;
          this.spinnerSrv.loadSpinner.next(false);
        }
      }
    });
  }

  onClickChangeOwnership() {
    this.spinnerSrv.loadSpinner.next(true);
    this.submitting = true;
    this.errorSubmit = false;
    if (this.accessId) {
      this.changeAccessOwnership();
    } else if (this.tableId) {
      this.changeTableOwnership();
    }
  }

  changeAccessOwnership() {
    const delegateOwnershipRequest = this.generateDelegateOwnershipRequest(this.accessId);
    this.guestAccessSrv.delegateOwnership(delegateOwnershipRequest).subscribe({
      next: async () => {
        this.submitting = false;
        this.spinnerSrv.loadSpinner.next(false);
        this.onHide();
        await this.router.navigate(['/']);
        this.messageSrv.add({
          summary: $localize`Evento transferido correctamente`,
          detail: this.userToAdd.name + this.userToAdd.lastName + $localize` es el nuevo owner del evento ` + this.event.eventName,
          severity: 'success'
        });
        setTimeout(() => {
          this.messageSrv.clear();
        }, 5000);
      },
      error: (err) => {
        this.submitting = false;
        this.errorSubmit = true;
        this.spinnerSrv.loadSpinner.next(false);
        this.errorAccessHandler(err.status);
      }
    });
  }

  changeTableOwnership() {
    const delegateOwnershipRequest = this.generateDelegateOwnershipRequest(this.tableId);
    this.tableGuestSrv.delegateOwnership(delegateOwnershipRequest).subscribe({
      next: async () => {
        this.submitting = false;
        this.onHide();
        this.spinnerSrv.loadSpinner.next(false);
        await this.router.navigate(['/']);
        this.messageSrv.add({
          summary: $localize`Evento transferido correctamente`,
          detail: this.userToAdd.name + this.userToAdd.lastName + $localize` es el nuevo owner del evento ` + this.event.eventName,
          severity: 'success'
        });
        setTimeout(() => {
          this.messageSrv.clear();
        }, 5000);
      },
      error: (err) => {
        this.spinnerSrv.loadSpinner.next(false);
        this.submitting = false;
        this.errorSubmit = true;
        this.errorTableHandler(err.status);
      }
    });
  }

  generateDelegateOwnershipRequest(ticketId: number): DelegateOwnershipRequest {
    const delegateOwnershipRequest = new DelegateOwnershipRequest();
    delegateOwnershipRequest.document = this.documentField.value;
    delegateOwnershipRequest.idType = this.documentTypeField.value;
    delegateOwnershipRequest.country = this.countryField.value.toUpperCase();
    delegateOwnershipRequest.ticketId = ticketId;
    return delegateOwnershipRequest;
  }

  cleanForm() {
    this.resetId();
    this.delegateOwnershipForm.controls.document.patchValue(null);
    this.delegateOwnershipForm.controls.country.patchValue('arg');
    this.delegateOwnershipForm.controls.documentType.patchValue(IdType.DNI);
  }

  cancel() {
    if (this.showingDataCustomerAndInvitation) {
      this.showingDataCustomerAndInvitation = false;
      this.userToAdd = null;
      this.guestAlreadyAuthorize = false;
      this.userIsBlocked = false;
    } else {
      this.onHide();
    }
  }

  onHide() {
    this.cleanForm();
    this.modalCtrl.dismiss();
    this.showingDataCustomerAndInvitation = false;
    this.showModal = false;
    this.guestAlreadyAuthorize = false;
    this.formHasBeenSubmitted = false;
    this.userToAdd = null;
    this.idOptionTypes = this.idTypesArg;
    this.userIsBlocked = false;
  }

  errorAccessHandler(status) {
    switch (status) {
      case 400:
        this.errorViaCode = $localize`El evento ya no es válido.`;
        break;
      case 404:
        this.errorViaCode = $localize`El ticket ya no es válido.`;
        break;
      case 403:
        this.errorViaCode =
          $localize`La persona buscada no es válida para transferirle el ticket.`;
        break;
      case 406:
        this.errorViaCode =
          $localize`No podes transferir un evento si ya ingresaste al mismo`;
        break;
      case 409:
        this.errorViaCode = $localize`La persona buscada ya posee un ticket para el evento.`;
        break;
      default:
        this.errorViaCode =
          $localize`Se ha producido un error, por favor intente luego.`;
        break;
    }
  }

  errorTableHandler(status) {
    switch (status) {
      case 400:
        this.errorViaCode = $localize`El evento ya no es válido.`;
        break;
      case 404:
        this.errorViaCode = $localize`El combo o la mesa ya no es válida.`;
        break;
      case 403:
        this.errorViaCode =
          $localize`Usuario no existente.`;
        break;
      case 406:
        this.errorViaCode =
          $localize`No podes transferir un evento si ya ingresaste al mismo`;
        break;
      case 409:
        this.errorViaCode =
          $localize`El usuario ya pertenece al evento.`;
        break;
      default:
        this.errorViaCode =
          $localize`Se ha producido un error, por favor intente luego.`;
        break;
    }
  }

}
