<div class="menu" [ngClass]="!viewMenu ? 'hide-menu' : ''">
  <div class="menu__wrapper">
    <div class="row myEvents" #myEvents (click)="goToMyEvents()">
      <div class="icon-container">
        <i class="icon fa-light fa-calendar-star text-gray-1"></i>
      </div>
      <p class="text-sm lh-16 text-gray-1 font-normal font-secondary my-0 ml-3" i18n="My events menu item">
        Mis Eventos
      </p>
    </div>
    <div class="row language" #language *ngIf="logged" (click)="openModalLanguageMobile()">
      <div class="icon-container">
        <i class="icon fa-light fa-globe text-gray-1"></i>
      </div>
      <p class="text-sm lh-16 text-gray-1 font-normal font-secondary my-0 ml-3" i18n="Change language menu item">
        Idioma
      </p>
    </div>
    <div class="row myAccount" (click)="goToProfile()" #myAccount>
      <div class="icon-container">
        <i class="icon fa-light fa-user text-gray-1"></i>
      </div>
      <p class="text-sm lh-16 text-gray-1 font-normal font-secondary my-0 ml-3" i18n="My data menu item">
        Mis datos
      </p>
    </div>
    <div class="row myAccount" (click)="goToConfigurations()" #configurationAccount>
      <div class="icon-container">
        <i class="icon fa-light fa-gear text-gray-1"></i>
      </div>
      <p class="text-sm lh-16 text-gray-1 font-normal font-secondary my-0 ml-3" i18n="Configuration menu item">
        Configuración de cuenta
      </p>
    </div>
    <div class="row ingressCode" #ingressCode (click)="openModalEventCode()">
      <div class="icon-container">
        <i class="icon fa-light fa-input-text text-gray-1"></i>
      </div>
      <p class="text-sm lh-16 text-gray-1 font-normal font-secondary my-0 ml-3" i18n="Ingress code menu item">
        Ingresar código de evento
      </p>
    </div>
    <div class="row support border-bottom" #support *ngIf="logged" (click)="contactSupport()">
      <div class="icon-container">
        <i class="icon fa-light fa-user-headset text-gray-1"></i>
      </div>
      <p class="text-sm lh-16 text-gray-1 font-normal font-secondary my-0 ml-3" i18n="Support menu item">
        Soporte Black iD
      </p>
    </div>
    <div class="row logout" #logout (click)="logOut()">
      <div class="icon-container">
        <i class="icon fa-light fa-right-from-bracket text-gray-1"></i>
      </div>
      <p class="text-sm lh-16 text-gray-1 font-normal font-secondary my-0 ml-3" i18n="Sign out menu item">
        Cerrar sesión
      </p>
    </div>
  </div>
</div>
