import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastDto } from '../models/ToastDto';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  showToast = new Subject<ToastDto>();

  constructor() { }

  generateToast(severity: string, summary: string, detail: string) {
    this.showToast.next({
      severity,
      detail,
      summary
    });
  }
}
