import { Injectable } from '@angular/core';
import { JSONStrings } from '../core/internalization/es';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() { }

  shouldShowErrors(field, formHasBeenSubmitted: boolean, page = '', customError = false, customKey = ''): Array<string> {
    if (field != null) {
      if (field.invalid && formHasBeenSubmitted) {
        return Object.keys(field.errors).map((err) => {
          return JSONStrings[page][err];
        });
      }
    } else if (customError) {
        return JSONStrings[page][customKey];
    } else {
      return [];
    }
  }
}
