import {Injectable, Input} from '@angular/core';

import {ModalActionComponent} from '../share/components/modal-action/modal-action.component';
import {ModalController} from '../controller/modal-controller';
import {ModalDownloadAppComponent} from '../share/components/modal-download-app/modal-download-app.component';
import {ModalNotSellingWebComponent} from '../share/components/modal-not-selling-web/modal-not-selling-web.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(
    private modalCtrl: ModalController,
  ) {
  }

  async alertMailValidationIsNotAvailable() {
    let modal = this.modalCtrl.create({
      component: ModalActionComponent,
      props: {
        imageName: 'icon-warning.svg',
        title: $localize`Error`,
        content: $localize`El mail ingresado no puede ser validado en este momento. Por favor intente en unos momentos.`,
        textBtn: $localize`Cerrar`
      }
    });
    modal.present();
    return await modal.onDidDismiss();
  }

  async invalidCredentials() {
    const modal = await this.modalCtrl.create({
      component: ModalActionComponent,
      props: {
        icon: 'icon-warning.svg',
        title: $localize`Error`,
        content: $localize`El usuario o la contraseña son incorrectas.`,
        textBtn: $localize`Intentar nuevamente`
      },
    });
    await modal.present();
    return modal;
  }

  async modalDownloadApp(title, content, twoBtns, contentBtn, isForDownload, linkToNavigate, showModal) {
    const modal = await this.modalCtrl.create({
      component: ModalDownloadAppComponent,
      props: {
        title: title,
        content: content,
        twoBtns: twoBtns,
        contentBtn: contentBtn,
        isForDownload: isForDownload,
        linkToNavigate: linkToNavigate,
        showModal: showModal
      }
    });
    await modal.present();
    return modal;
  }

  async modalStoreNotAvailableSellingForWeb(title, content, iconClass, showModal) {
    const modal = await this.modalCtrl.create({
      component: ModalNotSellingWebComponent,
      props: {
        showModal: showModal,
        title: title,
        content: content,
        iconClass: iconClass
      }
    });
    await modal.present();
    return modal;
  }
}
