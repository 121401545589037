import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';

import {PromotionService} from 'src/app/services/promotion.service';
import {Subscription} from 'rxjs';
import {ValidationCodeResult} from 'src/app/enum/ValidationCodeResult';

@Component({
  selector: 'app-promotional-code',
  templateUrl: './promotional-code.component.html',
  styleUrls: ['./promotional-code.component.scss']
})
export class PromotionalCodeComponent implements OnInit, OnDestroy {

  @Input() storeId: number;
  @Input() buyType: string;
  @Input() eventId: number;
  @Input() codePromo: string = null;
  @Input() formHasBeenSubmitted: boolean;
  @ViewChild('code') code: ElementRef;
  @Output() promoEmit = new EventEmitter();
  codeString: string;
  validCode = false;
  loadCode = false;
  invalidCode = false;
  promoCode = ''; // INPUT
  validating = false;
  subs = [];
  validationCodeResult = ValidationCodeResult.none;
  promoDiscount: number;
  validationErrorResult = false;
  promoName = null;

  constructor(
    private promotionSrv: PromotionService
  ) {
  }

  ngOnInit(): void {
    if (this.codePromo !== null && this.codePromo !== '') {
      this.promoCode = this.codePromo;
      this.validateRRPPCode();
    }
  }

  validateRRPPCode() {
    this.validating = true;
    const eventId = this.buyType === 'A' || this.buyType === 'T' ? this.eventId : null;
    let type;
    if (this.buyType === 'A') {
      type = 'ACCESS';
    } else if (this.buyType === 'T') {
      type = 'TABLE';
    }
    this.subs.push(
      this.promotionSrv.getViaCode(this.storeId, this.promoCode, type, eventId).subscribe({
        next: res => {
          this.validating = false;
          this.validationCodeResult = ValidationCodeResult.valid;
          if (this.validationCodeResult === 1) {
            this.validationErrorResult = false;
          }
          this.promoDiscount = res.discount;
          this.promoName = res.name;
          this.promoEmit.emit(res);
        },
        error: () => {
          sessionStorage.removeItem('promotionPersist');
          this.validating = false;
          this.validationCodeResult = ValidationCodeResult.invalid;
          if (this.validationCodeResult === 2) {
            this.validationErrorResult = true;
          }
          this.promoDiscount = null;
          this.promoEmit.emit(null);
        }
      })
    );
  }

  cleanValidationResult() {
    this.validationCodeResult = ValidationCodeResult.none;
    this.promoDiscount = null;
    this.validationErrorResult = false;
    this.promoEmit.emit(null);
  }

  ngOnDestroy(): void {
    this.subs.forEach((res: Subscription) => {
      res.unsubscribe();
    });
  }

  handleDataCode(event) {
    this.cleanValidationResult();
    this.promoCode = event;
  }
}
