<div class="event-grid__container" *ngIf="allCategoriesEvents || !loading">
  <section *ngFor="let category of allCategoriesEvents" class="event-grid">
    <div class="wrapper">
      <div class="event-grid__header">
        <p class="font-secondary text-lg lh-24 text-gray-2 font-normal my-0" *ngIf="!loading">{{category.name}}</p>
        <div class="skeleton__header" *ngIf="loading"></div>
      </div>
      <div class="grid__events">
        <div class="grid-item" *ngFor="let event of category.eventForCustomerList">
          <app-card-event [event]="event" [loading]="loading"></app-card-event>
        </div>
      </div>
    </div>
  </section>
</div>

<div class="event-grid__container" *ngIf="!allCategoriesEvents || loading">
  <section class="event-grid">
    <div class="wrapper">
      <div class="event-grid__header">
        <div class="skeleton__header" *ngIf="loading"></div>
      </div>
      <div class="grid__events">
        <app-card-event [loading]="loading"></app-card-event>
        <app-card-event [loading]="loading"></app-card-event>
        <app-card-event [loading]="loading"></app-card-event>
        <app-card-event [loading]="loading"></app-card-event>
        <app-card-event [loading]="loading"></app-card-event>
      </div>
    </div>
  </section>
  <section class="event-grid">
    <div class="wrapper">
      <div class="event-grid__header">
        <div class="skeleton__header" *ngIf="loading"></div>
      </div>
      <div class="grid__events">
        <app-card-event [loading]="loading"></app-card-event>
        <app-card-event [loading]="loading"></app-card-event>
        <app-card-event [loading]="loading"></app-card-event>
        <app-card-event [loading]="loading"></app-card-event>
        <app-card-event [loading]="loading"></app-card-event>
        <app-card-event [loading]="loading"></app-card-event>
        <app-card-event [loading]="loading"></app-card-event>
      </div>
    </div>
  </section>

</div>
