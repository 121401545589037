<div class="global-container background-home" data-aos="fade-in">
  <div *ngIf="!resendEmail" class="form-container p-grid p-p-4 p-shadow-5">
    <span class="text-content">{{textHeader}}</span>
  </div>
  <div *ngIf="resendEmail" class="form-container p-grid p-p-4 p-shadow-5 info">
    <i class="fal fa-info-circle info-icon"></i>
    <h2 class="info-heading" i18n>El link de validación expiró</h2>
    <span class="info-text">{{textHeader}}</span>
    <button class="btn btn__md btn__primary resend" type="button" (click)="reSendToken()" i18n>Volver a solicitar link</button>
  </div>
</div>
