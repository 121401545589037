<div class="card__event" *ngIf="!loading" (click)="eventDetail(event)">
  <div class="card__event--image">
    <img src="{{ mediaUrl + event.image }}" loading="lazy" class="image__event" *ngIf="!loading" alt=""/>
    <div class="skeleton__image" *ngIf="loading"></div>
  </div>
  <div class="card__event--info">
    <div class="event__producer" *ngIf="!loading">
      <p
        class="name-producer text-hypens ellipsis-one-line break-word">{{ event.storeDataOnEvent?.name | uppercase }}</p>
    </div>
    <div class="event__name" *ngIf="!loading">
      <p class="name-event text-hypens ellipsis-two-lines break-word">
        {{ event.eventName }}
      </p>
    </div>
    <div class="w-full" *ngIf="loading">
      <div class="skeleton__title--large"></div>
      <div class="skeleton__title--small mt-2"></div>
    </div>
    <div class="event__info">
      <div class="info__date" *ngIf="!loading">
        <i class="fa-light fa-calendar-lines text-primary fa-icon-info"></i>
        <p class="p-info my-0">
          {{ event.dateInit | date: dateFormat | uppercase }}
          '{{ event.dateInit | date: timeFormat | uppercase }} hs
        </p>
      </div>
      <div class="w-full" *ngIf="loading">
        <div class="skeleton__description--large"></div>
        <div class="skeleton__description--small mt-2"></div>
      </div>
      <div class="info__date mt-2" *ngIf="!access && !loading">
        <i class="fa-light fa-ticket text-primary fa-icon-info"></i>
        <p class="p-info my-0" i18n>Disponibles en puerta</p>
      </div>
      <div class="info__date mt-2"
           *ngIf="event.minorPrice && !event.hasAuthorizedAccess && !event.soldOut && !loading">
        <i class="fa-light fa-ticket text-primary fa-icon-info"></i>
        <p class="p-info my-0">
          <ng-container i18n>Desde</ng-container>
          {{currency}}{{ event.minorPrice | number: "1.0-0" }}
        </p>
      </div>
      <div class="info__date mt-2"
           *ngIf="!event.minorPrice && !event?.soldOut && !event.hasAuthorizedAccess && !loading">
        <i class="fa-light fa-ticket fa-icon-info text-primary"></i>
        <p class="p-info ml-10 my-0" i18n>A confirmar</p>
      </div>
      <div class="authorize__access mt-2" *ngIf="event.hasAuthorizedAccess && !loading">
        <span i18n>ingreso autorizado</span>
      </div>
      <div class="sold__out-bottom mt-2" *ngIf="event?.soldOut && !event.hasAuthorizedAccess && !loading">
        <span i18n>SOLD OUT</span>
      </div>
    </div>
  </div>
</div>
<div class="card__event noHover" *ngIf="loading">
  <div class="card__event--image">
    <div class="skeleton__image" *ngIf="loading"></div>
  </div>
  <div class="card__event--info">
    <div class="w-full" *ngIf="loading">
      <div class="skeleton__title--large"></div>
      <div class="skeleton__title--small mt-2"></div>
    </div>
    <div class="event__info">
      <div class="w-full" *ngIf="loading">
        <div class="skeleton__description--large"></div>
        <div class="skeleton__description--small mt-2"></div>
      </div>
    </div>
  </div>
</div>

