import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MentaService} from '../../../../../services/menta.service';
import {ResaleLinkResponse} from '../../../../../models/events/ResaleLinkResponse';
import {environment} from '../../../../../../environments/environment';

@Component({
  selector: 'app-modal-resale',
  templateUrl: './modal-resale.component.html',
  styleUrls: ['./modal-resale.component.scss']
})
export class ModalResaleComponent implements OnInit {
  showModal = true;
  @Input() ticketUUID: string;
  @Input() type: string;
  @Input() eventId: number;
  @Output() closeModal = new EventEmitter<boolean>();
  btnContinueMobile = $localize`Continuar`;
  btnContinueDesktop = $localize`Continuar a reventa`;
  btnCancel = $localize`Cancelar`;

  constructor(
    private mentaSrv: MentaService
  ) {
  }

  ngOnInit(): void {
  }

  cancel() {
    this.closeModal.emit(true);
  }

  onHide() {
    this.closeModal.emit(true);
  }

  onContinueResale() {
    const type = this.type === 'A' ? 'T' : 'M';
    this.mentaSrv.publishTicketOnResale(this.ticketUUID, type).subscribe({
      next: (res: ResaleLinkResponse) => {
        const returnUrl = environment.brandParams.logo.urlLink + '/my-events/event-detail/' + this.eventId;
        window.location.href = res.linkToPublishTicket + '&returnUrl=' + returnUrl;
      }
    });
    this.closeModal.emit(true);
  }


}
