<p-dialog [(visible)]="showModal" (onHide)="onHide()" [draggable]="false" [resizable]="false" [modal]="true"
          class="bid-modal">
  <ng-template pTemplate="header" class="bid-modal__header">
    <div class="filling-space"></div>
    <div class="flex flex-col justify-content-center align-items-center">
      <p class="bid-modal__header--headtitle" i18n>Transferir evento</p>
    </div>
  </ng-template>

  <div class="bid-modal__content" *ngIf="!showingDataCustomerAndInvitation || !guestIsCustomer">
    <div class="bid-modal__header--title">
      <div>
        <div class="margin-bottom-12" i18n>Al transferir este evento:</div>
        <div class="margin-bottom-12" i18n>- Se eliminará de tu listado de ”Mis Eventos” y ya no podrás ingresar con tu ID.</div>
        <div class="margin-bottom-12" i18n>- Perderás el control sobre el/los tickets que estás transfieriendo, ya que pasarán a ser propiedad de la persona que los recibe.</div>
        <div class="margin-bottom-24" i18n>- No podrás solicitar reembolsos sobre esta compra.</div>
      </div>
    </div>
    <form [formGroup]="delegateOwnershipForm">
      <div class="field__group">
        <bid-infinite-scroll
          *ngIf="allCountries && allCountries.length > 0"
          [parentForm]="delegateOwnershipForm"
          [formControlName]="'country'"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [items]="allCountries"
          [page]="'modalDelegateOwnership'"
          [placeholder]="placeHolderCountry"
          [bindLabel]="'es'"
          [bindValue]="'alpha3'"
          (dropdownChanges)="onDropdownChange($event)"
        ></bid-infinite-scroll>

        <app-bid-input-dni
          [type]="inputType"
          [min]="minDocumentLength"
          [max]="maxDocumentLength"
          [parentForm]="delegateOwnershipForm"
          [page]="'modalDelegateOwnership'"
          [dropdownControlName]="'documentType'"
          [dropdownOptions]="idOptionTypes"
          [dropdownOptionLabel]="'name'"
          [dropdownOptionValue]="'value'"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [inputControlName]="'document'"
          [largeMobile]="true"
          [bgLightDesktop]="true"
          [pattern]="idPattern"
          (dropdownChange)="onDropdownOptionChange($event)"
        >
        </app-bid-input-dni>
        <div class="field__item--info mt-2">
          <span i18n *ngIf="!errorCustomer && !errorSubmit">
            Para transferir un evento, la persona deberá estar registrado en Black iD. Al hacerlo, los invitados deberán volver a ser asignados.
          </span>
          <span class="text-error" i18n *ngIf="errorCustomer && !errorSubmit">
            No existe usuario registrado con el documento ingresado.
          </span>
          <span class="text-error" *ngIf="!errorCustomer && errorSubmit">
            {{errorViaCode}}
          </span>
        </div>
      </div>
    </form>
  </div>

  <div class="bid-modal__content" *ngIf="showingDataCustomerAndInvitation && guestIsCustomer">
    <div *ngIf="!guestAlreadyAuthorize && !userIsBlocked">
      <div class="container__ticket-data" *ngIf="!guestAlreadyAuthorize && !userIsBlocked">
        <div class="mb-4">
          <p class="text-lg lh-18 text-gray-1 font-light font-secondary my-0">
            <i class="fas fa-champagne-glasses text-gray-3 text-sm lh-16 mr-2" *ngIf="typeInvitation === 'T'"></i>
            <i class="fas fa-ticket text-gray-3 text-sm lh-16 mr-2" *ngIf="typeInvitation === 'A'"></i>
            <span *ngIf="typeInvitation === 'T'" i18n>Combo/Mesa </span>
            <span *ngIf="typeInvitation === 'A'" i18n>Ticket </span>
            <span class="text-primary font-weight-600" *ngIf="typeInvitation === 'T'"> {{tableData.name}}</span>
            <span class="text-primary font-weight-600" *ngIf="typeInvitation === 'A'"> {{accessData.name}}</span>
          </p>
        </div>

        <div class="display-flex flex-direction-column">
          <div class="w-full" *ngIf="tableData !== null || accessData !== null">
            <div class="display-flex align-items-start mb-2" *ngIf="typeInvitation === 'T' && tableData?.tableType">
              <i class="fa-light fa-champagne-glasses text-primary text-xs lh-16"></i>
              <p class="text-sm lh-16 my-0 text-gray-3 ml-2">
                {{tableData ? tableData?.tableType : accessData.accessType}}
              </p>
            </div>
            <div class="display-flex align-items-start mb-2" *ngIf="typeInvitation === 'T' && tableData?.capacity > 0">
              <i class="fa-light fa-people-pants-simple text-primary text-xs lh-16"></i>
              <p class="text-sm lh-16 my-0 text-gray-3 ml-2">{{tableData?.capacity}}
                <ng-container>personas</ng-container>
              </p>
            </div>
            <div class="display-flex align-items-start mb-2" *ngIf="typeInvitation === 'A' && accessData?.quantity > 0">
              <i class="fa-light fa-people-pants-simple text-primary text-xs lh-16"></i>
              <p class="text-sm lh-16 my-0 text-gray-3 ml-2">{{accessData?.quantity}}
                <ng-container>personas</ng-container>
              </p>
            </div>
            <div class="display-flex align-items-start mb-2"
                 *ngIf="typeInvitation === 'T' && tableData?.consumerCredit > 0">
              <i class="fa-light fa-circle-dollar-to-slot text-primary text-xs lh-16"></i>
              <p class="text-sm lh-16 my-0 text-gray-3 ml-2">
                <ng-container>Crédito de consumo:</ng-container>
                {{currency}}{{tableData?.consumerCredit | number:
                '1.0-2'}}</p>
            </div>
            <div *ngIf="typeInvitation === 'T'">
              <div class="display-flex align-items-center mb-2" *ngFor="let description of tableData?.descriptions">
                <i class="fa-light fa-circle-check text-primary mr-2"></i>
                <p class="text-sm text-gray-3 font-light my-0 lh-16 vertical-middle">{{description}}</p>
              </div>
            </div>
            <div *ngIf="typeInvitation === 'A'">
              <div class="display-flex align-items-center mb-2" *ngFor="let description of accessData?.descriptions">
                <i class="fa-light fa-circle-check text-primary mr-2"></i>
                <p class="text-sm text-gray-3 font-light my-0 lh-16 vertical-middle">{{description}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 mb-5 text-center">
        <i class="fal fa-arrow-down-long text-primary text-3xl lh-24"></i>
      </div>
      <div class="container__user-data" *ngIf="guestIsCustomer && !userIsBlocked">
        <div>
          <div class="avatar__profile" *ngIf="userToAdd.image else placeholder">
            <img src="{{ mediaUrl + userToAdd.image }}" alt=""/>
          </div>
          <ng-template #placeholder>
            <div class="icon-placeholder">
              <i class="fa-light fa-user text-3xl text-gray-4"></i>
            </div>
          </ng-template>
        </div>
        <div class="flex flex-col ml-4">
          <p class="my-0 text-gray-1 text-base font-secondary lh-16 font-weight-600">
            {{userToAdd?.name}} {{userToAdd.lastName}}
          </p>
          <span
            class="text-gray-3 text-sm lh-16 font-weight-400 mt-2">{{userToAdd?.document}}</span>
          <span
            class="text-gray-3 text-sm lh-16 font-weight-400 mt-2 break-word">{{userToAdd?.email}}</span>
        </div>
      </div>
    </div>
    <div class="message__guest_authorized" *ngIf="userIsBlocked">
      <i class="fal fa-info-circle text-info text-12xl mb-8"></i>
      <p class="mb-4 text-xl lh-26 text-gray-1 font-secondary font-weight-600" i18n>
        La cuenta del usuario al que quieres transferir se encuentra bloqueada.
      </p>
    </div>
    <div class="message__guest_authorized" *ngIf="guestAlreadyAuthorize">
      <i class="fal fa-info-circle text-info text-12xl mb-8"></i>
      <p class="mb-4 text-xl lh-26 text-gray-1 font-secondary font-weight-600">
              <span *ngIf="guestIsCustomer">
                {{userToAdd.name + ' ' + userToAdd.lastName }}
                <ng-container i18n>ya forma parte de este evento.</ng-container>
              </span>
        <span *ngIf="!guestIsCustomer">
                {{eventGuestRequest.document }}
          <ng-container i18n>ya forma parte de este evento.</ng-container>
              </span>
      </p>
    </div>
    <div class="message__guest_authorized" *ngIf="!guestIsCustomer">
      <i class="fal fa-info-circle text-info text-12xl mb-8"></i>
      <p class="mb-4 text-xl lh-26 text-gray-1 font-secondary font-weight-600">
        <span>
          <ng-container i18n>No existe usuario registrado con el documento ingresado.</ng-container>
          {{eventGuestRequest.document}}
        </span>
      </p>
    </div>
  </div>

  <ng-template pTemplate="footer" class="bid-modal__footer">
    <button
      pButton
      class="btn btn__secondary"
      (click)="cancel()"
      label="{{showingDataCustomerAndInvitation ? btnBack : btnCancel }}"
    ></button>
    <button
      pButton
      class="btn btn__primary"
      (click)="checkCustomerGuest()"
      label="{{btnContinue}}"
      *ngIf="!showingDataCustomerAndInvitation"
    ></button>
    <button
      pButton
      class="btn btn__primary"
      [disabled]="(delegateOwnershipForm.invalid && formHasBeenSubmitted) || submitting"
      (click)="onClickChangeOwnership()"
      label="{{btnTransferEvent}}"
      *ngIf="(showingDataCustomerAndInvitation && !guestAlreadyAuthorize && guestIsCustomer) && !userIsBlocked"
    ></button>
    <button
      pButton
      class="btn btn__primary"
      (click)="onHide()"
      label="{{btnReady}}"
      *ngIf="(showingDataCustomerAndInvitation && guestAlreadyAuthorize) || userIsBlocked"
    ></button>
  </ng-template>

</p-dialog>
