import {loadTranslations} from '@angular/localize';

const fallbackLocale = 'en';
const isLocaleSet = () => {
  return localStorage.getItem('current_locale') ? true : false;
};

export const setLocale = (locale: string) => {
  localStorage.setItem('current_locale', locale);
  location.reload();
};

export interface Locale {
  name: string;
  label: string;
}

export const supportedLocales: Locale[] = [
  {
    name: 'es',
    label: 'Español'
  },
  {
    name: 'en',
    label: 'English'
  }
];

export var currentLocale = localStorage.getItem('current_locale') ? localStorage.getItem('current_locale') : 'es';

export const i18nInit = async () => {
  if (!isLocaleSet()) {
    let browserLang = navigator.language.split('-')[0];
    let locale = supportedLocales.find(l => l.name = browserLang);

    if (locale) {
      setLocale(browserLang);
    } else {
      setLocale(fallbackLocale);
    }
  }
  const localeTranslationsModule = await import(`src/locale/${currentLocale}/messages.json`);
  loadTranslations(localeTranslationsModule.default.translations);
};
